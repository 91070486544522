
export class UserAccountDetailsModel {
  public companyTypes?: string[] = [];
  public industryTypes?: string[] = [];
  public roleTypes?: string[] = [];
  public marketingSources?: string[] = [];
constructor(obj = {} as any) {
  obj = obj || {};
  this.companyTypes = obj.companyTypes || [];
  this.industryTypes = obj.industryTypes || [];
  this.roleTypes = obj.roleTypes || [];
  this.marketingSources = obj.marketingSources || [];
}

}
