import { AccuracyAnalysisResultModel } from './AccuracyAnalysisResultModel';

export class ComplexAccuracyAnalysisResultModel {
  public values: AccuracyAnalysisResultModel[] = [];
  public difference: number | string = "";
  public differenceCount: number | string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.values = obj.values?.filter((item: any) => item !== undefined).map((item: any) => new AccuracyAnalysisResultModel(item)) || [];
  this.difference = obj.difference === null? "" : obj.difference;
  this.differenceCount = obj.differenceCount === null? "" : obj.differenceCount;
}

}
