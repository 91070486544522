import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { Icon, ICONS } from "components/icon/icon"
import { Input } from "components/form-components/input/input"
import { CustomTag } from "./custom-tag/custom-tag"
import { TagsCheckBox } from "./tags-checkbox/tags-checkbox"
import { TagType } from "./tags-modal"
import { Button } from "components/button/button"

type TagsModalBodyProps = {
  systemTags: TagType[]
  setSystemTags: (systemTags: TagType[]) => void

  customTags: TagType[]
  setCustomTags: (systemTags: TagType[]) => void

  selectedProducts: any[]
  itemId: string | null
  usedInAuditCreation: boolean
}

export const TagsModalBody = ({
  systemTags,
  setSystemTags,
  itemId,
  customTags,
  setCustomTags,
  selectedProducts,
  usedInAuditCreation,
}: TagsModalBodyProps) => {
  const ref = useRef(null)
  const dispatch: RootDispatch = useDispatch()
  const isViewer = useSelector(
    (state: RootState) => state.workspaces.isSelectedWorkspaceViewer,
  )

  const isRenamingTag = useSelector(
    (state: RootState) => state.tags.isRenamingTag.renaming,
  )
  const isRenamingTagIndex = useSelector(
    (state: RootState) => state.tags.isRenamingTag.index,
  )

  const schema = yup.object({
    newTag: yup.string().trim().max(120).required(),
  })

  const methods = useForm({
    resolver: yupResolver(schema),
  })

  const inputVisible = isRenamingTag && isRenamingTagIndex === undefined
  const selectedProdNo = selectedProducts?.length || 0

  const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      methods.handleSubmit(addNewTag)()
    }
  }

  const addNewTag = (tag: any) => {
    dispatch.tags.setIsRenamingTag({ renaming: false })
    const newTag = {
      name: tag.newTag,
      checked: 0,
      intermediate: false,
    }
    setCustomTags([...customTags, newTag])
    dispatch.tags.setSaveActive(true)
    methods.reset()
  }

  const handleAddNewTag = () => {
    dispatch.tags.setIsRenamingTag({ renaming: true })
    dispatch.tags.setSaveActive(false)
  }

  const cancelTagCreation = () => {
    methods.reset()
    dispatch.tags.setIsRenamingTag({ renaming: false })
  }

  const shouldDisable =
    isRenamingTag ||
    (!usedInAuditCreation && !itemId && !selectedProdNo) ||
    inputVisible ||
    isViewer

  const shouldDisableAdding = isRenamingTag || isViewer

  const onSystemTagCheck = (index: number, intermediate: boolean) => {
    const newSystemTags = systemTags.map((tag, tagIndex) => {
      if (tagIndex === index) {
        return {
          ...tag,
          checked:
            selectedProdNo < 2 || !intermediate
              ? !tag.checked
                ? 2
                : 0
              : (tag.checked + 1) % 3,
        }
      } else {
        return tag
      }
    })
    setSystemTags(newSystemTags)
    dispatch.tags.setSaveActive(true)
  }

  const scrollToBottom = () => {
    // @ts-expect-error type never
    ref.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    dispatch.tags.setIsRenamingTag({ renaming: false })

    // use timeout to make sure that custom tags are scrolled after loading
    const timeout = setTimeout(scrollToBottom, 500)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className="tags-modal-body">
      <div>
        <div className="tags-list-header">System Tags</div>
        <div className="tag-list">
          {systemTags.map((tag: TagType, index: any) => (
            <div key={index} className="ml-3 my-2.5 w-auto">
              <TagsCheckBox
                key={`${index}`}
                disabled={shouldDisable}
                className="text-gray-500 font-semibold "
                label={tag.name + ""}
                onChange={() => {
                  onSystemTagCheck(index, tag.intermediate)
                }}
                value={tag.checked}
              />
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className="tags-list-header">Custom Tags</div>
        <div className="tag-list">
          {customTags.map((tag, index) => (
            <div key={index} className="ml-3 my-2.5 w-auto">
              <CustomTag
                index={index}
                key={`${index}`}
                name={tag.name}
                checked={tag.checked}
                customTags={customTags}
                usedInAuditCreation={usedInAuditCreation}
                itemId={itemId}
                setCustomTags={setCustomTags}
                selectedProdNo={selectedProdNo}
                intermediate={tag.intermediate}
                isViewer={isViewer}
              />
            </div>
          ))}

          {inputVisible && (
            <FormProvider {...methods}>
              <div className="new-tag-input-container">
                <Input
                  required
                  className="add-new-tag-input new-tag-input"
                  autoComplete={"off"}
                  autofocus
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                    onEnter(event)
                  }
                  {...methods.register("newTag")}
                />
                <div className="new-tag-btn-div">
                  <button
                    onClick={methods.handleSubmit(addNewTag)}
                    disabled={
                      methods.watch("newTag") &&
                      methods.watch("newTag")?.trim().length < 1
                    }
                  >
                    <Icon
                      icon={ICONS.CHECK}
                      className={`${
                        methods.watch("newTag") &&
                        methods.watch("newTag")?.trim().length > 0
                          ? "text-green-500"
                          : "text-gray-300"
                      } mt-0.5`}
                    />
                  </button>
                  <button onClick={cancelTagCreation}>
                    <Icon icon={ICONS.CANCEL} className="text-red-500 mt-0.5" />
                  </button>
                </div>
              </div>
            </FormProvider>
          )}

          <Button
            variant="link"
            className="secondary"
            onClick={handleAddNewTag}
            disabled={shouldDisableAdding}
          >
            <Icon icon={ICONS.PLUS_CIRCLE} />
            <span ref={ref}>Add Custom Tag</span>
          </Button>
        </div>
      </div>
    </div>
  )
}
