import { FC } from "react"
import "./shelf-charts.scss"
import { connect } from "react-redux"
import { RootDispatch, RootState } from "store"
import { Bar, BarChart, CartesianGrid, Cell, Tooltip, XAxis, YAxis } from "recharts"
import { Card } from "components/card/card"
import { blue, fuchsia, violet, yellow } from "utils/colors"

type Props = ReturnType<typeof mapProps> & ReturnType<typeof mapDispatch>

const ShelfChartsComponent: FC<Props> = ({ shareOfShelf }: Props) => {
  const brandsCount: any = {}
  const colors = [fuchsia[500], violet[600], blue[500], yellow[500]]

  const generateChartData = (brandsCount: { [brand: string]: number }, total: number) => {
    const sortedBrands = Object.entries(brandsCount).sort((x: any, y: any) => {
      return y[1] - x[1]
    })

    const chartData = sortedBrands.slice(0, 7).map((brand) => {
      return {
        label: brand[0],
        value: ((brand[1] / total) * 100).toFixed(1).replace(".0", ""),
      }
    })

    const otherBrands = sortedBrands.slice(7)
    if (otherBrands.length) {
      const value = otherBrands.reduce((x: any, y: any) => x + y[1], 0)
      chartData.push({
        label: "Other",
        value: ((value / total) * 100).toFixed(1).replace(".0", ""),
      })
    }

    return chartData
  }

  const countBrands = (element: any) => {
    const brand = element?.brand || undefined
    if (!brandsCount[brand]) {
      brandsCount[brand] = 0
    }
    brandsCount[brand] += 1
  }

  const filteredShareOfShelf = shareOfShelf.results.filter((x: any) => !x.isFeatured)

  filteredShareOfShelf.slice(0, 5).forEach(countBrands)
  const topFiveChart = generateChartData(brandsCount, 5)

  filteredShareOfShelf.slice(5, 10).forEach(countBrands)
  const topTenChart = generateChartData(brandsCount, 10)

  filteredShareOfShelf.slice(10).forEach(countBrands)
  const firstPageChart = generateChartData(brandsCount, filteredShareOfShelf.length)

  const ChartComponent = ({ chartData, chartTitle }: any) => (
    <Card className="chart">
      <span className="text-title-2 font-semibold text-gray-500">{chartTitle}</span>
      <span className="text-title-2 font-extralight text-gray-500 mb-4">
        Share-of-Search
      </span>
      <BarChart
        layout="vertical"
        width={400}
        height={300}
        data={chartData}
        className="justify-self-center mr-14"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          domain={[0, Math.max(...chartData.map((x: any) => Number(x.value))) + 5]}
        />
        <YAxis type="category" dataKey="label" fontSize={15} width={100} />
        <Tooltip />
        <Bar dataKey="value" radius={[0, 5, 5, 0]}>
          {chartData.map((entry: any, index: any) => (
            <Cell key={index} fill={colors[index % 4]} />
          ))}
        </Bar>
      </BarChart>
    </Card>
  )

  return (
    <div className="charts-container">
      <ChartComponent chartData={firstPageChart} chartTitle="Page 1" />
      <ChartComponent chartData={topTenChart} chartTitle="Top 10" />
      <ChartComponent chartData={topFiveChart} chartTitle="Top 5" />
    </div>
  )
}

const mapProps = (state: RootState) => ({
  shareOfShelf: state.shareOfShelf,
})

const mapDispatch = (dispatch: RootDispatch) => ({
  fetchShareOfShelfData: dispatch.shareOfShelf.fetchShareOfShelfData,
})

export const ShelfCharts = connect(mapProps, mapDispatch)(ShelfChartsComponent)
