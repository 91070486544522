import { init, RematchRootState, RematchDispatch, Models } from "@rematch/core"
import loadingPlugin, { ExtraModelsFromLoading } from "@rematch/loading"
import auditCatalog from "./audit-catalog"
import account from "./account"
import dashboard from "./dashboard"
import audits from "./audits"
import grading from "./grading"
import productView from "./product-view"
import shareOfShelf from "./share-of-shelf/share-of-shelf"
import workspaces from "./workspaces"
import retailers from "./retailers"
import reports from "./reports"
import alerts from "./alerts"
import billing from "./billing"
import tags from "./tags"
import exports from "./exports"
import plans from "./plans"
import notifications from "./notifications"
import errorPath from "./error-path"
import uploadProductData from "./upload-product-data"
import tippyTarget from "./tippy-target"

interface RootModel extends Models<RootModel> {
  auditCatalog: typeof auditCatalog
  account: typeof account
  dashboard: typeof dashboard
  audits: typeof audits
  grading: typeof grading
  productView: typeof productView
  shareOfShelf: typeof shareOfShelf
  workspaces: typeof workspaces
  retailers: typeof retailers
  reports: typeof reports
  alerts: typeof alerts
  billing: typeof billing
  tags: typeof tags
  exports: typeof exports
  plans: typeof plans
  notifications: typeof notifications
  errorPath: typeof errorPath
  uploadProductData: typeof uploadProductData
  tippyTarget: typeof tippyTarget
}

type FullModel = ExtraModelsFromLoading<RootModel>

const models = {
  auditCatalog,
  account,
  dashboard,
  audits,
  grading,
  productView,
  shareOfShelf,
  workspaces,
  retailers,
  reports,
  alerts,
  billing,
  tags,
  exports,
  plans,
  notifications,
  errorPath,
  uploadProductData,
  tippyTarget,
} as RootModel

const store = init<RootModel, FullModel>({
  models,
  plugins: [loadingPlugin()],
  redux: {
    rootReducers: {
      RESET_WORKSPACE_DATA: (state: RootState) => {
        const { workspaces, account } = state
        state = { ...state, workspaces, account }
      },
    },
  },
} as any)

export default store
export type RootDispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel, FullModel>
export type RState = RematchRootState<RootModel>
