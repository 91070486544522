
export class ItemSnapshotBrandStoreModel {
  public name?: string = "";
  public link?: string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.name = obj.name === null? "" : obj.name;
  this.link = obj.link === null? "" : obj.link;
}

}
