import { Select } from "../form-components/select/select"
import { Button } from "../button/button"
import { Icon, ICONS } from "../icon/icon"
import "./table-pagination.scss"

type onChangeProps = {
  page: number
  pageSize: number
}

export type TablePaginationProps = {
  page?: any
  pageSize?: any
  totalCount?: number
  onPageChange: ({ page, pageSize }: onChangeProps) => void
  onPageSizeChange: ({ page, pageSize }: onChangeProps) => void
  pageSizeOptions?: any
  className?: string
  scrollableDivId?: string
  loading?: boolean
}

export const TablePagination = ({
  page = 1,
  onPageChange,
  pageSize = 50,
  onPageSizeChange,
  pageSizeOptions,
  totalCount = 0,
  className,
  scrollableDivId = "",
  loading,
}: TablePaginationProps) => {
  if (totalCount === 0) {
    return null
  }

  const hasPrevPage = page * pageSize > pageSize
  const hasNextPage = page * pageSize < totalCount

  const scrollToTop = () => {
    const gridDiv = document.getElementById(scrollableDivId)
    if (gridDiv) {
      gridDiv.scrollTop = 0
    }
  }

  const handlePageChange = ({ page, pageSize }: onChangeProps) => {
    onPageChange({ page, pageSize })
    scrollToTop()
  }

  const onPrevPage = () => {
    handlePageChange({ page: page - 1, pageSize })
  }
  const onNextPage = () => {
    handlePageChange({ page: page + 1, pageSize })
  }
  const onLastPage = () => {
    const lastPage = Math.ceil(totalCount / pageSize)

    handlePageChange({ page: lastPage, pageSize })
  }

  return (
    <div
      className={`flex justify-end items-center mt-5 mb-5 ${className ? className : ""}`}
    >
      <div className="mr-3 font-normal text-gray-500 text-body">Rows per page:</div>
      <Select
        options={pageSizeOptions}
        onChange={({ value }) => {
          onPageSizeChange({ pageSize: value, page: 1 })
        }}
        value={pageSize}
        menuPlacement="top"
        disabled={loading}
        className="pagination-dropdown"
      />
      <div className="mr-2 ml-2 min-w-14 text-center font-normal text-gray-500 text-body">
        {`${(page - 1) * pageSize + 1}-${
          page * pageSize > totalCount ? totalCount : page * pageSize
        } of ${totalCount}`}
      </div>
      <Button
        variant="outlined"
        onClick={() => handlePageChange({ page: 1, pageSize })}
        disabled={!hasPrevPage}
        style={{ padding: 0 }}
        className="ml-2 w-10 max-h-8"
      >
        <Icon icon={ICONS.CHEVRON_LEFT_FIRST} />
      </Button>
      <Button
        variant="outlined"
        onClick={onPrevPage}
        disabled={!hasPrevPage}
        style={{ padding: 0 }}
        className="ml-2 w-10 max-h-8"
      >
        <Icon icon={ICONS.CHEVRON_LEFT} />
      </Button>
      <Button
        variant="outlined"
        onClick={onNextPage}
        disabled={!hasNextPage}
        style={{ padding: 0 }}
        className="ml-2 w-10 max-h-8 p-0"
      >
        <Icon icon={ICONS.CHEVRON_RIGHT} />
      </Button>
      <Button
        variant="outlined"
        onClick={onLastPage}
        disabled={!hasNextPage}
        style={{ padding: 0 }}
        className="ml-2 w-10 max-h-8 p-0"
      >
        <Icon icon={ICONS.CHEVRON_RIGHT_LAST} />
      </Button>
    </div>
  )
}
