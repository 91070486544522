import { Row, flexRender } from "@tanstack/react-table"

export type Props = {
  rows: Row<any>[]
  resizable?: boolean
  expandable?: boolean
  customExpand?: boolean
  rowHeight?: number
  onRowClick?: (row: any) => void
}

export const TableBody = ({
  rows,
  resizable,
  expandable,
  customExpand,
  rowHeight = 45,
  onRowClick,
}: Props) => (
  <tbody>
    {rows.map((virtualRow, index) => {
      const row = expandable ? virtualRow : (rows[virtualRow.index] as Row<any>)
      const isSubRow = expandable && customExpand && row.depth > 0
      const clickableRowClassName =
        typeof onRowClick === "function" ? "hover:bg-blue-50" : ""

      return (
        <tr
          key={row.id}
          className={`${clickableRowClassName} ${isSubRow ? "bg-gray-50" : ""}`}
          onClick={() => {
            if (typeof onRowClick === "function") {
              onRowClick(row.original)
            }
          }}
        >
          {row.getVisibleCells().map((cell) => {
            // @ts-expect-error width
            const width = cell.column.columnDef?.width
            const isNextRowSubRow = rows[index + 1]?.depth > 0
            return (
              <td
                key={cell.id}
                style={{
                  width: resizable || !width ? cell.column.getSize() : width,
                  height: rowHeight,
                }}
                className={`border-b px-2 text-gray-500 truncate ${
                  isSubRow ? (!isNextRowSubRow ? "" : "border-b-gray-50") : ""
                }`}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            )
          })}
        </tr>
      )
    })}
  </tbody>
)
