import { RuleScoreCardModel } from './RuleScoreCardModel';
import { ScoreDescriptionType } from 'common/enums/ScoreDescriptionType.enum';

export class SectionScoreCardModel {
  public name?: string = "";
  public ruleScoreCards: RuleScoreCardModel[] = [];
  public score?: number | string = "";
  public isActive: boolean = false;
  public scoreDescription: ScoreDescriptionType = {} as ScoreDescriptionType;
constructor(obj = {} as any) {
  obj = obj || {};
  this.name = obj.name === null? "" : obj.name;
  this.ruleScoreCards = obj.ruleScoreCards?.filter((item: any) => item !== undefined).map((item: any) => new RuleScoreCardModel(item)) || [];
  this.score = obj.score === null? "" : obj.score;
  this.isActive = obj.isActive === null? false : obj.isActive;
  this.scoreDescription = obj.scoreDescription === null? {} as ScoreDescriptionType : obj.scoreDescription;
}

}
