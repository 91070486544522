import { ComplexAccuracyAnalysisResultModel } from './ComplexAccuracyAnalysisResultModel';

export class SpecificationSectionModel {
  public specifications: ComplexAccuracyAnalysisResultModel = {} as ComplexAccuracyAnalysisResultModel;
constructor(obj = {} as any) {
  obj = obj || {};
  this.specifications = !obj.specifications ? new ComplexAccuracyAnalysisResultModel() : new ComplexAccuracyAnalysisResultModel(obj.specifications);
}

}
