const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_MSLA_SIGN_UP_SIGN_IN_POLICY_ID,
  },

  authorities: {
    signUpSignIn: {
      authority: `https://${process.env.REACT_APP_MSLA_AUTHORITY_DOMAIN}/${process.env.REACT_APP_MSLA_B2CSCOPES_DOMAIN}/${process.env.REACT_APP_MSLA_SIGN_UP_SIGN_IN_POLICY_ID}`,
    },
  },
  authorityDomain: `https://${process.env.REACT_APP_MSLA_AUTHORITY_DOMAIN}`,
}

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSLA_CLIENT_ID || "", // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: process.env.REACT_APP_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
}

export const apiConfig = {
  b2cScopes: [
    `https://${process.env.REACT_APP_MSLA_B2CSCOPES_DOMAIN}/ContentStatusWebApi/WebApiUserAccess`,
  ],
  webApi: "http://localhost:5000/users/current",
}

export const loginRequest = {
  scopes: ["openid", ...apiConfig.b2cScopes],
}
