import { AlertDescription } from 'common/models/AlertDescription';
import { TestAlertModel } from 'common/models/TestAlertModel';
import { AlertModel } from 'common/models/AlertModel';
import { API } from "../api";

export const getDefinitionsApi = (workspaceId: string): Promise<AlertDescription> => API().get(`workspace/${workspaceId}/alerts/definitions`);

export const testAlertApi = (workspaceId: string, body: TestAlertModel): Promise<AlertDescription> => API().post(`workspace/${workspaceId}/alerts/test`, body);

export const getAllApi = (workspaceId: string): Promise<AlertModel[]> => API().get(`workspace/${workspaceId}/alerts`);

export const insertApi = (workspaceId: string, body: AlertModel): Promise<AlertModel> => API().post(`workspace/${workspaceId}/alerts`, body);

export const updateApi = (workspaceId: string, alertId: string, body: AlertModel): Promise<boolean> => API().put(`workspace/${workspaceId}/alerts/${alertId}`, body);

export const deleteApi = (workspaceId: string, alertId: string): Promise<boolean> => API().delete(`workspace/${workspaceId}/alerts/${alertId}`);

export const reTriggerAlertApi = (workspaceId: string): Promise<boolean> => API().post(`workspace/${workspaceId}/alerts/reTrigger`);
