import "./tags-modal.scss"
import { useModal } from "context/modal-context"
import { Button } from "components/button/button"
import { Header } from "components/header/header"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"
import { TagsSubHeader } from "./tags-sub-header/tags-sub-header"
import { FC, ReactNode, useEffect, useState } from "react"
import { TagsModalBody } from "./tags-modal-body"
import { ItemTagsModel } from "common/models/ItemTagsModel"
import {
  checkBoxesOnInit,
  computeItemsTagUpdate,
  getModalTitle,
  getSelected,
  stringToTags,
} from "./tags-modal-utlis"
import { TagsModalLegend } from "./tags-modal-legend"

type TagsModalProps = {
  itemId: string | null
  customSubHeader?: ReactNode
  tagsObj?: TagsObject
  setTagsObj?: SetTagsObject
}

export type SetTagsObject = (tagsObject: TagsObject) => void

export type TagsObject = {
  customTags: string[]
  systemTags: string[]
}

export type TagType = {
  name: string
  checked: number
  intermediate: boolean
}

export const TagsModal: FC<TagsModalProps> = ({
  itemId,
  customSubHeader,
  tagsObj,
  setTagsObj,
}: TagsModalProps) => {
  const { unSetModal } = useModal()
  const auditCatalog = useSelector((state: RootState) => state.auditCatalog)
  const workspaceSystemTags: string[] =
    useSelector((state: RootState) => state.tags.workSpaceTags?.systemTags) || []
  const workspaceCustomTags: string[] =
    useSelector((state: RootState) => state.tags.workSpaceTags?.customTags) || []
  const updatedWorkspaceTags =
    useSelector((state: RootState) => state.tags.updatedWorkspaceTags) || []
  const catalogItem = useSelector((state: RootState) => state.productView.catalogItem)
  const requestOptions = useSelector(
    (state: RootState) => state.auditCatalog.requestOptions,
  )
  const loadingAdd = useSelector(
    (state: RootState) => state.loading.effects.tags.addWorkspaceTagsAndAddItemTags,
  )
  const loadingUpdateItems = useSelector(
    (state: RootState) => state.loading.effects.tags.updateWorkspaceTagsMultipleItems,
  )
  const loadingUpdateTags = useSelector(
    (state: RootState) => state.loading.effects.tags.updateWorkspaceTags,
  )
  const saveActive = useSelector((state: RootState) => state.tags.saveActive)

  const fetchWorkspaceTags = useDispatch<RootDispatch>().tags.fetchWorkspaceTags
  const fetchProductDetails = useDispatch<RootDispatch>().productView.fetchProductDetails
  const updateWorkspaceTags = useDispatch<RootDispatch>().tags.updateWorkspaceTags
  const updateItemTags = useDispatch<RootDispatch>().tags.updateItemTags
  const addWorkspaceTagsAndAddItemTags =
    useDispatch<RootDispatch>().tags.addWorkspaceTagsAndAddItemTags
  const updateWorkspaceTagsMultipleItems =
    useDispatch<RootDispatch>().tags.updateWorkspaceTagsMultipleItems
  const resetUpdatedWorkspaceTagsRed =
    useDispatch<RootDispatch>().tags.resetUpdatedWorkspaceTagsRed
  const getCatalogFilters = useDispatch<RootDispatch>().auditCatalog.getCatalogFilters
  const getAuditCatalogData =
    useDispatch<RootDispatch>().auditCatalog.fetchAuditCatalogItems
  const getAuditCatalogColumns =
    useDispatch<RootDispatch>().auditCatalog.fetchAuditCatalogColumns
  const setIsSaveActive = useDispatch<RootDispatch>().tags.setSaveActive
  const selectedProducts = auditCatalog?.products?.filter(
    (prod) => prod.selected === true,
  )
  const selectedProdNo = selectedProducts?.length || 0
  const [customTags, setCustomTags] = useState<TagType[]>([])
  const [systemTags, setSystemTags] = useState<TagType[]>([])

  useEffect(() => {
    fetchWorkspaceTags()
  }, [])

  useEffect(() => {
    if (tagsObj !== undefined) {
      const workspaceSystemStringToTags: TagType[] = stringToTags(workspaceSystemTags)
      const workspaceCustomStringToTags: TagType[] = stringToTags(workspaceCustomTags)

      const selectedSystemTags = new Set(tagsObj.systemTags)
      setSystemTags(
        workspaceSystemStringToTags.map((tag) =>
          selectedSystemTags.has(tag.name) ? { ...tag, checked: 2 } : tag,
        ),
      )
      const selectedCustomTags = new Set(tagsObj.customTags)
      setCustomTags(
        workspaceCustomStringToTags.map((tag) =>
          selectedCustomTags.has(tag.name) ? { ...tag, checked: 2 } : tag,
        ),
      )
    } else {
      checkBoxesOnInit(
        selectedProducts,
        catalogItem,
        workspaceSystemTags,
        workspaceCustomTags,
        itemId,
        setSystemTags,
        setCustomTags,
      )
    }
    setIsSaveActive(false)
    // eslint-disable-next-line no-sparse-arrays
  }, [, auditCatalog, catalogItem, itemId])

  useEffect(() => {
    if (updatedWorkspaceTags === true) {
      const itemTags = computeItemsTagUpdate(
        selectedProducts,
        customTags,
        systemTags,
        workspaceSystemTags,
        workspaceCustomTags,
      )
      updateItemTags({ tags: itemTags }).then((response) => {
        if (response) {
          resetUpdatedWorkspaceTagsRed()
        }
      })
    }
  }, [updatedWorkspaceTags])

  function getSelectedTags(itemTags: string[], modalTags: TagType[]) {
    const checkedModalTags = modalTags.filter((tag) => tag.checked === 2)
    const partiallyCheckedModalTags = modalTags
      .filter((tag) => tag.checked === 1 && itemTags.includes(tag.name))
      .map((tag) => tag.name)
    const selectedTags = itemTags.filter(
      (itemTag) => !!checkedModalTags.find((tag) => tag.name === itemTag),
    )
    checkedModalTags.map((tag) => {
      if (!itemTags.includes(tag.name)) {
        selectedTags.push(tag.name)
      }
    })
    return selectedTags.concat(partiallyCheckedModalTags)
  }

  function getItemTags(itemId: string) {
    if (tagsObj !== undefined) {
      return {
        customTags: tagsObj.customTags,
        systemTags: tagsObj.systemTags,
      }
    }
    const item = selectedProducts.find((prod) => prod.id === itemId)
    const itemSystemTags = workspaceSystemTags.filter((el) => item?.tags.includes(el))
    const itemCustomTags = workspaceCustomTags.filter((el) => item?.tags.includes(el))
    return {
      customTags: getSelectedTags(itemCustomTags, customTags),
      systemTags: getSelectedTags(itemSystemTags, systemTags),
    }
  }

  function onSubmit() {
    const submitCustomTags = () => {
      const tags = customTags.map((tag) => tag.name)
      updateWorkspaceTags({ tags }).then((response) => {
        if (response) {
          unSetModal()
          fetchWorkspaceTags()

          if (tagsObj === undefined) {
            const selectedColumnsIds = auditCatalog.columns
              .filter((column) => column.isChecked)
              .map((column) => column.label)

            getAuditCatalogColumns(selectedColumnsIds)
            getAuditCatalogData({
              options: requestOptions,
              selectedProducts: auditCatalog.selectedProducts,
            })
            getCatalogFilters()
          }
        }
      })
    }

    if (tagsObj !== undefined && typeof setTagsObj === "function") {
      const checkedTagNames = (tags: TagType[]) =>
        tags.filter((tag) => tag.checked === 2).map((tag) => tag.name)
      const updatedTagsObj = {
        ...tagsObj,
        systemTags: checkedTagNames(systemTags),
        customTags: checkedTagNames(customTags),
      }
      setTagsObj(updatedTagsObj)
      submitCustomTags()
    } else if (itemId) {
      //* modal opened from ProductView
      const itemTags: ItemTagsModel = {
        id: itemId,
        customTags: getSelected(customTags),
        systemTags: getSelected(systemTags),
      }
      const workspaceTags = customTags.map((tag) => tag.name)
      addWorkspaceTagsAndAddItemTags({ itemTags: [itemTags], workspaceTags }).then(
        (response) => {
          if (response) {
            unSetModal()
            fetchProductDetails({ productId: itemId })
            fetchWorkspaceTags()
            getCatalogFilters()
          }
        },
      )
    } else {
      //* modal opened from AuditCatalog
      if (selectedProdNo > 0) {
        if (selectedProdNo === 1) {
          //* one item selected
          const itemTags: ItemTagsModel = {
            id: selectedProducts[0].id,
            customTags: getSelected(customTags),
            systemTags: getSelected(systemTags),
          }
          const workspaceTags = customTags.map((tag) => tag.name)
          addWorkspaceTagsAndAddItemTags({
            itemTags: [itemTags],
            workspaceTags,
          }).then((response) => {
            if (response) {
              unSetModal()
              const selectedColumnsIds = auditCatalog.columns
                .filter((column) => column.isChecked)
                .map((column) => column.label)

              getAuditCatalogColumns(selectedColumnsIds)
              getAuditCatalogData({
                options: requestOptions,
                selectedProducts: auditCatalog.selectedProducts,
              })
              getCatalogFilters()
            }
          })
        } else {
          //* multiple items selected
          const itemTags: ItemTagsModel[] = selectedProducts.map((product) => {
            return {
              id: product.id,
              customTags: getItemTags(product.id ?? "").customTags,
              systemTags: getItemTags(product.id ?? "").systemTags,
            }
          })
          const workspaceTags = customTags.map((tag) => tag.name)
          updateWorkspaceTagsMultipleItems({ tags: workspaceTags, itemTags }).then(
            (response) => {
              if (response) {
                unSetModal()
                const selectedColumnsIds = auditCatalog.columns
                  .filter((column) => column.isChecked)
                  .map((column) => column.label)

                getAuditCatalogColumns(selectedColumnsIds)
                getAuditCatalogData({
                  options: requestOptions,
                  selectedProducts: auditCatalog.selectedProducts,
                })
                getCatalogFilters()
              }
            },
          )
        }
      } else {
        //* no items selected -> update workspaceTags
        submitCustomTags()
      }
    }
  }

  useEffect(() => {
    if (itemId) {
      fetchProductDetails({ productId: itemId })
    }
  }, [itemId])

  return (
    <div className="tags-modal-container">
      <Header
        className="tags-modal-header"
        title={tagsObj !== undefined ? "Add Tags" : getModalTitle(itemId, selectedProdNo)}
      />
      {tagsObj !== undefined ? (
        <>
          <p className="text-gray-500 font-medium text-sub-headline">
            Insert system tags or create custom ones.
          </p>
          {customSubHeader}
        </>
      ) : (
        <TagsSubHeader itemId={itemId} selectedProdNo={selectedProdNo} />
      )}
      <div className="mt-5">
        <TagsModalBody
          itemId={itemId}
          usedInAuditCreation={tagsObj !== undefined}
          selectedProducts={selectedProducts}
          customTags={customTags}
          setCustomTags={setCustomTags}
          setSystemTags={setSystemTags}
          systemTags={systemTags}
        />
      </div>
      {selectedProdNo > 1 && <TagsModalLegend />}

      <div className="button-bar">
        <span className="flex justify-start">
          <Button variant="outlined" type="submit" onClick={unSetModal}>
            Cancel
          </Button>
        </span>

        <span className="flex justify-end">
          <Button
            variant="success"
            disabled={!saveActive}
            type={"reset"}
            onClick={onSubmit}
            loading={loadingAdd || loadingUpdateItems || loadingUpdateTags}
          >
            Save Changes
          </Button>
        </span>
      </div>
    </div>
  )
}
