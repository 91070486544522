import {
  TablePagination,
  TablePaginationProps,
} from "components/table-pagination/table-pagination"
import { Table, TableProps } from "components/table/table"

type Props = TableProps & TablePaginationProps

export const PaginatedTable = ({ ...rest }: Props) => {
  return (
    <>
      <Table {...rest} />
      <TablePagination {...rest} />
    </>
  )
}
