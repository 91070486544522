import { FC } from "react"
import "./full-page-loader.scss"
import { Loader } from "components/loader/loader"

type Props = {
  loading: boolean
}

export const FullPageLoader: FC<Props> = ({ loading }: Props) => {
  return loading ? (
    <div className="full-page-loader">
      {" "}
      <Loader className="text-primary-" message="Loading..." />
    </div>
  ) : null
}
