
export class WorkspaceAuditEntityModel {
  public id?: string = "";
  public lastInstanceId?: string = "";
  public workspaceId?: string = "";
  public scheduledJobType?: string = "";
  public owner?: string = "";
  public retailer?: string = "";
  public auditName?: string = "";
  public totalItems: number | string = "";
  public successfulItems: number | string = "";
  public failedItems: number | string = "";
  public notFoundItems: number | string = "";
  public status?: string = "";
  public lastRun?: string = "";
  public startDate?: string = "";
  public endDate?: string = "";
  public live: number | string = "";
  public averageHealth: number | string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.id = obj.id === null? "" : obj.id;
  this.lastInstanceId = obj.lastInstanceId === null? "" : obj.lastInstanceId;
  this.workspaceId = obj.workspaceId === null? "" : obj.workspaceId;
  this.scheduledJobType = obj.scheduledJobType === null? "" : obj.scheduledJobType;
  this.owner = obj.owner === null? "" : obj.owner;
  this.retailer = obj.retailer === null? "" : obj.retailer;
  this.auditName = obj.auditName === null? "" : obj.auditName;
  this.totalItems = obj.totalItems === null? "" : obj.totalItems;
  this.successfulItems = obj.successfulItems === null? "" : obj.successfulItems;
  this.failedItems = obj.failedItems === null? "" : obj.failedItems;
  this.notFoundItems = obj.notFoundItems === null? "" : obj.notFoundItems;
  this.status = obj.status === null? "" : obj.status;
  this.lastRun = obj.lastRun === null? "" : obj.lastRun;
  this.startDate = obj.startDate === null? "" : obj.startDate;
  this.endDate = obj.endDate === null? "" : obj.endDate;
  this.live = obj.live === null? "" : obj.live;
  this.averageHealth = obj.averageHealth === null? "" : obj.averageHealth;
}

}
