import { InteractionType } from "@azure/msal-browser"
import { useMsalAuthentication, useIsAuthenticated } from "@azure/msal-react"
import { setAccessToken } from "api/api"
import { apiConfig } from "api/msalConfig"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { FullPageLoader } from "../../components/full-page-loader/full-page-loader"
import { getCurrentUserApi } from "api/endpoints/users.api"
import { identify } from "../../utils/analytics"

const loginRequest = {
  scopes: ["openid", ...apiConfig.b2cScopes],
}

export const Home = () => {
  const { login, error, acquireToken, result } = useMsalAuthentication(
    InteractionType.Silent,
    loginRequest,
  )
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  const redirect = async () => {
    const user = await getCurrentUserApi("")

    identify(user.id, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.userName,
      companyIndustry: user.companyIndustry,
      companyName: user.companyName,
      companyRole: user.companyRole,
      companyTitle: user.companyTitle,
      companyType: user.companyType,
      marketingSource: user.marketingSource,
      cs_hdyhas: user.marketingSource,
      userFlags: user.userFlags,
      identityId: user.identityId,
      stripeCustomerId: user.stripeCustomerId,
      defaultPaymentMethod: user.defaultPaymentMethod,
    })

    if (user.userFlags !== null && user.userFlags) {
      if (user.userFlags.isOnBoardingFlowPassed) {
        navigate("/main/dashboard")
      } else {
        navigate("/onboarding#0")
      }
    } else {
      navigate("/onboarding#0")
    }
  }

  useEffect(() => {
    if (error) {
      login(InteractionType.Redirect, loginRequest)
    }
  }, [error])

  useEffect(() => {
    const interactionInProgress = Object.keys(sessionStorage)
      .toString()
      .includes("interaction.status")
    if (isAuthenticated && !interactionInProgress) {
      acquireToken(InteractionType.Silent, loginRequest)
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (result?.accessToken) {
      setAccessToken(result.accessToken)
      redirect()
    }
  }, [result])

  return <FullPageLoader loading={true} />
}
