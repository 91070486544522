import { RematchDispatch } from "@rematch/core"
import { getWorkspaceDashboardApi } from "api/endpoints/workspace.api"
import { WORKSPACE_ID } from "api/api"
import { WorkspaceDashboardModel } from "../common/models/WorkspaceDashboardModel"
import { getApi } from "../api/endpoints/dashboard.api"
import { DashboardModel } from "../common/models/DashboardModel"

type State = Readonly<{
  dashboardCardsData: WorkspaceDashboardModel | undefined
  aggregatedDashboardCards: DashboardModel | undefined
}>

const model = {
  state: {
    dashboardCardsData: undefined,
    aggregatedDashboardCards: undefined,
  } as State,
  reducers: {
    setDashboardCardsData: (state: State, payload: any): State => ({
      ...state,
      dashboardCardsData: payload,
    }),
    setAggregatedDashboardCards: (state: State, payload: DashboardModel): State => ({
      ...state,
      aggregatedDashboardCards: payload,
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchDashboardCardsData() {
      const dashboardCardsData = await getWorkspaceDashboardApi(WORKSPACE_ID)
      if (dashboardCardsData) {
        dispatch.dashboard.setDashboardCardsData(dashboardCardsData)
        return dashboardCardsData
      }
      return false
    },
    async fetchAggregatedDashboardCards() {
      const dashboardData = await getApi(WORKSPACE_ID)
      if (dashboardData) {
        dispatch.dashboard.setAggregatedDashboardCards(dashboardData)
      }
    },
  }),
}

export default model
