import { useLayoutEffect } from "react"
import { useSelector } from "react-redux"
import isEmpty from "is-empty"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import * as am5radar from "@amcharts/amcharts5/radar"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import { RootState } from "../../../store"
import { scoreToColor, scoreToStatus } from "utils"

const OVERALL_HEALTH_SCORE_DIV_ID = "OVERALL_HEALTH_SCORE_DIV_ID"

export const OverallHealthScore = () => {
  const overallScore = useSelector(
    (state: RootState) =>
      state.dashboard.aggregatedDashboardCards?.summarySection?.cards?.[0].items[0],
  )
  const summarySectionItems = useSelector(
    (state: RootState) =>
      state.dashboard.aggregatedDashboardCards?.summarySection?.cards?.[0]?.items,
  )

  const overallHealthScore = Number.parseFloat(
    summarySectionItems?.[0].myBrand?.replace(",", ".") || "",
  )

  //chart data + init
  useLayoutEffect(() => {
    if (!isEmpty(summarySectionItems)) {
      const items = summarySectionItems?.filter((_, index) => index) || []

      const root = am5.Root.new(OVERALL_HEALTH_SCORE_DIV_ID)
      root.setThemes([am5themes_Animated.new(root)])

      const chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          startAngle: 150,
          endAngle: 390,
        }),
      )

      // Create axis and its renderer
      // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
      const axisRenderer = am5radar.AxisRendererCircular.new(root, {
        innerRadius: am5.percent(90),
        radius: am5.percent(300),
      })

      axisRenderer.grid.template.setAll({
        stroke: root.interfaceColors.get("background"),
        visible: false,
        strokeOpacity: 1,
      })

      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0,
          min: items[items.length - 1].filters.values[0],
          max: items[0].filters.values[1],
          strictMinMax: true,
          renderer: axisRenderer,
        }),
      )

      // Add clock hand
      // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
      const axisDataItem = xAxis.makeDataItem({})

      const clockHand = am5radar.ClockHand.new(root, {
        innerRadius: -15,
        radius: -30,
        bottomWidth: 15,
        pinRadius: 10,
      })

      const bullet = axisDataItem.set(
        "bullet",
        am5xy.AxisBullet.new(root, {
          sprite: clockHand,
        }),
      )

      xAxis.createAxisRange(axisDataItem)

      axisDataItem.set("value", overallHealthScore)

      const label = chart.radarContainer.children.push(
        am5.Label.new(root, {
          fill: am5.color(scoreToColor(overallHealthScore)),
          centerX: am5.percent(50),
          textAlign: "center",
          centerY: am5.percent(-110),
          fontSize: 18,
        }),
      )

      bullet.get("sprite").on("rotation", function () {
        label.set("text", scoreToStatus(overallHealthScore))
      })

      chart.bulletsContainer.set("mask", undefined)

      // Create axis ranges bands
      // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Bands
      const bandsData = items.map((i, index, arr) => ({
        title: "",
        color: scoreToColor(arr.length - index),
        lowScore: i.filters.values[0],
        highScore: i.filters.values[1],
      }))

      am5.array.each(bandsData, function (data) {
        const axisRange = xAxis.createAxisRange(xAxis.makeDataItem({}))

        axisRange.setAll({
          value: data.lowScore,
          endValue: data.highScore,
        })

        axisRange.get("axisFill")?.setAll({
          visible: true,
          fill: am5.color(data.color),
        })
      })

      // Make stuff animate on load
      chart.appear(1000, 100)

      //remove logo
      root._logo?.dispose()

      return () => {
        root.dispose()
      }
    }
  }, [summarySectionItems])

  return (
    <div className="shadow rounded-lg bg-white p-7">
      <div className="flex justify-between items-center">
        <div className="text-title-2 text-gray-900 font-bold">{overallScore?.title}</div>
      </div>

      <div
        id={OVERALL_HEALTH_SCORE_DIV_ID}
        style={{ width: "100%", height: 300 }}
        className="relative -mt-5"
      >
        <div
          className="z-10 bg-white p-2 absolute left-1/2 top-1/2 -translate-x-2/4 translate-y-1/4 font-bold"
          style={{
            fontSize: 60,
            color: scoreToColor(overallHealthScore),
          }}
        >
          {overallHealthScore}
        </div>
      </div>

      <div className="flex justify-between">
        {summarySectionItems
          ?.filter((_, index) => index)
          .reverse()
          .map((i, index) => (
            <span className="flex items-center gap-1 text-caption-1">
              <div
                className="h-3 w-3 rounded-full"
                style={{ backgroundColor: scoreToColor(index + 1) }}
              />
              {`${i.filters.values[0]} - ${i.filters.values[1]}`}
            </span>
          ))}
      </div>
    </div>
  )
}
