import { FC } from "react"
import "./audits-modal.scss"
import { useModal } from "context/modal-context"
import { Button } from "components/button/button"
import { useSelector } from "react-redux"
import { RootState } from "store"

type Props = {
  title: string
  description: string | React.ReactNode
}

export const AuditsModal: FC<Props> = ({ title, description }: Props) => {
  const { unSetModal } = useModal()
  const items = useSelector((state: RootState) => state.audits.errorItems)
  const itemsLoading = useSelector(
    (state: RootState) => state.loading.effects.audits.fetchErrorItems,
  )

  const copyToClipboard = (data: string) => {
    navigator.clipboard.writeText(data)
  }

  const copyURLs = (items: string[]) => {
    const urls = items.map((itemLink: any) => itemLink).join("\n")
    copyToClipboard(urls)
  }

  return (
    <div className="audits-modal">
      <div className="items-header">
        <span className="text-title-3 mb-5">{title}</span>
        <span className="text-gray-500 text-body max-w-2xl">{description}</span>
      </div>
      <div className="items-list">
        {itemsLoading ? (
          <span className="no-items">Loading...</span>
        ) : (
          <>
            {items?.length === 0 ? (
              <span className="no-items">There are no items</span>
            ) : (
              <div className="grid">
                {items?.map((failedItem: string, index) => (
                  <a
                    key={index}
                    href={failedItem}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600 col-span-full mb-2 break-all"
                  >
                    {failedItem}
                  </a>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex justify-end mt-14">
        {!itemsLoading && items?.length !== 0 && (
          <Button variant="link" onClick={() => copyURLs(items)}>
            Copy Links
          </Button>
        )}
        <Button className="ml-3" variant="outlined" onClick={() => unSetModal()}>
          Close
        </Button>
      </div>
    </div>
  )
}
