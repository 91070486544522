import { ItemFilterModel } from "common/models/ItemFilterModel"
import { PropertyModel } from "common/models/PropertyModel"
import { DependencyModel } from "common/models/DependencyModel"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import { FC, useEffect, useState } from "react"
import { FilterSearch } from "../filter-search/filter-search"
import "./property-model-options.scss"
import { ScoreFilterModel } from "common/models/ScoreFilterModel"

type Props = {
  options: PropertyModel[] | any[] | undefined
  filters: ItemFilterModel
  filterName: keyof ItemFilterModel
  setFilters: (filters: ItemFilterModel) => void
  collapsed?: boolean
}

export const PropertyModelOptions: FC<Props> = ({
  options = [],
  filters,
  filterName,
  setFilters,
  collapsed,
}: Props) => {
  const [currentFilter, setCurrentFilter] = useState([] as string[])
  const [filteredOptions, setFilteredOptions] = useState(options.slice(0, 6))
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [searchValue, setSearchValue] = useState("")
  const compareProp = "value"

  const checkDependencies = (item: PropertyModel) => {
    return item.dependencies.find((dependency: DependencyModel) => {
      const filterSection = filters[dependency.category as keyof ItemFilterModel]
      if (Array.isArray(filterSection) && filterSection.length) {
        return (filterSection as Array<any>).find((filter) => {
          if (filter instanceof PropertyModel) {
            return filter.value === dependency.value
          } else if (filter instanceof ScoreFilterModel) {
            return filter.value === dependency.value
          } else {
            return filter === dependency.value
          }
        })
      } else {
        return true
      }
    })
  }

  const setValue = (item: PropertyModel) => {
    setFilters({
      ...filters,
      [filterName]: currentFilter.includes(String(item.value))
        ? currentFilter.filter((f) => f !== item.value)
        : [...currentFilter, String(item.value)],
    })
  }

  const filterOptions = (value: string) => {
    if (compareProp) {
      setFilteredOptions(
        options.filter((option) =>
          option[compareProp]?.toLowerCase().includes(value.toLowerCase()),
        ),
      )
    } else {
      setFilteredOptions(
        options.filter((option) => option.toLowerCase().includes(value.toLowerCase())),
      )
    }
  }

  useEffect(() => {
    setCurrentFilter(filters[filterName] as string[])

    if (searchValue) {
      filterOptions(searchValue)
    } else {
      setFilteredOptions(options)
    }
  }, [filters, filterName])

  useEffect(() => {
    const filterSection = filters[filterName]

    if (Array.isArray(filterSection)) {
      filterSection?.map((filter) => {
        if (typeof filter === "string") {
          const item = options.find((item) => item.value === filter)

          if (item && !checkDependencies(item)) {
            setCurrentFilter(currentFilter.filter((f) => f !== filter))
          }
        }
      })
    }
  }, [filters])

  useEffect(() => {
    if (!isFirstRender) {
      const filterSection = filters[filterName]

      if (Array.isArray(filterSection)) {
        filterSection.forEach((filter) => {
          if (typeof filter === "string") {
            if (!currentFilter.includes(filter)) {
              setFilters({ ...filters, [filterName]: currentFilter })
            }
          }
        })
      }
    }
    setIsFirstRender(false)
  }, [currentFilter])

  useEffect(() => {
    if (collapsed) {
      return
    }

    if (searchValue) {
      setFilteredOptions(filteredOptions)
    } else {
      setFilteredOptions(options)
    }
  }, [collapsed, searchValue])

  return (
    <div className="property-model-options">
      <FilterSearch
        options={options}
        compareProp={compareProp}
        setOptions={setFilteredOptions}
        value={searchValue}
        setSearchValue={setSearchValue}
      />
      {filteredOptions.map((item: PropertyModel, index: number) =>
        checkDependencies(item) ? (
          <Checkbox
            value={currentFilter.includes(String(item.value))}
            key={index}
            label={item.value}
            small
            onChange={() => setValue(item)}
          />
        ) : null,
      )}
    </div>
  )
}
