import { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import debounce from "lodash/debounce"

import "./reports-search.scss"

import { RootDispatch, RootState } from "store"
import { CatalogRequestOptions } from "common/models/CatalogRequestOptions"
import { PageModel } from "common/models/PageModel"
import { SearchInput } from "components/form-components/search-input/search-input"

type Props = {
  value: string | undefined
}

export const ReportsSearch: FC<Props> = ({ value }) => {
  const [searchQuery, setSearchQuery] = useState(value)
  const reports = useSelector((state: RootState) => state.reports)

  const dispatch: RootDispatch = useDispatch()

  const search = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value
    const options = new CatalogRequestOptions({
      pageModel: new PageModel({
        ...reports?.reportsRequestOptions.pageModel,
        page: 1,
      }),
      itemFilterModel: {
        ...reports?.reportsRequestOptions.itemFilterModel,
        searchValue: searchValue || undefined,
      },
    })

    dispatch.reports.setRequestOptions(options)
  }, 500)

  useEffect(() => setSearchQuery(value), [value])

  return (
    <div className="reports-search">
      <SearchInput
        placeholder="Search product..."
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchQuery(e.target.value)
          search(e)
        }}
        className="grid relative rounded-lg w-72"
        value={searchQuery ?? ""}
      />
    </div>
  )
}
