import { RetailersFilterBy } from "common/enums/RetailersFilterBy.enum"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import { Button } from "components/button/button"
import { useFormContext } from "react-hook-form"

type Props = {
  disabled: boolean
  filterBy: RetailersFilterBy
  filteredFrequentlyUsed: RetailerSelectorOption[]
  filteredRetailerOptions: RetailerSelectorOption[]
}

export const CheckAndClear = ({
  disabled,
  filterBy,
  filteredRetailerOptions,
  filteredFrequentlyUsed,
}: Props) => {
  const { setValue, getValues } = useFormContext()

  const isSubset = (arr1: RetailerSelectorOption[], arr2: RetailerSelectorOption[]) =>
    arr1.every((el) => arr2.find((el2: RetailerSelectorOption) => el.value === el2.value))

  const selectedRetailers = getValues("availableFor")
  const viewAllFilteredRetailers = [...filteredFrequentlyUsed, ...filteredRetailerOptions]
  const disabledCheckAll =
    disabled ||
    (filterBy === RetailersFilterBy.FrequentlyUsed
      ? isSubset(filteredFrequentlyUsed, selectedRetailers)
      : filterBy === RetailersFilterBy.AllSites
      ? isSubset(filteredRetailerOptions, selectedRetailers)
      : isSubset(viewAllFilteredRetailers, selectedRetailers))

  const disabledClear =
    disabled ||
    (filterBy === RetailersFilterBy.FrequentlyUsed
      ? filteredFrequentlyUsed.filter((el) =>
          selectedRetailers.find(
            (option: RetailerSelectorOption) => option.value === el.value,
          ),
        ).length === 0
      : filterBy === RetailersFilterBy.AllSites
      ? filteredRetailerOptions.filter((el) =>
          selectedRetailers.find(
            (option: RetailerSelectorOption) => option.value === el.value,
          ),
        ).length === 0
      : viewAllFilteredRetailers.filter((el) =>
          selectedRetailers.find(
            (option: RetailerSelectorOption) => option.value === el.value,
          ),
        ).length === 0)

  const handleCheckAll = () => {
    const updatedRetailers =
      filterBy === RetailersFilterBy.FrequentlyUsed
        ? selectedRetailers.concat(filteredFrequentlyUsed)
        : filterBy === RetailersFilterBy.AllSites
        ? selectedRetailers.concat(filteredRetailerOptions)
        : selectedRetailers.concat(viewAllFilteredRetailers)

    setValue("availableFor", updatedRetailers, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const handleClear = () => {
    const updatedRetailers =
      filterBy === RetailersFilterBy.FrequentlyUsed
        ? selectedRetailers?.filter(
            (el: RetailerSelectorOption) =>
              !filteredFrequentlyUsed.find((option) => option.value === el.value),
          )
        : filterBy === RetailersFilterBy.AllSites
        ? selectedRetailers?.filter(
            (el: RetailerSelectorOption) =>
              !filteredRetailerOptions.find((option) => option.value === el.value),
          )
        : selectedRetailers?.filter(
            (el: RetailerSelectorOption) =>
              !viewAllFilteredRetailers.find((option) => option.value === el.value),
          )

    setValue("availableFor", updatedRetailers, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleCheckAll}
        disabled={disabledCheckAll}
        className="mb-1"
      >
        Check All
      </Button>
      <Button
        variant="outlined"
        onClick={handleClear}
        disabled={disabledClear}
        className="mb-1"
      >
        Clear
      </Button>
    </>
  )
}
