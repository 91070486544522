import { useFormContext } from "react-hook-form"
import { BaseFormComponent } from "../base-form-component/base-form-component"
import "./textarea.scss"

type Props = {
  onClick?: any
  value?: string
  placeholder?: string
  label?: string
  name: string
  type?: string
  rows?: number
  autoComplete?: string
  disabled?: boolean
  className?: string
  required?: any
  readOnly?: boolean
  defaultValue?: string
  prefix?: React.ReactNode
  isMaxHeight?: boolean
  maxLength?: number
}

export const Textarea = ({
  onClick,
  placeholder,
  label = "",
  name,
  rows = 3,
  autoComplete,
  disabled,
  className = "",
  required,
  readOnly,
  prefix,
  isMaxHeight = false,
  maxLength,
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <BaseFormComponent
      name={name}
      label={label}
      required={required}
      className={`textarea ${isMaxHeight ? "3xl:h-full" : ""}`}
    >
      <>
        {prefix && <div className="textarea-prefix">{prefix}</div>}
        <textarea
          maxLength={maxLength}
          onClick={onClick}
          disabled={disabled}
          readOnly={readOnly}
          rows={isMaxHeight ? undefined : rows}
          style={{ height: isMaxHeight ? "100%" : undefined }}
          autoComplete={autoComplete}
          {...register(name, { required })}
          placeholder={placeholder}
          className={`base-input ${className} ${errors[name] ? "error" : ""}`}
        />
      </>
    </BaseFormComponent>
  )
}
