import { useState } from "react"
import { KeyWordChip } from "../keywords-accordion/keywords-chip"
import { Icon, ICONS } from "components/icon/icon"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../store"
import { Button } from "../../../../../components/button/button"
import "./keywords-body-column.scss"

const PRIMARY_KEYWORDS_INPUT_PLACEHOLDER =
  "Type or paste keywords or phrases with each unique keyword or phrase in its own line"

export type KeywordsAccordionBodyColumnProps = {
  title: string
  keywords: string[]
  setKeywords: (keywords: string[]) => void
}

export const KeywordsAccordionBodyColumn = ({
  title,
  keywords,
  setKeywords,
}: KeywordsAccordionBodyColumnProps) => {
  const [inputValue, setInputValue] = useState<string>("")
  const isViewer = useSelector(
    (state: RootState) => state.workspaces.isSelectedWorkspaceViewer,
  )

  const onDelete = (index: number) => {
    const keyWordsCpy = [...keywords]
    keyWordsCpy.splice(index, 1)

    setKeywords(keyWordsCpy)
  }

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value)
  }

  const addKeywords = () => {
    const newKeywords = inputValue
      .split("\n")
      .map((value) => value?.trim())
      .filter((value) => value !== "")

    setKeywords([...keywords, ...newKeywords])
    setInputValue("")
  }

  const removeAll = () => {
    setKeywords([])
  }

  return (
    <div className="keyword-accordion-column">
      <div className="keywords-column-title">{title}</div>
      <div className="keywords-input-grid mt-2">
        <textarea
          rows={8}
          className="add-new-key-word-input"
          placeholder={PRIMARY_KEYWORDS_INPUT_PLACEHOLDER}
          value={inputValue}
          onChange={handleInputChange}
          disabled={isViewer}
        />
        {inputValue !== "" && (
          <div className="relative col-start-2 row-start-1 self-end justify-self-end my-1 mr-5">
            <button onClick={addKeywords}>
              <Icon icon={ICONS.CHECK} className="text-green-500 mt-0.5" />
            </button>
            <button onClick={() => setInputValue("")}>
              <Icon icon={ICONS.CANCEL} className="text-red-500 mt-0.5" />
            </button>
          </div>
        )}
      </div>
      {keywords.length > 0 && (
        <div className="chip-container mt-3">
          <div className="chip-flex">
            {keywords?.map((word, index) => (
              <KeyWordChip
                label={word}
                key={index}
                index={index}
                onCancel={onDelete}
                disabled={isViewer}
              />
            ))}
          </div>

          <Button
            variant="link"
            isSmall
            className="clear-all-btn"
            onClick={removeAll}
            disabled={isViewer}
          >
            Remove All
          </Button>
        </div>
      )}
    </div>
  )
}
