import { Icon, ICONS } from "components/icon/icon"
import "./info-tool-tip.scss"

type ToolTipInfoIconProps = {
  children: any
  cardClasses?: string
  cardStyle?: any
}

export const ToolTipInfoIcon = ({
  children,
  cardClasses,
  cardStyle,
}: ToolTipInfoIconProps) => {
  return (
    <div className="info-icon-container">
      <Icon className="text-gray-500" size={5} icon={ICONS.INFORMATION_CIRCLE_OUTLINED} />
      <div className="tool-tip">
        <div style={cardStyle} className={`tool-tip-card ${cardClasses}`}>
          {children}
        </div>
      </div>
    </div>
  )
}
