import { ItemSnapshotProductDataModel } from './ItemSnapshotProductDataModel';
import { CatalogCountsModel } from './CatalogCountsModel';

export class ItemSnapshotModel {
  public data: ItemSnapshotProductDataModel = {} as ItemSnapshotProductDataModel;
  public retailer?: string = "";
  public createdAt: string = "";
  public snapshotCounts: CatalogCountsModel = {} as CatalogCountsModel;
constructor(obj = {} as any) {
  obj = obj || {};
  this.data = !obj.data ? new ItemSnapshotProductDataModel() : new ItemSnapshotProductDataModel(obj.data);
  this.retailer = obj.retailer === null? "" : obj.retailer;
  this.createdAt = obj.createdAt === null? "" : obj.createdAt;
  this.snapshotCounts = !obj.snapshotCounts ? new CatalogCountsModel() : new CatalogCountsModel(obj.snapshotCounts);
}

}
