import { Icon, ICONS } from "components/icon/icon"
import { FC, KeyboardEventHandler, useEffect, useState, useMemo } from "react"
import ReactSelect, { components } from "react-select"
import CreatableSelect from "react-select/creatable"
import colors from "utils/colors"
import { SelectProps, SelectOption } from "../select"

const customStyles = (controlHeight: number | string) => {
  return {
    control: (base: any, state: any) => {
      return {
        ...base,
        height: "100%",
        minHeight: controlHeight,
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: "6px",
        border: state.isFocused ? "1px solid var(--secondary-500)" : "1px solid #ced4da",
        boxShadow: state.isFocused ? "inset 0 0 0 1px var(--secondary-500)" : "none",
      }
    },
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    menu: (base: any) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
      maxWidth: "300px",
      right: 0,
    }),
    clearIndicator: (base: any) => ({
      ...base,
      color: colors.red[500],
    }),
  }
}

export const InnerSelect: FC<SelectProps> = ({
  options = [],
  value,
  onChange = () => {},
  disabled,
  readOnly = false,
  onBlur,
  onFocus,
  optionRender,
  valueRender,
  placeholder,
  hideIcon = false,
  showIconDivider = false,
  className = "",
  // name = "",
  styles = {},
  isMulti = false,
  isCreatable = false,
  hideSelectedValues = false,
  menuPlacement,
  isClearable,
  isSearchable,
  controlHeight = 48,
}: SelectProps) => {
  const [inputValue, setInputValue] = useState("")
  const [innerOptions, setInnerOptions] = useState<SelectOption[]>([])
  const [selected, setSelected] = useState<SelectOption | SelectOption[]>(value)

  useEffect(() => {
    if (isMulti && Array.isArray(value)) {
      setSelected(value)
    } else {
      setSelected([...options, ...innerOptions]?.filter((opt) => value === opt.value))
    }
  }, [value, options, isMulti, innerOptions])

  const createOption = (label: string): SelectOption =>
    ({ label, value: label } as SelectOption)

  const getComponents = useMemo(() => {
    const selectComponents: any = {
      SingleValue: ({ children, ...props }: any) => {
        return (
          <components.SingleValue {...props}>
            {valueRender ? valueRender(props.data) : children}
          </components.SingleValue>
        )
      },
      Option: (props: any) => {
        const { data, innerProps } = props
        return (
          <div
            {...innerProps}
            className={`select-option ${props.isSelected ? "selected" : ""} ${
              props.isDisabled ? "disabled" : ""
            }`}
          >
            {optionRender ? optionRender(data) : data.label}
          </div>
        )
      },
      IndicatorSeparator: () =>
        showIconDivider ? (
          <div className="h-5 border-r border-gray-500 mx-3"></div>
        ) : null,
      DropdownIndicator: (props: any) =>
        hideIcon ? null : (
          <Icon
            className={`mr-1${
              props.selectProps.menuIsOpen ? " transform -scale-y-100" : ""
            }`}
            size={5}
            icon={ICONS.CHEVRON_DOWN}
          />
        ),
    }

    return selectComponents
  }, [hideIcon, hideSelectedValues, placeholder, valueRender, optionRender])

  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue)
  }

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (inputValue) {
      let newOption
      switch (event.key) {
        case "Enter":
        case "Tab":
          setInputValue("")
          newOption = createOption(inputValue)
          setInnerOptions([...innerOptions, newOption])
      }
    }
  }

  const handleChange = (newValue: any, action: any) => {
    onChange(newValue, action)
  }

  const onCreateOption = (inputValue: string) => {
    const newOption = createOption(inputValue)
    setInputValue("")
    setInnerOptions([...innerOptions, newOption])
    onChange(newOption)
  }

  const customFilter = (option: any, searchText: string) =>
    option.label.toLowerCase().includes(searchText.toLowerCase())

  return isCreatable ? (
    <CreatableSelect
      classNamePrefix="select"
      options={[...options, ...innerOptions]}
      value={selected}
      onChange={handleChange}
      components={getComponents}
      placeholder={placeholder}
      styles={{ ...customStyles(controlHeight), ...styles }}
      inputValue={inputValue}
      isClearable
      isMulti={isMulti}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onCreateOption={onCreateOption}
      menuPortalTarget={document.querySelector("body")}
      menuPlacement={menuPlacement}
      hideSelectedOptions={hideSelectedValues}
      blurInputOnSelect
    />
  ) : (
    <ReactSelect
      classNamePrefix="select"
      options={options}
      value={selected}
      onChange={handleChange}
      components={getComponents}
      styles={{ ...customStyles(controlHeight), ...styles }}
      className={className}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={placeholder}
      isDisabled={disabled || readOnly}
      isOptionDisabled={(option) => option.disabled || false}
      isMulti={isMulti}
      isSearchable={isSearchable}
      filterOption={isSearchable ? customFilter : undefined}
      menuPortalTarget={document.querySelector("body")}
      menuPlacement={menuPlacement}
      hideSelectedOptions={hideSelectedValues}
      isClearable={isClearable}
      blurInputOnSelect
    />
  )
}
