import { useSelector } from "react-redux"
import { ColumnDef } from "@tanstack/react-table"

import { RootState } from "store"
import { DashboardRecommendationsCardModel } from "common/models/DashboardRecommendationsCardModel"
import { DashboardSectionCardModel } from "common/models/DashboardSectionCardModel"
import { getAttributesColumns, getProductExperienceColumns } from "../columns"
import { Table } from "components/table/table"

export const RecommendedActions = () => {
  const dashboardData = useSelector(
    (state: RootState) => state.dashboard.aggregatedDashboardCards,
  )

  // @ts-expect-error columns
  const ATTRIBUTE_COLUMNS: ColumnDef<any>[] = getAttributesColumns()
  // @ts-expect-error columns
  const PRODUCT_EXPERIENCE_COLUMNS: ColumnDef<any>[] = getProductExperienceColumns()

  return (
    <>
      <div className="4xl:max-w-[2000px] max-w-[1360px] mx-auto mb-4 mt-10">
        <div className="grid h-10 items-center gap-3">
          <div className="font-bold text-title-1">Recommended Actions</div>
        </div>
      </div>
      <div className="4xl:max-w-[2000px] max-w-[1360px] mx-auto grid grid-cols-2 gap-x-5">
        <div className="contents">
          <div
            className="option-first-row"
            style={{
              gridRow: 1,
              gridColumn: 1,
            }}
          >
            <span className="text-gray-500">Option 1: &nbsp;</span>
            <span className="font-bold">Attribute Focused Strategy</span>
          </div>
          <div className="option-second-row" style={{ gridRow: 2, gridColumn: 1 }}>
            This option focuses on improving a specific content attribute instead of the
            overall score. For example, if the product title score is a weak point, then
            it would make sense to focus on products with the worst titles. This can be a
            popular strategy as it's not hard to improve a title compared to creating
            quality product descriptions. The same tactic could be used to focus on
            products with only one image. After improving a single weak attribute score
            you can move on to another. This can be an especially great option if
            different parts of your team are responsible for different content types
            (images, copy).
          </div>
          <div className="option-third-row" style={{ gridRow: 3, gridColumn: 1 }}>
            {dashboardData?.firstOptionRecommendationsSection?.sections.map((section) => {
              const gridData = section.sectionCards
                ?.map((card) => [
                  {
                    title: card.title.name,
                    rowClassName: "recommendations-row-title",
                  },
                  ...card.items,
                ])
                .flat()

              return (
                <div>
                  <div className="flex mt-2 mb-5">
                    <div className="4xl:text-headline mr-2 text-callout text-gray-500">
                      {section.title.name}:
                    </div>
                    <div className="4xl:text-headline mr-2 text-callout text-fuchsia-900 font-bold">
                      {section.title.value}
                    </div>
                  </div>
                  <Table columns={ATTRIBUTE_COLUMNS} data={gridData} />
                </div>
              )
            })}
          </div>
        </div>
        <div className="contents">
          <div className="option-first-row" style={{ gridRow: 1, gridColumn: 2 }}>
            <span className="text-gray-500">Option 2: &nbsp;</span>
            <span className="font-bold">Product Focused Strategy</span>
          </div>
          <div className="option-second-row" style={{ gridRow: 2, gridColumn: 2 }}>
            This option focuses on specific products (lowest scoring, highest volume) and
            fixing the entire experience at one time. This means improving the title,
            description, feature bullets, product specs, images, and rich content. It's a
            longer editing process, but you're only re-syndicating once. This option will
            provide the largest single boost to your health scores and can be a great fit
            if you are working with an outside agency to develop product content or for
            retailers who take a long time to update the product pages.
          </div>
          <div className="option-third-row" style={{ gridRow: 3, gridColumn: 2 }}>
            {dashboardData?.secondOptionRecommendationsSection?.sections.map(
              (section: DashboardRecommendationsCardModel) => {
                const gridData = section.sectionCards
                  ?.map((card: DashboardSectionCardModel) => [
                    {
                      title: card.title.name,
                      rowClassName: "recommendations-row-title",
                    },
                    ...card.items,
                  ])
                  .filter((arr) => arr?.length > 1)
                  .flat()

                return (
                  <div>
                    <div className="flex mt-2 mb-5">
                      <div className="4xl:text-headline mr-2 text-callout text-gray-500">
                        {section.title.name}:
                      </div>
                      <div className="4xl:text-headline mr-2 text-callout text-fuchsia-900 font-bold">
                        {section.title.value}
                      </div>
                    </div>
                    <Table columns={PRODUCT_EXPERIENCE_COLUMNS} data={gridData} />
                  </div>
                )
              },
            )}
          </div>
        </div>
      </div>
    </>
  )
}
