import { InitialImportResultModel } from 'common/models/InitialImportResultModel';
import { DataMappingGetModel } from 'common/models/DataMappingGetModel';
import { PageModel } from 'common/models/PageModel';
import { UploadInfoTableModelPageResult } from 'common/models/UploadInfoTableModelPageResult';
import { ItemsCountModel } from 'common/models/ItemsCountModel';
import { DataSamplePreviewMappingModel } from 'common/models/DataSamplePreviewMappingModel';
import { ProductImportCsvModel } from 'common/models/ProductImportCsvModel';
import { Base64DownloadModel } from 'common/models/Base64DownloadModel';
import { API } from "../api";

export const importDataMappingApi = (workspaceId: string, body: any): Promise<InitialImportResultModel> => API().post(`workspace/${workspaceId}/data-mapping-imports`, body);

export const updateDataMappingApi = (workspaceId: string, body: any): Promise<InitialImportResultModel> => API().put(`workspace/${workspaceId}/data-mapping-imports`, body);

export const duplicateDataMappingApi = (workspaceId: string, body: any): Promise<InitialImportResultModel> => API().post(`workspace/${workspaceId}/data-mapping-imports/duplicate`, body);

export const getDataMappingByIdApi = (workspaceId: string, importId: string): Promise<DataMappingGetModel> => API().get(`workspace/${workspaceId}/data-mapping-imports/${importId}`);

export const getUploadsPaginatedApi = (workspaceId: string, body: PageModel): Promise<UploadInfoTableModelPageResult> => API().post(`workspace/${workspaceId}/data-mapping-imports/paginated`, body);

export const getUploadsCountApi = (workspaceId: string): Promise<ItemsCountModel> => API().get(`workspace/${workspaceId}/data-mapping-imports/count`);

export const getCatalogHeadersApi = (workspaceId: string): Promise<string[]> => API().get(`workspace/${workspaceId}/data-mapping-imports/headers`);

export const dataSamplePreviewApi = (workspaceId: string, body: DataSamplePreviewMappingModel): Promise<ProductImportCsvModel[]> => API().put(`workspace/${workspaceId}/data-mapping-imports/sample-preview`, body);

export const saveMapApi = (workspaceId: string, importId: string): Promise<boolean> => API().post(`workspace/${workspaceId}/data-mapping-imports/${importId}/save-map`);

export const cancelUploadApi = (workspaceId: string, importId: string): Promise<boolean> => API().delete(`workspace/${workspaceId}/data-mapping-imports/${importId}/cancel-upload`);

export const getSampleFileApi = (workspaceId: string): Promise<Base64DownloadModel> => API().get(`workspace/${workspaceId}/data-mapping-imports/sample`);

export const getImportErrorFileApi = (workspaceId: string, importId: string): Promise<Base64DownloadModel> => API().get(`workspace/${workspaceId}/data-mapping-imports/${importId}/error-file`);
