import { ContentComponent } from "pages/workspaces/manage-workspaces/warning-workspace-modal/warning-delete-modal"
import { red, yellow, green, blue, gray } from "./colors"
import { RetailersFilterBy } from "common/enums/RetailersFilterBy.enum"

export const defaultScoreDescriptions = [
  {
    score: 0,
    percent: 0,
    color: red[900],
    status: "Needs Improvement",
  },
  {
    score: 0.1,
    percent: 10,
    color: red[900],
    status: "Needs Improvement",
  },
  {
    score: 0.5,
    percent: 10,
    color: red[900],
    status: "Needs Improvement",
  },
  {
    score: 1,
    percent: 20,
    color: red[600],
    status: "Needs Improvement",
  },
  {
    score: 1.5,
    percent: 30,
    color: red[600],
    status: "Needs Improvement",
  },
  {
    score: 2,
    percent: 40,
    color: yellow[900],
    status: "Needs Improvement",
  },
  {
    score: 2.5,
    percent: 50,
    color: yellow[900],
    status: "Needs Improvement",
  },
  {
    score: 3,
    percent: 60,
    color: yellow[600],
    status: "Average",
  },
  {
    score: 3.5,
    percent: 70,
    color: yellow[600],
    status: "Average",
  },
  {
    score: 4,
    percent: 80,
    color: green[400],
    status: "Very Good",
  },
  {
    score: 4.5,
    percent: 90,
    color: green[400],
    status: "Very Good",
  },
  {
    score: 5,
    percent: 100,
    color: green[600],
    status: "Excellent",
  },
]

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export const gridPageSizes = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
]

export const workspaceDeleteConditions: ContentComponent[] = [
  {
    isTrue: false,
    message: "Please change your default workspace before deleting this one.",
  },
  {
    isTrue: false,
    message:
      "You have Owner permission only on this workspace. You cannot delete this workspace if you don’t have other workspaces in which you have the same permission.",
  },
]

export const USER_RIGHTS_DESCRIPTION = [
  {
    name: "Owner",
    description:
      "Can manage any aspect of the account, invite other users and change their permissions. Workspace Owners can also create and manage audits as wells as manage the catalog, tags, rules and other workspace properties. Owners are also able to transfer ownership to other users within the workspace.",
  },
  {
    name: "Admin",
    description:
      "Can manage any aspect of the account, invite other users and change their permissions. Administrators can also create and manage rules as wells as manage tags and properties. Administrators are unable to transfer ownership of the workspace.",
  },
  {
    name: "Collaborator",
    description:
      "Can manage the catalog, search through snapshots, and view reports. They can create or manage: rules, create scorecards and new tags/keywords. Additionally, collaborators cannot manage other users (invite, change roles etc).",
  },
  {
    name: "Viewer",
    description:
      "By default these users can only view, and interact with the catalog but cannot make any modifications. They can also see dashboards and reports, shared with them by collaborators and administrators.",
  },
]

export const ERRORS = {
  requestSuccessfulWithNoResolution: {
    message: "Sorry, we couldn't process your request!",
    errorCodes: [200],
  },
  badRequest: {
    message: "Sorry, we couldn't send your request!",
    errorCodes: [400, 405, 413, 415],
  },
  notFound: {
    message: "Oops, something went wrong!",
    linksToVerify: [
      "catalog/workspace/${workspaceId}/items",
      "catalog/workspace/${workspaceId}/items/count",
      "catalog/workspace/${workspaceId}/columnsSummaryFiltered",
      "catalog/workspace/${workspaceId}/reports/",
      "catalog/workspace/${workspaceId}/trend-score-reports/",
      "catalog/workspace/${workspaceId}/exports/paged",
      "workspaces/${workspaceId}/jobs/myaudits/paged",
      "workspaces/${workspaceId}/jobs/myaudits/count",
      "subscriptions/workspaces/assignedPlans",
    ],
    errorCodes: [404],
  },
  internalServerError: {
    message: "Oops, something went wrong!",
    errorCodes: [500, 503, 504],
  },
}

export const EMPTY_SKU_ERROR_OBJECT = {
  ASINError: false,
  GTINError: false,
  linkError: false,
  domainError: false,
  duplicateError: false,
}

export const NEW_RUBRIC = "new-scorecard"
export const NEW_RUBRIC_COPY = "new-scorecard-copy"

export const retailersFilterByOptions = [
  { label: "View All", value: RetailersFilterBy.ViewAll },
  { label: "Frequently Used", value: RetailersFilterBy.FrequentlyUsed },
  { label: "All Sites", value: RetailersFilterBy.AllSites },
]

export const invalidInputCharacters = new Set<string>(["e", "E", "+", "-"])
export const CREATE_AUDIT_EMPTY_GRID = {
  label: "Click here to create a New Audit!",
  route: "/main/audits/new-audit#1",
}

export const REQ_MESSAGE = "This field is required!"

export const LINK_CLASS_NAME =
  "cursor-pointer underline text-blue-600 hover:text-blue-800"

export const ratingsCountPossibleValues = [
  { key: "0.5", value: "0.5" },
  { key: "1", value: "1" },
  { key: "1.5", value: "1.5" },
  { key: "2", value: "2" },
  { key: "2.5", value: "2.5" },
  { key: "3", value: "3" },
  { key: "3.5", value: "3.5" },
  { key: "4", value: "4" },
  { key: "4.5", value: "4.5" },
  { key: "5", value: "5" },
]

export const SummaryColors: any = {
  PERCENT: blue[500],
  SCORE: blue[500],
  PRICE: blue[500],
  BLUE: blue[500],
  YELLOW: yellow[500],
  RED: red[700],
  GRAY: gray[700],
}

export const SummaryFormat = {
  PERCENT: "Percent",
  SCORE: "Score",
  PRICE: "Price",
  BLUE: "Blue",
  YELLOW: "Yellow",
  RED: "Red",
  GRAY: "Gray",
}

export const newScorecardSectionsClassName =
  "h-[calc(100%-80px)] overflow-y-auto gap-7 overflow-x-hidden px-5 py-4 grid"
