import { ScoreLevel } from 'common/enums/ScoreLevel.enum';

export class ScoreFilterModel {
  public scoreLevel: ScoreLevel = {} as ScoreLevel;
  public parentOperand?: string = "";
  public operand?: string = "";
  public operator?: string = "";
  public value: number | string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.scoreLevel = obj.scoreLevel === null? {} as ScoreLevel : obj.scoreLevel;
  this.parentOperand = obj.parentOperand === null? "" : obj.parentOperand;
  this.operand = obj.operand === null? "" : obj.operand;
  this.operator = obj.operator === null? "" : obj.operator;
  this.value = obj.value === null? "" : obj.value;
}

}
