import React, { useEffect, useState } from "react"
import { Icon, ICONS } from "components/icon/icon"
import { useFormContext } from "react-hook-form"
import { KeyWordChip } from "pages/catalogs/audit-catalog/keywords-modal/keywords-accordion/keywords-chip"
import { TagsModal, TagsObject } from "pages/catalogs/audit-catalog/tags-modal/tags-modal"
import { Button } from "components/button/button"
import { useModal } from "context/modal-context"
import { AddInBulkTagsTitle } from "components/add-in-bulk-title/add-in-bulk-title"

type Props = {
  customTagsName: string
  systemTagsName: string
}

export const AuditAddTags: React.FC<Props> = ({ customTagsName, systemTagsName }) => {
  const [isTagsOpen, setIsTagsOpen] = useState(false)

  const formContext = useFormContext()
  const { setModal } = useModal()

  const { setValue, watch } = formContext || {}
  const systemTags: string[] = watch(systemTagsName)
  const customTags: string[] = watch(customTagsName)

  const totalTags = [...systemTags, ...customTags]

  const resetItTagsOpen = () => setIsTagsOpen(totalTags.length !== 0)

  useEffect(() => {
    resetItTagsOpen()
    // eslint-disable-next-line no-sparse-arrays
  }, [, totalTags.length])

  return (
    <>
      <div className="add-tags-and-keywords-container">
        <span className="block-label">
          TAGGED PRODUCTS <span className="text-red-500">(required)</span>
        </span>
        <Button
          className="mx-2 p-2"
          variant="outlined"
          onClick={() => {
            setModal(
              <TagsModal
                itemId={null}
                tagsObj={{
                  customTags,
                  systemTags,
                }}
                setTagsObj={(tagsObj: TagsObject) => {
                  setValue(systemTagsName, tagsObj.systemTags)
                  setValue(customTagsName, tagsObj.customTags)
                  resetItTagsOpen()
                }}
                customSubHeader={
                  <span className="text-sub-headline text-gray-500 font-medium">
                    It is recommended to add{" "}
                    <span className="text-gray-900">My Brands</span> and{" "}
                    <span className="text-gray-900">Competitor SKU</span> tags if you want
                    this audit to appear in{" "}
                    <span className="text-gray-900">Content Insights</span>.
                  </span>
                }
              />,
            )
          }}
          style={{ height: 32 }}
        >
          Add Tags
        </Button>
        <div className="flex flex-grow" />
        <div className="px-2 flex gap-2 items-center">
          <AddInBulkTagsTitle
            total={totalTags.length}
            system={systemTags.length}
            custom={customTags.length}
          />
          <Button
            className=""
            variant="icon-btn"
            disabled={totalTags.length === 0}
            onClick={() => setIsTagsOpen((prev) => !prev)}
          >
            <Icon
              className={isTagsOpen ? "transform -scale-y-100" : ""}
              icon={ICONS.CHEVRON_DOWN}
            />
          </Button>
        </div>
      </div>
      {isTagsOpen && (
        <div className="add-tags-and-keywords-details-container">
          <div className="tags-details">
            {systemTags?.map((tag, index, tags) => (
              <KeyWordChip
                label={tag}
                key={index}
                index={index}
                onCancel={() => {
                  setValue(
                    systemTagsName,
                    tags.filter((tagName) => tagName !== tag),
                  )
                }}
              />
            ))}
            {customTags?.map((tag, index, tags) => (
              <KeyWordChip
                label={tag}
                key={index}
                index={index}
                onCancel={() => {
                  setValue(
                    customTagsName,
                    tags.filter((tagName) => tagName !== tag),
                  )
                }}
              />
            ))}
          </div>
          {totalTags.length > 0 && (
            <Button
              variant="link"
              className="clear-all-btn"
              onClick={() => {
                setValue(systemTagsName, [])
                setValue(customTagsName, [])
              }}
            >
              Remove All
            </Button>
          )}
        </div>
      )}
    </>
  )
}
