import { ScheduledJobType } from 'common/enums/ScheduledJobType.enum';
import { PageModel } from './PageModel';

export class _MyAuditGetModel {
  public scheduledJobType: ScheduledJobType = {} as ScheduledJobType;
  public searchQuery?: string = "";
  public pageModel: PageModel = {} as PageModel;
constructor(obj = {} as any) {
  obj = obj || {};
  //TODO fix nullable converting to empty object
  this.scheduledJobType = obj.scheduledJobType === null? null : obj.scheduledJobType;
  this.searchQuery = obj.searchQuery === null? "" : obj.searchQuery;
  this.pageModel = !obj.pageModel ? new PageModel() : new PageModel(obj.pageModel);
}

}
