import { MonitorCompareResult } from 'common/enums/MonitorCompareResult.enum';

export class AccuracyAnalysisResultModel {
  public compareResult: MonitorCompareResult = {} as MonitorCompareResult;
  public baseValue?: string = "";
  public comparedValue?: string = "";
  public difference: number | string = "";
  public differenceCount: number | string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.compareResult = obj.compareResult === null? {} as MonitorCompareResult : obj.compareResult;
  this.baseValue = obj.baseValue === null? "" : obj.baseValue;
  this.comparedValue = obj.comparedValue === null? "" : obj.comparedValue;
  this.difference = obj.difference === null? "" : obj.difference;
  this.differenceCount = obj.differenceCount === null? "" : obj.differenceCount;
}

}
