import React, { useEffect, useState } from "react"
import { Icon, ICONS } from "components/icon/icon"
import { useFormContext } from "react-hook-form"
import { KeyWordChip } from "pages/catalogs/audit-catalog/keywords-modal/keywords-accordion/keywords-chip"
import { Button } from "components/button/button"
import { useModal } from "context/modal-context"
import { EnterKeywordsModal } from "../../enter-keywords-modal/enter-keywords-modal"
import { AddInBulkKeywordsTitle } from "components/add-in-bulk-title/add-in-bulk-title"

type Props = {
  primaryKeywordsName: string
  secondaryKeywordsName: string
}

export const AuditAddKeywords: React.FC<Props> = ({
  secondaryKeywordsName,
  primaryKeywordsName,
}) => {
  const [isKeywordsOpen, setIsKeywordsOpen] = useState(false)

  const formContext = useFormContext()
  const { setModal } = useModal()

  const { setValue, watch } = formContext || {}
  const primaryKeywords: string[] = watch(primaryKeywordsName)
  const secondaryKeywords: string[] = watch(secondaryKeywordsName)

  const totalKeywords = [...primaryKeywords, ...secondaryKeywords]

  useEffect(() => {
    setIsKeywordsOpen(totalKeywords.length !== 0)
    // eslint-disable-next-line no-sparse-arrays
  }, [, totalKeywords.length])

  return (
    <>
      <div className="add-tags-and-keywords-container mt-5">
        <span className="block-label">KEYWORDS</span>
        <Button
          className="mx-2 p-2"
          variant="outlined"
          onClick={() => {
            setModal(
              <EnterKeywordsModal
                primaryKeywords={primaryKeywords}
                secondaryKeywords={secondaryKeywords}
                setPrimaryKeywords={(newKeyWords: string[]) =>
                  setValue(primaryKeywordsName, newKeyWords)
                }
                setSecondaryKeywords={(newKeyWords: string[]) =>
                  setValue(secondaryKeywordsName, newKeyWords)
                }
              />,
            )
          }}
          style={{ height: 32 }}
        >
          Add Keywords
        </Button>
        <div className="flex flex-grow" />
        <div className="px-2 flex gap-2 items-center">
          <AddInBulkKeywordsTitle
            total={totalKeywords?.length}
            primary={primaryKeywords?.length}
            secondary={secondaryKeywords?.length}
          />
          <Button
            className=""
            variant="icon-btn"
            disabled={totalKeywords.length === 0}
            onClick={() => setIsKeywordsOpen((prev) => !prev)}
          >
            <Icon
              className={isKeywordsOpen ? "transform -scale-y-100" : ""}
              icon={ICONS.CHEVRON_DOWN}
            />
          </Button>
        </div>
      </div>
      {isKeywordsOpen && (
        <div className="add-tags-and-keywords-details-container">
          <div className="tags-details">
            {primaryKeywords?.map((keyword, index, keywords) => (
              <KeyWordChip
                label={keyword}
                key={index}
                index={index}
                onCancel={() => {
                  setValue(
                    primaryKeywordsName,
                    keywords.filter((keywordName) => keywordName !== keyword),
                  )
                }}
              />
            ))}
            {secondaryKeywords?.map((keyword, index, keywords) => (
              <KeyWordChip
                label={keyword}
                key={index}
                index={index}
                onCancel={() => {
                  setValue(
                    secondaryKeywordsName,
                    keywords.filter((keywordName) => keywordName !== keyword),
                  )
                }}
              />
            ))}
          </div>
          {totalKeywords.length > 0 && (
            <Button
              variant="link"
              className="clear-all-btn"
              onClick={() => {
                setValue(primaryKeywordsName, [])
                setValue(secondaryKeywordsName, [])
              }}
            >
              Remove All
            </Button>
          )}
        </div>
      )}
    </>
  )
}
