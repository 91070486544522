import { useDispatch } from "react-redux"
import { JobItemStatus } from "common/enums/JobItemStatus.enum"
import { Button } from "components/button/button"
import { useModal } from "context/modal-context"
import { RootDispatch } from "store"
import { AuditsModal } from "../audits-modal/audits-modal"
import { ICONS, Icon } from "components/icon/icon"

type Props = {
  data: any
}

export const MissingItems = ({ data }: Props) => {
  const dispatch = useDispatch<RootDispatch>()
  const { setModal } = useModal()

  const openAuditsModal = (
    title: string,
    description: string | React.ReactNode,
    jobId: string,
    status: JobItemStatus,
  ) => {
    dispatch.audits.fetchErrorItems({ auditId: jobId, status })
    setModal(<AuditsModal title={title} description={description} />)
  }

  return (
    <Button
      tippyContent="Missing SKUs"
      className="not-found-items"
      variant="icon-btn"
      onClick={() => {
        if (data.notFoundItems > 0) {
          openAuditsModal(
            "SKUs Not Found",
            <>
              The following item(s) were not found. Before contacting your Merchant, you
              should click on some of the URL's to manually confirm as sometimes items may
              only be down for a short period.
              <span className="inline-flex translate-y-1">
                <span className="mr-2 self-start">
                  <Icon
                    tippyProps={{
                      placement: "right",
                      content: (
                        <div className="info-point">
                          <span className="text-caption-1 font-medium text-black">
                            More Info
                          </span>
                          <div className="text-caption-1 text-gray-500 self-center font-normal">
                            If these products should be live and they're still not
                            appearing, you should contact your Merchant as the lost sales
                            can quickly pile up. If you would like to re-test them, use
                            the <b>Copy Links</b> button and create a New Audit as this
                            will often lead to successful results.
                          </div>
                        </div>
                      ),
                    }}
                    icon={ICONS.INFORMATION_CIRCLE_OUTLINED}
                  />
                </span>
              </span>
            </>,
            data.lastInstanceId,
            JobItemStatus.NotFound,
          )
        }
      }}
    >
      <span className={data.notFoundItems > 0 ? "text-red-600" : "text-gray-500"}>
        {data.notFoundItems}
      </span>
    </Button>
  )
}
