import { useDispatch } from "react-redux"
import { JobItemStatus } from "common/enums/JobItemStatus.enum"
import { Button } from "components/button/button"
import { useModal } from "context/modal-context"
import { RootDispatch } from "store"
import { AuditsModal } from "../audits-modal/audits-modal"

type Props = {
  data: any
}

export const SuccessfulItems = ({ data }: Props) => {
  const dispatch = useDispatch<RootDispatch>()
  const { setModal } = useModal()

  const openAuditsModal = (
    title: string,
    description: string | React.ReactNode,
    jobId: string,
    status: JobItemStatus,
  ) => {
    dispatch.audits.fetchErrorItems({ auditId: jobId, status })
    setModal(<AuditsModal title={title} description={description} />)
  }

  return (
    <Button
      variant="icon-btn"
      disabled={data.successfulItems === 0}
      onClick={() =>
        openAuditsModal(
          "Successful Audits",
          "These products have been successfully audited.",
          data.lastInstanceId,
          JobItemStatus.Analyzed,
        )
      }
    >
      <span className={data.successfulItems > 0 ? "text-green-600" : "text-gray-500"}>
        {data.successfulItems}
      </span>
    </Button>
  )
}
