import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import "./index.scss"
import { App } from "./App"
import store from "./store"
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser"
import { msalConfig } from "./api/msalConfig"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { ModalProvider } from "context/modal-context"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

export const msalInstance = new PublicClientApplication(msalConfig)

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

Sentry.init({
  dsn:
    process.env.REACT_APP_REDIRECT_URI === "https://my.contentstatus.com"
      ? "https://9092a708f6e648ae92b4c43aa8f75231@o355143.ingest.sentry.io/6468333"
      : "",
  integrations: [new BrowserTracing()],
  environment:
    process.env.REACT_APP_REDIRECT_URI === "https://my.contentstatus.com"
      ? "frontend-production"
      : "frontend-development",

  tracesSampleRate: 1.0,
})

const queryClient = new QueryClient()
const router = createBrowserRouter([
  {
    path: "*",
    element: (
      <Provider store={store}>
        <ModalProvider>
          <QueryClientProvider client={queryClient}>
            <App msalInstance={msalInstance} />
          </QueryClientProvider>
        </ModalProvider>
      </Provider>
    ),
  },
])

const rootElement = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

rootElement.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <RouterProvider router={router} />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log)
