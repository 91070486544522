import { PageModel } from './PageModel';

export class ExportRequestOptions {
  public pageModel: PageModel = {} as PageModel;
constructor(obj = {} as any) {
  obj = obj || {};
  this.pageModel = !obj.pageModel ? new PageModel() : new PageModel(obj.pageModel);
}

}
