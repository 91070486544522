import { UserFlag } from './UserFlag';

export class UserModel {
  public id?: string = "";
  public userName?: string = "";
  public identityId?: string = "";
  public firstName?: string = "";
  public lastName?: string = "";
  public companyName?: string = "";
  public companyRole?: string = "";
  public companyTitle?: string = "";
  public companyIndustry?: string = "";
  public companyType?: string = "";
  public marketingSource?: string = "";
  public userFlags: UserFlag = {} as UserFlag;
  public stripeCustomerId?: string = "";
  public defaultPaymentMethod?: string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.id = obj.id === null? "" : obj.id;
  this.userName = obj.userName === null? "" : obj.userName;
  this.identityId = obj.identityId === null? "" : obj.identityId;
  this.firstName = obj.firstName === null? "" : obj.firstName;
  this.lastName = obj.lastName === null? "" : obj.lastName;
  this.companyName = obj.companyName === null? "" : obj.companyName;
  this.companyRole = obj.companyRole === null? "" : obj.companyRole;
  this.companyTitle = obj.companyTitle === null? "" : obj.companyTitle;
  this.companyIndustry = obj.companyIndustry === null? "" : obj.companyIndustry;
  this.companyType = obj.companyType === null? "" : obj.companyType;
  this.marketingSource = obj.marketingSource === null? "" : obj.marketingSource;
  this.userFlags = !obj.userFlags ? new UserFlag() : new UserFlag(obj.userFlags);
  this.stripeCustomerId = obj.stripeCustomerId === null? "" : obj.stripeCustomerId;
  this.defaultPaymentMethod = obj.defaultPaymentMethod === null? "" : obj.defaultPaymentMethod;
}

}
