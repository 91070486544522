import { useEffect } from "react"
import { connect, useSelector } from "react-redux"
import { RootState } from "store"
import { Header } from "components/header/header"
import { ShelfQueryForm } from "./shelf-query-form/shelf-query-form"
import { ShelfResults } from "./shelf-results/shelf-results"
import { ShelfCharts } from "./shelf-charts/shelf-charts"
import { abortController } from "api/api"
import "./share-of-shelf.scss"

type Props = ReturnType<typeof mapProps>

const ShareOfShelfComponent = ({ loading }: Props) => {
  const shareOfShelf = useSelector((state: RootState) => state.shareOfShelf)

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className="share-of-shelf">
      <Header
        title="Share-Of-Search"
        className="col-span-full"
        description="Understanding how your products and your competitor’s products rank for key site-search terms is critical to maximizing page traffic. Start a search below to see where keywords and phrases are used in titles, descriptions, and bullets and how products are ranking on each page."
      />
      <div className="col-span-full">
        <ShelfQueryForm />
      </div>
      {Array.isArray(shareOfShelf.results) && shareOfShelf?.results?.length > 0 ? (
        <>
          <ShelfCharts />
          <div className="col-span-full">
            <ShelfResults />
          </div>
        </>
      ) : (
        <>
          {shareOfShelf.results === undefined && !loading && (
            <div className="col-span-full flex justify-center font-semibold text-callout">
              No items found.
            </div>
          )}
        </>
      )}
    </div>
  )
}

const mapProps = (state: RootState) => ({
  loading: state.loading.effects.shareOfShelf.fetchShareOfShelfData,
})

export const ShareOfShelf = connect(mapProps)(ShareOfShelfComponent)
