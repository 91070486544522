import React, { FC, Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import { SuspenseLoader } from "../../components/suspense-loader/suspense-loader"
import { lazyLoadRetry } from "../../utils/lazyLoadRetry"
import "./catalogs.scss"

const AuditCatalog = React.lazy(
  lazyLoadRetry(() => import("./audit-catalog/audit-catalog")),
)
const ProductView = React.lazy(
  lazyLoadRetry(() => import("./audit-catalog/product-view/product-view")),
)

export const Catalogs: FC = () => {
  return (
    <div className="catalogs">
      <Suspense fallback={<SuspenseLoader delay={500} />}>
        <Routes>
          <Route path="/" element={<AuditCatalog />} />
          <Route path=":productId/*" element={<ProductView />} />
        </Routes>
      </Suspense>
    </div>
  )
}
