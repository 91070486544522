export enum CatalogReportType {
  RetailerCategoryBrand = 0,
  RetailerBrandCategory = 1,
  TagsRetailerCategoryBrand = 2,
  BrandCategory = 3,
  CategoryBrand = 4,
  BrandRetailer = 5,
  JobsRetailerCategoryBrand = 6,
  CategoryRetailer = 7
}
