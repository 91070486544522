import { useSelector } from "react-redux"
import DOMPurify from "dompurify"

import { RootState } from "../../../store"
import { HealthScoreBy } from "./health-score-by"
import { DashboardSectionCardModel } from "../../../common/models/DashboardSectionCardModel"
import { OverallHealthScore } from "./overall-health-score"
import { HealthScoreVsCompetitors } from "./health-score-vs-competitors"

import { DashboardAggregatedCard } from "../dashboard-aggregated-cards/DashboardAggregatedCard"
import { getHealthScoreColumns } from "../columns"

export const MyContentStatus = () => {
  const overallSummary = useSelector(
    (state: RootState) =>
      state.dashboard.aggregatedDashboardCards?.overallSummarySection?.cards[0],
  )
  const summarySection = useSelector(
    (state: RootState) => state.dashboard.aggregatedDashboardCards?.summarySection?.cards,
  )

  // @ts-expect-error columns
  const HEALTH_SCORE_COLUMNS: ColumnDef<any>[] = getHealthScoreColumns()

  return (
    <div className="4xl:max-w-[2000px] max-w-[1360px] mx-auto pt-10">
      <div className="grid h-10 items-center gap-3 mb-4">
        <div className="font-bold text-title-1">My Content Status</div>
      </div>
      <div className="grid grid-cols-3 auto-rows-auto gap-5 overall-summary-container">
        {overallSummary?.description && (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(overallSummary?.description),
            }}
            style={{ whiteSpace: "pre-line" }}
            className="shadow rounded-lg bg-white text-callout p-7 text-gray-500"
          />
        )}

        <OverallHealthScore />
        <HealthScoreVsCompetitors />

        {summarySection
          ?.slice(1)
          .map((card: DashboardSectionCardModel, index: number) => {
            return (
              <div className={`${index === 1 ? "row-start-2" : ""}`}>
                <HealthScoreBy
                  data={card.items}
                  title={card.title}
                  infoPointDescription={card.infoPointDescription}
                  myBrandFill={card.myBrandFill}
                  competitorFill={card.competitorFill}
                />
              </div>
            )
          })}

        {summarySection
          ?.slice(1)
          .map((card: DashboardSectionCardModel, index: number) => {
            const columns = HEALTH_SCORE_COLUMNS.map((el) => ({ ...el }))

            if (card.title.value === "by Brands") {
              columns.splice(2, 1)
              // @ ts-expect-error columns
              columns[0] = { ...columns[0], width: "76%" }
            }

            return (
              <div className={`${index === 1 ? "row-start-3" : ""}`}>
                <DashboardAggregatedCard
                  columns={columns}
                  data={card.items}
                  title={card.title}
                  descriptionContainerClass="summary-content-container"
                />
              </div>
            )
          })}
      </div>
    </div>
  )
}
