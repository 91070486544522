import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import isEmpty from "is-empty"

import "./content-insights.scss"

import { RootDispatch, RootState } from "../../store"
import { percentToColor } from "../../utils"
import { Icon, ICONS } from "components/icon/icon"
import { LINK_CLASS_NAME } from "utils/constants"
import { MyContentStatus } from "./components/my-content-status"
import { RecommendedActions } from "./components/recommended-actions"
import { Analysis } from "./components/analysis"
import { SCORES } from "./scores"

export const ContentInsights = () => {
  const dispatch: RootDispatch = useDispatch()
  const navigate = useNavigate()
  const workspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )
  const auditSummarySection = useSelector(
    (state: RootState) =>
      state.dashboard.aggregatedDashboardCards?.auditSummarySection?.cards?.at(0)?.items,
  )

  useEffect(() => {
    dispatch.dashboard.fetchAggregatedDashboardCards()
  }, [workspaceId])

  const gradingScale = (
    <div className="flex items-baseline mt-9 mb-8 scale-container relative">
      <div className="border-solid border border-white flex-1 absolute 4xl:top-[28px] top-[20px] w-full"></div>
      {SCORES.map(({ score, description, scoreColor }) => {
        const scoreElement = (
          <div
            className={`flex flex-col items-center z-0 ${
              score !== 0 && score !== 5 ? "flex-inner" : "flex-1"
            }`}
          >
            <div
              className={`4xl:h-14 4xl:w-14 4xl:text-headline h-10 w-10 rounded-full flex items-center justify-center text-white mb-2`}
              style={{
                background: scoreColor,
              }}
            >
              {score}
            </div>
            <div className="4xl:text-headline font-bold text-caption-1 text-gray-800 text-center">
              {description}
            </div>
          </div>
        )

        if (score === 0) {
          return (
            <div className="flex flex-col items-start flex-1 z-0">{scoreElement}</div>
          )
        }

        if (score === 5) {
          return <div className="flex flex-col items-end flex-1 z-0">{scoreElement}</div>
        }

        return scoreElement
      })}
    </div>
  )

  return (
    <div className="aggregated-dashboard">
      {/*banner*/}
      <div
        className="px-10 pt-9 pb-[99px]"
        style={{
          background:
            "linear-gradient(266.54deg, rgba(139, 92, 246, 0.3) -0.43%, rgba(245, 243, 255, 0.1) 100%)",
        }}
      >
        <div
          className="4xl:max-w-[2000px] max-w-[1360px] 4xl:min-h-[240px] min-h-[260px] mx-auto grid gap-10"
          style={{ gridTemplateColumns: "1fr 1fr" }}
        >
          <div className="text-white">
            <h1 className="4xl:text-large-title font-bold text-title-1 mb-[30px] mt-1 text-fuchsia-900">
              Welcome to Your Content Insights!
            </h1>
            <div className="4xl:text-title-3 text-gray-600 text-callout">
              <div className="mb-6">
                This report provides an overview of your current product content health,
                including your Content Health Scores, and the areas of improvement for
                your product pages so you know exactly where to focus.
              </div>
              <div>
                The results displayed below include a breakdown of core content elements,
                product availability, discrepancies between primary data sources and live
                data, and recommended actions you can take to improve the e-commerce
                experience.
              </div>
            </div>
          </div>

          <div
            className="px-8 border rounded-3xl border-white"
            style={{
              background:
                "linear-gradient(111.61deg, rgba(255, 255, 255, 0.36) 15.17%, rgba(255, 255, 255, 0.24) 95.84%)",
            }}
          >
            {/*score scale*/}
            {gradingScale}
            <div className="4xl:text-title-3 text-callout text-gray-600">
              Health Scores are configurable and are based on industry standards or
              specific retailer requirements if known. If you need a refresher,{" "}
              <span
                className={`4xl:text-title-3 text-callout ${LINK_CLASS_NAME}`}
                onClick={() => navigate(`/main/grading/scorecards`)}
              >
                visit your scorecards
              </span>{" "}
              to review the details.
            </div>
          </div>
        </div>
      </div>

      <div className="px-10">
        <div
          className="4xl:max-w-[2000px] max-w-[1360px] 4xl:px-12 4xl:h-36 4xl:-mt-[72px] -mt-[63px] mx-auto h-[126px] p-6 bg-white rounded-xl flex items-center justify-between"
          style={{
            boxShadow:
              "0px 2px 13px rgba(0, 0, 0, 0.05), 0px 1px 6px rgba(0, 0, 0, 0.03)",
          }}
        >
          {!isEmpty(auditSummarySection) ? (
            <>
              <div className="4xl:text-title-1 text-title-2 w-[25%] flex justify-center">
                <div className="font-bold text-gray-900">Audit Summary</div>
              </div>
              <div className="h-full text-gray-900 mx-2 border border-solid border-gray-200" />
              {auditSummarySection?.map((card: any, index: number) => {
                const titleSplit = card.title?.split(" ")
                const title = titleSplit[0]
                const subtitle = titleSplit.slice(1).join(" ")
                const scoreFields: string[] = [
                  "Product Availability",
                  "Live Audited",
                  "Master vs Live",
                  "Previous vs Live",
                  "Missing Pages",
                ]
                const scoreColor = scoreFields.includes(card.title)
                  ? percentToColor(card.myBrand.replace("%", ""))
                  : "#111827"

                return (
                  <>
                    <div className="text-left text-title-2 mx-2">
                      <div
                        className="4xl:text-title-1 text-title-1 mb-1"
                        style={{
                          color: scoreColor,
                        }}
                      >
                        {card.myBrand}
                      </div>
                      {title && (
                        <div className="4xl:text-title-3 text-footnote font-semibold text-gray-500">
                          {title}
                        </div>
                      )}
                      {subtitle && (
                        <div className="4xl:text-title-3 text-footnote text-gray-500">
                          {subtitle}
                        </div>
                      )}
                    </div>
                    {(index + 1) % 3 === 0 &&
                      index + 1 !== auditSummarySection.length && (
                        <div className="h-full mx-2 border border-solid border-gray-200"></div>
                      )}
                  </>
                )
              })}
            </>
          ) : (
            <div className="flex flex-col gap-1 items-center w-full font-medium">
              <div className="flex gap-3 text-gray-900">
                <Icon icon={ICONS.BELL} />
                <p>
                  At the moment, you have not conducted any audits. For this reason, we
                  have no information to show.
                </p>
              </div>
              <Link to="/main/audits/new-audit#1" className={LINK_CLASS_NAME}>
                Click here to create your first audit!
              </Link>
            </div>
          )}
        </div>
      </div>

      {!isEmpty(auditSummarySection) && (
        <div className="px-10">
          <MyContentStatus />
          <RecommendedActions />
          <Analysis />
        </div>
      )}
    </div>
  )
}
