import { WorkspaceAuditEntityModel } from './WorkspaceAuditEntityModel';

export class WorkspaceAuditEntityModelPageResultModel {
  public nextPage: number | string = "";
  public items: WorkspaceAuditEntityModel[] = [];
  public totalCount: number | string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.nextPage = obj.nextPage === null? "" : obj.nextPage;
  this.items = obj.items?.filter((item: any) => item !== undefined).map((item: any) => new WorkspaceAuditEntityModel(item)) || [];
  this.totalCount = obj.totalCount === null? "" : obj.totalCount;
}

}
