
export class DependencyModel {
  public value?: string = "";
  public category?: string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.value = obj.value === null? "" : obj.value;
  this.category = obj.category === null? "" : obj.category;
}

}
