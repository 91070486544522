import { FC, useState } from "react"
import { Button } from "components/button/button"
import { useModal } from "context/modal-context"
import { Icon, ICONS } from "components/icon/icon"
import { useDispatch } from "react-redux"
import { RootDispatch } from "store"
import { Input } from "components/form-components/input/input"
import { gray } from "utils/colors"

type Props = {
  setIsDeletedUser: React.Dispatch<React.SetStateAction<boolean>>
}

export const DeleteAccountModal: FC<Props> = ({ setIsDeletedUser }) => {
  const { unSetModal } = useModal()
  const [deleteInput, setDeleteInput] = useState("")
  const [closeModal, setCloseModal] = useState<boolean>(false)

  const dispatch: RootDispatch = useDispatch()

  return (
    <div className="max-w-md">
      {!closeModal ? (
        <>
          <div className="grid">
            <Icon
              icon={ICONS.DELETE}
              className="text-red-600 justify-self-center mb-7"
              size={10}
            />
            <span className="text-gray-900 font-bold text-title-3 text-center pb-6">
              You are about to delete your account!
            </span>
          </div>
          <div className="border-t border-gray-300 mb-6" />
          <div className="grid grid-flow-row">
            <span className="text-red-600 font-bold text-body pb-4">
              Warning: this action cannot be undone!
            </span>
            <span className="text-gray-500 text-footnote pb-6">
              The account will no longer be available, and all the data in the account
              will be permanently deleted.
            </span>
            <span className="text-gray-900 pb-1">
              To confirm this, type “DELETE” below:
            </span>

            <Input
              style={{ backgroundColor: gray[50], borderColor: gray[300] }}
              placeholder="Type here"
              required
              onChange={(event: any) => {
                setDeleteInput(event.target.value)
              }}
            />

            <div className="flex justify-between mt-7">
              <Button variant="outlined" onClick={unSetModal}>
                Cancel
              </Button>
              <Button
                variant="danger"
                disabled={deleteInput !== "DELETE"}
                onClick={() => {
                  dispatch.account.deleteAccount().then(() => {
                    setCloseModal(true)
                  })
                }}
              >
                Yes, Delete Account
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="grid max-w-sm">
          <span className="text-title-3 font-regular justify-self-center mb-5 text-red-600">
            Account deleted
          </span>
          <span className="text-gray-500 text-body font-medium text-center">
            Your account has been deleted successfully. All your data was removed from our
            services. Thank you for using Content Status.
          </span>
          <div className="justify-self-center mt-12">
            <Button
              variant="outlined"
              onClick={() => {
                unSetModal()
                setIsDeletedUser(true)
              }}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
