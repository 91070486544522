import { AccuracyAnalysisResultModel } from './AccuracyAnalysisResultModel';
import { ComplexAccuracyAnalysisResultModel } from './ComplexAccuracyAnalysisResultModel';

export class MarketingSectionModel {
  public title: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
  public description: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
  public bullet: ComplexAccuracyAnalysisResultModel = {} as ComplexAccuracyAnalysisResultModel;
constructor(obj = {} as any) {
  obj = obj || {};
  this.title = !obj.title ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.title);
  this.description = !obj.description ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.description);
  this.bullet = !obj.bullet ? new ComplexAccuracyAnalysisResultModel() : new ComplexAccuracyAnalysisResultModel(obj.bullet);
}

}
