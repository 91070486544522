import { TagsToolTipInfoIcon } from "../tags-info/tags-info"

const WARNING =
  "You can only add custom tags for now. Once you have selected at least one Record, you will be able to add those tags to the product."
const SUB_HEADER = "Select only the Tags you want associated with these specific SKUS."

export const TagsSubHeader = (props: {
  itemId: string | null
  selectedProdNo: number
}) => {
  const { selectedProdNo, itemId } = props

  return (
    <>
      {selectedProdNo === 0 && !itemId ? (
        <span className="mb-2 text-sub-headline font-medium text-red-600">
          <span className="mr-1">Warning!</span>
          <span>{WARNING}</span>
        </span>
      ) : (
        <span className="mb-2 flex">
          <span className="text-sub-headline font-medium text-gray-500">
            {SUB_HEADER}
          </span>
          <TagsToolTipInfoIcon />
        </span>
      )}
    </>
  )
}
