import { FC } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Icon, ICONS } from "components/icon/icon"

import "./breadcrumbs.scss"

export const ROUTE_TO_INCLUDE = "$"

type Props = {
  routes: Array<{
    label: string
    path: string
  }>
  className?: string
}

export const Breadcrumbs: FC<Props> = ({ routes, className }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()

  const isCurrent = (path: string) => {
    if (path.startsWith(ROUTE_TO_INCLUDE)) {
      return location.pathname.includes(path.replace(ROUTE_TO_INCLUDE, ""))
    }
    return location.pathname.endsWith(path)
  }

  return (
    <div className={`breadcrumbs ${className}`}>
      {routes
        .filter((route) =>
          location.pathname.includes(
            route.path.startsWith(ROUTE_TO_INCLUDE)
              ? route.path.replace(ROUTE_TO_INCLUDE, "")
              : route.path,
          ),
        )
        .map((route) => (
          <button
            key={route.label}
            className={`breadcrumb ${isCurrent(route.path) ? "active" : ""}`}
            onClick={() => navigate(route.path)}
            type="button"
          >
            {route.label}
            {!isCurrent(route.path) && (
              <span className="breadcrumb-separator">
                <Icon icon={ICONS.CHEVRON_RIGHT} size={3} />
              </span>
            )}
          </button>
        ))}
    </div>
  )
}
