import { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"
import * as yup from "yup"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AuditSubmitButtons } from "../audit-submit-buttons/audit-submit-buttons"
import { Header } from "components/header/header"
import { SubscriptionPlanType } from "common/enums/SubscriptionPlanType.enum"
import { ScheduledJobType } from "common/enums/ScheduledJobType.enum"
import { AuditStartTime } from "./audit-start-time/audit-start-time"
import { AuditPlanCards, PlansObj } from "./audit-plan-cards/audit-plan-cards"
import { AuditAddTags } from "./audit-add-tags/audit-add-tags"
import { AuditAddKeywords } from "./audit-add-keywords/audit-add-keywords"

import "./schedule-audit.scss"

type Props = {
  onSubmit: (values: any) => void
  handleBack: () => void
  isDisabledBack: boolean
}

const schema = yup.object({
  planId: yup.string().required(),
  runNow: yup.boolean().required(),
  systemTags: yup.array().required(),
  customTags: yup.array().required(),
  primaryKeywords: yup.array(),
  secondaryKeywords: yup.array(),
})

export const ScheduleAudit: FC<Props> = ({
  onSubmit,
  handleBack,
  isDisabledBack,
}: Props) => {
  const dispatch: RootDispatch = useDispatch()

  const selectedWorkspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )
  const workspacePlans = useSelector(
    (state: RootState) => state.plans.workspaceAssignedPlans,
  )
  const auditNextExecutionDate = useSelector(
    (state: RootState) => state.plans.auditNextExecutionDate,
  )
  const audits = useSelector((state: RootState) => state.audits)

  const [availablePlansObj, setAvailablePlansObj] = useState<PlansObj>({})
  const [scheduleType, setScheduleType] = useState<ScheduledJobType | null>(null)

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: (() => {
      const planId =
        workspacePlans &&
        workspacePlans.find(
          (plan) => plan.subscriptionPlanId === audits.selectedAudit?.planId,
        )
          ? audits.selectedAudit?.planId
          : undefined
      return {
        planId,
        runNow: planId ? audits.selectedAudit?.runNow : undefined,
        systemTags: audits.selectedAudit?.systemTags || [],
        customTags: audits.selectedAudit?.customTags || [],
        primaryKeywords: audits.selectedAudit?.primaryKeywords || [],
        secondaryKeywords: audits.selectedAudit?.secondaryKeywords || [],
      }
    })(),
  })

  const watchPlanId = methods.watch("planId")
  const watchRunNow = methods.watch("runNow")
  const systemTags = methods.watch("systemTags")
  const customTags = methods.watch("customTags")

  const isSubmitDisabled =
    watchPlanId === undefined ||
    watchRunNow === undefined ||
    (systemTags.length === 0 && customTags.length === 0)

  const updateScheduleType = (withRunNowCheck = false) => {
    const selectedPlan = workspacePlans.find(
      (plan) => plan.subscriptionPlanId === watchPlanId,
    )
    if (selectedPlan?.subscriptionPlanType !== undefined) {
      const subscriptionPlanType = SubscriptionPlanType[selectedPlan.subscriptionPlanType]
      const isRunningNow = !(subscriptionPlanType in ScheduledJobType)
      const newScheduleType = isRunningNow
        ? null
        : ScheduledJobType[subscriptionPlanType as keyof typeof ScheduledJobType]

      if (
        newScheduleType !== null &&
        auditNextExecutionDate[newScheduleType] === undefined
      ) {
        dispatch.plans.fetchAuditNextExecutionDate(newScheduleType)
      }

      if (isRunningNow) {
        methods.setValue("runNow", isRunningNow)
      }
      if (withRunNowCheck && typeof audits.selectedAudit?.runNow === "boolean") {
        methods.setValue(
          "runNow",
          !isRunningNow ? audits.selectedAudit?.runNow : isRunningNow,
        )
      }
      setScheduleType(newScheduleType)
    }
  }

  useEffect(updateScheduleType, [watchPlanId])

  useEffect(() => {
    dispatch.plans.fetchWorkspaceAssignedPlans([selectedWorkspaceId || ""])
    dispatch.tags.fetchWorkspaceTags()
  }, [])

  useEffect(() => {
    if (audits.selectedAudit) {
      methods.setValue("planId", audits.selectedAudit?.planId)
    }
  }, [audits.selectedAudit?.planId])

  useEffect(() => {
    if (workspacePlans.length) {
      const newAvailablePlansObj: PlansObj = {}
      workspacePlans.forEach(
        (plan) => (newAvailablePlansObj[plan.subscriptionPlanType?.toString()] = plan),
      )
      setAvailablePlansObj(newAvailablePlansObj)

      const selectedPlan = workspacePlans.find(
        (plan) => plan.subscriptionPlanId === watchPlanId,
      )
      if (!(selectedPlan && selectedPlan?.initialNumberOfItems > 0)) {
        methods.setValue("planId", undefined)
      } else {
        updateScheduleType(true)
      }
    }
  }, [workspacePlans])

  return (
    <FormProvider {...methods}>
      <form
        className="add-edit-audit-card-content"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="add-edit-audit-card-body">
          <div className="schedule-audit">
            <AuditPlanCards name="planId" availablePlansObj={availablePlansObj} />

            {watchPlanId !== undefined && (
              <AuditStartTime
                name="runNow"
                scheduleType={scheduleType}
                auditNextExecutionDate={
                  scheduleType !== null ? auditNextExecutionDate[scheduleType] : undefined
                }
              />
            )}

            {watchRunNow !== undefined && (
              <>
                <hr className="my-5" />
                <Header
                  title="Add Tags & Keywords"
                  description={
                    <span>
                      In order to advance to the next step, the{" "}
                      <b className="text-gray-900">Tagged Products</b> field is mandatory.
                      It is recommended to add <b className="text-gray-900">My Brands</b>{" "}
                      and <b className="text-gray-900">Competitor SKU</b> tags if you want
                      this audit to appear in{" "}
                      <b className="text-gray-900">Content Insights</b>.
                    </span>
                  }
                />

                <AuditAddTags systemTagsName="systemTags" customTagsName="customTags" />

                <AuditAddKeywords
                  primaryKeywordsName="primaryKeywords"
                  secondaryKeywordsName="secondaryKeywords"
                />
              </>
            )}
          </div>
        </div>

        <AuditSubmitButtons
          handleBack={handleBack}
          isDisabledBack={isDisabledBack}
          isDisabledSubmit={isSubmitDisabled}
        />
      </form>
    </FormProvider>
  )
}
