type State = Readonly<{
  path: string
}>

const model = {
  state: {
    path: "/main/dashboard",
  } as State,
  reducers: {
    setPath: (state: State, payload: any): State => ({
      ...state,
      path: payload,
    }),
  },
}

export default model
