type State = Readonly<{
  target: any
}>

const model = {
  state: {
    target: null,
  } as State,
  reducers: {
    setTarget: (state: State, payload: any): State => ({
      ...state,
      target: payload,
    }),
  },
}

export default model
