import { PlansModel } from 'common/models/PlansModel';
import { SubscriptionPlanModel } from 'common/models/SubscriptionPlanModel';
import { AvailablePlanModel } from 'common/models/AvailablePlanModel';
import { WorkspacePlanModel } from 'common/models/WorkspacePlanModel';
import { SubscriptionUpdateModel } from 'common/models/SubscriptionUpdateModel';
import { API } from "../api";

export const getSystemPlansApi = (): Promise<PlansModel> => API().get(`subscriptions/systemplans`);

export const subscribeUserPlanApi = (planId: string): Promise<SubscriptionPlanModel> => API().post(`subscriptions/subscribe/${planId}`);

export const assignPlanToWorkspaceApi = (subscriptionPlanId: string, body: string[]): Promise<boolean> => API().put(`subscriptions/assign/${subscriptionPlanId}`, body);

export const unAssignPlanToWorkspaceApi = (subscriptionPlanId: string, body: string[]): Promise<boolean> => API().put(`subscriptions/un-assign/${subscriptionPlanId}`, body);

export const askUnAssignPlanToWorkspaceApi = (subscriptionPlanId: string): Promise<boolean> => API().put(`subscriptions/ask-un-assign/${subscriptionPlanId}`);

export const getAvailablePlansDetailsApi = (): Promise<AvailablePlanModel[]> => API().get(`subscriptions/availableplans`);

export const getWorkspaceAssignedPlansApi = (body: string[]): Promise<WorkspacePlanModel[]> => API().post(`subscriptions/workspaces/assignedPlans`, body);

export const updateSubscriptionPlanApi = (body: SubscriptionUpdateModel): Promise<boolean> => API().put(`subscriptions`, body);

export const cancelSubscriptionApi = (subscriptionId: string): Promise<boolean> => API().delete(`subscriptions/${subscriptionId}`);
