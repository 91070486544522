import "./notifications-list.scss"
import { FC } from "react"
import { NotificationPriority } from "common/enums/NotificationPriority.enum"
import { NotificationModel } from "common/models/NotificationModel"
import { useDispatch } from "react-redux"
import { RootDispatch } from "store"
import { Icon, ICONS } from "components/icon/icon"
import { getHourFormatAmPm, getMonth } from "utils"

type Props = {
  notifications: NotificationModel[]
  variant: "pop-up" | "page"
}

export const NotificationsList: FC<Props> = ({ notifications, variant }) => {
  const markAsRead = useDispatch<RootDispatch>().notifications.markAsRead

  const getNotificationDisplayDate = (date: string) => {
    const localDate = new Date(date)
    const stringDate =
      getMonth(date) +
      " " +
      localDate.getDate() +
      ", " +
      getHourFormatAmPm(localDate.getHours(), localDate.getMinutes())

    return stringDate
  }

  return (
    <div className={`notifications-list ${variant}`}>
      {notifications.length > 0 ? (
        <>
          <span className="list-title">{variant === "pop-up" ? "LATEST" : "Latest"}</span>
          {notifications.map((notification: any) => (
            <div
              key={notification.id}
              className={`notification ${
                notification.isRead ? "bg-white" : "bg-gray-50 cursor-pointer"
              }`}
              onClick={() => {
                if (!notification.isRead) {
                  markAsRead([notification.id])
                }
              }}
            >
              <div className="grid">
                <span className="notification-type">{notification.title}</span>
                <span className="notification-description">
                  {notification.description}
                </span>
              </div>
              <div className="notification-details">
                <span className="notification-date">
                  {getNotificationDisplayDate(notification.createdAt)}
                </span>
                <div className={`notification-priority`}>
                  <span
                    className={`${NotificationPriority[
                      notification.notificationPriority
                    ].toLowerCase()}`}
                  >
                    {NotificationPriority[
                      notification.notificationPriority
                    ].toUpperCase()}
                  </span>
                </div>
              </div>
              {!notification.isRead && <div className="unread-dot"></div>}
            </div>
          ))}
        </>
      ) : (
        <div
          className="grid justify-self-center m-10"
          style={{ gridTemplateColumns: "0.5fr 4fr" }}
        >
          <Icon icon={ICONS.BELL} />
          <span className="text-body font-medium text-gray-900 ml-2">
            No Notifications.
          </span>
        </div>
      )}
    </div>
  )
}
