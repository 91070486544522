module.exports = { 
  gray: {
    50: "#F8FAFD",
    100: "#F3F4F6",
    200: "#E5E7EB",
    300: "#D1D5DB",
    400: "#9CA3AF",
    500: "#6B7280",
    600: "#4B5563",
    700: "#374151",
    800: "#1F2937",
    900: "#111827",
  },
  violet: {
    50: "#F5F3FF",
    100: "#EDE9FE",
    200: "#DDD6FE",
    300: "#C4B5FD",
    400: "#A78BFA",
    500: "#8B5CF6",
    600: "#7C3AED",
    700: "#6D28D9",
    800: "#5B21B6",
    900: "#4C1D95",
  },
  red: {
    50: "#FFEAEE",
    100: "#FFCCD2",
    200: "#F99798",
    300: "#F26D6F",
    400: "#FD464A",
    500: "#FF2E2C",
    600: "#F4202D",
    700: "#E20E28",
    800: "#D5001F",
    900: "#C70010",
  },
  yellow: {
    50: "#FFF8E1",
    100: "#FFEBB2",
    200: "#FFDF81",
    300: "#FFD44D",
    400: "#FFC825",
    500: "#FFBF00",
    600: "#FFB100",
    700: "#FF9E00",
    800: "#FF8D00",
    900: "#FF6D00",
  },
  green: {
    50: "#E5FAEA",
    100: "#C1F2CA",
    200: "#95EAA8",
    300: "#5EE282",
    400: "#14DA63",
    500: "#00D242",
    600: "#00C138",
    700: "#00AE2A",
    800: "#009C1A",
    900: "#007B00",
  },
  blue: {
    50: "#e0f4fd",
    100: "#b0e3fa",
    200: "#7bd0f6",
    300: "#43bef2",
    400: "#00b0f0",
    500: "#00a2ed",
    600: "#0095DF",
    700: "#0082cb",
    800: "#0071b7",
    900: "#005196",
  },
  fuchsia: {
    50: "#FDF4FF",
    100: "#FAE8FF",
    200: "#F5D0FE",
    300: "#F0ABFC",
    400: "#E879F9",
    500: "#D946EF",
    600: "#BF2CD6",
    700: "#9704AE",
    800: "#790A90",
    900: "#66009E",
  },
}
