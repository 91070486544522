import { useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import { RootState } from "store"
import { DashboardCardItemModel } from "common/models/DashboardCardItemModel"
import { Icon, ICONS } from "components/icon/icon"
import { getHealthScoreRequestOptions } from "../columns"
import { getLabelMaxWidthForScreen } from "utils"

type Props = {
  data: DashboardCardItemModel[]
  title?: any
  infoPointDescription?: string
  myBrandFill?: string
  competitorFill?: string
}

const HEALTH_SCORE_BY_ID = "HEALTH_SCORE_BY_ID"

export const HealthScoreBy = ({
  data = [],
  title,
  infoPointDescription = "Description here",
  myBrandFill = "#0095DF",
  competitorFill = "#B0E3FA",
}: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auditCatalog = useSelector((state: RootState) => state.auditCatalog)

  const divID = HEALTH_SCORE_BY_ID + title.value

  function createSeries(
    field: string,
    name: string,
    seriesColor: any,
    root: any,
    xAxis: any,
    yAxis: any,
    chart: any,
    chartData: any,
  ) {
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: field,
        categoryYField: "title",
        sequencedInterpolation: true,
      }),
    )

    // navigation click event
    const navigateToFilteredCatalog = (ev: any): void => {
      const isMyBrand = ev.target.dataItem.component._settings.valueXField === "myBrand"
      const dataFilters = ev.target.dataItem.dataContext.filters

      const requestOptions = getHealthScoreRequestOptions(
        dataFilters,
        isMyBrand,
        auditCatalog,
      )
      dispatch.auditCatalog.setRequestOptions(requestOptions)

      navigate("/main/audit-catalog")
    }

    series.columns.template.events.on("click", (ev: any) => {
      navigateToFilteredCatalog(ev)
    })

    series.columns.template.set("fill", am5.color(seriesColor))
    series.columns.template.setAll({
      height: am5.p100,
      strokeOpacity: 0,
    })

    series.columns.template.states.create("hover", {
      strokeOpacity: 1,
      opacity: 0.3,
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    })

    series.bullets.push(function () {
      const valueLabel = am5.Label.new(root, {
        centerY: am5.p50,
        text: "{valueX}",
        populateText: true,
        fill: am5.color("#0095DF"),
        background: am5.Rectangle.new(root, {
          fill: am5.color(0x000000),
          fillOpacity: 0,
        }),
        cursorOverStyle: "pointer",
      })

      valueLabel.events.on("click", function (ev: any) {
        navigateToFilteredCatalog(ev)
      })

      return am5.Bullet.new(root, {
        locationX: 1,
        locationY: 0.5,
        sprite: valueLabel,
      })
    })

    series.data.setAll(chartData)
    series.appear()

    return series
  }

  //chart data + init
  useLayoutEffect(() => {
    if (data.length) {
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      const root = am5.Root.new(divID)

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)])

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "none",
          wheelY: "none",
          paddingLeft: 0,
          layout: root.verticalLayout,
          interactive: true,
        }),
      )

      // Data
      const chartData = data.map((d) => ({
        ...d,
        myBrand: Number(d.myBrand?.replace(",", ".")),
        competitorSku: Number(d.competitorSku?.replace(",", ".")),
      }))

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      const yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "title",
          renderer: am5xy.AxisRendererY.new(root, {
            inversed: true,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
          }),
        }),
      )

      yAxis.get("renderer").grid.template.set("forceHidden", true)
      yAxis.get("renderer").labels.template.setAll({
        centerX: am5.p0,
        fill: am5.color(0x6b7280),
        // responsive maxWidth related to screen width
        maxWidth: getLabelMaxWidthForScreen(window.screen.width),
        oversizedBehavior: "wrap",
      })
      yAxis.data.setAll(chartData)

      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {
            strokeOpacity: 0.1,
            minGridDistance: 50,
            visible: false,
          }),
          min: 0,
          max: 5,
        }),
      )
      xAxis.get("renderer").grid.template.set("forceHidden", true)
      xAxis.get("renderer").labels.template.set("visible", false)

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      createSeries(
        "myBrand",
        "My Brand",
        myBrandFill,
        root,
        xAxis,
        yAxis,
        chart,
        chartData,
      )
      createSeries(
        "competitorSku",
        "Competitor",
        competitorFill,
        root,
        xAxis,
        yAxis,
        chart,
        chartData,
      )

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      const legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
          marginTop: 5,
        }),
      )

      legend.data.setAll(chart.series.values)
      chart.zoomOutButton.set("forceHidden", true)

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}))
      cursor.lineY.set("forceHidden", true)
      cursor.lineX.set("forceHidden", true)

      //remove logo
      root._logo?.dispose()

      return () => {
        root.dispose()
      }
    }
  }, [data])

  return (
    <div className="dashboard-aggregated-card h-full flex flex-col shadow rounded-lg bg-white p-5 pb-1">
      <div className="flex flex-col p-2 relative">
        <div className="flex items-center">
          <div className="text-title-2 text-gray-500 mr-2">{title.name}</div>
          <div className="font-bold text-title-2 flex">{title.value}</div>
        </div>

        <span className="mr-2 self-start absolute -top-2 -right-5 text-gray-500">
          <Icon
            icon={ICONS.INFORMATION_CIRCLE_OUTLINED}
            tippyProps={{
              placement: "left",
              maxWidth: 250,
              content: (
                <div className="info-point">
                  <span className="text-caption-1 font-medium text-black">
                    {title.name} {title.value}
                  </span>
                  <div className="text-caption-1 text-gray-500 self-center font-normal">
                    {infoPointDescription}
                  </div>
                </div>
              ),
            }}
          />
        </span>
      </div>
      <div className="h-[400px]">
        <div id={divID} className="w-full h-full" />
      </div>
    </div>
  )
}
