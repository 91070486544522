
export class ItemAlertsModel {
  public name?: string = "";
  public id?: string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.name = obj.name === null? "" : obj.name;
  this.id = obj.id === null? "" : obj.id;
}

}
