import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { RootDispatch, RootState } from "store"
import { useDispatch, useSelector } from "react-redux"
import { Home } from "pages/home/home"
import { Main } from "pages/main/main"
import { IPublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { Invite } from "pages/invite/invite"
import { Onboarding } from "pages/onboarding/onboarding"
import { AnalyticsBrowser } from "@segment/analytics-next"
import {
  errorEventInstance,
  ERROR_PAGE_NOT_FOUND,
  ERROR_WORKSPACE_ERROR,
} from "utils/error-event-instance"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { WorkspaceError } from "pages/error/error"
import { useEffect } from "react"
import { useModal } from "context/modal-context"
import { checkCatalogURL } from "api/api"
import Tippy, { useSingleton } from "@tippyjs/react"
import { updatePageTitle } from "utils/pageTitle"

export const analytics = AnalyticsBrowser.load({
  writeKey:
    process.env.REACT_APP_REDIRECT_URI === "https://dev.contentstatus.com"
      ? "XRFzqP6SSnvWYmBYTXh3i7R6u7VFDnJr"
      : process.env.REACT_APP_REDIRECT_URI === "https://my.contentstatus.com"
      ? "mRMwADaQDvFrPAcoOHmdCFGFvlavnBWA"
      : "",
})

type Props = {
  msalInstance: IPublicClientApplication
}

export const App = ({ msalInstance }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch: RootDispatch = useDispatch()
  const { unSetModal } = useModal()
  const selectedWorkspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )
  //tippy singleton instance to be used in entire app
  const [source, target] = useSingleton({
    overrides: ["placement"],
  })

  useEffect(() => {
    networkErrorBoundary()
    return () => {
      errorEventInstance.removeListener(ERROR_PAGE_NOT_FOUND, () => {})
      errorEventInstance.removeListener(ERROR_WORKSPACE_ERROR, () => {})
    }
  }, [])

  useEffect(() => {
    // save target into store
    dispatch.tippyTarget.setTarget(target)
    // eslint-disable-next-line no-sparse-arrays
  }, [, selectedWorkspaceId])

  useEffect(() => {
    if (location.pathname !== "/main/error" && location.pathname !== "/workspace-error") {
      dispatch.errorPath.setPath(location.pathname)
    }

    unSetModal()
  }, [location.pathname])

  useEffect(() => {
    updatePageTitle(location.pathname, location.hash)
  }, [location.pathname, location.hash])

  const networkErrorBoundary = () => {
    errorEventInstance.on(ERROR_PAGE_NOT_FOUND, (error) => {
      if (location.pathname !== "/main/error") {
        const isCatalogURL = checkCatalogURL(error.path)

        if (
          (window.location.pathname.includes("/main/audit-catalog") && isCatalogURL) ||
          (window.location.pathname !== "/main/audit-catalog" && !isCatalogURL) ||
          (window.location.pathname.includes("/main/alerts") &&
            error.path.includes("alerts")) ||
          (window.location.pathname.includes("/main/downloads") &&
            error.path.includes("exports"))
        ) {
          navigate("/main/error", { replace: true })
          unSetModal()
        }
      }
    })

    errorEventInstance.on(ERROR_WORKSPACE_ERROR, () => {
      if (location.pathname !== "/workspace-error") {
        navigate("/workspace-error", { replace: true })
        unSetModal()
      }
    })
  }

  return (
    <MsalProvider instance={msalInstance}>
      <Tippy singleton={source} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={4}
        bodyClassName="text-gray-500 font-medium text-body"
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/invite/:token" element={<Invite />} />
        <Route path="/main/*" element={<Main />} />
        <Route path="/onboarding/*" element={<Onboarding />} />
        <Route path="/workspace-error" element={<WorkspaceError />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </MsalProvider>
  )
}
