export const StarFull = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.04917 3.92707C9.34852 3.00576 10.6519 3.00576 10.9513 3.92707L12.0209 7.21886C12.1547 7.63089 12.5387 7.90985 12.9719 7.90985H16.4331C17.4018 7.90985 17.8046 9.14946 17.0209 9.71886L14.2207 11.7533C13.8702 12.0079 13.7236 12.4593 13.8575 12.8713L14.927 16.1631C15.2264 17.0844 14.1719 17.8506 13.3882 17.2812L10.588 15.2467C10.2375 14.9921 9.76293 14.9921 9.41244 15.2467L6.61227 17.2812C5.82856 17.8506 4.77408 17.0844 5.07343 16.1631L6.143 12.8713C6.27688 12.4593 6.13022 12.0079 5.77973 11.7533L2.97956 9.71886C2.19585 9.14946 2.59862 7.90985 3.56735 7.90985H7.02855C7.46177 7.90985 7.84573 7.63089 7.9796 7.21886L9.04917 3.92707Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const StarEmpty = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.04917 3.92707C9.34852 3.00576 10.6519 3.00576 10.9513 3.92707L12.0209 7.21886C12.1547 7.63089 12.5387 7.90985 12.9719 7.90985H16.4331C17.4018 7.90985 17.8046 9.14946 17.0209 9.71886L14.2207 11.7533C13.8702 12.0079 13.7236 12.4593 13.8575 12.8713L14.927 16.1631C15.2264 17.0844 14.1719 17.8506 13.3882 17.2812L10.588 15.2467C10.2375 14.9921 9.76293 14.9921 9.41244 15.2467L6.61227 17.2812C5.82856 17.8506 4.77408 17.0844 5.07343 16.1631L6.143 12.8713C6.27688 12.4593 6.13022 12.0079 5.77973 11.7533L2.97956 9.71886C2.19585 9.14946 2.59862 7.90985 3.56735 7.90985H7.02855C7.46177 7.90985 7.84573 7.63089 7.9796 7.21886L9.04917 3.92707Z"
        fill="currentColor"
      />
    </svg>
  );
};
