import { getColumnSummary } from "utils"
import { blue } from "utils/colors"

type Props = {
  summary: any
  summaryFormat: string
}

export const ColumnSummary = ({ summary, summaryFormat }: Props) => {
  const columnSummary = getColumnSummary(summaryFormat, summary)

  return (
    <div
      className="font-semibold text-sub-headline rounded self-end text-white text-center p-1 col-span-full w-full mb-2"
      style={{
        backgroundColor:
          columnSummary.color === undefined ? blue[500] : columnSummary.color,
      }}
    >
      {columnSummary.summary}
    </div>
  )
}
