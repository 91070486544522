import { FC } from "react"
import { Button } from "components/button/button"
import "./catalog-success-modal.scss"

type CatalogSuccessModalProps = {
  onClose: any
  children: any
  buttonLabel?: string
  titleClassName?: string
  buttonClassName?: string
  header?: string
  childrenClassName?: string
}

export const CatalogSuccessModal: FC<CatalogSuccessModalProps> = ({
  onClose,
  children,
  buttonLabel = "View Catalog",
  titleClassName = "text-title-3",
  buttonClassName,
  header,
  childrenClassName,
}) => {
  return (
    <div className="grid mx-4">
      <div className="success-header">
        <span className={titleClassName}>{header ? header : "Success!"}</span>
      </div>
      <div
        className={`text-body font-medium text-gray-500 w-[340px] text-center ${childrenClassName}`}
      >
        {children}
      </div>
      <Button
        className={`justify-self-center mt-12 ${buttonClassName ? buttonClassName : ""}`}
        onClick={() => onClose()}
        variant="outlined"
      >
        {buttonLabel}
      </Button>
    </div>
  )
}
