import { Button } from "components/button/button"
import { CSSProperties, FC } from "react"
import { useFormContext } from "react-hook-form"
import "./audit-submit-buttons.scss"

type Props = {
  submitLabel?: string
  backLabel?: string
  isDisabledSubmit?: boolean
  isDisabledBack?: boolean
  isLoadingSubmit?: boolean
  style?: CSSProperties
  handleBack?: () => void
}

export const AuditSubmitButtons: FC<Props> = ({
  submitLabel = "Next Step",
  backLabel = "Back",
  style,
  handleBack,
  isDisabledBack,
  isDisabledSubmit,
  isLoadingSubmit = false,
}: Props) => {
  const {
    formState: { isValid },
    handleSubmit,
  } = useFormContext()

  return (
    <div className="add-edit-audit-card-footer" style={style}>
      <Button
        className="mr-3"
        variant="outlined"
        onClick={handleBack}
        disabled={isDisabledBack}
      >
        {backLabel}
      </Button>

      <Button
        type="submit"
        disabled={submitLabel ? isDisabledSubmit : !isValid}
        loading={isLoadingSubmit}
        onClick={handleSubmit}
      >
        {submitLabel}
      </Button>
    </div>
  )
}
