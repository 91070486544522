import { Link } from "react-router-dom"

import { Icon, ICONS } from "components/icon/icon"
import { LINK_CLASS_NAME } from "utils/constants"

type Props = {
  emptyTableMessage?: string
  emptyTableLink?: { label: string; route: string }
}

const EmptyLink = ({ emptyTableMessage, emptyTableLink }: Props) => {
  return (
    <div className="flex items-center justify-center gap-2 absolute left-0 right-0 mt-5">
      <Icon icon={ICONS.BELL} />
      <p className="text-body">{emptyTableMessage ?? "No items found."}</p>
      {emptyTableLink && (
        <Link to={emptyTableLink.route} className={`${LINK_CLASS_NAME} font-medium`}>
          {emptyTableLink.label}
        </Link>
      )}
    </div>
  )
}

export default EmptyLink
