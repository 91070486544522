import { FC, useEffect } from "react"
import { useSelector } from "react-redux"
import * as yup from "yup"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { RootState } from "store"
import { AuditSubmitButtons } from "../audit-submit-buttons/audit-submit-buttons"
import { retailersToOptions } from "utils"
import { SelectRetailer } from "components/select-retailer/select-retailer"

type Props = {
  onSubmit: (values: any) => void
  handleBack: () => void
  isDisabledBack: boolean
}

const schema = yup.object({
  auditName: yup.string().required("Required").trim().max(280),
  retailer: yup.object().required("Required"),
})

export const SelectAuditRetailer: FC<Props> = ({
  onSubmit,
  handleBack,
  isDisabledBack,
}: Props) => {
  const selectedAudit = useSelector((state: RootState) => state.audits.selectedAudit)
  const allRetailers = useSelector((state: RootState) => state.retailers.allRetailers)
  const frequentlyUsedRetailers = useSelector(
    (state: RootState) => state.retailers.frequentlyUsedRetailers,
  )
  const selectedRetailer =
    allRetailers.find((r) => r.id === selectedAudit?.retailerId) ??
    frequentlyUsedRetailers.find((r) => r.id === selectedAudit?.retailerId)
  const defaultRetailerValue = selectedRetailer
    ? retailersToOptions([selectedRetailer])[0]
    : undefined

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      auditName: selectedAudit?.name,
      retailer: defaultRetailerValue,
    },
  })
  const isSubmitDisable =
    !(!!methods.watch("retailer.value") && !!methods.watch("auditName")) ||
    methods.formState.errors.auditName !== undefined

  useEffect(() => {
    methods.setValue("auditName", selectedAudit?.name)
    methods.setValue("retailer", defaultRetailerValue)
  }, [selectedAudit])

  return (
    <FormProvider {...methods}>
      <form
        className="add-edit-audit-card-content mx-5"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <SelectRetailer name="retailer" />
        <AuditSubmitButtons
          handleBack={handleBack}
          isDisabledBack={isDisabledBack}
          isDisabledSubmit={isSubmitDisable}
          style={{ width: "calc(100% + 40px)", marginLeft: "-20px" }}
        />
      </form>
    </FormProvider>
  )
}
