import { kebabToStartCase } from "./index"

const PAGE_TITLE = "Content Status"

const TitleByRoute: any = {
  dashboard: "Dashboard",
  "share-of-search": "Share-Of-Search",
  audits: {
    label: "My Audits",
    subRoutes: {
      "my-audits": {
        byHash: {
          "#0": "One Time",
          "#1": "Daily",
          "#2": "Weekly",
          "#3": "Monthly",
        },
      },
      "new-audit": "New Audit",
      id: "Edit Audit",
    },
  },
  grading: {
    label: "Customize Scorecards",
    subRoutes: {
      scorecards: "List",
      "new-scorecard": "New",
      "manage-defaults": "Manage Defaults",
      "new-scorecard-copy": "Duplicate",
      id: "Edit",
    },
  },
  alerts: {
    label: "Create Alerts",
    subRoutes: {
      list: "List",
      create: "New Rule",
      "copy-alert": "Duplicate",
      edit: "Edit",
    },
  },
  "audit-catalog": {
    label: "Catalog",
    subRoutes: {
      id: "Audit",
    },
  },
  reports: "Reports",
  account: "Account",
  billing: {
    label: "Billing & Plans",
    subRoutes: {
      plans: "Plans",
      "available-plan": "Available Plans",
    },
    noSubRoutes: "Billing",
  },
  workspaces: {
    label: "Workspaces",
    lastSubRoutes: {
      upload: "Upload Product Data",
    },
  },
  downloads: "Downloads",
  invoices: "Invoices",
  error: "Error",
  notifications: "Notifications",
  onboarding: {
    label: "Onboarding",
    byHash: {
      "#0": "#1",
      "#1": "#2",
      "#2": "#3",
      "#3": "#4",
    },
  },
  "field-mapping": {
    label: "Upload Product Data",
    lastSubRoutes: {
      duplicate: "Duplicate",
      edit: "Edit",
    },
    noSubRoutes: "New",
    byHash: {
      "#0": "#1",
      "#1": "#2",
      "#2": "#3",
    },
  },
  "content-insights": "Content Insights",
}

const addRoute = (inStr: string) => ` | ${inStr}`
const addHash = (inStr: string) => ` ${inStr}`

export const updatePageTitle = (pathname: string, hash: string) => {
  const fullRoute = pathname?.replace("/main", "")?.replace("/", "").split("/")
  const route = fullRoute[0]
  let withHashFlag = true
  let pageTitle = PAGE_TITLE

  if (route in TitleByRoute) {
    const titleRoute = TitleByRoute[route as keyof typeof TitleByRoute]

    if (typeof titleRoute === "string") {
      pageTitle += addRoute(titleRoute)
    } else {
      pageTitle += addRoute(titleRoute.label)
      if (fullRoute.length > 1) {
        const subRoute = fullRoute[1]
        if (subRoute) {
          if (titleRoute.subRoutes) {
            if (subRoute in titleRoute.subRoutes) {
              if (typeof titleRoute.subRoutes[subRoute] === "string") {
                pageTitle += addRoute(titleRoute.subRoutes[subRoute])
              } else if (hash && hash in titleRoute.subRoutes[subRoute].byHash) {
                pageTitle += addRoute(titleRoute.subRoutes[subRoute].byHash[hash])
                withHashFlag = false
              }
            } else if (titleRoute.subRoutes?.id !== undefined && subRoute?.length > 0) {
              if (fullRoute.length > 2) {
                pageTitle += addRoute(kebabToStartCase(fullRoute[fullRoute.length - 1]))
              } else {
                pageTitle += addRoute(titleRoute.subRoutes.id)
              }
            }
          } else if (titleRoute.lastSubRoutes) {
            const lastSubRoute = fullRoute[fullRoute.length - 1]
            if (lastSubRoute && lastSubRoute in titleRoute.lastSubRoutes) {
              pageTitle += addRoute(titleRoute.lastSubRoutes[lastSubRoute])
            } else if (fullRoute.length > 2) {
              pageTitle += addRoute(kebabToStartCase(fullRoute[fullRoute.length - 1]))
            }
          }
        }
      } else if (titleRoute.noSubRoutes) {
        pageTitle += addRoute(titleRoute.noSubRoutes)
      }
      if (titleRoute.byHash && hash && hash in titleRoute.byHash) {
        pageTitle += addHash(titleRoute.byHash[hash])
        withHashFlag = false
      }
    }
  }

  if (withHashFlag && hash) {
    pageTitle += addHash(hash)
  }

  if (pageTitle !== document.title) {
    document.title = pageTitle
  }
}
