import React, { useEffect, useLayoutEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../store"
import isEmpty from "is-empty"
import { Input } from "../../../components/form-components/input/input"
import { Select } from "../../../components/form-components/select/select"
import { Button } from "../../../components/button/button"
import {
  dataSamplePreviewApi,
  getCatalogHeadersApi,
} from "../../../api/endpoints/data-mapping-import.api"
import { Icon, ICONS } from "../../../components/icon/icon"
import { SecondStepTypes } from "../field-mapping"
import "../field-mapping.scss"

interface Props {
  data: SecondStepTypes
  step: number
  setStep: (st: number) => void
  setData: React.Dispatch<React.SetStateAction<SecondStepTypes>>
  onCancel: () => void
  setStepThreeData: React.Dispatch<React.SetStateAction<any>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  scrollToTop: () => void
}

export const StepTwo: React.FC<Props> = ({
  data,
  step,
  setStep,
  setData,
  onCancel,
  setStepThreeData,
  setLoading,
  scrollToTop,
}) => {
  const [catalogHeaders, setCatalogHeaders] = useState<string[]>([])
  const [selectedHeaders, setSelectedHeaders] = useState<string[]>(
    data?.mappings?.map((el) => Object.values(el))?.flat(),
  )
  const selectedWorkspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )
  const { mappings } = data

  const getAvailableHeaders = (value: string) => {
    return catalogHeaders.filter(
      (header) =>
        header === value || !selectedHeaders.includes(header) || header === "Do Not Map",
    )
  }

  const renderMappingStatus = (value: string | null) => {
    if (value === null) {
      return (
        <div className="w-32 text-center">
          <div className="text-gray-300">map to</div>
          <div className="mx-4 border border-dashed border-gray-300"></div>
        </div>
      )
    }

    if (value === "Do Not Map") {
      return (
        <div className="w-32 flex items-center justify-center">
          <div className="text-red-600">
            <Icon icon={ICONS.CANCEL_SOLID_ICON} size={6} />
          </div>
        </div>
      )
    }

    return (
      <div className="w-32 text-center">
        <div className="text-green-600">mapped</div>
        <div className="border border-solid border-green-600"></div>
      </div>
    )
  }

  const handleMapSelect =
    (key: string, id: string) => (option: { value: string }, action: any) => {
      const updatedData = mappings.map((el) => {
        if (el[key] !== undefined && el.id === id) {
          return {
            [key]: option?.value || null,
          }
        }
        return el
      })

      setData({
        ...data,
        mappings: updatedData,
      })

      if (action && action.action !== "clear") {
        const selectedMappings = selectedHeaders.filter(
          (header) =>
            new Set(updatedData.map((el) => Object.values(el)).flat()).has(header) ||
            header === option.value,
        )

        return setSelectedHeaders([...selectedMappings, option.value])
      }

      return setSelectedHeaders(
        selectedHeaders.filter(
          (header: string) => header !== action.removedValues[0]?.value,
        ),
      )
    }

  const isNextDisabled =
    isEmpty(mappings) || mappings.some((row: any) => row[Object.keys(row)[0]] === null)

  const onSubmit = () => {
    setLoading(true)

    if (selectedWorkspaceId) {
      const mappings = data.mappings.map((mapping) => {
        const [key, value] = Object.entries(mapping)[0]

        return { fileHeader: key, catalogHeader: value }
      })
      const payload = {
        ...data,
        mappings,
      }

      dataSamplePreviewApi(selectedWorkspaceId, payload).then((resp) => {
        setStepThreeData({ rows: resp, dataMappingId: data.dataMappingId })
        setStep(step + 1)
        setLoading(false)
      })
    }
  }

  useLayoutEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    if (selectedWorkspaceId) {
      getCatalogHeadersApi(selectedWorkspaceId).then((resp) =>
        setCatalogHeaders(["Do Not Map", ...resp]),
      )
    }
  }, [selectedWorkspaceId])

  if (isEmpty(data)) {
    return null
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center">
        <div>
          <div className="title">Edit Mapping</div>
          <div className="description">
            Map the columns from the uploaded file with the predefined ones from the
            application.
            <br></br>
            To continue to the next step, you must select an option for each row.
          </div>
        </div>
        <Button variant="outlined" className="mr-3" onClick={onCancel}>
          Cancel Upload
        </Button>
      </div>
      <div className="divider"></div>
      <div className="mb-6 flex bg-gray-100 p-2 rounded font-medium w-full">
        <span className="w-3/5">File Header</span>
        <span>Catalog Header</span>
      </div>
      <div className="flex-1 overflow-x-auto mb-8">
        {data?.mappings.map((row, index: number) => {
          const [key, value] = Object.entries(row)[0]

          return (
            <div className="flex items-center mb-4 mx-6" key={`${index}-${key}`}>
              <div className="flex-1">
                <Input value={key} readOnly />
              </div>
              {renderMappingStatus(value)}
              <Select
                options={getAvailableHeaders(value)?.map((header) => {
                  return {
                    value: header,
                    label: header,
                  }
                })}
                value={value}
                onChange={handleMapSelect(key, row.id)}
                className="flex-1"
                menuPlacement="auto"
                hideSelectedValues
                isClearable={true}
                isSearchable={true}
                placeholder="None"
                optionRender={(option: any) => (
                  <div
                    className={`${option.label === "Do Not Map" ? "text-red-600" : ""}`}
                  >
                    {option.label}
                  </div>
                )}
              />
            </div>
          )
        })}
      </div>
      <div className="flex justify-end items-center">
        <Button variant="outlined" onClick={() => setStep(step - 1)} className="mr-3">
          Back
        </Button>
        <Button variant="primary" disabled={isNextDisabled} onClick={onSubmit}>
          Next Step
        </Button>
      </div>
    </div>
  )
}
