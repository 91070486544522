import { RematchDispatch } from "@rematch/core"
import { gridPageSizes } from "../utils/constants"
import { UploadInfoTableModel } from "../common/models/UploadInfoTableModel"
import {
  cancelUploadApi,
  getUploadsCountApi,
  getUploadsPaginatedApi,
} from "../api/endpoints/data-mapping-import.api"
import { PageModel } from "../common/models/PageModel"
import { SortDirection } from "../common/enums/SortDirection.enum"
import { WORKSPACE_ID } from "../api/api"

type State = Readonly<{
  uploads: UploadInfoTableModel[]
  totalItemsCount: number
  pageSizeOptions: { label: string; value: number }[]
  requestOptions: PageModel
}>

const model = {
  state: {
    uploads: [],
    totalItemsCount: 0,
    pageSizeOptions: gridPageSizes,
    requestOptions: new PageModel({
      page: 1,
      pageSize: 10,
      sortField: "updatedAt",
      sortDirection: SortDirection.Descending,
    }),
  } as State,
  reducers: {
    setUploads: (state: State, payload: any): State => ({
      ...state,
      uploads: payload,
    }),
    setTotalItemsCount: (state: State, payload: any): State => ({
      ...state,
      totalItemsCount: payload,
    }),
    setRequestOptions: (state: State, payload: any): State => ({
      ...state,
      requestOptions: payload,
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchUploads(payload: any, rootState?: any) {
      const { items } = await getUploadsPaginatedApi(
        payload?.workspaceId ?? WORKSPACE_ID,
        payload?.requestOptions ?? rootState?.uploadProductData?.requestOptions,
      )

      return dispatch.uploadProductData.setUploads(items)
    },
    async fetchTotalCount(payload?: string) {
      const { count } = await getUploadsCountApi(payload ?? WORKSPACE_ID)

      return dispatch.uploadProductData.setTotalItemsCount(count)
    },
    async deleteUpload(payload: any, rootState: any) {
      const workspaceId = payload?.workspaceId ?? WORKSPACE_ID
      const cancelUpload = await cancelUploadApi(workspaceId, payload?.deleteWorkspaceId)

      if (cancelUpload) {
        Promise.all([
          this.fetchTotalCount(workspaceId),
          this.fetchUploads({
            workspaceId,
            requestOptions: rootState.uploadProductData.requestOptions,
          }),
        ])
      }
    },
  }),
}

export default model
