import { Loader } from "components/loader/loader"

const TableLoader = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-white opacity-90 z-[2]">
      <Loader className="w-full text-primary- mt-2" message="Loading..." />
    </div>
  )
}

export default TableLoader
