import { ItemFilterModel } from "common/models/ItemFilterModel"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import { FC } from "react"
import "./flag-pair-model-options.scss"

type Props = {
  trueLabel: string
  falseLabel: string
  filters: ItemFilterModel
  filterName: keyof ItemFilterModel
  setFilters: (filters: ItemFilterModel) => void
}

export const FlagPairModelOptions: FC<Props> = ({
  trueLabel,
  falseLabel,
  filters,
  filterName,
  setFilters,
}: Props) => {
  const setValue = (flag: boolean) => {
    setFilters({
      ...filters,
      [filterName]: filters[filterName] !== flag ? flag : undefined,
    })
  }

  return (
    <div className="flag-model-options">
      <Checkbox
        label={trueLabel}
        value={filters[filterName] === true}
        onChange={() => setValue(true)}
        small
      />
      <Checkbox
        label={falseLabel}
        value={filters[filterName] === false}
        onChange={() => setValue(false)}
        small
      />
    </div>
  )
}
