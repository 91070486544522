import { ReactNode } from "react"
import { Button } from "components/button/button"
import { Header } from "components/header/header"
import { ICONS, Icon } from "components/icon/icon"
import { EMPTY_SKU_ERROR_OBJECT } from "utils/constants"
import { SkuInputError } from "common/types/SkuInputError.type"
import { SkuDetails } from "common/types/SkuDetails.type"

type Props = {
  errorObject: SkuInputError
  skus: (string | SkuDetails)[]
  isSubmitDisabled: boolean
  children: ReactNode
}

export const ReviewUploads = ({
  errorObject,
  skus,
  isSubmitDisabled,
  children,
}: Props) => {
  const copySkusToClipboard = () => {
    navigator.clipboard.writeText(
      skus.map((sku) => typeof sku !== "string" && sku.value).join("\n"),
    )
  }

  return (
    <div>
      <hr className="mt-2.5 mb-5" />
      <Header
        title="Review Uploads"
        description="Review sample of loaded data, verify accuracy, and fix any errors"
        className="col-auto"
      />
      <div className="mb-3">
        {JSON.stringify(errorObject) !== JSON.stringify(EMPTY_SKU_ERROR_OBJECT) ? (
          <>
            <div className="text-red-600 text-sub-headline font-bold">
              Warning! Errors were found in the list below.
            </div>
            <div className="text-gray-500 font-medium">
              You can eliminate each type of error separately.
            </div>
            <div className="text-gray-900 font-medium">
              Be careful because every change made to remove the errors will also affect
              the initial list.
            </div>
          </>
        ) : (
          <>
            SKUs:{" "}
            <span className={skus.length ? "text-green-500" : "text-red-500"}>
              {skus.length}
            </span>
            {skus.length ? (
              <div className="grid grid-flow-col gap-5 my-2">
                Loaded Data (pasted URLs):
              </div>
            ) : null}
          </>
        )}
      </div>

      <div id="reviewUploads" className="grid">
        {skus.length ? (
          <>
            <div className="rounded-lg border border-gray-200 overflow-y-auto max-h-56">
              {skus.map((sku, index) =>
                typeof sku !== "string" ? (
                  <div
                    key={index}
                    className="grid grid-flow-col justify-start gap-x-2 p-2 bg-gray-50 break-all"
                  >
                    {sku.count === 1 &&
                    !sku.ASINError &&
                    !sku.domainError &&
                    !sku.GTINError &&
                    !sku.linkError ? (
                      <Icon icon={ICONS.CHECK} className="text-green-500 mt-0.5" />
                    ) : (
                      <Icon icon={ICONS.CANCEL} className="text-red-500 mt-0.5" />
                    )}
                    {sku.value}
                    {sku.count > 1 && (
                      <div className="text-red-500">({sku.count} duplicates)</div>
                    )}
                    {sku.ASINError && <div className="text-red-500">(ASIN Error)</div>}
                    {sku.domainError && (
                      <div className="text-red-500">(Domain Error)</div>
                    )}
                    {sku.GTINError && <div className="text-red-500">(GTIN Error)</div>}
                    {sku.linkError && <div className="text-red-500">(Link Error)</div>}
                  </div>
                ) : null,
              )}
            </div>
          </>
        ) : null}
        {children}
      </div>
      {Object.values(errorObject).filter((i) => i === true)?.length === 0 && (
        <div className="flex justify-end w-full -mt-2.5">
          <Button
            onClick={copySkusToClipboard}
            variant="outlined"
            disabled={isSubmitDisabled}
            isSmall
          >
            Copy All Links
          </Button>
        </div>
      )}
    </div>
  )
}
