import { UniqueSectionModel } from './UniqueSectionModel';
import { MarketingSectionModel } from './MarketingSectionModel';
import { ImageSectionModel } from './ImageSectionModel';
import { RichContentSectionModel } from './RichContentSectionModel';
import { SpecificationSectionModel } from './SpecificationSectionModel';
import { ItemSnapshotModel } from './ItemSnapshotModel';

export class ItemMonitorModel {
  public uniqueSection: UniqueSectionModel = {} as UniqueSectionModel;
  public marketingSection: MarketingSectionModel = {} as MarketingSectionModel;
  public imageSection: ImageSectionModel = {} as ImageSectionModel;
  public richContentSection: RichContentSectionModel = {} as RichContentSectionModel;
  public specificationSection: SpecificationSectionModel = {} as SpecificationSectionModel;
  public previousItemSnapshot: ItemSnapshotModel = {} as ItemSnapshotModel;
  public accuracy: number | string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.uniqueSection = !obj.uniqueSection ? new UniqueSectionModel() : new UniqueSectionModel(obj.uniqueSection);
  this.marketingSection = !obj.marketingSection ? new MarketingSectionModel() : new MarketingSectionModel(obj.marketingSection);
  this.imageSection = !obj.imageSection ? new ImageSectionModel() : new ImageSectionModel(obj.imageSection);
  this.richContentSection = !obj.richContentSection ? new RichContentSectionModel() : new RichContentSectionModel(obj.richContentSection);
  this.specificationSection = !obj.specificationSection ? new SpecificationSectionModel() : new SpecificationSectionModel(obj.specificationSection);
  this.previousItemSnapshot = !obj.previousItemSnapshot ? new ItemSnapshotModel() : new ItemSnapshotModel(obj.previousItemSnapshot);
  this.accuracy = obj.accuracy === null? "" : obj.accuracy;
}

}
