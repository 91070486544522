import { RematchDispatch } from "@rematch/core"
import {
  getCurrentUserApi,
  updateUserApi,
  deleteUserAccountApi,
  checkUserAllocationApi,
} from "api/endpoints/users.api"
import { getAccountDetailsConstantsApi } from "api/endpoints/utils.api"
import { UserModel } from "common/models/UserModel"
import { UserAccountDetailsModel } from "common/models/UserAccountDetailsModel"

type State = Readonly<{
  isLoggedIn: boolean
  user: UserModel
  userAccountDetailsOptions: UserAccountDetailsModel
}>

const model = {
  state: {
    isLoggedIn: false,
    user: new UserModel(),
    userAccountDetailsOptions: new UserAccountDetailsModel(),
  } as State,
  reducers: {
    loadedUserData: (state: State, payload: UserModel): State => ({
      ...state,
      user: { ...payload },
    }),
    setUserAccountDetailsOptions: (
      state: State,
      payload: UserAccountDetailsModel,
    ): State => ({ ...state, userAccountDetailsOptions: payload }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchUserData() {
      const user = await getCurrentUserApi("")

      if (user) {
        dispatch.account.loadedUserData(user)
      }
    },
    async updateUser(payload: any) {
      const user = await updateUserApi(payload)
      if (user) {
        dispatch.account.loadedUserData(user)
      }
    },
    async fetchUserAccountDetailsOptions() {
      const userAccountDetailsOptions = await getAccountDetailsConstantsApi()

      if (userAccountDetailsOptions) {
        dispatch.account.setUserAccountDetailsOptions(userAccountDetailsOptions)
      }
    },
    async deleteAccount() {
      const isDeleted = await deleteUserAccountApi()

      return isDeleted
    },
    async checkCanBeDeletedAccount() {
      const canBeDeleted = await checkUserAllocationApi()

      return canBeDeleted
    },
  }),
}

export default model
