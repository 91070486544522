import { AccuracyAnalysisResultModel } from './AccuracyAnalysisResultModel';

export class RichContentSectionModel {
  public video: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
  public documents: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
  public viewIn360: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
  public enhanced: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
  public aPlusModules: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
constructor(obj = {} as any) {
  obj = obj || {};
  this.video = !obj.video ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.video);
  this.documents = !obj.documents ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.documents);
  this.viewIn360 = !obj.viewIn360 ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.viewIn360);
  this.enhanced = !obj.enhanced ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.enhanced);
  this.aPlusModules = !obj.aPlusModules ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.aPlusModules);
}

}
