
export class KeywordMatchModel {
  public keyword?: string = "";
  public titleCount: number | string = "";
  public descriptionCount: number | string = "";
  public bulletsCount: number | string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.keyword = obj.keyword === null? "" : obj.keyword;
  this.titleCount = obj.titleCount === null? "" : obj.titleCount;
  this.descriptionCount = obj.descriptionCount === null? "" : obj.descriptionCount;
  this.bulletsCount = obj.bulletsCount === null? "" : obj.bulletsCount;
}

}
