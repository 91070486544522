import React, { FC, useEffect, Suspense } from "react"
import "./alerts.scss"
import { Route, Routes, Navigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { RootDispatch } from "store"
import { SuspenseLoader } from "../../components/suspense-loader/suspense-loader"
import { lazyLoadRetry } from "../../utils/lazyLoadRetry"
import { abortController } from "api/api"

const AlertsList = React.lazy(lazyLoadRetry(() => import("./alerts-list/alerts-list")))
const CreateAlert = React.lazy(lazyLoadRetry(() => import("./create-alert/create-alert")))

export const Alerts: FC = () => {
  const dispatch: RootDispatch = useDispatch()

  useEffect(() => {
    dispatch.alerts.fetchAlerts()
    dispatch.alerts.getDefinitions()

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className="alerts">
      <Suspense fallback={<SuspenseLoader delay={500} />}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/main/alerts/list" />} />
          <Route path="/list" element={<AlertsList />} />
          <Route path="/create" element={<CreateAlert />} />
          <Route path="/edit/:alertId" element={<CreateAlert />} />
          <Route path="/copy-alert" element={<CreateAlert />} />
        </Routes>
      </Suspense>
    </div>
  )
}
