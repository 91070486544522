import { CatalogDynamicFiltersModel } from 'common/models/CatalogDynamicFiltersModel';
import { CatalogRequestOptions } from 'common/models/CatalogRequestOptions';
import { CatalogItemModelPageResultModel } from 'common/models/CatalogItemModelPageResultModel';
import { CatalogItemsCountModel } from 'common/models/CatalogItemsCountModel';
import { CatalogColumnsSummaryModel } from 'common/models/CatalogColumnsSummaryModel';
import { CatalogReportType } from 'common/enums/CatalogReportType.enum';
import { CatalogReportRowModelPageResult } from 'common/models/CatalogReportRowModelPageResult';
import { PageModel } from 'common/models/PageModel';
import { TrendScoreReportRowModelPageResult } from 'common/models/TrendScoreReportRowModelPageResult';
import { TrendScoreReportModel } from 'common/models/TrendScoreReportModel';
import { API } from "../api";

export const getCatalogFiltersApi = (workspaceId: string): Promise<CatalogDynamicFiltersModel> => API().get(`catalog/workspace/${workspaceId}/filters`);

export const getCatalogItemsApi = (workspaceId: string, body: CatalogRequestOptions): Promise<CatalogItemModelPageResultModel> => API().post(`catalog/workspace/${workspaceId}/items`, body);

export const getPreviousVersionCatalogItemsApi = (workspaceId: string, jobInstanceId: string, body: CatalogRequestOptions): Promise<CatalogItemModelPageResultModel> => API().post(`catalog/workspace/${workspaceId}/job-instance/${jobInstanceId}/items`, body);

export const getCatalogItemsCoutnApi = (workspaceId: string, body: CatalogRequestOptions): Promise<CatalogItemsCountModel> => API().post(`catalog/workspace/${workspaceId}/items/count`, body);

export const getPreviousVersionCatalogItemsCountApi = (workspaceId: string, jobInstanceId: string, body: CatalogRequestOptions): Promise<CatalogItemsCountModel> => API().post(`catalog/workspace/${workspaceId}/job-instance/${jobInstanceId}/items/count`, body);

export const getCatalogColumnsSummaryApi = (workspaceId: string): Promise<CatalogColumnsSummaryModel> => API().get(`catalog/workspace/${workspaceId}/columnsSummary`);

export const getCatalogColumnsSummaryFilteredApi = (workspaceId: string, body: CatalogRequestOptions): Promise<CatalogColumnsSummaryModel> => API().post(`catalog/workspace/${workspaceId}/columnsSummaryFiltered`, body);

export const getPreviousVersionCatalogColumnsSummaryApi = (workspaceId: string, jobInstanceId: string, body: CatalogRequestOptions): Promise<CatalogColumnsSummaryModel> => API().post(`catalog/workspace/${workspaceId}/job-instance/${jobInstanceId}/columnsSummary`, body);

export const getFilteredCatalogReportsApi = (workspaceId: string, reportType: CatalogReportType, body: CatalogRequestOptions): Promise<CatalogReportRowModelPageResult> => API().post(`catalog/workspace/${workspaceId}/reports/${reportType}/filtered`, body);

export const getTrendScoreReportsApi = (workspaceId: string, reportType: CatalogReportType, body: PageModel): Promise<TrendScoreReportRowModelPageResult> => API().post(`catalog/workspace/${workspaceId}/trend-score-reports/${reportType}/rows`, body);

export const getFilteredTrendScoreReportsApi = (workspaceId: string, reportType: CatalogReportType, body: CatalogRequestOptions): Promise<TrendScoreReportRowModelPageResult> => API().post(`catalog/workspace/${workspaceId}/trend-score-reports/${reportType}/rows/filtered`, body);

export const getTrendReportApi = (workspaceId: string, reportType: CatalogReportType): Promise<TrendScoreReportModel> => API().get(`catalog/workspace/${workspaceId}/trend-score-reports/${reportType}`);

export const getCatalogReportsApi = (workspaceId: string, reportType: CatalogReportType, body: PageModel): Promise<CatalogReportRowModelPageResult> => API().post(`catalog/workspace/${workspaceId}/reports/${reportType}`, body);

export const deleteCatalogItemsApi = (workspaceId: string, body: string[]): Promise<boolean> => API().post(`catalog/workspace/${workspaceId}/items/delete`, body);
