import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Menu } from "@headlessui/react"
import isArray from "lodash/isArray"

import { Button } from "components/button/button"
import { RootDispatch, RootState } from "store"
import { NotificationsList } from "../notifications-list/notifications-list"
import { NotificationModel } from "common/models/NotificationModel"
import { Switch } from "components/form-components/switch/switch"

import "./notifications-popup.scss"

export const NotificationsPopUp: FC = () => {
  const navigate = useNavigate()
  const [isDisabledButtons, setIsDisabledButtons] = useState<boolean>(false)
  const [isUnreadChecked, setIsUnreadChecked] = useState<boolean>(false)
  const [filteredNotifications, setFilteredNotifications] = useState<NotificationModel[]>(
    [],
  )
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications,
  )

  const markAsRead = useDispatch<RootDispatch>().notifications.markAsRead

  useEffect(() => {
    if (notifications.length) {
      if (isUnreadChecked) {
        setFilteredNotifications(
          notifications.filter((notification) => !notification.isRead).slice(0, 4),
        )
      } else {
        setFilteredNotifications(notifications.slice(0, 4))
      }
    } else {
      setFilteredNotifications([])
    }
  }, [notifications])

  useEffect(() => {
    if (filteredNotifications.length === 0) {
      setIsDisabledButtons(true)
    } else {
      setIsDisabledButtons(false)
    }
  }, [filteredNotifications])

  const onSwitch = (newValue: boolean) => {
    if (newValue) {
      setFilteredNotifications(
        notifications.filter((notification) => !notification.isRead).slice(0, 4),
      )
      setIsUnreadChecked(true)
    } else {
      setFilteredNotifications(notifications.slice(0, 4))
      setIsUnreadChecked(false)
    }
  }

  const isDisabledMarkAsRead = () => {
    if (isArray(notifications)) {
      return notifications.filter((notification) => !notification.isRead).length === 0
    }
    return true
  }

  return (
    <div className="notifications-popup">
      <div className="notifications-popup-header">
        <span className="text-title-2 font-medium">Notifications</span>
        <label className="switch">
          <div
            className={`ml-3 ${
              isDisabledButtons && !isUnreadChecked ? "text-gray-300" : "text-gray-500"
            } text-caption-2 font-medium mr-2`}
          >
            Only show unread
          </div>
          <Switch
            value={isUnreadChecked}
            onChange={onSwitch}
            disabled={
              notifications.length === filteredNotifications.length
                ? isDisabledButtons
                : false
            }
            withoutLabel
          />
        </label>
      </div>
      <div className="border-t border-gray-200 mt-4 mx-6" />
      <Button
        variant="link"
        className="secondary justify-self-end mr-6"
        onClick={() => {
          const unreadNotifications = notifications.filter(
            (notification) => !notification.isRead,
          )

          markAsRead(unreadNotifications.map((notification) => notification.id || ""))
        }}
        disabled={isDisabledMarkAsRead()}
      >
        Mark all as read
      </Button>
      <NotificationsList notifications={filteredNotifications} variant="pop-up" />
      <Menu.Item>
        <div className="px-6 pb-4">
          <Button
            variant="outlined"
            className="w-full"
            onClick={() => !isDisabledButtons && navigate("/main/notifications")}
            disabled={isDisabledButtons}
            withOnClickPropagation
          >
            View All Notifications
          </Button>
        </div>
      </Menu.Item>
    </div>
  )
}
