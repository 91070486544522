import { RematchDispatch } from "@rematch/core"
import { SELECTED_WORKSPACE_ID, WORKSPACE_ID } from "api/api"
import {
  addWorkspaceLogoApi,
  createWorkspaceApi,
  deleteMemberWorkspaceApi,
  deleteWorkspaceApi,
  getUserWorkspacesApi,
  getWorkspaceMembersApi,
  removeWorkspaceLogoApi,
  updateDefaultWorkspaceApi,
  updateMemberRoleApi,
  updateWorkspaceApi,
} from "api/endpoints/workspace.api"
import { UpdateMemberRoleModel } from "common/models/UpdateMemberRoleModel"
import { UserAllocatedWorkspaceModel } from "common/models/UserAllocatedWorkspaceModel"
import { WorkspaceAllocatedUserModel } from "common/models/WorkspaceAllocatedUserModel"
import { WorkspaceCreateModel } from "common/models/WorkspaceCreateModel"
import { WorkspaceModel } from "common/models/WorkspaceModel"
import { UserRole } from "../common/enums/UserRole.enum"

type State = Readonly<{
  workspaceMap: {
    [key: string]: UserAllocatedWorkspaceModel
  }
  workspaceList: UserAllocatedWorkspaceModel[]
  selectedWorkspaceId: string | undefined
  teamMembers: WorkspaceAllocatedUserModel[]
  selectedWorkspaceUserRole: UserRole
  isSelectedWorkspaceViewer: boolean
  dataLoading: boolean
}>

const model = {
  state: {
    workspaceMap: {},
    workspaceList: [],
    selectedWorkspaceId: undefined,
    teamMembers: [],
    selectedWorkspaceUserRole: 0,
    isSelectedWorkspaceViewer: true,
    dataLoading: true,
  } as State,
  reducers: {
    loadedWorkspaces: (
      state: State,
      payload: UserAllocatedWorkspaceModel[] = [],
    ): State => {
      const selectedWorkspaceId =
        localStorage.getItem(SELECTED_WORKSPACE_ID) ||
        payload.find((space) => space.isDefault)?.workspaceId
      const workspaceMap = payload
        .map((w) => ({ [w.workspaceId || ""]: w }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})
      const selectedWorkspaceUserRole =
        selectedWorkspaceId !== undefined
          ? workspaceMap?.[selectedWorkspaceId].userRole
          : UserRole.Viewer

      return {
        ...state,
        workspaceMap,
        workspaceList: payload,
        selectedWorkspaceId,
        selectedWorkspaceUserRole,
        isSelectedWorkspaceViewer: selectedWorkspaceUserRole === UserRole.Viewer,
      }
    },
    loadedTeam: (state: State, payload: WorkspaceAllocatedUserModel[]): State => ({
      ...state,
      teamMembers: payload,
    }),
    removeUser: (
      state: State,
      payload: { workspaceId: string; userId: string },
    ): State => ({
      ...state,
      teamMembers: state.teamMembers.filter((member) => member.userId !== payload.userId),
    }),
    selectWorkspace: (state: State, payload: UserAllocatedWorkspaceModel): State => {
      const selectedWorkspaceUserRole =
        payload.workspaceId !== undefined
          ? state.workspaceMap?.[payload.workspaceId]?.userRole
          : UserRole.Viewer

      return {
        ...state,
        selectedWorkspaceId: payload.workspaceId,
        selectedWorkspaceUserRole,
        isSelectedWorkspaceViewer: selectedWorkspaceUserRole === UserRole.Viewer,
      }
    },
    setDataLoading: (state: State, payload: boolean): State => ({
      ...state,
      dataLoading: payload,
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchWorkspaces() {
      const workspaces = (await getUserWorkspacesApi("")) || []

      if (workspaces && workspaces.length > 0) {
        if (
          !localStorage.getItem(SELECTED_WORKSPACE_ID) ||
          !workspaces.find(
            (space) => space.workspaceId === localStorage.getItem(SELECTED_WORKSPACE_ID),
          )
        ) {
          localStorage.setItem(
            SELECTED_WORKSPACE_ID,
            workspaces?.find((space) => space.isDefault)?.workspaceId || "",
          )
        }
        dispatch.workspaces.loadedWorkspaces(workspaces)
        dispatch.workspaces.setDataLoading(false)
      }
      return workspaces
    },
    async createWorkspace(payload: WorkspaceCreateModel) {
      const result = await createWorkspaceApi(payload)

      if (result) {
        this.refreshWorkspace()
        return result
      }
      return false
    },
    async updateWorkspace(payload: WorkspaceModel) {
      const result = await updateWorkspaceApi(WORKSPACE_ID, payload)

      if (result) {
        this.refreshWorkspace()
        return result
      }
      return false
    },
    async updateDefaultWorkspace(payload: string) {
      const result = await updateDefaultWorkspaceApi(payload)

      if (result) {
        this.refreshWorkspace()
      }
      return result
    },
    async fetchTeam(payload: string) {
      const team = await getWorkspaceMembersApi(payload)

      if (team) {
        dispatch.workspaces.loadedTeam(team)
      }
      dispatch.workspaces.setDataLoading(false)
    },
    async removeTeamMember(payload: { workspaceId: string; userId: string }) {
      const result = await deleteMemberWorkspaceApi(payload.workspaceId, payload.userId)

      if (result) {
        dispatch.workspaces.removeUser(payload)
      }
      return result
    },
    async deleteWorkspace(payload: string) {
      const result = await deleteWorkspaceApi(payload)

      if (result) {
        this.refreshWorkspace()
      }
      return result
    },
    async updateMemberRole(payload: {
      workspaceId: string
      member: UpdateMemberRoleModel
    }) {
      const result = await updateMemberRoleApi(payload.workspaceId, payload.member)

      if (result) {
        this.fetchTeam(payload.workspaceId)
        this.refreshWorkspace()
      }
      return result
    },
    async refreshWorkspace() {
      const workspaces = await getUserWorkspacesApi("")

      if (workspaces) {
        dispatch.workspaces.loadedWorkspaces(workspaces)
      }
      return workspaces
    },
    async addLogoToWorkspace(payload: any) {
      const result = await addWorkspaceLogoApi(payload.workspaceId, payload.logo)

      if (result) {
        this.refreshWorkspace()
      }
      return result
    },
    async removeLogoFromWorkspace(payload: string) {
      const result = await removeWorkspaceLogoApi(payload)

      if (result) {
        this.refreshWorkspace()
      }
      return result
    },
  }),
}

export default model
