import { RematchDispatch } from "@rematch/core"
import { ShareOfShelfAPI } from "./share-of-shelf-api"

type State = Readonly<{
  results: any[]
}>

const getShareOfShelfData = (body: {
  retailer: string
  searchUrl: string
}): Promise<any> => ShareOfShelfAPI().post(`/misc/qlDigitalShelf`, body)

const model = {
  state: {
    results: [],
  } as State,
  reducers: {
    loaded: (state: State, payload: any): State => ({
      ...state,
      results: payload,
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchShareOfShelfData(payload: any) {
      const shareOfShelfResults: any = await getShareOfShelfData(payload)

      if (shareOfShelfResults) {
        dispatch.shareOfShelf.loaded(shareOfShelfResults.data)
      }
    },
  }),
}

export default model
