import { useDispatch } from "react-redux"
import { JobItemStatus } from "common/enums/JobItemStatus.enum"
import { Button } from "components/button/button"
import { useModal } from "context/modal-context"
import { RootDispatch } from "store"
import { AuditsModal } from "../audits-modal/audits-modal"
import { ICONS, Icon } from "components/icon/icon"

type Props = {
  data: any
}

export const FailedItems = ({ data }: Props) => {
  const dispatch = useDispatch<RootDispatch>()
  const { setModal } = useModal()

  const openAuditsModal = (
    title: string,
    description: string | React.ReactNode,
    jobId: string,
    status: JobItemStatus,
  ) => {
    dispatch.audits.fetchErrorItems({ auditId: jobId, status })
    setModal(<AuditsModal title={title} description={description} />)
  }

  return (
    <Button
      tippyContent="Product Errors"
      className="failed-items"
      variant="icon-btn"
      onClick={() => {
        if (data.failedItems > 0) {
          openAuditsModal(
            "Product Errors",
            <>
              The following item(s) incurred an error, which can be the result of any
              number of issues. Please note, your account is not charged for Errors.
              <span className="inline-flex translate-y-1">
                <span className="mr-2 self-start">
                  <Icon
                    tippyProps={{
                      placement: "right",
                      content: (
                        <div className="info-point">
                          <span className="text-caption-1 font-medium text-black">
                            More Info
                          </span>
                          <div className="text-caption-1 text-gray-500 self-center font-normal">
                            If you would like to re-test them, use the <b>Copy Links</b>{" "}
                            button and create a New Audit as this will often lead to
                            successful results.
                          </div>
                        </div>
                      ),
                    }}
                    icon={ICONS.INFORMATION_CIRCLE_OUTLINED}
                  />
                </span>
              </span>
            </>,
            data.lastInstanceId,
            JobItemStatus.Failed,
          )
        }
      }}
    >
      <span className={data.failedItems > 0 ? "text-yellow-600" : "text-gray-500"}>
        {data.failedItems}
      </span>
    </Button>
  )
}
