import React from "react"
import { useFormContext } from "react-hook-form"
import { REQ_MESSAGE } from "utils/constants"
import { resolvePath } from "utils"
import "./base-form-component.scss"

type Props = {
  name: string
  label?: string | JSX.Element
  required: boolean
  className?: string
  children: React.ReactNode
  noErrorMessage?: boolean
}

export const BaseFormComponent: React.FC<Props> = ({
  name,
  label,
  required,
  children,
  className = "",
  noErrorMessage = false,
}) => {
  const {
    formState: { errors },
  } = useFormContext()
  const error = resolvePath(errors, name)

  return (
    <div className={`base-form-component ${className} ${error ? "error" : ""}`}>
      {label && (
        <label className="component-label" htmlFor={name}>
          {label}
          {required && <span className="text-primary-900 font-medium ml-0.5">*</span>}
        </label>
      )}
      {children}
      <div className={noErrorMessage ? "mb-3.5" : "error-message"}>
        {error &&
          (error.message.includes("is a required field") ? REQ_MESSAGE : error.message)}
      </div>
    </div>
  )
}
