import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"
import { RetailerSelector } from "./retailer-selector/retailer-selector"
import { SearchInput } from "components/form-components/search-input/search-input"
import { Select, SelectOption } from "components/form-components/select/select"
import { filterOptionsBySearch, retailersToOptions } from "utils"
import { AuditType } from "common/enums/AuditType.enum"
import { Loader } from "components/loader/loader"
import { retailersFilterByOptions } from "utils/constants"
import { RetailersFilterBy } from "common/enums/RetailersFilterBy.enum"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import { CheckAndClear } from "./check-and-clear/check-and-clear"

type Props = {
  name: string
  disabled?: boolean
}

export const SelectRetailer = ({ name, disabled = false }: Props) => {
  const [retailerOptions, setRetailerOptions] = useState<RetailerSelectorOption[]>([])
  const [frequentlyUsedOptions, setFrequentlyUsedOptions] = useState<
    RetailerSelectorOption[]
  >([])
  const [searchQuery, setSearchQuery] = useState("")
  const [isScrolled, setIsScrolled] = useState(false)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filterBy, setFilterBy] = useState(RetailersFilterBy.ViewAll)
  const dispatch = useDispatch<RootDispatch>()
  const elementRef = useRef<HTMLDivElement>(null)
  const allRetailers = useSelector((state: RootState) => state.retailers.allRetailers)
  const frequentlyUsedRetailers = useSelector(
    (state: RootState) => state.retailers.frequentlyUsedRetailers,
  )
  const selectedAudit = useSelector((state: RootState) => state.audits.selectedAudit)
  const filteredFrequentlyUsed = filterOptionsBySearch(frequentlyUsedOptions, searchQuery)
  const filteredRetailerOptions = filterOptionsBySearch(retailerOptions, searchQuery)

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value?.toLowerCase())
  }

  useEffect(() => {
    const allRetailerOptions = retailersToOptions(allRetailers)
    const frequentlyUsedRetailerOptions = retailersToOptions(frequentlyUsedRetailers)

    switch (filterBy) {
      case RetailersFilterBy.FrequentlyUsed:
        setRetailerOptions(frequentlyUsedRetailerOptions)
        break
      case RetailersFilterBy.AllSites:
        setRetailerOptions(allRetailerOptions)
        break
      case RetailersFilterBy.ViewAll: {
        setIsLoading(true)
        const fetchData1 =
          name === "availableFor"
            ? dispatch.retailers.fetchAllRetailers()
            : dispatch.retailers.fetchAllRetailersByAuditType(
                selectedAudit?.auditType || AuditType.Product,
              )
        const fetchData2 = dispatch.retailers.fetchFrequentlyUsedRetailers(
          selectedAudit?.auditType || AuditType.Product,
        )
        Promise.all([fetchData1, fetchData2]).finally(() => setIsLoading(false))
        break
      }
    }
  }, [filterBy])

  useEffect(() => {
    setFrequentlyUsedOptions(retailersToOptions(frequentlyUsedRetailers))
    setRetailerOptions(
      retailersToOptions(
        allRetailers.filter(
          (el) => !frequentlyUsedRetailers.find((option) => option.id === el.id),
        ),
      ),
    )
  }, [frequentlyUsedRetailers, allRetailers])

  const onWheel = () => {
    const newIsScrolled = elementRef.current?.scrollTop !== 0
    if (newIsScrolled !== isScrolled) {
      setIsScrolled(newIsScrolled)
    }

    const newIsScrolledToBottom =
      elementRef.current?.scrollHeight !==
      (elementRef.current?.scrollTop ?? 0) + (elementRef.current?.offsetHeight ?? 0)

    if (newIsScrolledToBottom !== isScrolledToBottom) {
      setIsScrolledToBottom(newIsScrolledToBottom)
    }
  }

  return (
    <>
      <div className="flex gap-3 w-full my-3 items-end">
        <div style={{ width: "calc(100% - 320px)" }}>
          <SearchInput
            placeholder="Search"
            onChange={onSearch}
            className="w-full"
            height={48}
          />
        </div>
        <div style={{ width: 300 }}>
          <Select
            label="Filter by"
            options={retailersFilterByOptions}
            value={filterBy}
            onChange={(option: SelectOption) => {
              const newOption = retailersFilterByOptions.find(
                (el) => el.value === option.value,
              )
              setFilterBy(newOption?.value as RetailersFilterBy)
            }}
          />
        </div>
        {name === "availableFor" && (
          <CheckAndClear
            disabled={disabled}
            filterBy={filterBy}
            filteredFrequentlyUsed={filteredFrequentlyUsed}
            filteredRetailerOptions={filteredRetailerOptions}
          />
        )}
      </div>
      <div
        className="h-full overflow-y-auto overflow-x-hidden py-5 w-full"
        ref={elementRef}
        onWheel={onWheel}
      >
        {isScrolled && (
          <div
            className="absolute h-1 w-full -mt-6 bg-white"
            style={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              width: name === "availableFor" ? undefined : "calc(100% - 40px)",
            }}
          />
        )}

        {isLoading ? (
          <Loader className="left-1/2 text-primary-" message="Loading..." />
        ) : (
          <>
            {filterBy === RetailersFilterBy.ViewAll && (
              <>
                {filteredFrequentlyUsed.length > 0 && (
                  <>
                    <p className="text-callout font-semibold mb-3">Frequently used</p>
                    <RetailerSelector
                      name={name}
                      options={filteredFrequentlyUsed}
                      required
                      disabled={disabled}
                    />
                  </>
                )}
                {filteredRetailerOptions.length > 0 &&
                  frequentlyUsedOptions.length > 0 && (
                    <p className="text-callout font-semibold my-3">Others</p>
                  )}
              </>
            )}
            {((filterBy === RetailersFilterBy.ViewAll &&
              (filteredRetailerOptions.length > 0 ||
                filteredFrequentlyUsed.length === 0)) ||
              filterBy !== RetailersFilterBy.ViewAll) && (
              <RetailerSelector
                name={name}
                options={filteredRetailerOptions}
                required
                disabled={disabled}
              />
            )}
          </>
        )}
        {isScrolledToBottom && name === "availableFor" && (
          <div
            className="absolute bottom-0 -mb-[85px] w-full h-0.5"
            style={{
              boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
              borderRadius: "0px 0px 8px 8px",
            }}
          />
        )}
      </div>
    </>
  )
}
