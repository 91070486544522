import { ToolTipInfoIcon } from "components/info-tool-tip/info-tool-tip"
import { TagsCheckBox } from "./tags-checkbox/tags-checkbox"

export const TagsModalLegend = () => {
  return (
    <div className="tags-legend">
      <div className="legend-title">Legend</div>

      <div className="flex items-center justify-center border-r border-gray-300">
        <TagsCheckBox label="" value={0} disabled={true} />
        <span className="text-caption-1 text-gray-500">Unchecked</span>
      </div>

      <div className="flex items-center justify-center border-r border-gray-300">
        <TagsCheckBox label="" value={2} disabled={true} />
        <span className="text-caption-1 text-gray-500">Checked</span>
      </div>

      <div className="flex items-center justify-start ml-10 ">
        <TagsCheckBox label="" value={1} disabled={true} />
        <span className="text-caption-1 text-gray-500">Partially Checked</span>
        <div className="w-5 ml-3">
          <ToolTipInfoIcon
            cardStyle={{
              width: "250px",
              height: "115px",
            }}
          >
            <div className="legend-tooltip-title">Partially Checked</div>
            <span className="legend-tooltip-text">
              This option shows you that
              <b className="text-black mr-1"> only some of the selected products</b>
              contain that tag.
              <div>
                You can return to this option, only for tags that are already
                intermediate.
              </div>
            </span>
          </ToolTipInfoIcon>
        </div>
      </div>
    </div>
  )
}
