import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import "./radio.scss"

type Props = {
  label?: string | JSX.Element
  name?: string
  value?: string
  disabled?: boolean
  className?: string
  readonly?: boolean
  required?: boolean
  onChange?: any
  checked?: boolean
}

export const Radio: React.FC<Props> = React.memo(
  ({
    label,
    name,
    readonly,
    className = "",
    value,
    disabled,
    required,
    onChange,
    checked,
  }) => {
    const formContect = useFormContext()
    const { register, control } = formContect || {}

    return name && control ? (
      <label className={`radio ${className}`}>
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={() => (
            <input
              type="radio"
              {...register(name)}
              disabled={disabled}
              readOnly={readonly}
              onChange={onChange}
              className={`radio-input ${disabled ? "disabled" : "border-gray-500"}`}
              value={value}
            />
          )}
        />
        <span
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          {label}
        </span>
      </label>
    ) : (
      <label className={`radio ${className}`}>
        <input
          type="radio"
          disabled={disabled}
          readOnly={readonly}
          onChange={onChange}
          className={`radio-input ${disabled ? "disabled" : "border-gray-500"}`}
          checked={checked}
        />
        <span
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          {label}
        </span>
      </label>
    )
  },
)
