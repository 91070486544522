import { FC, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import debounce from "lodash/debounce"
import { ColumnDef } from "@tanstack/react-table"

import { downloadInvoiceApi } from "api/endpoints/invoices.api"
import { SortDirection } from "common/enums/SortDirection.enum"
import { Button } from "components/button/button"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import { SearchInput } from "components/form-components/search-input/search-input"
import { Select } from "components/form-components/select/select"
import { Icon, ICONS } from "components/icon/icon"
import { RootDispatch, RootState } from "store"
import { gridPageSizes } from "../../utils/constants"
import { Card } from "../../components/card/card"
import { PageModel } from "../../common/models/PageModel"
import { PaginatedTable } from "components/paginated-table/paginated-table"

const INVOICES_GRID_ID = "invoicesDiv"

type RequestChangeParams = {
  page?: number
  pageSize?: number
  column?: any
  searchQuery?: string
  invoiceStatus?: number
  planFrequencyType?: number
}

const selectStyle = {
  control: (base: any, state: any) => {
    return {
      ...base,
      height: "100%",
      minHeight: "40px",
      paddingLeft: "10px",
      paddingRight: "10px",
      borderRadius: "6px",
      border: state.isFocused ? "1px solid var(--secondary-500)" : "1px solid #ced4da",
      boxShadow: state.isFocused ? "inset 0 0 0 1px var(--secondary-500)" : "none",
    }
  },
}

const SelectAllInvoicesCheckbox = ({ selected }: { selected: boolean }) => {
  const dispatch = useDispatch<RootDispatch>()
  const selectAllInvoices = dispatch.billing.selectAllPaginatedInvoices
  return (
    <Checkbox
      onChange={(event: any) => selectAllInvoices(event.target.checked)}
      value={selected}
    />
  )
}

const SelectInvoice = ({
  invoiceId,
  selected,
  disabled,
}: {
  invoiceId: string
  selected: boolean
  disabled: boolean
}) => {
  const dispatch = useDispatch<RootDispatch>()
  const selectInvoice = dispatch.billing.selectPaginatedInvoice
  return (
    <Checkbox
      value={selected}
      onChange={(event: any) =>
        selectInvoice({ invoiceId, selected: event.target.checked })
      }
      disabled={disabled}
    />
  )
}

export const Invoices: FC = () => {
  const [isDisabledSingleDownload, setIsDisabledSingleDownload] = useState<boolean>(false)
  const [searchVal, setSearchVal] = useState<string>("")
  const dispatch: RootDispatch = useDispatch()
  const invoices = useSelector((state: RootState) => state.billing.paginatedInvoices)
  const requestOptions = useSelector((state: RootState) => state.billing.requestOptions)
  const itemsCount = useSelector((state: RootState) => state.billing.itemsCount)
  const loading = useSelector(
    (state: RootState) => state.loading.effects.billing.getPaginatedInvoices,
  )
  const userId = useSelector((state: RootState) => state.account.user.id) || ""

  const setRequestOptions = dispatch.billing.setRequestOptions
  const {
    pageModel: { page, pageSize, sortField, sortDirection },
  } = requestOptions

  const schema = yup.object({
    filterByStatus: yup.number().nullable(true),
    filterByType: yup.number().nullable(true),
  })

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      filterByStatus: null,
      filterByType: null,
    },
  })
  const watchFilters = methods.watch(["filterByStatus", "filterByType"])

  const checkbox = {
    header: () => (
      <SelectAllInvoicesCheckbox
        selected={
          invoices.find(
            (invoice: any) => !invoice?.selected && invoice?.paymentStatus === "Paid",
          )
            ? false
            : !invoices.every((invoice: any) => invoice?.paymentStatus !== "Paid")
        }
      />
    ),
    enableResizing: false,
    accessorKey: "index",
    minSize: 50,
    size: 23,
    cell: ({ row: { original: data } }: any) => (
      <SelectInvoice
        invoiceId={data.invoiceId}
        selected={data.selected || false}
        disabled={data.paymentStatus !== "Paid"}
      />
    ),
  }

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      checkbox,
      {
        header: "Name",
        accessorKey: "planName",
        cell: ({ row: { original: data } }: any) => data.planName,
      },
      {
        header: "Status",
        accessorKey: "paymentStatus",
        cell: ({ row: { original: data } }: any) => {
          const content =
            data.paymentStatus === "Paid" ? (
              <div className="text-green-600 font-semibold">{data.paymentStatus}</div>
            ) : data.paymentStatus === "UnPaid" ? (
              <div className="text-red-600 font-semibold">Unpaid</div>
            ) : (
              data.paymentStatus
            )
          return content
        },
      },
      {
        header: "Type",
        accessorKey: "planType",
        cell: ({ row: { original: data } }: any) => data.planType,
      },
      {
        header: "Month/Year",
        accessorKey: "expiryDate",
        cell: ({ row: { original: data } }: any) => data.expiryDate,
      },
      {
        header: "Total Amount",
        accessorKey: "totalAmount",
        cell: ({ row: { original: data } }: any) => `$${data.totalAmount}`,
      },
      {
        header: "Download",
        cell: ({ row: { original: data } }: any) => (
          <>
            <Button
              tippyContent="Download"
              className="download"
              variant="icon-btn"
              disabled={isDisabledSingleDownload || data.paymentStatus !== "Paid"}
              onClick={async () => {
                const download = await downloadInvoiceApi(userId, [data.invoiceId])

                const hiddenElement = document.createElement("a")
                hiddenElement.href =
                  "data:application/zip;base64," + download.base64EncodedFile
                hiddenElement.target = "_blank"
                hiddenElement.download = "CS_Invoice.zip"
                hiddenElement.click()
                hiddenElement.remove()
              }}
            >
              <Icon
                className={`${
                  isDisabledSingleDownload || data.paymentStatus !== "Paid"
                    ? "text-gray-300"
                    : "text-gray-500"
                }`}
                icon={ICONS.DOWNLOAD}
              />{" "}
            </Button>
          </>
        ),
      },
    ],
    [invoices],
  )

  useEffect(() => {
    const subscription = methods.watch((values: any) => {
      onRequestParamsChange({
        invoiceStatus: values.filterByStatus,
        planFrequencyType: values.filterByType,
        page: 1,
      })
    })
    return () => subscription.unsubscribe()
  }, [watchFilters])

  useEffect(() => {
    dispatch.billing.getPaginatedInvoices(requestOptions)
    dispatch.billing.getInvoicesCount(requestOptions)
  }, [requestOptions])

  useEffect(() => {
    if (invoices.filter((invoice: any) => invoice?.selected).length > 0) {
      setIsDisabledSingleDownload(true)
    } else {
      setIsDisabledSingleDownload(false)
    }
  }, [invoices])

  useEffect(() => {
    return () => {
      setRequestOptions({
        filter: {
          searchQuery: "",
          invoiceStatus: null,
          planFrequencyType: null,
        },
        pageModel: new PageModel({
          page: 1,
          pageSize: 10,
          sortField: "createdAt",
          sortDirection: SortDirection.Descending,
        }),
      })
    }
  }, [])

  const statusOptions = [
    { value: null, label: "All" },
    { value: 3, label: "Paid" },
    { value: 4, label: "Unpaid" },
  ]
  const typeOptions = [
    { value: null, label: "All" },
    { value: 0, label: "Monthly" },
    { value: 1, label: "Yearly" },
    { value: 2, label: "One Time" },
  ]

  const invoiceDownload = async () => {
    const selectedInvoicesIds = invoices
      .filter((invoice: any) => invoice.selected)
      .map((invoice: any) => invoice.invoiceId)
    const download = await downloadInvoiceApi(userId, selectedInvoicesIds)

    const hiddenElement = document.createElement("a")
    hiddenElement.href = "data:application/zip;base64," + download.base64EncodedFile
    hiddenElement.target = "_blank"
    hiddenElement.download = "CS_Invoice.zip"
    hiddenElement.click()
    hiddenElement.remove()
  }

  const resetSelection = () => {
    dispatch.billing.selectAllPaginatedInvoices(false)
  }

  const onRequestParamsChange = ({
    page,
    pageSize,
    column,
    searchQuery,
    invoiceStatus,
    planFrequencyType,
  }: RequestChangeParams) => {
    setRequestOptions({
      pageModel: Object.assign(
        {
          ...requestOptions.pageModel,
        },
        !!page && { page },
        !!pageSize && { pageSize },
        !!column && {
          sortField: column.accessorKey,
          sortDirection:
            sortDirection === SortDirection.Ascending
              ? SortDirection.Descending
              : SortDirection.Ascending,
        },
      ),
      filter: Object.assign(
        { ...requestOptions.filter },
        searchQuery !== undefined && { searchQuery },
        invoiceStatus !== undefined && { invoiceStatus },
        planFrequencyType !== undefined && { planFrequencyType },
      ),
    })
  }
  const debouncedSearch = (searchQuery: any) =>
    onRequestParamsChange({ searchQuery, page: 1 })

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const onSearchChange = debounce(debouncedSearch, 500)
    const val = e.target.value
    setSearchVal(val)
    onSearchChange(val)
  }

  const filtersDisabled =
    loading || (watchFilters.join() === "," && !searchVal && invoices.length === 0)

  return (
    <div className="mx-5">
      <div className="font-bold mb-4 mt-7 text-title-3">Invoices</div>
      <Card>
        <div className="flex items-end relative">
          <div className="mb-[14px] mr-5 grow">
            <SearchInput
              value={searchVal}
              placeholder="Search..."
              onChange={onSearch}
              disabled={isDisabledSingleDownload || filtersDisabled}
            />
          </div>
          <FormProvider {...methods}>
            <form className="grid grid-flow-col gap-5 items-end">
              <Select
                label="Filter by Status"
                name="filterByStatus"
                className="w-full"
                options={statusOptions}
                disabled={isDisabledSingleDownload || filtersDisabled}
                styles={selectStyle}
              />
              <Select
                label="Filter by Type"
                name="filterByType"
                className="w-full"
                options={typeOptions}
                disabled={isDisabledSingleDownload || filtersDisabled}
                styles={selectStyle}
              />
              {isDisabledSingleDownload && (
                <div className="mb-[14px]">
                  <Button
                    className="mr-2.5 h-12"
                    variant="primary"
                    onClick={() => {
                      invoiceDownload()
                    }}
                  >
                    Download Selected
                  </Button>
                  <Button
                    className="h-12"
                    variant="outlined"
                    onClick={() => resetSelection()}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </form>
          </FormProvider>
        </div>
        <PaginatedTable
          resizable
          columns={columns}
          data={invoices}
          loading={loading}
          emptyTableMessage="No Invoice Found."
          onSortChange={(column) => onRequestParamsChange({ column })}
          sortField={sortField}
          sortDirection={sortDirection}
          maxHeight="calc(100vh - 400px)"
          rowHeight={48}
          scrollableDivId={INVOICES_GRID_ID}
          page={page}
          onPageChange={onRequestParamsChange}
          pageSize={pageSize}
          onPageSizeChange={onRequestParamsChange}
          totalCount={itemsCount}
          pageSizeOptions={gridPageSizes}
        />
      </Card>
    </div>
  )
}
