export enum AssetType {
  CatalogExport = 0,
  ReportExport = 1,
  Resources = 2,
  CustomLogo = 3,
  MasterProductImport = 4,
  ItemImport = 5,
  TrendScoreReportExport = 6,
  DataMappingImport = 7,
  RetailerLogos = 8
}
