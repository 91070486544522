import { FC, useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs"
import { Card } from "components/card/card"
import { Header } from "components/header/header"
import { useNavigate, useParams } from "react-router-dom"
import { ReviewAudit } from "./review-audit/review-audit"
import { ScheduleAudit } from "./schedule-audit/schedule-audit"
import { SelectAuditRetailer } from "./select-audit-retailer/select-audit-retailer"
import { UploadSkus } from "./upload-skus/upload-skus"
import { Skeleton } from "components/skeleton/skeleton"
import { SubscriptionPlanType } from "common/enums/SubscriptionPlanType.enum"
import { ScheduledJobType } from "common/enums/ScheduledJobType.enum"
import { Stepper } from "components/stepper/stepper"
import { SelectAuditType } from "./select-audit-type/select-audit-type"
import { JobCreateModel } from "common/models/JobCreateModel"
import { AuditType } from "common/enums/AuditType.enum"
import { EnterKeywords } from "./enter-keywords/enter-keywords"
import { AuditCreationSuccessModal } from "./audit-creation-success-modal"
import { useModal } from "context/modal-context"
import { useHashRouting } from "common/hooks/useHashRouting"
import "./add-edit-audit.scss"

type Props = ReturnType<typeof mapProps> & ReturnType<typeof mapDispatch>

const NEW_AUDIT = "new-audit"

const LAST_STEP = 5

const stepHeaders: { [key: number]: any } = {
  1: {
    title: "New Audit",
    description: "Please enter the name of the audit and select your preferred option.",
  },
  2: {
    title: "Select Your Site",
    description: "Select one site from our growing list of pre-programmed sites below.",
  },
  3: {
    executeCondition: (auditData: JobCreateModel) => {
      if (auditData?.auditType === AuditType.Search) {
        return {
          title: "Enter Keywords",
          description:
            "Type or paste keywords or phrases with each unique keyword or phrase in its own line.",
        }
      }
      return {
        title: "Upload & Review",
        description: "Select one of the options for this retailer and paste them below.",
      }
    },
  },
  4: {
    title: "Plan Type",
    description: "Which plan do you want to use for this audit?",
  },
  5: { title: "Approval Policy", description: "" },
}

const AddEditAuditComponent: FC<Props> = ({
  audits,
  workspaces,
  loading,
  fetchAudit,
  createOrEditAudit,
  setSelectedAudit,
  selectAudit,
  workspacePlans,
}: Props) => {
  const navigate = useNavigate()
  const { setModal } = useModal()
  const { auditId } = useParams()
  const selectedAudit = useSelector((state: RootState) => state.audits.selectedAudit)
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false)
  const [step, setStep] = useHashRouting<number>(
    (stepToCheck) => stepToCheck >= 1 && stepToCheck <= LAST_STEP,
    1,
  )
  const [stepHeaderData, setStepHeaderData] = useState(stepHeaders[1])
  const [initialAudit, setInitialAudit] = useState<JobCreateModel>({} as JobCreateModel)

  useEffect(() => {
    console.log("my audit", selectedAudit)
  }, [selectedAudit])

  useEffect(() => {
    if (typeof stepHeaders[step]?.executeCondition === "function") {
      setStepHeaderData(stepHeaders[step].executeCondition(audits.selectedAudit))
    } else {
      setStepHeaderData(stepHeaders[step])
    }
  }, [step])

  useEffect(() => {
    if (auditId !== NEW_AUDIT && auditId) {
      fetchAudit(auditId).then((data) => {
        if (data) {
          setInitialAudit(data)
        }
      })
    } else {
      selectAudit({})
    }
    // eslint-disable-next-line no-sparse-arrays
  }, [, auditId, fetchAudit, selectAudit])

  const onBack = () => {
    setStep(step - 1)
  }

  const navigateToMyAudits = () => navigate("/main/audits/my-audits#0")

  const onSubmit = (values: any) => {
    switch (step) {
      case 1:
        setSelectedAudit({
          jobId: auditId !== NEW_AUDIT ? auditId : null,
          name: values.auditName,
          auditType: values.auditType,
        })
        setStep(2)
        break
      case 2:
        if (
          selectedAudit?.retailerId !== undefined &&
          selectedAudit?.retailerId !== values.retailer.value
        ) {
          setSelectedAudit({ items: undefined })
        }

        setSelectedAudit({
          retailerId: values.retailer.value,
        })
        setStep(3)
        break
      case 3:
        setSelectedAudit({
          items: values.skus,
        })
        setStep(4)
        break
      case 4: {
        const selectedPlan = workspacePlans.find(
          (plan) => plan.subscriptionPlanId === values.planId,
        )
        const selectedPlanType = selectedPlan
          ? ScheduledJobType[
              SubscriptionPlanType[
                selectedPlan.subscriptionPlanType
              ] as keyof typeof ScheduledJobType
            ] ?? null
          : null

        setSelectedAudit({
          planId: values.planId,
          runNow: values.runNow,
          systemTags: values.systemTags,
          customTags: values.customTags,
          primaryKeywords: values.primaryKeywords,
          secondaryKeywords: values.secondaryKeywords,
          startDate: new Date().toISOString(),
          jobType: selectedPlanType,
          workspaceId: workspaces.selectedWorkspaceId,
          jobId:
            audits.selectedAudit?.jobId !== null && values.planId
              ? audits.selectedAudit?.jobId
              : null,
        })
        setStep(5)
        break
      }
      case 5: {
        setIsDisabledSubmit(true)
        const selectedAudit = audits.selectedAudit

        const jobId =
          typeof initialAudit?.jobType === typeof selectedAudit?.jobType
            ? selectedAudit?.jobId
            : null
        const updatedAudit = { ...selectedAudit, jobId }

        createOrEditAudit(updatedAudit).then((response) => {
          if (response) {
            setModal(<AuditCreationSuccessModal customUnSetModal={navigateToMyAudits} />)
          }
        })
      }
    }
  }

  return (
    <div className="add-edit-audit-container">
      <Breadcrumbs
        routes={[
          {
            label: "My Audits",
            path: "/main/audits",
          },
          {
            label: auditId === NEW_AUDIT ? "New Audit" : "Edit Audit",
            path: `/main/audits/${auditId}`,
          },
        ]}
      />
      <div className="add-edit-audit-stepper-container">
        <Stepper steps={Array(LAST_STEP).fill({ label: "" })} activeStep={step - 1} />
      </div>
      <Skeleton loading={loading}>
        <Card className="add-edit-audit-card-container">
          <div className="add-edit-audit-card-header">
            <Header
              title={stepHeaderData.title || ""}
              description={stepHeaderData.description || ""}
              actions={[
                {
                  label: "Cancel Audit",
                  variant: "outlined",
                  onClick: navigateToMyAudits,
                },
              ]}
            />
          </div>

          {
            {
              1: (
                <SelectAuditType
                  onSubmit={onSubmit}
                  handleBack={onBack}
                  isDisabledBack={step === 1}
                />
              ),
              2: (
                <SelectAuditRetailer
                  onSubmit={onSubmit}
                  handleBack={onBack}
                  isDisabledBack={step === 1}
                />
              ),
              3: (
                <>
                  {audits.selectedAudit?.auditType === AuditType.Search ? (
                    <EnterKeywords
                      onSubmit={onSubmit}
                      handleBack={onBack}
                      isDisabledBack={step === 1}
                    />
                  ) : (
                    <UploadSkus
                      onSubmit={onSubmit}
                      handleBack={onBack}
                      isDisabledBack={step === 1}
                    />
                  )}
                </>
              ),
              4: (
                <ScheduleAudit
                  onSubmit={onSubmit}
                  handleBack={onBack}
                  isDisabledBack={step === 1}
                />
              ),
              5: (
                <ReviewAudit
                  onSubmit={onSubmit}
                  isDisabledSubmit={isDisabledSubmit}
                  handleBack={onBack}
                  isDisabledBack={step === 1}
                />
              ),
            }[step]
          }
        </Card>
      </Skeleton>
    </div>
  )
}

const mapProps = (state: RootState) => ({
  audits: state.audits,
  workspaces: state.workspaces,
  loading: state.loading.effects.audits.fetchAudit,
  workspacePlans: state.plans.workspaceAssignedPlans,
})

const mapDispatch = (dispatch: RootDispatch) => ({
  fetchAudit: dispatch.audits.fetchAudit,
  setSelectedAudit: dispatch.audits.setSelectedAudit,
  createOrEditAudit: dispatch.audits.createOrEditAudit,
  selectAudit: dispatch.audits.selectAudit,
})

export const AddEditAudit = connect(mapProps, mapDispatch)(AddEditAuditComponent)

export default AddEditAudit
