import { Button } from "components/button/button"
import { Icon, ICONS } from "components/icon/icon"
import React from "react"
import { FC, ReactNode, useEffect, useRef, useState } from "react"
import useResizeObserver from "@react-hook/resize-observer"
import "./filter-category.scss"

type Props = {
  title: string
  children: ReactNode
  hasSearch?: boolean
}

const INITIAL_HEIGHT = 210

export const FilterCategory: FC<Props> = ({ title, children }: Props) => {
  const [collapsed, setCollapsed] = useState(true)
  const [longBody, setLongBody] = useState(false)
  const ref = useRef(null)

  const toggle = () => {
    setCollapsed(!collapsed)
    if (ref.current && !collapsed) {
      ;(ref.current as any)?.scrollTo(0, 0)
    }
  }

  const useSize = (target: any) => {
    const [size, setSize] = useState(0)

    React.useLayoutEffect(() => {
      setSize(target?.current?.getBoundingClientRect())
    }, [target])

    useResizeObserver(target, (entry) => setSize(entry?.target?.scrollHeight))

    return size
  }

  const filterListSize = useSize(ref)

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { collapsed })
    }
    return child
  })

  useEffect(() => {
    setLongBody(INITIAL_HEIGHT < filterListSize)
  }, [filterListSize])

  return (
    <div className="filter-category">
      <div className="category-title">{title}</div>
      <div ref={ref} className={`category-body ${collapsed ? "collapsed" : ""}`}>
        {childrenWithProps}
      </div>
      {longBody && (
        <Button onClick={toggle} variant="link" className="secondary">
          {collapsed ? (
            <div className="flex items-center">
              <Icon icon={ICONS.CHEVRON_DOWN} /> Show all
            </div>
          ) : (
            <div className="flex items-center">
              <Icon icon={ICONS.CHEVRON_UP} /> Show less
            </div>
          )}
        </Button>
      )}
    </div>
  )
}
