import { useModal } from "context/modal-context"
import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { CatalogSuccessModal } from "../../catalogs/audit-catalog/catalog-success-modal/catalog-success-modal"

type Props = {
  customUnSetModal: () => void
}

export const AuditCreationSuccessModal: FC<Props> = () => {
  const { unSetModal } = useModal()
  const navigate = useNavigate()

  return (
    <CatalogSuccessModal
      onClose={() => {
        navigate("/main/audits/my-audits#0")
        unSetModal()
      }}
      buttonLabel={"Close"}
    >
      <span className="text-body font-medium text-gray-500 max-w-[340px] text-center">
        You started a new job and you’ll soon impress the team with all those great
        insights.
      </span>
    </CatalogSuccessModal>
  )
}
