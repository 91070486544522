import { Stepper as ReactStepper } from "react-form-stepper"
import "./stepper.scss"

type Step = {
  label: string
}

type Props = {
  steps: Step[]
  activeStep: number
  className?: string
  stepClassName?: string
}

export const Stepper = ({ steps, activeStep = 0, className, stepClassName }: Props) => {
  return (
    <ReactStepper
      steps={steps.map((step) => ({
        ...step,
        className: stepClassName ? stepClassName : "rc-stepper",
      }))}
      activeStep={activeStep}
      connectorStateColors={true}
      className={className}
      styleConfig={{
        activeBgColor: "#ffffff",
        activeTextColor: "#9704AE",
        completedBgColor: "#9704AE",
        completedTextColor: "#ffffff",
        inactiveBgColor: "#ffffff",
        inactiveTextColor: "#6B7280",
        size: "32px",
        circleFontSize: "1rem",
        labelFontSize: "0.875rem",
        borderRadius: "50%",
        fontWeight: 500,
      }}
      connectorStyleConfig={{
        disabledColor: "#D1D5DB",
        activeColor: "#9704AE",
        completedColor: "#9704AE",
        stepSize: "32px",
        size: "2px",
        style: "solid",
      }}
    />
  )
}
