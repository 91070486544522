import { FC } from "react"
import "./loader.scss"

type Props = {
  className?: string
  size?: number
  message?: string
}

export const Loader: FC<Props> = ({ className = "", size = 10, message }: Props) => {
  return (
    <>
      <div
        className={`relative w-${size} loader-primary flex items-center justify-center animate-pulse ${className} ${
          message && "add-margin"
        }`}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          {message && (
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="currentColor" />
                <stop offset="100%" stopColor="transparent" />
              </linearGradient>
            </defs>
          )}
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke={message ? "url(#gradient)" : "currentColor"}
            strokeLinecap="round"
            strokeWidth="10"
            r="35"
            strokeDasharray="180.93361431346415 56.97787143782138"
            transform="rotate(354.844 50 50)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
        <span className="text-gray-500 text-sub-headline self-center justify-self-center">
          {message ? message : ""}
        </span>
      </div>
    </>
  )
}
