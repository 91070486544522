import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"
import { toast } from "react-toastify"
import { FormProvider, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import isEqual from "lodash/isEqual"

import "../field-mapping.scss"
import "./step-one.scss"

import { RootState } from "../../../store"
import { Icon, ICONS } from "../../../components/icon/icon"
import { Input } from "../../../components/form-components/input/input"
import { Button } from "../../../components/button/button"
import { Checkbox } from "../../../components/form-components/checkbox/checkbox"
import { Radio } from "../../../components/form-components/radio/radio"
import { Select } from "../../../components/form-components/select/select"
import { DataMappingType } from "../../../common/enums/DataMappingType.enum"
import { InitialImportResultModel } from "../../../common/models/InitialImportResultModel"
import { useSize } from "../../../common/hooks/useElementSize"
import {
  duplicateDataMappingApi,
  importDataMappingApi,
  updateDataMappingApi,
} from "../../../api/endpoints/data-mapping-import.api"
import { getSampleFileApi } from "../../../api/endpoints/data-mapping-import.api"
import { ERRORS, LINK_CLASS_NAME } from "../../../utils/constants"
import { downloadBase64Csv } from "../../../utils"
import { FirstStepTypes, SecondStepTypes } from "../field-mapping"

const schema = yup
  .object({
    name: yup.string().max(100).trim().required("You must enter a Name"),
    dataSourceName: yup.string().when("uploadType", {
      is: "1",
      then: () => {
        return yup.string().required("You must enter a Data Source Name")
      },
    }),
  })
  .required()

interface CurrentFormStateInterface {
  name: string
  file: any
  retailers: string[]
}

type Props<T extends FirstStepTypes> = {
  data: T
  secondStepData: SecondStepTypes
  setData: React.Dispatch<React.SetStateAction<T>>
  step: number
  setStep: (st: number) => void
  onRetailerChange: (id: string) => void
  onCheckAllRetailers: () => void
  onClearAllRetailers: () => void
  onCancel: () => void
  previousData: any
  setPreviousData: React.Dispatch<React.SetStateAction<any>>
  setSecondStepData: React.Dispatch<React.SetStateAction<any>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  scrollToTop: () => void
  uploadId: string | undefined
  isDuplicate: boolean | undefined
  setIsDuplicate: React.Dispatch<React.SetStateAction<boolean>>
}

export const StepOne = ({
  data,
  secondStepData,
  setData,
  step,
  setStep,
  onRetailerChange,
  onCheckAllRetailers,
  onClearAllRetailers,
  onCancel,
  previousData,
  setPreviousData,
  setSecondStepData,
  setLoading,
  scrollToTop,
  uploadId,
  isDuplicate,
  setIsDuplicate,
}: Props<FirstStepTypes>) => {
  const [isDragging, setIsDragging] = useState(false)
  const [dropZoneSize, setDropZoneSize] = useState({ height: 0, width: 0 })
  const { retailers } = data
  const selectedWorkspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )
  const retailerSourceList = useSelector(
    (state: RootState) => state.retailers.allRetailers,
  )

  const dropdownRef = useRef(null)
  const size = useSize(dropdownRef)

  const methods = useForm({
    mode: "onChange",
    defaultValues: useMemo(() => data, [data]),
    resolver: yupResolver(schema),
  })

  const { getRootProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: {
      "text/csv": [".csv"],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.type === "text/csv") {
        setData({ ...data, file: acceptedFiles[0] })
      }
      setIsDragging(false)
    },
    onDragEnter: () => {
      setIsDragging(true)
    },
    onDragLeave: () => {
      setIsDragging(false)
    },
    onDropRejected: (fileRejections) => {
      if (fileRejections.length > 1) {
        return toast.error("Too many files uploaded!")
      }
      const { errors } = fileRejections[0]
      toast.error(errors[0]?.message)
    },
    onError: () => {
      toast.error(ERRORS.requestSuccessfulWithNoResolution.message)
    },
  })

  const isNextStepDisabled =
    !methods.formState.isValid ||
    !data.file ||
    (methods.getValues("uploadType") === "0" &&
      !data.retailers.some((retailer: any) => retailer.selected))

  const isClearDisabled = data.retailers.every((retailer: any) => !retailer.selected)

  const isCheckAllDisabled = data.retailers.every((retailer: any) => retailer.selected)

  const onGetHeaders = (
    headers: InitialImportResultModel,
    formData: any,
    currentFormState: any,
  ) => {
    const mappings = headers?.fileHeaders?.map((header: string, index: number) => {
      const previousHeaderValue = secondStepData?.mappings?.find(
        (prevMap) => prevMap[header] !== undefined,
      )

      if (previousHeaderValue) {
        return previousHeaderValue
      }

      return {
        [header]: null,
        id: index,
      }
    })

    setData(formData)
    setPreviousData(currentFormState)
    setSecondStepData({ mappings, dataMappingId: headers.importFileId })
    setStep(step + 1)
    setLoading(false)
  }

  const onSubmit = (submitData: any) => {
    // eslint-disable-next-line
    const { file, retailers, ...rest } = submitData
    const formData = { ...data, ...rest }
    const currentFormState: CurrentFormStateInterface = {
      name: formData.name,
      file: formData.file,
      retailers: [],
    }

    const formPayload = new FormData()
    formPayload.append("fileName", formData.file.name)
    formPayload.append("name", formData.name)

    if (formData.uploadType === "0") {
      const formDataRetailers = formData.retailers
        .filter((retailer: any) => retailer.selected)
        .map((r: any) => r.id)

      formPayload.append("type", String(DataMappingType.MasterVersion))
      formDataRetailers.forEach((retailer: any) =>
        formPayload.append("retailerIds", retailer),
      )
      currentFormState["retailers"] = formDataRetailers
    } else {
      formPayload.append("type", String(DataMappingType.Source))
      formPayload.append("retailerIds", formData.dataSourceName)
      currentFormState["retailers"] = [formData.dataSourceName]
    }

    if (isEqual(currentFormState, previousData)) {
      setData(formData)
      return setStep(step + 1)
    }

    //append null on same file for edit/duplicate
    if (isEqual(previousData.file, currentFormState.file)) {
      formPayload.set("file", "")
    } else {
      formPayload.append("file", formData.file, formData.file.name)
    }

    //DUPLICATE MODE
    if (isDuplicate && uploadId) {
      formPayload.append("id", uploadId)

      return (
        selectedWorkspaceId &&
        duplicateDataMappingApi(selectedWorkspaceId, formPayload).then(
          (headers: InitialImportResultModel) => {
            onGetHeaders(headers, formData, currentFormState)
            setIsDuplicate(false)
          },
        )
      )
    }

    // EDIT MODE
    if (uploadId || secondStepData.dataMappingId) {
      const mappingId = secondStepData.dataMappingId ? secondStepData.dataMappingId : ""
      const id = uploadId ? uploadId : mappingId

      formPayload.append("id", id)

      return (
        selectedWorkspaceId &&
        updateDataMappingApi(selectedWorkspaceId, formPayload).then(
          (headers: InitialImportResultModel) => {
            onGetHeaders(headers, formData, currentFormState)
          },
        )
      )
    }

    //NEW UPLOAD
    if (selectedWorkspaceId) {
      setLoading(true)
      formPayload.append("file", formData.file, formData.file.name)

      return importDataMappingApi(selectedWorkspaceId, formPayload).then(
        (headers: InitialImportResultModel) =>
          onGetHeaders(headers, formData, currentFormState),
      )
    }
  }

  useLayoutEffect(() => {
    setDropZoneSize(size)
  }, [isDragging, size, data])

  useLayoutEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    methods.reset(data)
  }, [data])

  return (
    <div {...getRootProps({ className: "h-full flex flex-col" })}>
      <FormProvider {...methods}>
        <div className="flex justify-between items-center">
          <div>
            <div className="title">Details</div>
            <div className="description">
              Add a name, upload the file and select the preferred option.
            </div>
          </div>
          <Button variant="outlined" className="mr-3" onClick={onCancel}>
            Cancel Upload
          </Button>
        </div>
        <div className="divider"></div>
        {isDragging ? (
          <div
            className="flex justify-center items-center flex-col"
            style={{
              height: dropZoneSize.height,
            }}
          >
            <Icon
              icon={ICONS.CLOUD_ARROW_UP}
              size={8}
              className="mb-2 text-fuchsia-900"
            />
            <div className="font-medium text-body">Drop file here</div>
          </div>
        ) : (
          <div ref={dropdownRef} className="flex flex-col flex-1 overflow-hidden">
            <div className="flex">
              <div className="mr-5 w-1/2">
                <div className="font-bold text-sub-headline text-gray-900 mb-1">Name</div>
                <div className="text-body font-medium mb-3 text-gray-500">
                  Please enter the name of the map you want to create.
                </div>
                <Input
                  value={data.name}
                  onChange={(ev: any) => {
                    setData((prevState) => ({
                      ...prevState,
                      name: ev.target.value,
                    }))
                  }}
                />
              </div>
              <div className="w-1/2">
                <div className="font-bold text-sub-headline text-gray-900 mb-1">
                  Import File
                </div>
                <div className="text-body font-medium mb-3 text-gray-500 flex">
                  <div> Upload file or drag and drop here. &nbsp;</div>
                  <div
                    className={LINK_CLASS_NAME}
                    onClick={() => {
                      if (selectedWorkspaceId) {
                        getSampleFileApi(selectedWorkspaceId).then(downloadBase64Csv)
                      }
                    }}
                  >
                    Get sample here!
                  </div>
                </div>
                <div className={"relative"}>
                  <Input
                    onClick={open}
                    required
                    value={
                      data.file?.name
                        ? data.file?.name
                        : "Click Here to Upload (CSV file)"
                    }
                    onChange={() => {}}
                    className={`upload-input ${data.file?.name ? "" : "empty"}`}
                  />
                  <div
                    className={`absolute right-2 top-[14px] text-red-600 font-medium  ${
                      data.file?.name ? "visible" : "hidden"
                    }`}
                    onClick={() =>
                      setData((prevState: any) => ({
                        ...prevState,
                        file: null,
                      }))
                    }
                  >
                    <Icon icon={ICONS.CANCEL} />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex my-7 ml-2 gap-12">
              <Radio
                label="Upload Master Version Content"
                name="uploadType"
                value="0"
                onChange={() => {
                  setData((prevState) => ({
                    ...prevState,
                    uploadType: "0",
                  }))
                }}
              />
              <Radio
                label="Upload as a Source"
                name="uploadType"
                value="1"
                onChange={() => {
                  setData((prevState) => ({
                    ...prevState,
                    uploadType: "1",
                  }))
                }}
              />
            </div>
            {methods.getValues("uploadType") === "0" ? (
              <>
                <div className="font-bold text-sub-headline mb-3">
                  Select the Retailers you'd like this data to apply to
                </div>
                <div className="border border-solid border-gray-300 p-[14px] pr-0 flex flex-1 flex-col overflow-hidden">
                  <div>
                    <Button
                      variant="outlined"
                      className="secondary mb-5 mr-3"
                      onClick={() => onCheckAllRetailers()}
                      disabled={isCheckAllDisabled}
                    >
                      Check All
                    </Button>
                    <Button
                      variant="outlined"
                      className="secondary mb-5"
                      onClick={() => onClearAllRetailers()}
                      disabled={isClearDisabled}
                    >
                      Clear
                    </Button>
                  </div>
                  <div className="overflow-x-auto">
                    {retailers &&
                      retailers.map((retailer: any) => (
                        <Checkbox
                          label={retailer.name}
                          value={retailer.selected}
                          onChange={() => onRetailerChange(retailer.id)}
                          key={retailer.id}
                        />
                      ))}
                  </div>
                </div>
              </>
            ) : (
              <div>
                <Select
                  value={data.dataSourceName}
                  options={retailerSourceList.map((opt) => ({
                    value: opt.id,
                    label: opt.name,
                  }))}
                  onChange={(option: any) => {
                    setData((prevState) => ({
                      ...prevState,
                      dataSourceName: option.value,
                    }))
                  }}
                />
              </div>
            )}
          </div>
        )}
        <div className="mt-8 flex justify-end">
          <Button
            variant="primary"
            onClick={methods.handleSubmit(onSubmit)}
            disabled={isNextStepDisabled}
          >
            Next Step
          </Button>
        </div>
      </FormProvider>
    </div>
  )
}
