import { FC } from "react"
import { Icon, ICONS } from "components/icon/icon"

type Props = {
  placeholder: string
  onChange: (e: any) => void
  className?: string
  disabled?: boolean
  value?: string
  defaultValue?: string
  height?: number
}

export const SearchInput: FC<Props> = ({
  placeholder,
  onChange,
  className,
  disabled,
  value,
  defaultValue,
  height,
}) => {
  return (
    <div className={`relative ${className}`} style={{ height: height }}>
      <input
        placeholder={placeholder}
        className={`border border-gray-300 p-2 rounded-md placeholder-gray-300 pr-8 w-full${
          !disabled
            ? " hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:border-2"
            : " bg-gray-50"
        }`}
        onChange={onChange}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        style={{ height: height }}
      />
      <div
        className={`absolute inset-y-0 right-4 flex items-center top-0.5${
          disabled ? " text-gray-300" : ""
        }`}
      >
        <Icon icon={ICONS.SEARCH} size={4} />
      </div>
    </div>
  )
}
