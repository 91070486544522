import { Icon, ICONS } from "components/icon/icon"
import { TippyWrapper } from "components/tippyWrapper/tippyWrapper"
import { truncateString } from "utils"
import "./keywords-chip.scss"

export type KeyWordChipProps = {
  label: string
  index: number
  onCancel: (index: number) => void
  disabled?: boolean
}

const MAX_CHAR_LENGTH = 30

export const KeyWordChip = ({ label, onCancel, index, disabled }: KeyWordChipProps) => {
  return (
    <div key={index} className={`keywords-chip ${disabled ? "disabled" : ""}`}>
      <TippyWrapper content={label.length > MAX_CHAR_LENGTH ? label : undefined}>
        <div className="pr-1 w-full">{truncateString(label, MAX_CHAR_LENGTH)}</div>
      </TippyWrapper>
      <div
        onClick={() => {
          onCancel(index)
        }}
      >
        <Icon icon={ICONS.CANCEL} size={4} className="cancel-icon" />
      </div>
    </div>
  )
}
