import { Button } from "../../../components/button/button"
import { useModal } from "../../../context/modal-context"

export const SuccessMappingModal = () => {
  const { unSetModal } = useModal()

  return (
    <div className="text-center">
      <div className="font-bold text-title-3 mb-5">Success!</div>
      <div className="font-medium text-body text-gray-500 mb-14">
        The file has been successfully uploaded and mapped.
      </div>
      <Button
        variant="outlined"
        onClick={() => {
          unSetModal()
        }}
      >
        Close
      </Button>
    </div>
  )
}
