import { RematchDispatch } from "@rematch/core"
import cloneDeep from "lodash/cloneDeep"
import set from "lodash/set"

import { WORKSPACE_ID } from "api/api"
import {
  createJobApi,
  deleteWorkspaceJobAndCollectedDataNewApi,
  deleteWorkspaceJobNewApi,
  getAllWorkspaceAuditsApi,
  getAuditItemsApi,
  getJobApi,
  getWorkspaceAuditsApi,
  getWorkspaceJobsCountApi,
} from "api/endpoints/jobs.api"
import { SortDirection } from "common/enums/SortDirection.enum"
import { JobCreateModel } from "common/models/JobCreateModel"
import { PageModel } from "../common/models/PageModel"
import { _MyAuditGetModel } from "../common/models/_MyAuditGetModel"
import { gridPageSizes } from "../utils/constants"
import { ScheduledJobType } from "../common/enums/ScheduledJobType.enum"
import { RootState } from "store"
import { WorkspaceAuditEntityModelPageResultModel } from "common/models/WorkspaceAuditEntityModelPageResultModel"

export const ONE_TIME = "oneTime"
export const DAILY = "Daily"
export const WEEKLY = "Weekly"
export const MONTHLY = "Monthly"

type AuditTypes = {
  [ONE_TIME]: string
  [DAILY]: string
  [WEEKLY]: string
  [MONTHLY]: string
}

type AuditModel = {
  audits: any
  totalItemsCount: number
  pageSizeOptions: { label: string; value: number }[]
  requestOptions: _MyAuditGetModel
}

const auditsModel: AuditModel = {
  audits: [],
  totalItemsCount: 100,
  pageSizeOptions: gridPageSizes,
  requestOptions: new _MyAuditGetModel({
    scheduledJobType: null,
    searchQuery: "",
    pageModel: new PageModel({
      page: 1,
      pageSize: 10,
      sortField: "createdAt",
      sortDirection: SortDirection.Descending,
    }),
  }),
}

type State = Readonly<{
  [ONE_TIME]: AuditModel
  [DAILY]: AuditModel
  [WEEKLY]: AuditModel
  [MONTHLY]: AuditModel
  myAudits: {
    oneTimeAudits: any[]
    recurringAudits: any[]
  }
  allAudits: AuditModel[]
  allAuditsNextPage: number
  selectedAudit: JobCreateModel | null
  errorItems: string[]
  currentSortFieldOneTimeAudits: string
  currentSortFieldRecurringAudits: string
  sortDirectionOneTimeAudits: SortDirection
  sortDirectionRecurringAudits: SortDirection
}>

const getAuditsPayload = (
  rootState: RootState,
  initialScheduledJobType: null | string,
) => {
  const scheduledJobType =
    initialScheduledJobType !== null ? initialScheduledJobType : ONE_TIME
  return {
    requestOptions: cloneDeep(
      rootState.audits[scheduledJobType as keyof AuditTypes]?.requestOptions,
    ),
    auditsType: scheduledJobType,
  }
}

const model = {
  state: {
    [ONE_TIME]: set(cloneDeep(auditsModel), ["requestOptions", "scheduledJobType"], null), //for sanity
    [DAILY]: set(
      cloneDeep(auditsModel),
      ["requestOptions", "scheduledJobType"],
      ScheduledJobType.Daily,
    ),
    [WEEKLY]: set(
      cloneDeep(auditsModel),
      ["requestOptions", "scheduledJobType"],
      ScheduledJobType.Weekly,
    ),
    [MONTHLY]: set(
      cloneDeep(auditsModel),
      ["requestOptions", "scheduledJobType"],
      ScheduledJobType.Monthly,
    ),
    myAudits: {
      oneTimeAudits: [],
      recurringAudits: [],
    },
    allAudits: [],
    allAuditsNextPage: 0,
    errorItems: [],
    selectedAudit: null,
    currentSortFieldOneTimeAudits: "startedAt",
    currentSortFieldRecurringAudits: "lastRun",
    sortDirectionOneTimeAudits: SortDirection.Ascending,
    sortDirectionRecurringAudits: SortDirection.Ascending,
  } as State,
  reducers: {
    loaded: (state: State, payload: any): State => ({
      ...state,
      myAudits: { ...state.myAudits, ...payload },
    }),
    setOneTimeAudits: (state: State, payload: any): State => ({
      ...state,
      myAudits: { ...state.myAudits, oneTimeAudits: payload },
    }),
    setRecurringAudits: (state: State, payload: any): State => ({
      ...state,
      myAudits: { ...state.myAudits, recurringAudits: payload },
    }),
    setAllAudits: (state: State, payload: any): State => ({
      ...state,
      allAudits: payload.items,
      allAuditsNextPage: payload.nextPage,
    }),
    selectAudit: (state: State, payload: any): State => ({
      ...state,
      selectedAudit: payload,
    }),
    setSelectedAudit: (state: State, payload: any): State => ({
      ...state,
      selectedAudit: { ...state.selectedAudit, ...payload },
    }),
    setErrorItems: (state: State, payload: string[]): State => ({
      ...state,
      errorItems: payload,
    }),
    setCurrentSortFieldOneTimeAudits: (state: State, payload: string): State => ({
      ...state,
      currentSortFieldOneTimeAudits: payload,
    }),
    setCurrentSortFieldRecurringAudits: (state: State, payload: string): State => ({
      ...state,
      currentSortFieldRecurringAudits: payload,
    }),
    setSortDirectionOneTimeAudits: (state: State, payload: any): State => ({
      ...state,
      sortDirectionOneTimeAudits: payload,
    }),
    setSortDirectionRecurringAudits: (state: State, payload: any): State => ({
      ...state,
      sortDirectionRecurringAudits: payload,
    }),
    setAudits: (state: State, payload: any, auditsType: any): State => ({
      ...state,
      [auditsType]: {
        ...state[auditsType as keyof AuditTypes],
        audits: payload.items,
      },
    }),
    setRequestOptions: (state: State, payload: any, auditsType: any): State => ({
      ...state,
      [auditsType]: {
        ...state[auditsType as keyof AuditTypes],
        requestOptions: payload,
      },
    }),
    setAuditsCount: (state: State, payload: any, auditsType: any): State => ({
      ...state,
      [auditsType]: {
        ...state[auditsType as keyof AuditTypes],
        totalItemsCount: payload.count,
      },
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchTotalCount(payload: any) {
      const count = await getWorkspaceJobsCountApi(WORKSPACE_ID, payload.requestOptions)

      if (count) {
        dispatch.audits.setAuditsCount(count, payload.auditsType)
        return count
      }
      return false
    },
    async fetchAudits(payload: any) {
      const audits = await getWorkspaceAuditsApi(WORKSPACE_ID, payload.requestOptions)

      if (audits) {
        dispatch.audits.setAudits(audits, payload.auditsType)
        return audits
      }
      return false
    },
    async fetchAllAudits(payload: PageModel, rootState: any) {
      const audits = await getAllWorkspaceAuditsApi(WORKSPACE_ID, payload)

      if (audits?.items.length) {
        const oldAllAudits = rootState.audits.allAudits
        const oldAllAuditsNextPage = rootState.audits.allAuditsNextPage

        if (
          payload.page !== 1 &&
          (audits.nextPage > 1 || oldAllAuditsNextPage > 1) &&
          audits.items.length > 0 &&
          oldAllAudits.length > 0
        ) {
          const updatedAudits = new WorkspaceAuditEntityModelPageResultModel({
            items: [...oldAllAudits, ...audits.items],
            nextPage: audits.nextPage,
            totalCount: audits.totalCount,
          })
          dispatch.audits.setAllAudits(updatedAudits)
        } else {
          dispatch.audits.setAllAudits(audits)
        }
      }

      return audits
    },
    async fetchAudit(payload: string) {
      const audit = await getJobApi(WORKSPACE_ID, payload)

      if (audit) {
        dispatch.audits.setSelectedAudit(audit)
        return audit
      }
      return false
    },
    async createOrEditAudit(payload: any) {
      return await createJobApi(WORKSPACE_ID, payload)
    },
    async deleteAudit(payload: any, rootState: any) {
      const { id } = payload
      const scheduledJobType = (payload.scheduledJobType as keyof AuditTypes) || ONE_TIME
      const fetchPayload = getAuditsPayload(rootState, scheduledJobType)

      // load previous page if las item deleted
      const currentPage = Number(fetchPayload.requestOptions?.pageModel?.page)
      const auditsLength = rootState.audits[scheduledJobType]?.audits?.length
      if (!Number.isNaN(currentPage) && currentPage > 1 && auditsLength === 1) {
        fetchPayload.requestOptions.pageModel.page = currentPage - 1
      }

      const response = await deleteWorkspaceJobNewApi(WORKSPACE_ID, id)

      if (response) {
        dispatch.audits.setRequestOptions(fetchPayload.requestOptions, scheduledJobType)
        this.fetchAudits(fetchPayload)
        this.fetchTotalCount(fetchPayload)
      }
      return response
    },
    async deleteAuditAndItems(payload: any, rootState: any) {
      const { id } = payload
      const scheduledJobType = (payload.scheduledJobType as keyof AuditTypes) || ONE_TIME
      const fetchPayload = getAuditsPayload(rootState, scheduledJobType)

      // load previous page if las item deleted
      const currentPage = Number(fetchPayload.requestOptions?.pageModel?.page)
      const auditsLength = rootState.audits[scheduledJobType]?.audits?.length
      if (!Number.isNaN(currentPage) && currentPage > 1 && auditsLength === 1) {
        fetchPayload.requestOptions.pageModel.page = currentPage - 1
      }

      const response = await deleteWorkspaceJobAndCollectedDataNewApi(WORKSPACE_ID, id)

      if (response) {
        dispatch.audits.setRequestOptions(fetchPayload.requestOptions, scheduledJobType)
        this.fetchAudits(fetchPayload)
        this.fetchTotalCount(fetchPayload)
      }
      return response
    },
    async fetchErrorItems(payload: any) {
      const errorItems = await getAuditItemsApi(
        WORKSPACE_ID,
        payload.auditId,
        payload.status,
      )

      if (errorItems) {
        dispatch.audits.setErrorItems(errorItems)
        return errorItems
      }
      return false
    },
  }),
}

export default model
