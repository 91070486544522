import { RematchDispatch } from "@rematch/core"
import { WORKSPACE_ID } from "api/api"
import {
  getItemDetailsApi,
  getPreviousVersionItemDetailsApi,
} from "api/endpoints/items.api"
import { CatalogItemModel } from "common/models/CatalogItemModel"
import { getItemMasterProductApi } from "../api/endpoints/master-products.api"

type State = Readonly<{
  catalogItem: CatalogItemModel
  masterProduct: any
  itemMonitor: any
}>

const model = {
  state: {
    catalogItem: new CatalogItemModel(),
  } as State,
  reducers: {
    setProductDetails: (state: State, payload: any): State => {
      return {
        ...state,
        catalogItem: { ...payload },
      }
    },
    setMasterProductDetails: (state: State, payload: any): State => {
      return {
        ...state,
        masterProduct: payload,
      }
    },
    setItemMonitorDetails: (state: State, payload: any): State => {
      return {
        ...state,
        itemMonitor: payload,
      }
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchProductDetails(payload: any) {
      const productId = payload.productId
      const catalogItem = await getItemDetailsApi(WORKSPACE_ID, productId)

      if (catalogItem) {
        const masterProductItem = await getItemMasterProductApi(WORKSPACE_ID, productId)

        dispatch.productView.setProductDetails(catalogItem)
        dispatch.productView.setItemMonitorDetails(catalogItem.itemMonitor)

        if (masterProductItem) {
          dispatch.productView.setMasterProductDetails(masterProductItem)
        }
      }
    },
    async fetchPreviousVersionProductDetails(payload: any) {
      const snapshotId = payload.snapshotId
      const catalogItem = await getPreviousVersionItemDetailsApi(WORKSPACE_ID, snapshotId)

      if (catalogItem) {
        dispatch.productView.setProductDetails(catalogItem)
      }
    },
  }),
}

export default model
