type AddInBulkTitleProps = {
  total: number
  primary: number
  secondary: number
  totalName: string
  primaryName: string
  secondaryName: string
}

export const AddInBulkTitle = ({
  total,
  primary,
  secondary,
  totalName,
  primaryName,
  secondaryName,
}: AddInBulkTitleProps) => {
  return (
    <span className="text-footnote text-gray-900 w-auto">
      {`${totalName}: ${total}`}
      <span className="mx-2 h-1/2 border border-gray-200"></span>
      {`${primaryName}: ${primary}`}
      <span className="mx-2 h-1/2 border border-gray-200"></span>
      {`${secondaryName}: ${secondary}`}
    </span>
  )
}

type AddInBulkKeywordsTitleProps = {
  total: number
  primary: number
  secondary: number
}

export const AddInBulkKeywordsTitle = ({
  total,
  primary,
  secondary,
}: AddInBulkKeywordsTitleProps) => (
  <AddInBulkTitle
    total={total}
    primary={primary}
    secondary={secondary}
    totalName="Total Keywords"
    primaryName="Primary"
    secondaryName="Secondary"
  />
)

type AddInBulkTagsTitleProps = {
  total: number
  system: number
  custom: number
}

export const AddInBulkTagsTitle = ({
  total,
  system,
  custom,
}: AddInBulkTagsTitleProps) => (
  <AddInBulkTitle
    total={total}
    primary={system}
    secondary={custom}
    totalName="Total Tags"
    primaryName="System"
    secondaryName="Custom"
  />
)
