import { FC, useEffect } from "react"
import "./shelf-query-form.scss"
import { connect } from "react-redux"
import { RootDispatch, RootState } from "store"
import { Card } from "components/card/card"
import * as yup from "yup"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button } from "components/button/button"
import { Input } from "components/form-components/input/input"
import { Select } from "components/form-components/select/select"
import { Loader } from "components/loader/loader"

type Props = ReturnType<typeof mapProps> & ReturnType<typeof mapDispatch>

const schema = yup.object({
  shelfQueryForm: yup.string(),
})

const keywords = [
  {
    value: 1,
    label: "Keyword or Phrase",
  },
  {
    value: 2,
    label: "Search URL (from Retailer website)",
  },
]

const retailers = [
  {
    value: 1,
    label: "Amazon",
    name: "amazon",
    searchUrl: "https://www.amazon.com/s?k=",
  },
  {
    value: 2,
    label: "Walmart",
    name: "walmart",
    searchUrl: "https://www.walmart.com/search/?query=",
  },
  {
    value: 3,
    label: "Sam's Club",
    name: "samsclub",
    searchUrl: "https://www.samsclub.com/s/",
  },
  {
    value: 4,
    label: "Home Depot",
    name: "homedepot",
    searchUrl: "https://www.homedepot.com/s/",
  },
]

const ShelfQueryFormComponent: FC<Props> = ({
  fetchShareOfShelfData,
  loading,
  resetResults,
}: Props) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      retailer: 0,
      searchBy: 0,
      keyword: "",
    },
  })

  useEffect(() => {
    if (methods.getValues("keyword") === "") {
      resetResults([])
    }
  }, [])

  const handleAmazonUrl = (input: string): string => {
    let amazonSearchUrl
    if (input.indexOf("s?k=") > -1) {
      amazonSearchUrl = input
    } else if (input.indexOf("s?i=") > -1) {
      amazonSearchUrl = input.slice(0, 23) + "s?k=" + input.slice(27)
    } else {
      amazonSearchUrl = input.slice(0, 23) + "s?k=" + input.slice(23)
    }

    return amazonSearchUrl
  }

  const handleWalmartUrl = (input: string): string => {
    let walmartSearchUrl
    if (input.indexOf("search/?query=") > -1) {
      walmartSearchUrl = input
    } else if (input.indexOf("shop/deals") > -1 || input.indexOf("browse") > -1) {
      walmartSearchUrl = input.slice(0, 24) + "search/?query=" + input.slice(24)
    } else {
      walmartSearchUrl = input.slice(0, 24) + "search/?query=" + input.slice(27)
    }

    return walmartSearchUrl
  }

  const handleSamClubUrl = (input: string): string => {
    let samSearchUrl
    if (input.indexOf("/s/") > -1) {
      samSearchUrl = input
    } else if (input.indexOf("/content/") > -1) {
      samSearchUrl = input.slice(0, 25) + "s/" + input.slice(25)
    } else {
      samSearchUrl = input.slice(0, 25) + "s/" + input.slice(27)
    }

    return samSearchUrl
  }

  const onSubmit = (values: any) => {
    const retailer = retailers.find((ret) => ret.value === values.retailer)

    const isWalmartOrSam =
      retailer?.value === 2
        ? handleWalmartUrl(values.keyword)
        : handleSamClubUrl(values.keyword)

    const isAmazon =
      retailer?.value === 1 ? handleAmazonUrl(values.keyword) : isWalmartOrSam

    const searchUrl =
      values.searchBy === 1 ? retailer?.searchUrl + values.keyword : isAmazon

    fetchShareOfShelfData({ retailer: retailer?.name, searchUrl })
  }

  return (
    <Card className="shelf-query-form">
      <div className="text-large-title">
        <div className="font-semibold text-center text-title-1 mb-4 text-fuchsia-900">
          Start Your Search
        </div>
      </div>
      <FormProvider {...methods}>
        <form className="grid gap-5 formGrid" onSubmit={methods.handleSubmit(onSubmit)}>
          <Select
            className="h-full"
            options={retailers}
            placeholder="Select a Retailer"
            name="retailer"
          />
          <Select
            className="h-full"
            options={keywords}
            placeholder="Select an Option"
            name="searchBy"
            disabled={methods.getValues("retailer") === 0}
          />
          <Input
            placeholder="Type here"
            name="keyword"
            disabled={
              methods.getValues("retailer") === 0 || methods.getValues("searchBy") === 0
            }
          />
          <Button
            type="submit"
            variant="primary"
            className="top-[7%] relative"
            disabled={
              methods.getValues("retailer") === 0 ||
              methods.getValues("searchBy") === 0 ||
              methods.watch("keyword") === "" ||
              loading
            }
          >
            Submit
          </Button>
        </form>
      </FormProvider>
      {loading && (
        <Loader
          className="w-full text-primary- mt-2 justify-self-center"
          message="Loading..."
        />
      )}
    </Card>
  )
}

const mapProps = (state: RootState) => ({
  loading: state.loading.effects.shareOfShelf.fetchShareOfShelfData,
})

const mapDispatch = (dispatch: RootDispatch) => ({
  fetchShareOfShelfData: dispatch.shareOfShelf.fetchShareOfShelfData,
  resetResults: dispatch.shareOfShelf.loaded,
})

export const ShelfQueryForm = connect(mapProps, mapDispatch)(ShelfQueryFormComponent)
