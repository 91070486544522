import { Button } from "components/button/button"
import { FC } from "react"
import { TippyWrapper } from "components/tippyWrapper/tippyWrapper"
import "./header.scss"

type Props = {
  title: any
  description?: string | React.ReactNode
  className?: string
  actions?: {
    label: string
    onClick: () => void
    variant?: string
    disabled?: boolean
    loading?: boolean
    props?: any
  }[]
  rightSide?: React.ReactNode
  children?: React.ReactNode
  tooltip?: string | boolean
}

export const Header: FC<Props> = ({
  title,
  description,
  className = "",
  actions = [],
  rightSide = null,
  children,
  tooltip,
}: Props) => {
  return (
    <div className={`header ${className}`}>
      <div>
        {tooltip ? (
          <TippyWrapper
            tippyProps={{
              placement: "bottom-start",
              maxWidth: 1080,
              content: <div className="text-hover">{tooltip}</div>,
            }}
          >
            <div className={`relative cursor-pointer`}>
              <div className="header-title">{title}</div>
            </div>
          </TippyWrapper>
        ) : (
          <div className="header-title">{title}</div>
        )}
        <div className="header-description">{description}</div>
        {children}
      </div>
      <div className="header-actions">
        {actions.map(({ label, onClick, props, disabled, variant, loading }) => (
          <Button
            key={label}
            variant={variant}
            disabled={disabled}
            loading={loading}
            onClick={onClick}
            {...props}
          >
            {label}
          </Button>
        ))}
      </div>
      {rightSide && <div className="header-right-side">{rightSide}</div>}
    </div>
  )
}
