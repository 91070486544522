import { Loader } from "components/loader/loader"
import React from "react"
import "./card.scss"

type Props = {
  children: React.ReactNode
  className?: string
  loading?: boolean
  footer?: React.ReactNode
  style?: any
}

export const Card: React.FC<Props> = ({
  children,
  className = "",
  style,
  loading,
  footer,
}) => {
  return (
    <div
      className={`card ${className}${
        footer
          ? " sm:max-h-[70vh] lg:max-h-[70vh] xl:max-h-[70vh] 2xl:max-h-[75vh] 3xl:max-h-[75vh]"
          : ""
      }`}
      style={style}
    >
      {children}
      {loading && (
        <div className="card-loading">
          <Loader className="text-primary-" message="Loading..." />
        </div>
      )}
      {footer && <div className="footer">{footer}</div>}
    </div>
  )
}
