import { UserModel } from 'common/models/UserModel';
import { PaymentMethodModel } from 'common/models/PaymentMethodModel';
import { PaymentMethodUpdateModel } from 'common/models/PaymentMethodUpdateModel';
import { API } from "../api";

// provide workspaceToken if present
export const getCurrentUserApi = (workspaceToken: string): Promise<UserModel> => API().get(`users/current${workspaceToken ? "?workspaceToken=" + workspaceToken : ""}`);

export const updateUserApi = (body: UserModel): Promise<UserModel> => API().put(`users`, body);

export const deleteUserAccountApi = (): Promise<boolean> => API().delete(`users/current/delete`);

export const checkUserAllocationApi = (): Promise<boolean> => API().get(`users/current/allocation`);

export const getUserPaymentMethodsApi = (): Promise<PaymentMethodModel[]> => API().get(`users/current/paymentMethods`);

export const updateUserDefaultPaymentMethodApi = (paymentMethodId: string): Promise<boolean> => API().put(`users/current/paymentMethods/${paymentMethodId}/default`);

export const updateUserPaymentMethodApi = (paymentMethodId: string, body: PaymentMethodUpdateModel): Promise<PaymentMethodModel[]> => API().put(`users/current/paymentMethods/${paymentMethodId}`, body);

export const deleteUserPaymentMethodApi = (paymentMethodId: string): Promise<boolean> => API().delete(`users/current/paymentMethods/${paymentMethodId}`);
