
export class ItemSnapshotDocumentModel {
  public title?: string = "";
  public url?: string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.title = obj.title === null? "" : obj.title;
  this.url = obj.url === null? "" : obj.url;
}

}
