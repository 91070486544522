import { ItemPropertySummary } from "common/models/ItemPropertySummary"

export const updateArrayData = (prevValues: any[], newValues: any[], page: number) => {
  if (page > 1) {
    return [
      ...prevValues.map((item) => newValues.find((i) => i.id === item.id) || item),
      ...newValues.filter((item) => !prevValues.find((i) => i.id === item.id)),
    ]
  } else {
    return newValues
  }
}

export const updateObjectInArray = (array: any, payload: any, key: string) => {
  return array?.map((item: any) => {
    if (item[key] !== payload[key]) {
      return item
    }

    return {
      ...item,
      ...payload,
    }
  })
}

function lowerCaseFirst(str: string): string {
  return str[0].toLowerCase() + str.slice(1)
}

export const columnSummaryAccessorToCamelCase = (
  columnSummary: ItemPropertySummary[],
) => {
  return columnSummary?.map((item: ItemPropertySummary) => {
    if (!item.value?.includes(" ")) {
      const splitAcc = item.value
        ?.split(".")
        .map((str: string) => lowerCaseFirst(str))
        .join(".")

      return {
        ...item,
        value: item.value?.includes(".") ? splitAcc : item.value,
      }
    } else {
      return item
    }
  })
}
