import { ScoreLevel } from "common/enums/ScoreLevel.enum"
import { HealthScoreFilterModel } from "common/models/HealthScoreFilterModel"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import { RuleScoreFilterModel } from "common/models/RuleScoreFilterModel"
import { ScoreFilterModel } from "common/models/ScoreFilterModel"
import { SectionScoreFilterModel } from "common/models/SectionScoreFilterModel"
import { FC } from "react"
import "./health-score-options.scss"
import { ValueSignOption, SIGN } from "./value-sign-option/value-sign-option"

type Props = {
  healthScoreFilters: HealthScoreFilterModel | undefined
  filters: ItemFilterModel
  setFilters: (filters: ItemFilterModel) => void
}

type HealthOption = {
  name: string,
  value: number,
  sign: SIGN,
  checked: boolean
}

export const HealthScoreOptions: FC<Props> = ({
  healthScoreFilters,
  filters,
  setFilters,
}: Props) => {
  const getFilterIndex = (item: ScoreFilterModel): number => {
    if (healthScoreFilters) {
      return filters.scoreFilters ? filters.scoreFilters.findIndex(filter =>
        item.scoreLevel === filter.scoreLevel &&
        item.parentOperand === filter.parentOperand &&
        item.operand === filter.operand) : -1;
    }
    return -1;
  }

  const setHealthScore = (item: HealthOption, level: ScoreLevel, parent: string = '') => {
    const healthScoreFilter = new ScoreFilterModel({
      scoreLevel: level,
      parentOperand: parent,
      operand: item.name,
      operator: item.sign,
      value: item.value,
    });

    const index = getFilterIndex(healthScoreFilter);
    const scoreFilters = filters.scoreFilters ? [...filters.scoreFilters] : [];

    if (index === -1) {
      if (item.checked) {
        scoreFilters.push(healthScoreFilter);
      }
    } else {
      if (item.checked) {
        scoreFilters[index] = healthScoreFilter;
      } else {
        scoreFilters.splice(index, 1);
      }
    }

    setFilters({
      ...filters,
      scoreFilters
    })
  };

  const getValues = (
    filterModel: HealthScoreFilterModel | SectionScoreFilterModel | RuleScoreFilterModel | undefined,
    parentName: string = ""
  ) => {
    if (filterModel) {
      const found = filters.scoreFilters?.find(filter => filterModel.name === filter.operand &&
        filterModel.scoreLevel === filter.scoreLevel &&
        parentName === filter.parentOperand);
      if (found) {
        return {
          checked: true,
          value: found?.value,
          sign: found?.operator as SIGN,
        }
      } else {
        return {
          checked: false,
          value: 5,
          sign: SIGN.LT,
        }
      }
    } else {
      return {
        checked: false,
        value: 5,
        sign: SIGN.LT,
      }
    }
  }

  return <div className="health-score-options">
    <ValueSignOption name={healthScoreFilters?.name}
      {...getValues(healthScoreFilters, '')} labelSuffix=" score is"
      onChange={(value) => setHealthScore(value, ScoreLevel.TopLevel)} >
      {
        healthScoreFilters?.sectionScoreFilters.map((item, sectionIndex) => (
          <ValueSignOption key={sectionIndex} name={item.name}
            {...getValues(item, healthScoreFilters?.name)} labelSuffix=" score is"
            onChange={(value) => setHealthScore(value, ScoreLevel.SectionLevel, healthScoreFilters?.name)} >
            {
              item.ruleScoreFilters.map((subItem, subIndex) => (
                <ValueSignOption key={subIndex} name={subItem.name}
                  {...getValues(subItem, item.name)} labelSuffix=" score is"
                  onChange={(value) => setHealthScore(value, ScoreLevel.RuleLevel, item?.name)} />
              ))
            }
          </ValueSignOption>
        ))
      }
    </ValueSignOption>
  </div >
}
