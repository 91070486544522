import isEmpty from "is-empty"

import { CatalogReportType } from "common/enums/CatalogReportType.enum"
import { CatalogReportRowModel } from "common/models/CatalogReportRowModel"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import { TrendScoreReportRowModel } from "common/models/TrendScoreReportRowModel"
import { SelectOption } from "components/form-components/select/select"
import { Icon, ICONS } from "components/icon/icon"
import { enumToArray, scoreToColor, toFixedRoundingReports } from "utils"
import { Row, TrendRow } from "./reports"

export const reportsColumns = (selectedReport: SelectOption) => {
  return {
    accessorKey: "name",
    header: `${selectedReport?.label}`,
    minSize: 300,
    cell: ({ row, getValue }: any) => {
      return (
        <div
          style={{
            paddingLeft: `${row.depth * 2}rem`,
          }}
          className="flex items-center justify-left gap-2"
        >
          {row.getCanExpand() === true && (
            <button type="button" onClick={row.getToggleExpandedHandler()}>
              <Icon
                icon={row.getIsExpanded() ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_RIGHT}
              />
            </button>
          )}
          {getValue()}
        </div>
      )
    },
    footer: (props: any) => props.column.id,
  }
}

export const reports = enumToArray(CatalogReportType).sort((a: any, b: any) => {
  const textA = a.label.toUpperCase()
  const textB = b.label.toUpperCase()
  return textA < textB ? -1 : textA > textB ? 1 : 0
})

const TrendScoreRowRenderer = ({
  value,
  prevValue,
}: {
  value: number | null
  prevValue: number | null
}) => {
  return (
    <>
      {value !== null ? (
        <div
          className={` p-2 rounded font-semibold text-center w-auto`}
          style={{
            backgroundColor: scoreToColor(value),
            color: "white",
          }}
        >
          <div className={`item items-center flex flex-row justify-center`}>
            {toFixedRoundingReports(value)}
            {prevValue !== null && prevValue !== value && (
              <Icon
                className="ml-2"
                icon={prevValue < value ? ICONS.TRENDING_UP : ICONS.TRENDING_DOWN}
              />
            )}
          </div>
        </div>
      ) : (
        <div className={` p-2 rounded font-semibold text-center w-auto`}>--</div>
      )}
    </>
  )
}

const BasicViewRowRenderer = ({
  isScore,
  isPercentage,
  value,
}: {
  isScore: boolean
  isPercentage: boolean
  value: number
}) => {
  return (
    <>
      {isScore ? (
        <div
          className={`text-white p-2 rounded font-semibold text-center w-auto`}
          style={{
            backgroundColor: scoreToColor(Number(value)),
          }}
        >
          <div className={`item items-center flex flex-row justify-center`}>
            {toFixedRoundingReports(value)}
          </div>
        </div>
      ) : isPercentage ? (
        <div>{toFixedRoundingReports(value)}%</div>
      ) : (
        <div className="text-left">{value}</div>
      )}
    </>
  )
}

export const RowRenderer = ({
  row,
  rowKey,
  isTrendScore = false,
  prevRowKey = "",
}: {
  row: any
  rowKey: string
  isTrendScore?: boolean
  prevRowKey?: string
}) => {
  const isScore = rowKey?.includes("Score")
  const isPercentage = rowKey?.includes("Usage")

  const item =
    row && isTrendScore && rowKey !== "totalItems"
      ? row.find((item: any) => item.date.split("T")[0] === rowKey.split("T")[0])
      : undefined
  const prevItem =
    row && isTrendScore && prevRowKey !== ""
      ? row.find((item: any) => item.date.split("T")[0] === prevRowKey.split("T")[0])
      : undefined
  const value =
    isTrendScore && rowKey !== "totalItems"
      ? item
        ? item.value
        : null
      : row[rowKey as keyof typeof row]
  const prevValue = prevItem ? prevItem.value : null

  return (
    <>
      {isTrendScore ? (
        <TrendScoreRowRenderer value={value} prevValue={prevValue} />
      ) : (
        <BasicViewRowRenderer
          isScore={isScore}
          isPercentage={isPercentage}
          value={value}
        />
      )}
    </>
  )
}

const headerComponent = (start: string, end: string) => (
  <span className="text-caption-1 flex flex-col font-medium" title={start + " " + end}>
    <span className="truncate">{start}</span>
    <span className="font-normal">{end}</span>
  </span>
)

export const getBasicViewData = (
  allReports: CatalogReportRowModel[],
  selectedReport: SelectOption,
  setReportColumns: React.Dispatch<any>,
) => {
  if (allReports && selectedReport) {
    const firstRow = allReports[0] || {}
    setReportColumns(
      Object.keys(firstRow)
        .filter(
          (key: string) => typeof firstRow[key as keyof typeof firstRow] === "number",
        )
        .map((key: string) => {
          const labelText = key.replaceAll(/([A-Z])/gm, " $1").trim()
          const start = labelText.slice(0, labelText.lastIndexOf(" "))
          const end = labelText.slice(labelText.lastIndexOf(" ") + 1)

          return {
            id: key,
            header: headerComponent(start.charAt(0).toUpperCase() + start.slice(1), end),
            cell: ({ row: { original: data } }: any) => (
              <RowRenderer row={data} rowKey={key} />
            ),
            minSize: end === "Score" ? 80 : undefined,
          }
        }),
    )
  }
}

export const getScoreTrendsViewData = (
  allReports: TrendScoreReportRowModel[],
  selectedReport: SelectOption,
  columns: any[],
  setReportColumns: React.Dispatch<any>,
) => {
  if (allReports && selectedReport && columns.length > 0) {
    setReportColumns([
      {
        id: "SKU Totals",
        header: headerComponent("SKU", "Totals"),
        cell: ({ row: { original: data } }: any) => (
          <RowRenderer row={data} rowKey={"totalItems"} />
        ),
      },
      ...columns.map((col, index) => {
        return {
          id: index.toString(),
          header: headerComponent(
            new Date(col.label).toLocaleDateString("en-US", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }),
            "",
          ),
          cell: ({ row: { original: data } }: any) => (
            <RowRenderer
              row={data.values}
              rowKey={col.label}
              isTrendScore
              prevRowKey={index - 1 >= 0 ? columns[index - 1].label : ""}
            />
          ),
          minSize: 80,
        }
      }),
    ])
  }
}

export const toggleRow = (rows: Row[] | TrendRow[], clickedRow: Row | TrendRow): any => {
  return rows.map((row: Row | TrendRow) => {
    if (row.name === clickedRow.name) {
      row.expanded = !row.expanded
    }
    if (row.rows && row.rows.length) {
      return { ...row, rows: toggleRow(row.rows, clickedRow) }
    }
    return row
  })
}

export const getAllExpandedRows = (rows: Row[] | TrendRow[], index = 0) => {
  const expandedRows: any[] = []
  rows.forEach((row: any) => {
    expandedRows.push({ ...row, padding: index * 3 })
    if (row.expanded && row.rows) {
      const subRows = getAllExpandedRows(row.rows, index + 1) || []
      expandedRows.push(...subRows)
    }
  })

  return expandedRows
}

export const countFilters = (filters: ItemFilterModel) => {
  return Object.keys(filters).reduce((acc, key) => {
    const value = filters[key as keyof ItemFilterModel]
    if (Array.isArray(value) && value.length > 0) {
      return acc + value.length
    } else if (typeof value === "boolean" && value !== undefined) {
      return acc + 1
    } else if (typeof value === "string" && !isEmpty(value)) {
      return acc + 1
    }
    return acc
  }, 0)
}
