import { RematchDispatch } from "@rematch/core"
import { SELECTED_WORKSPACE_ID } from "api/api"
import { getJobNextExecutionDateApi } from "api/endpoints/jobs.api"
import {
  askUnAssignPlanToWorkspaceApi,
  assignPlanToWorkspaceApi,
  cancelSubscriptionApi,
  getAvailablePlansDetailsApi,
  getSystemPlansApi,
  getWorkspaceAssignedPlansApi,
  subscribeUserPlanApi,
  unAssignPlanToWorkspaceApi,
  updateSubscriptionPlanApi,
} from "api/endpoints/subscription.api"
import { getUserWorkspacesApi } from "api/endpoints/workspace.api"
import { ScheduledJobType } from "common/enums/ScheduledJobType.enum"
import { UserRole } from "common/enums/UserRole.enum"
import { AvailablePlanModel } from "common/models/AvailablePlanModel"
import { PlansModel } from "common/models/PlansModel"
import { SubscriptionUpdateModel } from "common/models/SubscriptionUpdateModel"
import { SystemPlanModel } from "common/models/SystemPlanModel"
import { WorkspacePlanModel } from "common/models/WorkspacePlanModel"

export type AuditNextExecutionDate = Partial<{
  [scheduleTypeKey in ScheduledJobType]: string
}>

type State = Readonly<{
  availablePlans: any[]
  systemPlans: {
    oneTimePlans: SystemPlanModel[]
    monthlyPlans: SystemPlanModel[]
    weeklyPlans: SystemPlanModel[]
    dailyPlans: SystemPlanModel[]
  }
  workspacesToAssign: any[]
  workspaceAssignedPlans: WorkspacePlanModel[]
  selectedWorkspaceAssignedPlans: WorkspacePlanModel[]
  auditNextExecutionDate: AuditNextExecutionDate
}>

const model = {
  state: {
    availablePlans: [],
    systemPlans: {
      oneTimePlans: [],
      monthlyPlans: [],
      weeklyPlans: [],
      dailyPlans: [],
    },
    workspacesToAssign: [],
    workspaceAssignedPlans: [],
    selectedWorkspaceAssignedPlans: [],
    auditNextExecutionDate: {},
  } as State,
  reducers: {
    loadSystemPlans: (state: State, payload: PlansModel): State => ({
      ...state,
      systemPlans: { ...state.systemPlans, ...payload },
    }),
    loadAvailablePlans: (state: State, payload: AvailablePlanModel[]): State => ({
      ...state,
      availablePlans: payload,
    }),
    loadWorkspacesToAssign: (state: State, payload: any): State => ({
      ...state,
      workspacesToAssign: payload,
    }),
    loadWorkspaceAssignedPlans: (state: State, payload: any): State => ({
      ...state,
      workspaceAssignedPlans: payload,
    }),
    loadSelectedWorkspaceAssignedPlans: (state: State, payload: any): State => ({
      ...state,
      selectedWorkspaceAssignedPlans: payload,
    }),
    selectPlan: (state: State, payload: any): State => ({
      ...state,
      availablePlans: state.availablePlans.map((plan) => ({
        ...plan,
        selected: plan.id === payload.id ? payload.selected : plan.selected,
      })),
    }),
    setAuditNextExecutionDate: (
      state: State,
      payload: AuditNextExecutionDate,
    ): State => ({
      ...state,
      auditNextExecutionDate: { ...state.auditNextExecutionDate, ...payload },
    }),
    resetPlanSelection: (state: State): State => ({
      ...state,
      availablePlans: state.availablePlans.map((plan) => ({ ...plan, selected: false })),
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchSystemPlans() {
      const systemPlans = await getSystemPlansApi()

      if (systemPlans) {
        dispatch.plans.loadSystemPlans(systemPlans)
        return systemPlans
      }

      return false
    },
    async fetchAvailablePlans() {
      const availablePlans = await getAvailablePlansDetailsApi()

      if (availablePlans) {
        await dispatch.plans.loadAvailablePlans(availablePlans)
      }
    },
    async fetchAllWorkspacesAssignedPlans(payload: string[]) {
      const workspaceAssignedPlans = await getWorkspaceAssignedPlansApi(payload)

      if (workspaceAssignedPlans) {
        dispatch.plans.loadWorkspaceAssignedPlans(workspaceAssignedPlans)
        return workspaceAssignedPlans
      }

      return false
    },
    async fetchWorkspacesToAssign(payload: any) {
      const workspacesToAssign = await getUserWorkspacesApi(payload.searchValue)
      if (workspacesToAssign) {
        const workspacesIds: any = []
        workspacesToAssign.forEach((workspace) => {
          workspacesIds.push(workspace.workspaceId)
        })

        const workspacesAssignedPlans = await this.fetchAllWorkspacesAssignedPlans(
          workspacesIds,
        )
        const workspaces = workspacesToAssign
          .filter(
            (workspace) =>
              workspace.userRole !== UserRole.Collaborator &&
              workspace.userRole !== UserRole.Viewer,
          )
          .filter((workspace) => {
            const currentPlan = payload.availablePlans.find(
              (plan: any) => plan.id === payload.subscriptionPlanId,
            )

            const samePlans = workspacesAssignedPlans
              ? workspacesAssignedPlans.filter(
                  (plan) =>
                    plan.subscriptionPlanId !== payload.subscriptionPlanId &&
                    plan.workspaceId === workspace.workspaceId &&
                    plan.subscriptionPlanType === currentPlan.subscriptionPlanType,
                )
              : []

            if (samePlans.length > 0) {
              return false
            } else {
              return true
            }
          })

        dispatch.plans.loadWorkspacesToAssign(workspaces)
        return workspaces
      }
      return []
    },
    async fetchWorkspaceAssignedPlans(payload: string[]) {
      const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE_ID) || ""
      const workspaceAssignedPlans = await this.fetchAllWorkspacesAssignedPlans(payload)

      if (payload.includes(selectedWorkspaceId) && workspaceAssignedPlans) {
        await dispatch.plans.loadSelectedWorkspaceAssignedPlans(workspaceAssignedPlans)
      }
    },
    async fetchAuditNextExecutionDate(payload: ScheduledJobType) {
      const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE_ID) || ""
      const auditNextExecutionDate = await getJobNextExecutionDateApi(
        selectedWorkspaceId,
        payload,
      )

      const auditNextExecutionDateObj = { [payload]: auditNextExecutionDate }

      if (auditNextExecutionDate) {
        await dispatch.plans.setAuditNextExecutionDate(auditNextExecutionDateObj)
      }
    },
    async fetchSelectedWorkspaceAssignedPlans() {
      const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE_ID) || ""
      const workspaceAssignedPlans = await getWorkspaceAssignedPlansApi([
        selectedWorkspaceId,
      ])

      await dispatch.plans.loadSelectedWorkspaceAssignedPlans(workspaceAssignedPlans)
    },
    async assignPlanToWorkspaces(payload: any) {
      const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE_ID) || ""
      const isAssigned = await assignPlanToWorkspaceApi(
        payload.subscriptionPlanId,
        payload.body,
      )

      if (isAssigned) {
        this.fetchAvailablePlans()

        if (payload.body.includes(selectedWorkspaceId)) {
          this.fetchSelectedWorkspaceAssignedPlans()
        }
      }
      return isAssigned
    },
    async unAssignPlanFromWorkspace(payload: any) {
      const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE_ID) || ""
      const isUnassigned = await unAssignPlanToWorkspaceApi(
        payload.subscriptionPlanId,
        payload.body,
      )

      if (isUnassigned) {
        this.fetchAllWorkspacesAssignedPlans(payload.body)
        this.fetchAvailablePlans()

        if (payload.body.includes(selectedWorkspaceId)) {
          this.fetchSelectedWorkspaceAssignedPlans()
        }
      }
      return isUnassigned
    },
    async askUnAssignPlanFromWorkspace(payload: any) {
      const askUnAssign = await askUnAssignPlanToWorkspaceApi(payload.subscriptionPlanId)

      if (askUnAssign) {
        await this.fetchAllWorkspacesAssignedPlans(payload.body)
      }
      return askUnAssign
    },
    async updateSubscription(payload: SubscriptionUpdateModel) {
      const result = await updateSubscriptionPlanApi(payload)

      if (result) {
        await this.fetchAvailablePlans()
      }
      return result
    },
    async cancelSubscription(payload: string) {
      const result = await cancelSubscriptionApi(payload)

      if (result) {
        await this.fetchAvailablePlans()
      }
      return result
    },
    async subscribeUserPlan(payload: any, state: any) {
      const plan = await subscribeUserPlanApi(payload)

      if (plan) {
        this.fetchAvailablePlans()
        if (
          Object.keys(state.workspaces.workspaceMap).length === 1 &&
          state.plans.selectedWorkspaceAssignedPlans.length === 0
        ) {
          await this.fetchSelectedWorkspaceAssignedPlans()
        }

        return plan
      }
      return false
    },
  }),
}

export default model
