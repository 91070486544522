import { EventEmitter } from "events"

export const ERROR_PAGE_NOT_FOUND = "PageNotFoundError"
export const ERROR_WORKSPACE_ERROR = "WorkspaceError"

export const AXIOS_ERRORS = [ERROR_PAGE_NOT_FOUND, ERROR_PAGE_NOT_FOUND]

const errorEventInstance = new EventEmitter()
errorEventInstance.setMaxListeners(1)

export { errorEventInstance }
