import { FC } from "react"
import { connect } from "react-redux"
import { RootDispatch, RootState } from "store"

import "./shelf-results.scss"

import { Card } from "components/card/card"
import { Table } from "components/table/table"
import { Icon, ICONS } from "components/icon/icon"
import { Button } from "components/button/button"
import { downloadCsv, renderWithDollarSign } from "utils"
import { ImageComponent } from "components/image/image"

type Props = ReturnType<typeof mapProps> & ReturnType<typeof mapDispatch>

const ShelfResultsComponent: FC<Props> = ({ shareOfShelf }: Props) => {
  const columns = [
    {
      header: "Page",
      accessorKey: "page",
      size: 60,
    },
    {
      header: "Rank",
      accessor: "rank",
      size: 60,
      cell: ({ row: { original: data } }: any) =>
        data.rank === null ? (
          <Icon className="align-middle inline-block" icon={ICONS.FEATURED} />
        ) : (
          data.rank
        ),
    },
    {
      header: "Paid",
      width: "7%",
      cell: ({ row: { original: data } }: any) => (
        <span>{data.isFeatured ? "Featured" : data.isSponsored ? "Sponsored" : ""}</span>
      ),
    },
    {
      header: "Badge",
      accessorKey: "badge",
      width: "7%",
    },
    {
      header: "Brand",
      accessorKey: "brand",
      width: "7%",
    },
    {
      header: "Product ID",
      accessorKey: "productId",
      width: "10%",
    },
    {
      header: "Image",
      accessor: "imageUrl",
      width: "5%",
      cell: ({ row: { original: data } }: any) => (
        <ImageComponent
          src={data.imageUrl}
          alt={""}
          className="h-10 w-10 rounded border border-gray-100"
        />
      ),
    },
    {
      header: "Title",
      accessorKey: "productTitle",
      width: "40%",
    },
    {
      header: "Rating",
      accessorKey: "rating",
      width: "5%",
    },
    {
      header: "MSRP",
      accessor: "msrp",
      width: "5%",
      cell: ({ row: { original: data } }: any) =>
        data.msrp ? renderWithDollarSign(data.msrp) : "",
    },
    {
      header: "Sale Price",
      accessor: "price",
      width: "5%",
      cell: ({ row: { original: data } }: any) =>
        data.price ? renderWithDollarSign(data.price) : "",
    },
    {
      header: "Availability",
      accessorKey: "availability",
      width: "15%",
    },
    {
      header: "Shipping Option",
      accessorKey: "shippingOption",
      width: "20%",
    },
  ]

  const csvHeaders =
    "Page,Rank,Paid,Badge,Brand,Product ID,Image,Title,Usage Title,Usage Description,Usage Bullet,Rating,MSRP,Sale Price,Availability,Shipping Option\n"
  const csvFileName = `CS_Share-of-Shelf_${Date.now()}.csv`

  const copyToClipboard = async (data: string) => {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(data)
    }
  }

  const copyURLs = (shareOfShelfData: any) => {
    const urls = shareOfShelfData.map((x: any) => x.productUrl).join("\n")
    copyToClipboard(urls)
  }

  const formatDataForCsv = (shareOfShelfData: any) => {
    const formattedData: any = []

    shareOfShelfData.forEach((product: any) => {
      formattedData.push([
        product.page,
        product.rank,
        product.isFeatured ? "Featured" : product.isSponsored ? "Sponsored" : "",
        product.badge,
        product.brand?.replaceAll(",", " "),
        product.productId,
        product.imageUrl,
        product.productTitle?.replaceAll(",", " "),
        product.usageTitle?.replaceAll(",", " "),
        product.usageDescription?.replaceAll(",", " "),
        product.usageBullets?.replaceAll(",", " "),
        product.rating,
        product.msrp,
        product.price,
        product.availability?.replaceAll(",", " "),
        product.shippingOption?.replaceAll(",", " "),
      ])
    })

    return formattedData
  }

  return (
    <div className="shelf-results">
      <div className="header">
        <div className="inline-block">
          <span className="text-gray-500">
            <Icon className="align-middle inline-block" icon={ICONS.FEATURED} />
            Featured - Paid placements at the top of the page with typically only one
            brand featured
          </span>
          <span className="text-gray-500 ml-5 inline-block w-full">
            Sponsored - Paid placements that fall within the results
          </span>
        </div>
        <Button
          type="button"
          className="mr-3 justify-self-end"
          onClick={() => copyURLs(shareOfShelf.results)}
        >
          Copy URLs
        </Button>
        <Button
          type="button"
          className="justify-self-end"
          onClick={() =>
            downloadCsv(formatDataForCsv(shareOfShelf.results), csvHeaders, csvFileName)
          }
        >
          Download
        </Button>
      </div>
      <Card>
        <Table
          columns={columns}
          data={shareOfShelf.results}
          maxHeight="500px"
          resizable
        />
      </Card>
    </div>
  )
}

const mapProps = (state: RootState) => ({
  shareOfShelf: state.shareOfShelf,
})

const mapDispatch = (dispatch: RootDispatch) => ({
  fetchShareOfShelfData: dispatch.shareOfShelf.fetchShareOfShelfData,
})

export const ShelfResults = connect(mapProps, mapDispatch)(ShelfResultsComponent)
