import axios from "axios"
import {
  errorEventInstance,
  ERROR_PAGE_NOT_FOUND,
  ERROR_WORKSPACE_ERROR,
} from "utils/error-event-instance"
import { msalInstance } from "../index"
import { loginRequest } from "./msalConfig"
import { ERRORS } from "utils/constants"
import { toast } from "react-toastify"

export const ACCESS_TOKEN = "access_token"
export const SELECTED_WORKSPACE_ID = "selected_workspace_id"
export const WORKSPACE_ID = "{workspaceId}"

export let abortController = new AbortController()

export const API = (
  baseURL = process.env.REACT_APP_API_URL,
  callOptions: any = {},
): any => {
  const options = { headers: {}, baseURL, ...callOptions }

  const axiosInstance = axios.create(options)

  const token = getAccessToken()

  if (token) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  axiosInstance.interceptors.request.use(
    (config) => {
      if (abortController.signal.aborted || checkCatalogURL(config.url || "")) {
        abortController = new AbortController()
      }

      config.url = (config.url || "").replace(
        /{workspaceId}/gm,
        localStorage.getItem(SELECTED_WORKSPACE_ID) || "",
      )
      return { ...config, signal: abortController.signal }
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response?.data?.token) {
        setAccessToken(response.data)
      } else if (typeof response.data === "boolean" && !response.data) {
        if (
          (response.config.method === "delete" &&
            response.config.url?.includes("users/current/paymentMethods/")) ||
          (response.config.method === "post" &&
            response.config.url?.includes("members/add"))
        ) {
          return Promise.resolve(response.data)
        }

        toast.error(ERRORS.requestSuccessfulWithNoResolution.message)
      }

      return Promise.resolve(response.data)
    },
    (error) => handleErrors(error),
  )

  return axiosInstance
}

const handleErrors = (error: any) => {
  const originalRequest = error.config
  if (error?.response?.status === 401) {
    clearToken()
    const accounts = msalInstance.getAllAccounts()
    const interactionInProgress = Object.keys(sessionStorage)
      .toString()
      .includes("interaction.status")
    if (accounts.length > 0 && !interactionInProgress) {
      return msalInstance
        .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
        .then((response: any) => {
          if (response.accessToken) {
            setAccessToken(response.accessToken)
            originalRequest.headers.Authorization = `Bearer ${response.accessToken}`
            return axios(originalRequest)
              .then((res) => {
                return res.data
              })
              .catch((err) => {
                return Promise.resolve(err.response?.data)
              })
          }
        })
        .catch(() => {
          clearToken()
          return msalInstance
            .loginRedirect({ ...loginRequest })
            .then((response: any) => {
              setAccessToken(response.accessToken)
              originalRequest.headers.Authorization = `Bearer ${response.accessToken}`
              return axios(originalRequest)
                .then((res) => {
                  return res.data
                })
                .catch((err) => {
                  return Promise.resolve(err.response?.data)
                })
            })
            .catch((err) => {
              return Promise.resolve(err.response?.data)
            })
        })
    } else if (!interactionInProgress) {
      return msalInstance
        .loginRedirect({
          ...loginRequest,
        })
        .then((response: any) => {
          setAccessToken(response.accessToken)
          originalRequest.headers.Authorization = `Bearer ${response.accessToken}`
          return axios(originalRequest)
            .then((res) => {
              return res.data
            })
            .catch((err) => {
              return Promise.resolve(err.response?.data)
            })
        })
        .catch((err) => {
          clearToken()
          return Promise.resolve(err.response?.data)
        })
    }
  } else {
    handleEventEmitters(error)
  }
}

const handleEventEmitters = (error: any) => {
  if (localStorage.getItem(SELECTED_WORKSPACE_ID)) {
    if (
      error?.response?.config.url.includes("notifications") &&
      error?.response?.config.url.includes("all")
    ) {
      return Promise.resolve(error?.response?.data)
    }

    if (ERRORS.internalServerError.errorCodes.includes(error?.response?.status)) {
      errorEventInstance.emit(ERROR_PAGE_NOT_FOUND, {
        status: error.response.status,
        msg: "A network error occurred",
        path: error.response?.config.url,
      })
    } else if (
      ERRORS.notFound.errorCodes.includes(error?.response?.status) &&
      (error?.response?.config.method === "get" ||
        ERRORS.notFound.linksToVerify.find((link) =>
          link
            .replace("${workspaceId}", localStorage.getItem(SELECTED_WORKSPACE_ID) || "")
            .includes(error?.response?.config.url),
        ))
    ) {
      errorEventInstance.emit(ERROR_PAGE_NOT_FOUND, {
        status: error.response.status,
        msg: "An error occurred",
        path: error.response?.config.url,
      })
    } else if (
      ERRORS.notFound.errorCodes.includes(error?.response?.status) &&
      error?.response?.config.method !== "get" &&
      !ERRORS.notFound.linksToVerify.find((link) =>
        link
          .replace("${workspaceId}", localStorage.getItem(SELECTED_WORKSPACE_ID) || "")
          .includes(error?.response?.config.url),
      )
    ) {
      toast.error(ERRORS.notFound.message)
    } else if (
      ERRORS.badRequest.errorCodes.includes(error?.response?.status) &&
      (error?.response?.config.method === "post" ||
        error?.response?.config.method === "put" ||
        error?.response?.config.method === "delete")
    ) {
      toast.error(ERRORS.badRequest.message)
    }
  } else {
    if (ERRORS.internalServerError.errorCodes.includes(error?.response?.status)) {
      errorEventInstance.emit(ERROR_WORKSPACE_ERROR, {
        status: error.response.status,
        msg: "A workspace error occurred",
        path: error.response?.config.url,
      })
    } else if (ERRORS.notFound.errorCodes.includes(error?.response?.status)) {
      errorEventInstance.emit(ERROR_WORKSPACE_ERROR, {
        status: error.response.status,
        msg: "A workspace error occurred",
        path: error.response?.config.url,
      })
    }
  }

  return Promise.resolve(error?.response?.data)
}

export const setAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token)
}

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN)
}

export const clearToken = () => {
  localStorage.removeItem(ACCESS_TOKEN)
}

export const checkCatalogURL = (url: string) => {
  return (
    ((url || "").includes("catalog") ||
      (url || "").includes("savedViews") ||
      (url || "").includes("columns") ||
      (url || "").includes("items") ||
      (url || "").includes("tags") ||
      (url || "").includes("alerts") ||
      checkExportURL(url)) &&
    !(url || "").includes("download")
  )
}

const checkExportURL = (url: string) => {
  return (
    (url || "").includes("exports") &&
    (url || "").includes("catalog") &&
    !(url || "").includes("report") &&
    !(url || "").includes("paged") &&
    !(url || "").includes("count")
  )
}
