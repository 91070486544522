
export class CustomFieldValue {
  public key?: string = "";
  public value?: string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.key = obj.key === null? "" : obj.key;
  this.value = obj.value === null? "" : obj.value;
}

}
