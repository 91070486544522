import { FC, useEffect } from "react"
import * as yup from "yup"
import { connect } from "react-redux"
import { RootDispatch, RootState } from "store"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Input } from "components/form-components/input/input"
import { AuditSubmitButtons } from "../audit-submit-buttons/audit-submit-buttons"
import { AuditTypeSelector } from "./audit-type-selector/audit-type-selector"

type Props = ReturnType<typeof mapProps> &
  ReturnType<typeof mapDispatch> & {
    onSubmit: (values: any) => void
    handleBack: () => void
    isDisabledBack: boolean
  }

const schema = yup.object({
  auditName: yup
    .string()
    .required("In order to continue, it is required to add the audit name!")
    .trim()
    .max(280),
  auditType: yup.number().required(),
})

export const SelectAuditTypeComponent: FC<Props> = ({
  onSubmit,
  handleBack,
  isDisabledBack,
  audits,
}: Props) => {
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      auditName: audits.selectedAudit?.name,
      auditType: audits.selectedAudit?.auditType,
    },
  })

  const isSubmitDisable =
    !(methods.watch("auditType") !== undefined && !!methods.watch("auditName")) ||
    methods.formState.errors.auditName !== undefined

  useEffect(() => {
    methods.setValue("auditName", audits.selectedAudit?.name)
    methods.setValue("auditType", audits.selectedAudit?.auditType)
  }, [audits.selectedAudit, methods])

  return (
    <FormProvider {...methods}>
      <form
        className="add-edit-audit-card-content"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="add-edit-audit-card-body">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12">
            <div className="mb-4" style={{ width: 484 }}>
              <p className="text-sub-headline font-medium mb-2">Audit Job Name</p>
              <Input
                name="auditName"
                placeholder="Type Here..."
                defaultValue={audits.selectedAudit?.name}
              />
            </div>
            <div className="col-span-full">
              <p className="text-sub-headline font-medium">
                Select the Audit Type you want to do
              </p>
              <AuditTypeSelector name="auditType" />
            </div>
          </div>
        </div>

        <AuditSubmitButtons
          handleBack={handleBack}
          isDisabledBack={isDisabledBack}
          isDisabledSubmit={isSubmitDisable}
        />
      </form>
    </FormProvider>
  )
}

const mapProps = (state: RootState) => ({
  audits: state.audits,
})

const mapDispatch = (dispatch: RootDispatch) => ({
  setSelectedAudit: dispatch.audits.setSelectedAudit,
})

export const SelectAuditType = connect(mapProps, mapDispatch)(SelectAuditTypeComponent)
