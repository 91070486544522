export const TriggerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.5 8.5H15.51C15.5045 8.5 15.5 8.49552 15.5 8.49V2.5M11.5 12.5L15.5 8.5M11 3.51365C6.26201 3.7731 2.5 7.69722 2.5 12.5C2.5 17.4706 6.52944 21.5 11.5 21.5C16.3028 21.5 20.2269 17.738 20.4863 13M11 8.02747C8.75002 8.27619 7 10.1837 7 12.5C7 14.9853 9.01472 17 11.5 17C13.8163 17 15.7238 15.25 15.9725 13"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export const ExpandIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      width="20"
      height="20"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
      />
    </svg>
  )
}

export const UploadFileIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 24C7.18629 24 4.5 21.3137 4.5 18C4.5 15.1394 6.50185 12.7464 9.18106 12.1454C9.06253 11.6159 9 11.0653 9 10.5C9 6.35786 12.3579 3 16.5 3C20.129 3 23.1559 5.57738 23.8503 9.00147C23.9001 9.00049 23.95 9 24 9C28.1421 9 31.5 12.3579 31.5 16.5C31.5 20.1284 28.9234 23.1551 25.5 23.85M22.5 19.5L18 15M18 15L13.5 19.5M18 15L18 33"
        stroke="#0095DF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const PageNotFoundIcon = () => {
  return (
    <svg
      width="461"
      height="270"
      viewBox="0 0 461 301"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.37"
        d="M80.2498 219.076H42.948C32.9659 207.958 30.2967 190.24 36.2582 176.193C41.9416 162.803 54.1444 153.957 61.5377 141.565C77.3161 115.118 69.6895 75.7167 91.1303 54.3366C101.848 43.6493 117.26 40.5587 131.849 39.3094C146.439 38.0601 161.516 38.0951 175.009 32.0141C191.647 24.5153 204.189 8.41046 221.326 2.34115C240.569 -4.47323 263.589 4.03018 274.834 22.1076C281.87 33.4188 284.526 47.6302 292.847 57.8903C306.303 74.4807 330.008 75.768 346.789 88.4015C358.299 97.0665 366.07 111.217 367.554 126.214C369.345 144.32 362.935 164.886 373.059 179.503C377.996 186.632 386 190.649 391.32 197.453C396.621 204.234 398.113 210.412 396.446 219.076H80.2498Z"
        fill="url(#paint0_linear_7187_99042)"
      />
      <path
        opacity="0.37"
        d="M89.7815 219.076H52.4797C42.4976 207.958 39.8283 190.24 45.7899 176.193C51.4733 162.803 63.6761 153.957 71.0693 141.565C86.8478 115.118 79.2212 75.7167 100.662 54.3366C111.379 43.6493 126.791 40.5587 141.381 39.3094C155.97 38.0601 171.048 38.0951 184.541 32.0141C201.178 24.5153 213.72 8.41046 230.858 2.34115C250.1 -4.47323 273.121 4.03018 284.366 22.1076C291.402 33.4188 294.057 47.6302 302.379 57.8903C315.834 74.4807 339.539 75.768 356.321 88.4015C367.831 97.0665 375.602 111.217 377.085 126.214C378.876 144.32 372.466 164.886 382.59 179.503C387.527 186.632 395.532 190.649 400.852 197.453C406.153 204.234 407.645 210.412 405.977 219.076H89.7815Z"
        fill="url(#paint1_linear_7187_99042)"
      />
      <g filter="url(#filter0_d_7187_99042)">
        <path
          d="M158.935 80.7527L157.288 80.8071H8.53896L8.18157 80.8537C6.70542 70.9868 14.428 60.9645 24.1473 58.3074C32.6468 55.9844 42.5914 58.051 49.708 52.8534C56.0866 48.1997 57.6715 39.4981 61.8436 32.7933C64.4385 28.6212 68.1366 25.1406 72.4641 22.8176C77.887 19.9042 84.654 18.8864 90.2167 21.5202C100.068 26.1972 101.956 39.2495 108.544 47.9433C110.013 49.8856 111.862 51.6958 114.216 52.3406C116.383 52.9389 118.683 52.4727 120.905 52.0765C128.185 50.7946 136.917 50.9111 141.447 56.7536C143.576 59.5039 144.337 63.0311 145.518 66.2942C146.699 69.565 148.633 72.9213 151.904 74.0944C153.474 74.6461 155.253 74.6616 156.636 75.5861C158.298 76.6971 158.834 78.6783 158.935 80.7527Z"
          fill="url(#paint2_linear_7187_99042)"
        />
      </g>
      <g filter="url(#filter1_d_7187_99042)">
        <path
          d="M448.657 118.693H275.194C273.811 116.875 273.64 114.062 274.627 111.902C275.909 109.067 278.581 107.14 281.246 105.54C283.911 103.931 286.747 102.455 288.743 100.07C292.573 95.4939 292.348 88.9289 293.195 83.0243C294.189 76.0165 297.126 69.0786 302.425 64.3782C304.523 62.5136 306.954 61.0374 309.503 59.8565C316.767 56.4769 325.764 55.6301 332.554 59.8876C339.049 63.9587 342.234 71.7046 347.898 76.8711C349.234 78.0986 350.765 79.1941 352.536 79.6059C357.61 80.7712 361.922 76.1719 365.728 72.6213C371.136 67.5791 378.361 63.811 385.695 64.7511C394.319 65.8699 400.985 73.2662 404.256 81.3229C405.623 84.6792 406.664 88.3928 409.384 90.778C416.36 96.8924 428.139 90.0399 436.273 94.4839C439.326 96.1543 441.37 99.1921 443.001 102.269C445.72 107.404 447.639 112.967 448.657 118.693Z"
          fill="url(#paint3_linear_7187_99042)"
        />
      </g>
      <path
        d="M245.605 233.824C229.933 251.445 202.944 253.025 185.323 237.353C167.702 221.681 166.122 194.691 181.794 177.07C197.466 159.449 224.456 157.869 242.077 173.542C259.698 189.214 261.278 216.203 245.605 233.824Z"
        fill="white"
      />
      <path
        d="M245.605 233.824C229.933 251.445 202.944 253.025 185.323 237.353C167.702 221.681 166.122 194.691 181.794 177.07C197.466 159.449 224.456 157.869 242.077 173.542C259.698 189.214 261.278 216.203 245.605 233.824Z"
        stroke="#BF2CD6"
        strokeWidth="10.6748"
      />
      <path
        d="M236.757 179.524L247.398 167.56M180.003 243.336L190.644 231.371M175.812 171.75L201.736 194.806L220.133 186.168L205.274 222.954L221.677 212.542L251.588 239.146"
        stroke="#BF2CD6"
        strokeWidth="6.67176"
        strokeLinecap="round"
      />
      <path
        d="M406.113 219.076H42.5496C42.5496 219.076 22.9878 232.691 52.0704 234.997C81.154 237.302 126.517 239.479 125.397 249.852C124.278 260.226 98.0121 258.177 98.0121 276.491C98.0121 294.805 219.153 290.578 219.153 290.578C219.153 290.578 299.255 292.124 309 280C318.746 267.876 281.412 256.777 288.188 251.995C294.963 247.214 332.487 260.951 339.817 249.34C347.147 237.728 338.351 230.386 351.87 228.849C365.39 227.312 403.833 232.868 406.113 219.076Z"
        fill="url(#paint4_linear_7187_99042)"
      />
      <path
        d="M378.626 251.389C393.807 251.389 406.113 247.72 406.113 243.193C406.113 238.666 393.807 234.997 378.626 234.997C363.445 234.997 351.139 238.666 351.139 243.193C351.139 247.72 363.445 251.389 378.626 251.389Z"
        fill="url(#paint5_linear_7187_99042)"
      />
      <path
        d="M85.9581 256.512C99.2272 256.512 109.984 253.721 109.984 250.279C109.984 246.837 99.2272 244.046 85.9581 244.046C72.6889 244.046 61.9322 246.837 61.9322 250.279C61.9322 253.721 72.6889 256.512 85.9581 256.512Z"
        fill="url(#paint6_linear_7187_99042)"
      />
      <defs>
        <filter
          id="filter0_d_7187_99042"
          x="0"
          y="12"
          width="170.935"
          height="80.8536"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7187_99042"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7187_99042"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_7187_99042"
          x="266"
          y="49"
          width="194.657"
          height="81.6927"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7187_99042"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7187_99042"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7187_99042"
          x1="213.389"
          y1="229.961"
          x2="226.859"
          y2="40.2716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#31D1D6" />
          <stop offset="0.3733" stopColor="#32CED8" />
          <stop offset="0.6175" stopColor="#36C6DF" />
          <stop offset="0.824" stopColor="#3CB7EB" />
          <stop offset="1" stopColor="#45A3FA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7187_99042"
          x1="222.921"
          y1="229.961"
          x2="236.391"
          y2="40.2715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#31D1D6" />
          <stop offset="0.3733" stopColor="#32CED8" />
          <stop offset="0.6175" stopColor="#36C6DF" />
          <stop offset="0.824" stopColor="#3CB7EB" />
          <stop offset="1" stopColor="#45A3FA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7187_99042"
          x1="83.4678"
          y1="81.3776"
          x2="83.4678"
          y2="48.2755"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9E6D9" />
          <stop offset="0.121" stopColor="#FCF1EA" />
          <stop offset="0.2698" stopColor="#FEF9F6" />
          <stop offset="0.4751" stopColor="#FFFEFD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7187_99042"
          x1="360.866"
          y1="123.771"
          x2="362.961"
          y2="91.5069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9E6D9" />
          <stop offset="0.121" stopColor="#FCF1EA" />
          <stop offset="0.2698" stopColor="#FEF9F6" />
          <stop offset="0.4751" stopColor="#FFFEFD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7187_99042"
          x1="220.144"
          y1="293.579"
          x2="221.218"
          y2="222.885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8AEBD6" />
          <stop offset="0.4615" stopColor="#89E9D7" />
          <stop offset="0.6276" stopColor="#84E2DA" />
          <stop offset="0.7461" stopColor="#7DD7DE" />
          <stop offset="0.8419" stopColor="#72C6E5" />
          <stop offset="0.9229" stopColor="#64B0EE" />
          <stop offset="0.9944" stopColor="#5395F9" />
          <stop offset="1" stopColor="#5193FA" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_7187_99042"
          x1="378.626"
          y1="289.042"
          x2="378.626"
          y2="226.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8AEBD6" />
          <stop offset="0.4615" stopColor="#89E9D7" />
          <stop offset="0.6276" stopColor="#84E2DA" />
          <stop offset="0.7461" stopColor="#7DD7DE" />
          <stop offset="0.8419" stopColor="#72C6E5" />
          <stop offset="0.9229" stopColor="#64B0EE" />
          <stop offset="0.9944" stopColor="#5395F9" />
          <stop offset="1" stopColor="#5193FA" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_7187_99042"
          x1="85.9585"
          y1="289.042"
          x2="85.9585"
          y2="226.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8AEBD6" />
          <stop offset="0.4615" stopColor="#89E9D7" />
          <stop offset="0.6276" stopColor="#84E2DA" />
          <stop offset="0.7461" stopColor="#7DD7DE" />
          <stop offset="0.8419" stopColor="#72C6E5" />
          <stop offset="0.9229" stopColor="#64B0EE" />
          <stop offset="0.9944" stopColor="#5395F9" />
          <stop offset="1" stopColor="#5193FA" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const NetworkErrorIcon = () => {
  return (
    <div className="shadow-lg rounded-xl">
      <svg
        width="406"
        height="290"
        viewBox="0 0 406 290"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="406" height="290" rx="9.28" fill="#F3F4F6" />
        <path
          d="M0 11.6C0 5.1935 5.1935 0 11.6 0H203V290H11.6C5.19351 290 0 284.807 0 278.4V11.6Z"
          fill="white"
        />
        <path
          d="M406 209.96H352.64C347.515 209.96 343.36 205.805 343.36 200.68V177.48"
          stroke="black"
          strokeWidth="3.48"
        />
        <path
          d="M319 290V244.76C319 239.635 323.155 235.48 328.28 235.48H406"
          stroke="#D1D5DB"
          strokeWidth="3.48"
        />
        <path
          d="M205.198 46.4H268.126C273.251 46.4 277.406 50.5548 277.406 55.68V88.9932C277.406 94.1184 281.561 98.2732 286.686 98.2732H368.88C374.005 98.2732 378.16 102.428 378.16 107.553V144.908C378.16 150.034 374.005 154.188 368.88 154.188H353.016C347.89 154.188 343.736 158.343 343.736 163.468V168.487C343.736 173.612 339.581 177.767 334.456 177.767H291.723C286.598 177.767 282.443 181.922 282.443 187.047V233.16C282.443 238.285 278.289 242.44 273.163 242.44H201.84"
          stroke="#D1D5DB"
          strokeWidth="3.48"
        />
        <path
          d="M343.36 0V45.24C343.36 50.3652 347.515 54.52 352.64 54.52H406"
          stroke="#D1D5DB"
          strokeWidth="3.48"
        />
        <path d="M233.16 290V242.44" stroke="black" strokeWidth="3.48" />
        <path
          d="M230.84 121.8H269.12C274.245 121.8 278.4 117.645 278.4 112.52V97.4399"
          stroke="#111827"
          strokeWidth="3.48"
        />
        <circle cx="278.4" cy="97.44" r="5.8" fill="#111827" />
        <circle cx="343.36" cy="177.48" r="5.8" fill="#111827" />
        <circle cx="388.6" cy="54.52" r="5.8" fill="#D1D5DB" />
        <circle cx="319" cy="266.8" r="5.8" fill="#D1D5DB" />
        <circle cx="244.76" cy="46.4" r="5.8" fill="#D1D5DB" />
        <circle cx="233.16" cy="242.44" r="5.8" fill="#111827" />
        <g opacity="0.4">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M202.999 103.79C202.999 103.79 202.998 103.79 202.998 103.79C180.238 103.79 161.787 122.24 161.787 145C161.787 167.76 180.238 186.211 202.998 186.211C202.998 186.211 202.999 186.211 202.999 186.211V177.053C202.999 177.053 202.998 177.053 202.998 177.053C185.296 177.053 170.945 162.702 170.945 145C170.945 127.298 185.296 112.947 202.998 112.947C202.998 112.947 202.999 112.947 202.999 112.947V103.79Z"
            fill="#BF2CD6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M203 98.6382C201.42 98.6385 200.139 99.9196 200.139 101.5V115.237C200.139 116.817 201.42 118.098 203 118.099V98.6382ZM203 139.695L196.374 121.143L187.376 142.138H159.5C157.92 142.138 156.639 143.419 156.639 145C156.639 146.581 157.92 147.862 159.5 147.862H191.151L195.89 136.804L203 156.713V139.695ZM203 171.901C201.42 171.902 200.139 173.183 200.139 174.763V188.5C200.139 190.08 201.42 191.362 203 191.362V171.901Z"
            fill="#BF2CD6"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203 186.211C225.759 186.21 244.209 167.76 244.209 145C244.209 122.241 225.759 103.79 203 103.79V112.947C220.702 112.948 235.051 127.298 235.051 145C235.051 162.702 220.702 177.052 203 177.053V186.211Z"
          fill="#111827"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203 191.362C203 191.362 203 191.362 203 191.362C204.581 191.362 205.862 190.081 205.862 188.5V174.763C205.862 173.183 204.581 171.901 203 171.901C203 171.901 203 171.901 203 171.901V191.362ZM203 156.713L207.882 170.383L214.317 147.862H246.5C248.081 147.862 249.362 146.581 249.362 145C249.362 143.42 248.081 142.138 246.5 142.138H210L207.276 151.669L203 139.695V156.713ZM203 118.099C203 118.099 203 118.099 203 118.099C204.581 118.099 205.862 116.817 205.862 115.237V101.5C205.862 99.9195 204.581 98.6382 203 98.6382C203 98.6382 203 98.6382 203 98.6382V118.099Z"
          fill="#111827"
        />
        <rect opacity="0.4" y="20.88" width="203" height="12.76" fill="#BF2CD6" />
      </svg>
    </div>
  )
}

export const OverwriteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
      />
    </svg>
  )
}

export const RippleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5"
      />
    </svg>
  )
}
