import { Menu, Transition } from "@headlessui/react"
import React, { Fragment } from "react"
import "./dropdown.scss"

type DropdownProps = {
  children: React.ReactNode
  trigger: React.ReactNode
  style?: any
  origin?: {
    x: "left" | "right" | "center" | ""
    y: "top" | "bottom" | "center" | ""
  }
}

export const Dropdown = ({ children, trigger, style }: DropdownProps) => {
  return (
    <Menu as="div" className="dropdown">
      {trigger}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`menu-items`} style={style}>
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
