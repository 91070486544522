import { ForwardedRef, forwardRef, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { RootDispatch, RootState } from "../../store"
import { Stepper } from "../../components/stepper/stepper"
import { Card } from "../../components/card/card"
import { StepOne } from "./steps/step-one"
import { StepTwo } from "./steps/step-two"
import { StepThree } from "./steps/step-three"
import { Loader } from "../../components/loader/loader"
import { ProductImportCsvModel } from "../../common/models/ProductImportCsvModel"
import {
  cancelUploadApi,
  getDataMappingByIdApi,
} from "../../api/endpoints/data-mapping-import.api"
import "./steps/step-one.scss"
import { RetailerModel } from "../../common/models/RetailerModel"
import { HeaderMappingModel } from "../../common/models/HeaderMappingModel"
import { useHashRouting } from "common/hooks/useHashRouting"

type Retailer = {
  id: string | undefined
  name: string | undefined
  selected: boolean
}

type Mapping = {
  [key: string]: any
}

export interface FirstStepTypes {
  name: string | undefined
  uploadType: string
  dataSourceName: string | undefined
  file: any
  retailers: Retailer[]
}

export interface SecondStepTypes {
  mappings: Mapping[]
  dataMappingId: string | undefined
}

export interface ThirdStepTypes {
  rows: ProductImportCsvModel[]
  dataMappingId: string
}

export const FieldMapping = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const [step, setStep] = useHashRouting<number>(
    (stepToCheck) => stepToCheck >= 0 && stepToCheck <= 2,
    0,
  )
  const [loading, setLoading] = useState(false)
  const [firstStepData, setFirstStepData] = useState({
    name: "",
    uploadType: "0",
    dataSourceName: undefined,
    file: null,
    retailers: [],
  } as FirstStepTypes)
  const [firstStepPreviousData, setFirstStepPreviousData] = useState({})
  const [secondStepData, setSecondStepData] = useState<SecondStepTypes>({
    mappings: [],
    dataMappingId: "",
  })
  const [thirdStepData, setThirdStepData] = useState<ThirdStepTypes>({
    rows: [],
    dataMappingId: "",
  })
  const [retailerList, setRetailerList] = useState<RetailerModel[] | []>([])
  const [isDuplicate, setIsDuplicate] = useState(useLocation().state)
  const { uploadId } = useParams()
  const { edit: isEdit } = useParams()
  const selectedWorkspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )

  const dispatch: RootDispatch = useDispatch()
  const navigate = useNavigate()

  const onRetailerChange = (id: string) =>
    setFirstStepData({
      ...firstStepData,
      retailers: firstStepData.retailers.map((retailer: any) =>
        retailer.id === id
          ? {
              ...retailer,
              selected: !retailer.selected,
            }
          : retailer,
      ),
    })

  const onCheckAllRetailers = () =>
    setFirstStepData({
      ...firstStepData,
      retailers: firstStepData.retailers.map((retailer: any) => ({
        ...retailer,
        selected: true,
      })),
    })

  const onClearAllRetailers = () =>
    setFirstStepData({
      ...firstStepData,
      retailers: firstStepData.retailers.map((retailer: any) => ({
        ...retailer,
        selected: false,
      })),
    })

  const onCancelUpload = () => {
    if (selectedWorkspaceId && secondStepData.dataMappingId && !isEdit && !isDuplicate) {
      return cancelUploadApi(selectedWorkspaceId, secondStepData.dataMappingId).then(() =>
        navigate(`/main/workspaces/${selectedWorkspaceId}/edit/upload`, {
          state: 1,
        }),
      )
    }

    return navigate(`/main/workspaces/${selectedWorkspaceId}/edit/upload`, {
      state: 1,
    })
  }

  const scrollToTop = () => {
    if (ref != null && typeof ref !== "function") {
      ref.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }

  useEffect(() => {
    dispatch.retailers.fetchAllRetailers().then((retailerList) => {
      if (uploadId) {
        return setRetailerList(retailerList)
      }

      return setFirstStepData({
        ...firstStepData,
        retailers: retailerList.map((retailer) => ({
          id: retailer.id,
          name: retailer.name,
          selected: false,
        })),
      })
    })
  }, [])

  //fetch initial data for edit or duplicate action
  useEffect(() => {
    if (uploadId && selectedWorkspaceId && retailerList) {
      getDataMappingByIdApi(selectedWorkspaceId, uploadId).then(
        ({ name, dataMappingType, fileName, id, retailerIds, mappingList }) => {
          const data = {
            name,
            file: { name: fileName },
            retailers: retailerList.map((retailer: RetailerModel) => ({
              id: retailer.id,
              name: retailer.name,
              selected:
                dataMappingType === 0 &&
                ((retailer?.id && retailerIds.includes(retailer.id)) || false),
            })),
          }

          setFirstStepData({
            ...data,
            uploadType: String(dataMappingType),
            dataSourceName: dataMappingType === 1 ? retailerIds[0] : undefined,
          })
          setFirstStepPreviousData({
            file: { name: fileName },
          })
          setSecondStepData({
            mappings: mappingList.map(
              ({ fileHeader, catalogHeader }: HeaderMappingModel) => ({
                [fileHeader as string]: catalogHeader,
              }),
            ),
            dataMappingId: id,
          })

          if (!isDuplicate) {
            setFirstStepPreviousData({ ...data, retailers: retailerIds })
          }
        },
      )
    }
  }, [uploadId, selectedWorkspaceId, retailerList])

  return (
    <div className="field-mapping">
      <div className="3xl:max-w-[600px] max-w-[400px] mx-auto my-3">
        <Stepper
          steps={[{ label: "" }, { label: "" }, { label: "" }]}
          activeStep={step}
        />
      </div>
      <Card
        className="max-w-[800px] h-[800px] mx-auto mb-4 relative"
        style={{ display: "block" }}
      >
        {
          {
            0: (
              <StepOne
                data={firstStepData}
                setData={setFirstStepData}
                secondStepData={secondStepData}
                setSecondStepData={setSecondStepData}
                step={step}
                setStep={setStep}
                onRetailerChange={onRetailerChange}
                onCheckAllRetailers={onCheckAllRetailers}
                onClearAllRetailers={onClearAllRetailers}
                onCancel={onCancelUpload}
                previousData={firstStepPreviousData}
                setPreviousData={setFirstStepPreviousData}
                setLoading={setLoading}
                scrollToTop={scrollToTop}
                uploadId={uploadId}
                isDuplicate={isDuplicate}
                setIsDuplicate={setIsDuplicate}
              />
            ),
            1: (
              <StepTwo
                data={secondStepData}
                setData={setSecondStepData}
                step={step}
                setStep={setStep}
                onCancel={onCancelUpload}
                setStepThreeData={setThirdStepData}
                setLoading={setLoading}
                scrollToTop={scrollToTop}
              />
            ),
            2: (
              <StepThree
                data={thirdStepData}
                step={step}
                setStep={setStep}
                onCancel={onCancelUpload}
                scrollToTop={scrollToTop}
              />
            ),
          }[step]
        }
        {loading && (
          <div className="absolute w-full h-full top-0 bg-white bg-opacity-50 flex items-center justify-center">
            <Loader className="w-full text-primary- mt-2" message="Loading..." />
          </div>
        )}
      </Card>
    </div>
  )
})
