import React, { Suspense, useEffect } from "react"
import { Card } from "components/card/card"
import { Tabs } from "components/tabs/tabs"
import { Routes, Route } from "react-router-dom"
import { SuspenseLoader } from "../../components/suspense-loader/suspense-loader"
import { lazyLoadRetry } from "../../utils/lazyLoadRetry"
import "./billing-and-plans.scss"
import { abortController } from "api/api"

const Billing = React.lazy(lazyLoadRetry(() => import("./billing/billing")))
const Plans = React.lazy(lazyLoadRetry(() => import("./plans/plans")))
const AvailablePlans = React.lazy(
  lazyLoadRetry(() => import("./available-plans/available-plans")),
)

export const BillingAndPlans = () => {
  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <Card className="billing-container mx-auto mt-10">
      <Tabs
        tabs={[
          { label: "Billing", path: "/main/billing" },
          { label: "Plans", path: "plans" },
          { label: "Available Plans", path: "available-plan" },
          //{ label: "Branding", path: "branding" },
        ]}
      />
      <Suspense fallback={<SuspenseLoader delay={500} />}>
        <Routes>
          <Route path="/" element={<Billing />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/available-plan" element={<AvailablePlans />} />
          {/* <Route path="/branding" element={<Branding />} /> */}
        </Routes>
      </Suspense>
    </Card>
  )
}
