import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"

import { Button } from "components/button/button"
import { Input } from "components/form-components/input/input"
import { Icon, ICONS } from "components/icon/icon"

import { TagsCheckBox } from "../tags-checkbox/tags-checkbox"
import { TagType } from "../tags-modal"
import "./custom-tag.scss"

type CustomTagProps = {
  index: number
  name: string
  checked: number
  customTags: TagType[]
  setCustomTags: (customTags: TagType[]) => void
  usedInAuditCreation: boolean
  itemId: string | null
  selectedProdNo: number
  intermediate: boolean
  isViewer?: boolean
}

export const CustomTag = ({
  name,
  index,
  checked,
  customTags,
  setCustomTags,
  usedInAuditCreation,
  itemId,
  selectedProdNo,
  intermediate,
  isViewer,
}: CustomTagProps) => {
  const dispatch: RootDispatch = useDispatch()
  const [tagName, setTagName] = useState(name)
  const [originalTagName, setOriginalTagName] = useState(name)
  const isRenamingTag = useSelector(
    (state: RootState) => state.tags.isRenamingTag.renaming,
  )
  const renamingTagIndex = useSelector(
    (state: RootState) => state.tags.isRenamingTag.index,
  )

  const onCustomTagCheck = (index: number, intermediate: boolean) => {
    const newCustomTags = customTags.map((tag, tagIndex) => {
      if (tagIndex === index) {
        return {
          ...tag,
          checked:
            selectedProdNo < 2 || !intermediate
              ? !tag.checked
                ? 2
                : 0
              : (tag.checked + 1) % 3,
        }
      } else {
        return tag
      }
    })
    setCustomTags(newCustomTags)
    dispatch.tags.setSaveActive(true)
  }

  const onInputChange = (event: any) => {
    setTagName(event.target.value)
  }

  const onInputBlur = () => {
    const updatedTags: TagType[] =
      customTags?.map((tag, tagIndex) => {
        if (index === tagIndex) {
          return { ...tag, name: tagName, checked: tag.checked }
        } else {
          return tag
        }
      }) || []
    dispatch.tags.setSaveActive(true)
    setCustomTags(updatedTags)
  }

  const handleEdit = (index: number) => {
    setOriginalTagName(tagName)
    dispatch.tags.setIsRenamingTag({ renaming: true, index: index })
  }

  const onDeleteTag = (tagIndex: number) => {
    const deletedTags = customTags.filter((_, index) => index !== tagIndex)
    dispatch.tags.setSaveActive(true)
    setCustomTags(deletedTags)
  }

  const onEnter = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === "Enter") {
      handleFinishEditingTag(index)
    }
  }

  const shouldDisableInput = (index: number) => {
    return isRenamingTag && renamingTagIndex !== index
  }

  const shouldEnableViewInput = (index: number) => {
    return isRenamingTag && renamingTagIndex === index
  }

  const cancelTagCreation = (index: number) => {
    dispatch.tags.setIsRenamingTag({ renaming: false, index })
    setTagName(originalTagName)
  }

  const handleFinishEditingTag = (index: number) => {
    dispatch.tags.setIsRenamingTag({ renaming: false, index })
    if (tagName.trim() === "") {
      const deletedTags = customTags.filter((tag, tagIndex) => index !== tagIndex)
      setCustomTags(deletedTags)
    } else {
      onInputBlur()
    }
  }

  useEffect(() => {
    setTagName(name)
    // eslint-disable-next-line no-sparse-arrays
  }, [, name])

  return (
    <div
      className={`custom-tag-container ${
        shouldEnableViewInput(index) ? "input-visible" : ""
      }`}
    >
      {shouldEnableViewInput(index) ? (
        <div className="max-w-full">
          <div className="new-tag-input-container">
            <Input
              key={`${index}`}
              name="existingTag"
              required
              value={tagName}
              onChange={onInputChange}
              className="add-new-tag-input new-tag-input"
              autoComplete={"off"}
              autofocus
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                onEnter(event, index)
              }
            />
            <div className="new-tag-btn-div">
              <button
                onClick={() => {
                  handleFinishEditingTag(index)
                }}
              >
                <Icon
                  icon={ICONS.CHECK}
                  className={`${
                    shouldEnableViewInput(index) ? "text-green-500" : "text-gray-300"
                  } mt-0.5`}
                />
              </button>
              <button
                onClick={() => {
                  cancelTagCreation(index)
                }}
              >
                <Icon icon={ICONS.CANCEL} className="text-red-500 mt-0.5" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="max-w-full tag-row">
            <TagsCheckBox
              key={`${index}`}
              disabled={
                shouldDisableInput(index) ||
                (!selectedProdNo && !itemId && !usedInAuditCreation)
              }
              label={tagName}
              className="text-gray-500 font-semibold custom-tag-checkbox"
              onChange={() => {
                onCustomTagCheck(index, intermediate)
              }}
              value={checked}
            />
            <div className="flex items-center justify-items-center h-5">
              <Button
                tippyContent="Rename"
                className="custom-tag-btn"
                variant="icon-btn"
                onClick={() => {
                  handleEdit(index)
                }}
                disabled={shouldDisableInput(index) || isViewer}
              >
                <Icon icon={ICONS.PENCIL} size={4} />
              </Button>
            </div>

            <div className="flex items-center justify-items-center h-5">
              <Button
                className="custom-tag-btn"
                variant="icon-btn"
                onClick={() => onDeleteTag(index)}
                disabled={shouldDisableInput(index) || isViewer}
              >
                <Icon icon={ICONS.DELETE} size={4} />
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
