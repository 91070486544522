import { scoreToColor } from "utils"

export const SCORES = [
  {
    score: 0,
    description: "None",
    scoreColor: scoreToColor(0),
  },
  {
    score: 1,
    description: "Poor",
    scoreColor: scoreToColor(1),
  },
  {
    score: 2,
    description: "Needs Improvement",
    scoreColor: scoreToColor(2),
  },
  {
    score: 3,
    description: "Average",
    scoreColor: scoreToColor(3),
  },
  {
    score: 4,
    description: "Very Good",
    scoreColor: "#5EE282",
  },
  {
    score: 5,
    description: (
      <div>
        Best
        <div>in Class</div>
      </div>
    ),
    scoreColor: scoreToColor(5),
  },
]
