import React from "react"

export const lazyLoadRetry = (componentImport: any) => () => {
  return new Promise<{ default: React.ComponentType<any> }>((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false",
    )

    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false")
        resolve(component)
      })
      .catch((err: any) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem("retry-lazy-refreshed", "true")
          return window.location.reload()
        }

        //TODO emit an error when error handling flow is done
        reject(err)
      })
  })
}

