import { SELECTED_WORKSPACE_ID } from "api/api"
import axios from "axios"
import { toast } from "react-toastify"
import { ERRORS } from "utils/constants"
import {
  errorEventInstance,
  ERROR_PAGE_NOT_FOUND,
  ERROR_WORKSPACE_ERROR,
} from "utils/error-event-instance"

export const ShareOfShelfAPI = (
  baseURL = "https://main-backend-sf3pg75z2q-uc.a.run.app/api",
  callOptions: any = {},
): any => {
  const options = { headers: {}, baseURL, ...callOptions }

  const axiosInstance = axios.create(options)

  axiosInstance.interceptors.request.use(
    (config) => {
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  axiosInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response.data)
    },
    (error) => {
      if (localStorage.getItem(SELECTED_WORKSPACE_ID)) {
        if (ERRORS.internalServerError.errorCodes.includes(error?.response?.status)) {
          return Promise.resolve(error?.response?.data)
        } else if (ERRORS.notFound.errorCodes.includes(error?.response?.status)) {
          errorEventInstance.emit(ERROR_PAGE_NOT_FOUND, {
            response: { status: error.response.status, msg: "An error occurred" },
          })

          return Promise.resolve(error?.response?.data)
        } else if (
          (ERRORS.badRequest.errorCodes.includes(error?.response?.status) &&
            error?.response?.config.method === "post") ||
          error?.response?.config.method === "put"
        ) {
          toast.error(ERRORS.badRequest.message)

          return Promise.resolve(error?.response?.data)
        } else {
          return Promise.resolve(error?.response?.data)
        }
      } else {
        if (ERRORS.internalServerError.errorCodes.includes(error?.response?.status)) {
          errorEventInstance.emit(ERROR_WORKSPACE_ERROR, {
            response: {
              status: error.response.status,
              msg: "A workspace error occurred",
            },
          })

          return Promise.resolve(error?.response?.data)
        } else {
          return Promise.resolve(error?.response?.data)
        }
      }
    },
  )

  return axiosInstance
}
