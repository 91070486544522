import { FC, useEffect, useState } from "react"
import * as yup from "yup"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch, useSelector } from "react-redux"

import "./onboarding.scss"

import { RootState } from "store"
import { Card } from "../../components/card/card"
import { getTermsAndConditionsApi } from "api/endpoints/utils.api"
import { UserFlag } from "common/models/UserFlag"
import { Stepper } from "components/stepper/stepper"
import { StepOne, StepTwo, StepThree, LastStep, StepButtons } from "./onboarding-steps"
import { useHashRouting } from "common/hooks/useHashRouting"

type Props = ReturnType<any>

const schema = yup.object({
  firstName: yup.string().max(75).trim().required(),
  lastName: yup.string().max(75).trim().required(),
  userName: yup.string().required(),
  companyTitle: yup.string().required(),
  companyName: yup.string().required(),
  companyType: yup.string().required(),
  companyRole: yup.string().required(),
  companyIndustry: yup.string().required(),
  marketingSource: yup.string().required(),
  licenseAgreement: yup.boolean().required().oneOf([true]),
})

const stepOfField = {
  firstName: 0,
  lastName: 0,
  userName: 0,
  companyTitle: 1,
  companyName: 1,
  companyType: 1,
  companyRole: 2,
  companyIndustry: 2,
  marketingSource: 2,
  licenseAgreement: 2,
}

const LAST_STEP = 3

export const Onboarding: FC<Props> = () => {
  const [progress, setProgress] = useState(0)
  const [terms, setTerms] = useState<any>({})
  const [step, setStep] = useHashRouting<number>(
    (stepToCheck) => stepToCheck >= 0 && stepToCheck <= LAST_STEP,
    0,
  )
  const [canContinue, setCanContinue] = useState(false)

  const options = useSelector(
    (state: RootState) => state.account.userAccountDetailsOptions,
  )
  const user = useSelector((state: RootState) => state.account.user)
  const dispatch = useDispatch()

  const companyTypes =
    options.companyTypes?.map((companyType) => ({
      value: companyType,
      label: companyType,
    })) || []
  const industryTypes =
    options.industryTypes?.map((industryType) => ({
      value: industryType,
      label: industryType,
    })) || []
  const roleTypes =
    options.roleTypes?.map((roleType) => ({
      value: roleType,
      label: roleType,
    })) || []
  const marketingSources =
    options.marketingSources?.map((roleType) => ({
      value: roleType,
      label: roleType,
    })) || []

  const defaultValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    userName: user.userName,
    companyTitle: user.companyTitle || "",
    companyName: user.companyName || "",
    companyType: user.companyType || "",
    companyRole: user.companyRole || "",
    companyIndustry: user.companyIndustry || "",
    marketingSource: user.marketingSource || "",
    licenseAgreement:
      typeof user.userFlags?.isOnBoardingFlowPassed === "boolean"
        ? user.userFlags.isOnBoardingFlowPassed
        : false,
  }

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit = (values: any) => {
    if (step === LAST_STEP - 1) {
      const newUserFlags = { isOnBoardingFlowPassed: true }
      const userFlags = new UserFlag(newUserFlags)
      const newUser = { ...user, ...values }
      newUser.userFlags = userFlags
      dispatch.account.updateUser(newUser).then(() => {
        calculateProgress()
        setStep(LAST_STEP)
      })
    }
  }

  const calculateProgress = () => {
    const values = Object.values(methods.getValues())
    let newProgress = 100
    values.forEach((value: any) => {
      if (!value) {
        newProgress -= 10
      }
    })
    setProgress(newProgress | 0)
  }

  useEffect(() => {
    const allErrorFields = Object.keys(methods.formState.errors)

    const validateStepTwo = () => {
      const { companyType, companyTitle, companyName } = methods.getValues()
      if (!companyType) {
        allErrorFields.push("companyType")
      }
      if (!companyTitle) {
        allErrorFields.push("companyTitle")
      }
      if (!companyName) {
        allErrorFields.push("companyName")
      }
    }

    const validateStepThree = () => {
      const { companyRole, companyIndustry, marketingSource, licenseAgreement } =
        methods.getValues()
      if (!companyRole) {
        allErrorFields.push("companyRole")
      }
      if (!companyIndustry) {
        allErrorFields.push("companyIndustry")
      }
      if (!marketingSource) {
        allErrorFields.push("marketingSource")
      }
      if (!licenseAgreement) {
        allErrorFields.push("licenseAgreement")
      }
    }

    switch (step) {
      case 1:
        validateStepTwo()
        break

      case 2:
        validateStepThree()
        break

      default:
        break
    }

    const errorFieldsForThisStep = allErrorFields.filter(
      (fieldKey) => stepOfField[fieldKey as keyof typeof stepOfField] <= step,
    )

    setCanContinue(errorFieldsForThisStep.length === 0)
  }, [step, methods.formState])

  useEffect(() => {
    methods.reset(defaultValues)
  }, [user, methods])

  useEffect(() => {
    dispatch.account.fetchUserAccountDetailsOptions()
    dispatch.account.fetchUserData()
  }, [])

  useEffect(() => {
    const getTermsAndConditions = async () => {
      const terms = await getTermsAndConditionsApi()
      setTerms(terms)
    }
    getTermsAndConditions()
  }, [])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="onboarding">
          <div className="onboarding-stepper-container">
            <Stepper steps={Array(LAST_STEP).fill({ label: "" })} activeStep={step} />
          </div>
          <Card className="max-w-[546px] mx-auto">
            <div className="p-2 pt-4">
              {
                {
                  0: <StepOne />,
                  1: <StepTwo companyTypes={companyTypes} />,
                  2: (
                    <StepThree
                      roleTypes={roleTypes}
                      industryTypes={industryTypes}
                      marketingSources={marketingSources}
                      terms={terms}
                    />
                  ),
                  3: <LastStep progress={progress} />,
                }[step]
              }
              <StepButtons
                step={step}
                setStep={setStep}
                lastStep={LAST_STEP}
                canContinue={canContinue}
              />
            </div>
          </Card>
        </div>
      </form>
    </FormProvider>
  )
}
