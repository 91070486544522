import { ItemSnapshotVideoModel } from './ItemSnapshotVideoModel';
import { ItemSnapshotSpecificationModel } from './ItemSnapshotSpecificationModel';
import { ItemSnapshotStarRatingModel } from './ItemSnapshotStarRatingModel';
import { ItemSnapshotDocumentModel } from './ItemSnapshotDocumentModel';
import { ItemSnapshotBrandStoreModel } from './ItemSnapshotBrandStoreModel';

export class ItemSnapshotProductDataModel {
  public title?: string = "";
  public retailerUid?: string = "";
  public productUrl?: string = "";
  public description?: string = "";
  public price: number | string = "";
  public msrp: number | string = "";
  public currency?: string = "";
  public availability?: string = "";
  public brand?: string = "";
  public upc?: string = "";
  public modelName?: string = "";
  public rating: number | string = "";
  public ratingCount: number | string = "";
  public reviewsCount: number | string = "";
  public manufacturer?: string = "";
  public images: string[] = [];
  public videos: ItemSnapshotVideoModel[] = [];
  public categories: string[] = [];
  public bullets: string[] = [];
  public keywords: string[] = [];
  public specifications: ItemSnapshotSpecificationModel[] = [];
  public starRatings: ItemSnapshotStarRatingModel[] = [];
  public documents: ItemSnapshotDocumentModel[] = [];
  public has360View: boolean = false;
  public soldBy?: string = "";
  public shippedBy?: string = "";
  public hasCoupon: boolean = false;
  public hasAPlusContent: boolean = false;
  public subscribeAndSave: boolean = false;
  public color?: string = "";
  public style?: string = "";
  public country?: string = "";
  public manufacturerPartNumber?: string = "";
  public productBadge?: string = "";
  public fulfillmentType?: string = "";
  public aPlusModulesCount: number | string = "";
  public hasEnhancedContent: boolean = false;
  public aPlusModuleType?: string = "";
  public aPlusModuleTemplateCount?: any = undefined;
  public brandStore: ItemSnapshotBrandStoreModel = {} as ItemSnapshotBrandStoreModel;
  public isPrime: boolean = false;
  public variantDimensions: ItemSnapshotSpecificationModel[] = [];
  public variants: string[] = [];
  public variantIdUrls: string[] = [];
  public variantOptions?: string = "";
  public variantOptionUrls: string[] = [];
  public descriptionSections: ItemSnapshotSpecificationModel[] = [];
  public bestSellersRankFlat?: string = "";
  public isPromotion: boolean = false;
  public amazonSellers: string[] = [];
  public fitment: boolean = false;
  public parentAsin?: string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.title = obj.title === null? "" : obj.title;
  this.retailerUid = obj.retailerUid === null? "" : obj.retailerUid;
  this.productUrl = obj.productUrl === null? "" : obj.productUrl;
  this.description = obj.description === null? "" : obj.description;
  this.price = obj.price === null? "" : obj.price;
  this.msrp = obj.msrp === null? "" : obj.msrp;
  this.currency = obj.currency === null? "" : obj.currency;
  this.availability = obj.availability === null? "" : obj.availability;
  this.brand = obj.brand === null? "" : obj.brand;
  this.upc = obj.upc === null? "" : obj.upc;
  this.modelName = obj.modelName === null? "" : obj.modelName;
  this.rating = obj.rating === null? "" : obj.rating;
  this.ratingCount = obj.ratingCount === null? "" : obj.ratingCount;
  this.reviewsCount = obj.reviewsCount === null? "" : obj.reviewsCount;
  this.manufacturer = obj.manufacturer === null? "" : obj.manufacturer;
  this.images = obj.images || [];
  this.videos = obj.videos?.filter((item: any) => item !== undefined).map((item: any) => new ItemSnapshotVideoModel(item)) || [];
  this.categories = obj.categories || [];
  this.bullets = obj.bullets || [];
  this.keywords = obj.keywords || [];
  this.specifications = obj.specifications?.filter((item: any) => item !== undefined).map((item: any) => new ItemSnapshotSpecificationModel(item)) || [];
  this.starRatings = obj.starRatings?.filter((item: any) => item !== undefined).map((item: any) => new ItemSnapshotStarRatingModel(item)) || [];
  this.documents = obj.documents?.filter((item: any) => item !== undefined).map((item: any) => new ItemSnapshotDocumentModel(item)) || [];
  this.has360View = obj.has360View === null? false : obj.has360View;
  this.soldBy = obj.soldBy === null? "" : obj.soldBy;
  this.shippedBy = obj.shippedBy === null? "" : obj.shippedBy;
  this.hasCoupon = obj.hasCoupon === null? false : obj.hasCoupon;
  this.hasAPlusContent = obj.hasAPlusContent === null? false : obj.hasAPlusContent;
  this.subscribeAndSave = obj.subscribeAndSave === null? false : obj.subscribeAndSave;
  this.color = obj.color === null? "" : obj.color;
  this.style = obj.style === null? "" : obj.style;
  this.country = obj.country === null? "" : obj.country;
  this.manufacturerPartNumber = obj.manufacturerPartNumber === null? "" : obj.manufacturerPartNumber;
  this.productBadge = obj.productBadge === null? "" : obj.productBadge;
  this.fulfillmentType = obj.fulfillmentType === null? "" : obj.fulfillmentType;
  this.aPlusModulesCount = obj.aPlusModulesCount === null? "" : obj.aPlusModulesCount;
  this.hasEnhancedContent = obj.hasEnhancedContent === null? false : obj.hasEnhancedContent;
  this.aPlusModuleType = obj.aPlusModuleType === null? "" : obj.aPlusModuleType;
  this.aPlusModuleTemplateCount = obj.aPlusModuleTemplateCount;
  this.brandStore = !obj.brandStore ? new ItemSnapshotBrandStoreModel() : new ItemSnapshotBrandStoreModel(obj.brandStore);
  this.isPrime = obj.isPrime === null? false : obj.isPrime;
  this.variantDimensions = obj.variantDimensions?.filter((item: any) => item !== undefined).map((item: any) => new ItemSnapshotSpecificationModel(item)) || [];
  this.variants = obj.variants || [];
  this.variantIdUrls = obj.variantIdUrls || [];
  this.variantOptions = obj.variantOptions === null? "" : obj.variantOptions;
  this.variantOptionUrls = obj.variantOptionUrls || [];
  this.descriptionSections = obj.descriptionSections?.filter((item: any) => item !== undefined).map((item: any) => new ItemSnapshotSpecificationModel(item)) || [];
  this.bestSellersRankFlat = obj.bestSellersRankFlat === null? "" : obj.bestSellersRankFlat;
  this.isPromotion = obj.isPromotion === null? false : obj.isPromotion;
  this.amazonSellers = obj.amazonSellers || [];
  this.fitment = obj.fitment === null? false : obj.fitment;
  this.parentAsin = obj.parentAsin === null? "" : obj.parentAsin;
}

}
