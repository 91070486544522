import { FC } from "react"
import "./review-audit.scss"
import { connect, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"
import * as yup from "yup"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AuditSubmitButtons } from "../audit-submit-buttons/audit-submit-buttons"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import { Header } from "components/header/header"
import { Icon, ICONS } from "components/icon/icon"
import { ScheduledJobType } from "common/enums/ScheduledJobType.enum"
import { format, parseISO } from "date-fns"

type Props = { isDisabledSubmit: boolean } & ReturnType<typeof mapProps> &
  ReturnType<typeof mapDispatch> & {
    onSubmit: (values: any) => void
    handleBack: () => void
    isDisabledBack: boolean
  }

const schema = yup.object({
  approveCheck: yup.boolean().isTrue("Required"),
  agreeCheck: yup.boolean().isTrue("Required"),
})

export const ReviewAuditComponent: FC<Props> = ({
  audits,
  retailers,
  onSubmit,
  handleBack,
  isDisabledBack,
  isDisabledSubmit,
}: Props) => {
  const isLoadingSubmit = useSelector(
    (state: RootState) => state.loading.effects.audits.createOrEditAudit,
  )
  const auditNextExecutionDate = useSelector(
    (state: RootState) => state.plans.auditNextExecutionDate,
  )

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      approveCheck: false,
      agreeCheck: false,
    },
  })

  const isInnerSubmitDisabled = !(
    !!methods.watch("approveCheck") && !!methods.watch("agreeCheck")
  )

  return (
    <FormProvider {...methods}>
      <form
        className="add-edit-audit-card-content"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="add-edit-audit-card-body">
          <div className="review-audit">
            <div style={{ minWidth: 450 }} className="flex flex-col justify-between">
              <div>
                <Header
                  title="Audit Details"
                  description="Please review and approve the following details:"
                />
                <div className="text-gray-500 grid gap-y-2">
                  <div className="text-gray-500 items-baseline grid grid-flow-col justify-start gap-x-2">
                    <Icon
                      icon={ICONS.ARROW_RIGHT}
                      className="text-primary- ml-5"
                      size={3.5}
                    />
                    <div>
                      Audits will be run on{" "}
                      <b>
                        {
                          retailers.find((r) => r.id === audits.selectedAudit?.retailerId)
                            ?.name
                        }
                      </b>{" "}
                      for <b>{audits.selectedAudit?.items?.length} SKUs</b>.
                    </div>
                  </div>
                  <div className="text-gray-500 items-baseline grid grid-flow-col justify-start gap-x-2">
                    <Icon
                      icon={ICONS.ARROW_RIGHT}
                      className="text-primary- ml-5"
                      size={3.5}
                    />
                    <div>
                      This audit job will begin{" "}
                      {audits.selectedAudit?.runNow && <b>today</b>}
                      {!audits.selectedAudit?.runNow &&
                        audits.selectedAudit?.jobType !== undefined && (
                          <>
                            on{" "}
                            <b>
                              {format(
                                parseISO(
                                  auditNextExecutionDate[
                                    audits.selectedAudit?.jobType
                                  ] as string,
                                ),
                                "EEEE LLLL do",
                              )}
                            </b>
                          </>
                        )}
                      .
                    </div>
                  </div>
                </div>
              </div>
              <Checkbox
                name="approveCheck"
                label={
                  <>
                    I approve applying these {audits.selectedAudit?.items?.length} SKUs to
                    my{" "}
                    <b>
                      {audits.selectedAudit?.jobType !== undefined &&
                      audits.selectedAudit?.jobType !== null
                        ? ScheduledJobType[audits.selectedAudit?.jobType]
                        : "One-Time"}{" "}
                      Audit
                    </b>{" "}
                    plan.
                  </>
                }
                className="my-5 4xl:my-0 4xl:self-end"
              />
            </div>

            <div className="border-b border-gray-200 mb-5 4xl:border-b-0 4xl:border-r 4xl:mb-0 4xl:mx-20" />

            <div>
              <Header
                title="Approve & submit"
                description="Please review and agree to the following in order to click submit."
              />
              <div className="text-gray-500 items-baseline grid grid-flow-col gap-x-2">
                <Icon
                  icon={ICONS.ARROW_RIGHT}
                  className="text-primary- ml-5"
                  size={3.5}
                />
                Content Status does not claim ownership of content that is returned; the
                ownership of returned content remains with the original author. You agree
                that your use of Content Status technology and tools are restricted to the
                extraction of data from publicly accessible websites which may or may not
                require a user login. In all cases where copyrighted and or confidential
                data may be included as part or all of the extracted data, you agree to
                comply with all copyright laws and rules covering such data including (but
                not limited to) the reproduction and resale of such data. You agree that
                Content Status will not be held responsible for legal liabilities incurred
                through the use or misuse of data obtained via Content Status technology.
              </div>
              <Checkbox
                name="agreeCheck"
                label="I read and agree to the above statement."
                className="mt-5"
              />
            </div>
          </div>
        </div>

        <AuditSubmitButtons
          submitLabel="Submit"
          isDisabledSubmit={isDisabledSubmit || isInnerSubmitDisabled}
          isLoadingSubmit={isLoadingSubmit}
          handleBack={handleBack}
          isDisabledBack={isDisabledBack}
        />
      </form>
    </FormProvider>
  )
}

const mapProps = (state: RootState) => ({
  audits: state.audits,
  retailers: state.retailers.allRetailers,
  loading: state.loading.effects.audits.fetchAudit,
})

const mapDispatch = (dispatch: RootDispatch) => ({
  fetchAudit: dispatch.audits.fetchAudit,
  setSelectedAudit: dispatch.audits.setSelectedAudit,
})

export const ReviewAudit = connect(mapProps, mapDispatch)(ReviewAuditComponent)
