import React from "react"
import { useFormContext } from "react-hook-form"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { SubscriptionPlanType } from "common/enums/SubscriptionPlanType.enum"
import { WorkspacePlanModel } from "common/models/WorkspacePlanModel"
import { Icon, ICONS } from "components/icon/icon"
import { RootState } from "store"
import { NewAuditCard } from "../../new-audit-card/new-audit-card"
import { LINK_CLASS_NAME } from "utils/constants"

const allPlans = [
  {
    type: SubscriptionPlanType.FreeTrial,
    title: "FREE TRIAL",
    description: "These are one-time audits that can be conduct at anytime.",
  },
  {
    type: SubscriptionPlanType.OneTime,
    title: "FLEX | ONE-TIME AUDIT",
    description: "These are one-time audits that can be conduct at anytime.",
  },
  {
    type: SubscriptionPlanType.Monthly,
    title: "SMART | MONTHLY AUDITING",
    description:
      "This is a monthly auditing plan that conduct in 1st Monday of the Month.",
  },
  {
    type: SubscriptionPlanType.Weekly,
    title: "BRILLIANT | WEEKLY AUDITING",
    description: "This is a weekly auditing plan that conduct every Monday.",
  },
  {
    type: SubscriptionPlanType.Daily,
    title: "GENIUS | DAILY AUDITING",
    description: "This is a daily audit plan that runs from Monday to Friday.",
  },
]

export type PlansObj = {
  [planType: string]: WorkspacePlanModel
}

type Props = {
  name: string
  availablePlansObj: PlansObj
}

export const AuditPlanCards: React.FC<Props> = ({ name, availablePlansObj }) => {
  const formContext = useFormContext()
  const { setValue, watch } = formContext || {}
  const value = watch(name)

  const workspacePlans = useSelector(
    (state: RootState) => state.plans.workspaceAssignedPlans,
  )

  return (
    <div className="select-grid-card">
      {allPlans.map((plan) => {
        const availablePlan = availablePlansObj[plan.type.toString()]
        const disabled = availablePlan === undefined

        if (plan.type === SubscriptionPlanType.FreeTrial) {
          const hasAnyPlan =
            workspacePlans.filter(
              (workspacePlan) =>
                workspacePlan.subscriptionPlanType !== SubscriptionPlanType.FreeTrial,
            )?.length > 0
          if (hasAnyPlan) {
            return null
          }
        }

        return (
          <NewAuditCard
            selected={availablePlan?.subscriptionPlanId === value && value !== undefined}
            title={plan.title}
            disabled={disabled}
            onClick={() => setValue(name, availablePlan?.subscriptionPlanId)}
            body={
              disabled ? (
                <div className="flex justify-center items-center h-full">
                  <Link
                    className={`text-footnote font-semibold mb-4 ${LINK_CLASS_NAME}`}
                    to="/main/billing/plans"
                  >
                    <p>No plans available!</p>
                    <p>Click here to buy one.</p>
                  </Link>
                </div>
              ) : (
                <div className="flex flex-col gap-5 justify-between h-full">
                  <span className="text-footnote">{plan.description}</span>
                  <span className="text-caption-2 text-gray-900 font-semibold relative">
                    Remaining Audits | {availablePlan.numberOfItemsLeft}
                    {plan.type === SubscriptionPlanType.FreeTrial && (
                      <Icon
                        tippyProps={{
                          content: (
                            <div className="info-point" style={{ width: 300 }}>
                              <div className="mb-1 text-black text-caption-1 font-medium">
                                Important!
                              </div>
                              <div className="text-gray-500 text-caption-1 text-left">
                                Even if you still have units left, this free trial will
                                stop working if you buy a new plan.
                              </div>
                            </div>
                          ),
                          interactive: true,
                          placement: "right",
                        }}
                        className="absolute -right-2 -top-1/3"
                        icon={ICONS.INFORMATION_CIRCLE_OUTLINED}
                        size={5}
                      />
                    )}
                  </span>
                </div>
              )
            }
            style={{ width: 242 }}
          />
        )
      })}
    </div>
  )
}
