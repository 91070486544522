import { VideoUrlType } from 'common/enums/VideoUrlType.enum';

export class ItemSnapshotVideoModel {
  public videoUrl?: string = "";
  public posterUrl?: string = "";
  public videoUrlType: VideoUrlType = {} as VideoUrlType;
constructor(obj = {} as any) {
  obj = obj || {};
  this.videoUrl = obj.videoUrl === null? "" : obj.videoUrl;
  this.posterUrl = obj.posterUrl === null? "" : obj.posterUrl;
  this.videoUrlType = obj.videoUrlType === null? {} as VideoUrlType : obj.videoUrlType;
}

}
