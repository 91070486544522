import { CatalogSavedViewModel } from 'common/models/CatalogSavedViewModel';
import { UpdateSavedViewsModel } from 'common/models/UpdateSavedViewsModel';
import { UpdateCatalogSavedViewModel } from 'common/models/UpdateCatalogSavedViewModel';
import { CatalogSharedViewsModel } from 'common/models/CatalogSharedViewsModel';
import { API } from "../api";

export const createSavedViewApi = (workspaceId: string, body: CatalogSavedViewModel): Promise<boolean> => API().post(`workspaces/${workspaceId}/savedViews`, body);

export const getAllUserSavedViewsApi = (workspaceId: string): Promise<CatalogSavedViewModel[]> => API().get(`workspaces/${workspaceId}/savedViews`);

export const getUserSavedViewApi = (workspaceId: string, savedViewId: string): Promise<CatalogSavedViewModel> => API().get(`workspaces/${workspaceId}/savedViews/${savedViewId}`);

export const renameUserSavedViewApi = (workspaceId: string, savedViewId: string, body: UpdateSavedViewsModel): Promise<boolean> => API().put(`workspaces/${workspaceId}/savedViews/${savedViewId}/rename`, body);

export const setViewAsDefaultApi = (workspaceId: string, savedViewId: string): Promise<boolean> => API().post(`workspaces/${workspaceId}/savedViews/${savedViewId}/setDefault`);

export const deleteUserSavedViewsApi = (workspaceId: string, body: string[]): Promise<boolean> => API().post(`workspaces/${workspaceId}/savedViews/delete`, body);

export const updateUserSavedViewApi = (savedViewId: string, workspaceId: string, body: UpdateCatalogSavedViewModel): Promise<boolean> => API().put(`workspaces/${workspaceId}/savedViews/${savedViewId}/update`, body);

export const getAllSharedViewsApi = (workspaceId: string): Promise<CatalogSharedViewsModel[]> => API().get(`workspaces/${workspaceId}/savedViews/shared`);

export const copySharedViewApi = (workspaceId: string, sharedViewId: string): Promise<boolean> => API().post(`workspaces/${workspaceId}/savedViews/${sharedViewId}/copy`);

export const shareViewApi = (savedViewId: string, workspaceId: string, body: string[]): Promise<boolean> => API().post(`workspaces/${workspaceId}/savedViews/${savedViewId}/share`, body);

export const deleteUserSharedViewsApi = (workspaceId: string, body: string[]): Promise<boolean> => API().post(`workspaces/${workspaceId}/savedViews/delete/sharedViews`, body);
