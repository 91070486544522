import { ItemFilterModel } from './ItemFilterModel';
import { ItemDashboardFilterModel } from './ItemDashboardFilterModel';
import { PageModel } from './PageModel';
// !! itemDashboardFilterModel: ItemDashboardFilterModel | null for catalog filtering
export class CatalogRequestOptions {
  public itemFilterModel: ItemFilterModel = {} as ItemFilterModel;
  public itemDashboardFilterModel: ItemDashboardFilterModel | null = null;
  public pageModel: PageModel = {} as PageModel;
constructor(obj = {} as any) {
  obj = obj || {};
  this.itemFilterModel = !obj.itemFilterModel ? new ItemFilterModel() : new ItemFilterModel(obj.itemFilterModel);
  this.itemDashboardFilterModel = !obj.itemDashboardFilterModel ? null : new ItemDashboardFilterModel(obj.itemDashboardFilterModel);
  this.pageModel = !obj.pageModel ? new PageModel() : new PageModel(obj.pageModel);
}

}
