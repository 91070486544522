import { RematchDispatch } from "@rematch/core"
import { WORKSPACE_ID } from "api/api"
import { reTriggerItemsApi } from "api/endpoints/items.api"
import {
  createRubricApi,
  deleteRetailerCategoryAllocationApi,
  deleteRubricApi,
  getDefaultRubricsApi,
  getNewDefaultRubricsApi,
  getRubricApi,
  getWorkspaceRubricsApiPaged,
  saveRubricChagesApi,
  updateDefaultRubricApi,
} from "api/endpoints/rubrics.api"
import { SortDirection } from "common/enums/SortDirection.enum"
import { RetailerRubricModel } from "common/models/RetailerRubricModel"
import { RubricDetailsModel } from "common/models/RubricDetailsModel"
import { RubricModel } from "common/models/RubricModel"
import { SectionWeightingModel } from "common/models/SectionWeightingModel"
import { gridPageSizes } from "utils/constants"
import { updateObjectInArray } from "./store-utils"
import { RubricsGetModel } from "common/models/RubricsGetModel"

export const defaultRequestOptionsForRubrics: RubricsGetModel = {
  searchQuery: "",
  type: null,
  pageModel: {
    page: 1,
    pageSize: 20,
    sortField: "createdAt",
    sortDirection: SortDirection.Descending,
  },
}

type State = Readonly<{
  scoringRubrics: { items: RubricModel[]; nextPage?: number }
  defaultRubrics: { items: RetailerRubricModel[] }
  selectedRubric: RubricDetailsModel | null
  pageSizeOptions: { label: string; value: number }[]
  requestOptions: RubricsGetModel
  isEditScoreWeighting: boolean
  currentWeighting: SectionWeightingModel[]
}>

const model = {
  state: {
    scoringRubrics: { items: [], nextPage: 0 },
    defaultRubrics: { items: [] },
    selectedRubric: null,
    pageSizeOptions: gridPageSizes,
    requestOptions: defaultRequestOptionsForRubrics,
    isEditScoreWeighting: false,
    currentWeighting: [],
  } as State,
  reducers: {
    loadedScoringRubrics: (state: State, payload: any): State => ({
      ...state,
      scoringRubrics: { items: payload },
    }),
    loadedDefaultRubrics: (state: State, payload: any): State => ({
      ...state,
      defaultRubrics: { items: payload },
    }),
    setRetailerDefaultRubric: (state: State, payload: any): State => ({
      ...state,
      defaultRubrics: updateObjectInArray(state.defaultRubrics, payload, "retailerId"),
    }),
    setSelectedRubric: (state: State, payload: RubricDetailsModel | null): State => ({
      ...state,
      selectedRubric: payload,
    }),
    setRequestOptions: (state: State, payload: RubricsGetModel): State => ({
      ...state,
      requestOptions: payload,
    }),
    setIsEditScoreWeighting: (state: State, payload: boolean): State => ({
      ...state,
      isEditScoreWeighting: payload,
    }),
    setCurrentWeighting: (state: State, payload: SectionWeightingModel[]): State => ({
      ...state,
      currentWeighting: payload,
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchGradingData(payload: RubricsGetModel, rootState: any) {
      const scoringRubrics = await getWorkspaceRubricsApiPaged(WORKSPACE_ID, payload)
      const page = payload.pageModel.page
      const searchQuery = payload.searchQuery
      if (scoringRubrics?.items && scoringRubrics.items.length !== undefined) {
        dispatch.grading.loadedScoringRubrics(
          page === 1 || searchQuery !== ""
            ? scoringRubrics.items
            : [...rootState.grading.scoringRubrics.items, ...scoringRubrics.items],
        )

        return scoringRubrics
      }
    },
    async fetchDefaultRubrics() {
      const defaultRubrics = await getDefaultRubricsApi(WORKSPACE_ID)

      if (defaultRubrics) {
        dispatch.grading.loadedDefaultRubrics(defaultRubrics)
      }
    },
    async updateDefaultRubrics(payload: any) {
      const result = await updateDefaultRubricApi(WORKSPACE_ID, payload)

      if (result) {
        this.fetchDefaultRubrics()
      }
      return result
    },
    async deleteRubric(payload: { rubricId: string }, rootState: any) {
      const result = await deleteRubricApi(WORKSPACE_ID, payload.rubricId)

      if (result) {
        dispatch.grading.loadedScoringRubrics(
          rootState.grading.scoringRubrics.items.filter(
            (r: RubricModel) => r.id !== payload.rubricId,
          ),
        )
      }
      return result
    },
    async deleteRetailerCategoryRubric(payload: string) {
      const result = await deleteRetailerCategoryAllocationApi(WORKSPACE_ID, payload)

      if (result) {
        this.fetchDefaultRubrics()
      }
      return result
    },
    async fetchRubric(payload: string) {
      const rubric = await getRubricApi(payload)

      if (rubric) {
        dispatch.grading.setSelectedRubric(rubric)
        return rubric
      }
      return false
    },
    async fetchNewDefaultRubric() {
      const rubric = await getNewDefaultRubricsApi()

      if (rubric) {
        dispatch.grading.setSelectedRubric(rubric)
        return rubric
      }
      return false
    },
    async saveRubricChanges(payload: any, rootState: any) {
      const updatedRubric = await saveRubricChagesApi(
        WORKSPACE_ID,
        payload.rubricId,
        payload.rubric,
      )

      if (updatedRubric) {
        this.fetchGradingData(rootState.grading.requestOptions, rootState)
        return updatedRubric
      }
      return false
    },
    async createNewRubric(payload: any, rootState: any) {
      const updatedRubric = await createRubricApi(WORKSPACE_ID, payload)

      if (updatedRubric) {
        this.fetchGradingData(rootState.grading.requestOptions, rootState)
        return updatedRubric
      }
      return false
    },
    async reTriggerItems(payload: string) {
      await reTriggerItemsApi(payload)
    },
  }),
}

export default model
