import React, { useLayoutEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import "../field-mapping.scss"

import { RootState } from "../../../store"
import { useModal } from "../../../context/modal-context"
import { Table } from "components/table/table"
import { Button } from "../../../components/button/button"
import { ImageComponent } from "../../../components/image/image"
import { SuccessMappingModal } from "../success-mapping-modal/SuccessMappingModal"
import { ProductImportCsvModel } from "../../../common/models/ProductImportCsvModel"
import { saveMapApi } from "../../../api/endpoints/data-mapping-import.api"
import { ThirdStepTypes } from "../field-mapping"

type Props = {
  data: ThirdStepTypes
  step: number
  setStep: (st: number) => void
  onCancel: () => void
  scrollToTop: () => void
}
const getColumns = () => {
  const tooltipInstance = useSelector((state: RootState) => state.tippyTarget.target)

  return [
    {
      header: "Rank",
      isSortable: true,
      accessorKey: "rank",
      size: 60,
    },
    {
      header: "Brand",
      isSortable: true,
      accessorKey: "brand",
      size: 200,
    },
    {
      header: "Title",
      isSortable: true,
      accessorKey: "title",
    },
    {
      header: "Main Image",
      isSortable: true,
      cell: ({
        row: {
          original: { images },
        },
      }: any) => {
        if (images && images[0]) {
          return (
            <ImageComponent
              src={images[0]}
              alt={images[0]}
              tooltipInstance={tooltipInstance}
              className="image h-10 w-10 rounded border border-gray-100"
            />
          )
        }
      },
    },
    {
      header: "Product ID",
      isSortable: true,
      accessorKey: "retailerUid",
    },
  ]
}

export const StepThree: React.FC<Props> = ({
  data,
  step,
  setStep,
  onCancel,
  scrollToTop,
}) => {
  const navigate = useNavigate()
  const { rows, dataMappingId } = data
  const gridRows = rows
    .map((row: ProductImportCsvModel, index: number) => ({ ...row, rank: index + 1 }))
    .slice(0, 10)
  const selectedWorkspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )

  const { setModal } = useModal()

  const onSaveMap = () => {
    if (!selectedWorkspaceId || !dataMappingId) {
      return
    }

    saveMapApi(selectedWorkspaceId, dataMappingId).then((resp) => {
      if (resp) {
        navigate(`/main/workspaces/${selectedWorkspaceId}/edit/upload`, {
          state: 1,
        })
        setModal(<SuccessMappingModal />)
      }
    })
  }

  useLayoutEffect(scrollToTop, [])

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center">
        <div>
          <div className="title">Sample Preview</div>
          <div className="description">
            In the table below you can see the information from the file imported and
            mapped to our application. Only the first 10 Rows are visible to this preview.
          </div>
        </div>
        <Button variant="outlined" className="mr-3" onClick={onCancel}>
          Cancel Upload
        </Button>
      </div>
      <div className="divider"></div>
      <div className="mb-6 flex-1">
        <Table
          columns={getColumns()}
          data={gridRows}
          emptyTableMessage="No data available"
        />
      </div>
      <div className="mt-8 flex justify-end">
        <Button variant="outlined" className="mr-3" onClick={() => setStep(step - 1)}>
          Back
        </Button>
        <Button variant="success" onClick={onSaveMap}>
          Save Map
        </Button>
      </div>
    </div>
  )
}
