import React from "react"
import { ScheduledJobType } from "common/enums/ScheduledJobType.enum"
import { Header } from "components/header/header"
import { format, parseISO } from "date-fns"
import { useFormContext } from "react-hook-form"
import { NewAuditCard } from "../../new-audit-card/new-audit-card"

type Props = {
  name: string
  auditNextExecutionDate?: string
  scheduleType: ScheduledJobType | null
}

export const AuditStartTime: React.FC<Props> = ({
  name,
  auditNextExecutionDate,
  scheduleType,
}) => {
  const formContext = useFormContext()
  const { setValue, watch } = formContext || {}
  const value = watch(name)

  return (
    <>
      <hr className="my-5" />
      <div>
        <Header
          title="Audit Start Time"
          description="When do you want to start auditing these SKUs?"
        />
        <div className="select-grid-card">
          <NewAuditCard
            selected={value === true}
            title="RUN NOW"
            onClick={() => setValue(name, true)}
            body={
              <div className="flex flex-col gap-5 justify-between h-full">
                <span className="text-footnote">
                  {scheduleType === null
                    ? "These will run the audit now and only once."
                    : "These will run the audit now, and add it to a recurring schedule if applicable."}
                </span>
                <span className="text-caption-2 text-gray-900 font-semibold">
                  {scheduleType === null ? (
                    "Only applicable for Flex & Free Plan"
                  ) : typeof auditNextExecutionDate === "string" ? (
                    <>
                      Runs now & will add to regular schedule starting{" "}
                      {format(parseISO(auditNextExecutionDate as string), "EEEE LLLL do")}
                      .
                    </>
                  ) : null}
                </span>
              </div>
            }
            style={{ width: 242 }}
          />
          <NewAuditCard
            selected={value === false}
            title="WAIT"
            disabled={scheduleType === null}
            onClick={() => setValue(name, false)}
            body={
              <div className="flex flex-col gap-5 justify-between h-full">
                <span className="text-footnote">
                  This will add the audit to recurring schedule.
                </span>
                <span
                  className={`text-caption-2 font-semibold${
                    scheduleType !== null ? " text-gray-900" : ""
                  }`}
                >
                  {scheduleType === null ? (
                    "Disabled for Flex & Free Plan."
                  ) : typeof auditNextExecutionDate === "string" ? (
                    <>
                      Will add to regular schedule starting{" "}
                      {format(parseISO(auditNextExecutionDate as string), "EEEE LLLL do")}
                      .
                    </>
                  ) : null}
                </span>
              </div>
            }
            style={{ width: 242 }}
          />
        </div>
      </div>
    </>
  )
}
