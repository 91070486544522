import { RetailerModel } from 'common/models/RetailerModel';
import { AuditType } from 'common/enums/AuditType.enum';
import { API } from "../api";

export const getRetailersApi = (workspaceId: string): Promise<RetailerModel[]> => API().get(`retailers?workspaceId=${workspaceId}`);

export const getRetailerByAuditTypeApi = (auditType: AuditType, workspaceId: string): Promise<RetailerModel[]> => API().get(`retailers/${auditType}?workspaceId=${workspaceId}`);

export const getFrequentlyUsedByAuditTypeApi = (auditType: AuditType, workspaceId: string): Promise<RetailerModel[]> => API().get(`retailers/${auditType}/frequently-used/${workspaceId}`);

export const getRetailerCategoriesApi = (retailerId: string): Promise<string[]> => API().get(`retailers/${retailerId}/categories`);
