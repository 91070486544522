import { CatalogItemModel } from "common/models/CatalogItemModel"
import { ItemTagsModel } from "common/models/ItemTagsModel"
import { TagType } from "./tags-modal"

export function getModalTitle(itemId: string | null, selectedProdNo: number): string {
  if (itemId) {
    return "Edit item tags"
  }
  if (selectedProdNo === 0) {
    return "Manage Workspace tags"
  } else {
    return `You've Selected ${selectedProdNo} Records for Tagging`
  }
}

export function getSelected(tags: TagType[]) {
  const checkedTags = tags.filter((tag) => tag.checked)
  return checkedTags.map((tag) => tag.name)
}

export function checkCountMap(
  countMap: Map<string, number>,
  tag: string,
  selectedProdNo: number,
): TagType {
  const tagCount: number = countMap.get(tag) || 0
  if (tagCount === selectedProdNo) {
    return {
      name: tag,
      checked: 2, //* full
      intermediate: false,
    }
  }
  if (tagCount === 0) {
    return {
      name: tag,
      checked: 0, //* empty
      intermediate: false,
    }
  }
  if (tagCount > 0 && tagCount < selectedProdNo) {
    return {
      name: tag,
      checked: 1, //* partial
      intermediate: true,
    }
  }
  return {
    name: "",
    checked: 0,
    intermediate: false,
  }
}

export function disableCheckBoxes(
  itemId: string | null,
  selectedProdNo: number,
): boolean {
  if (itemId) {
    return false
  }
  if (selectedProdNo === 0) {
    return true
  }
  return false
}

export function stringToTags(stringTags: string[]) {
  const rez = stringTags.map((tag) => {
    const rez: TagType = {
      name: tag,
      checked: 0,
      intermediate: false,
    }
    return rez
  })

  return rez
}

export function checkBoxesOnInit(
  selectedProducts: any[],
  catalogItem: CatalogItemModel,
  workspaceSystemTags: string[],
  workspaceCustomTags: string[],
  itemId: string | null,
  setSystemTags: (tags: any[]) => void,
  setCustomTags: (tags: any[]) => void,
) {
  const workspaceSystemStringToTags = stringToTags(workspaceSystemTags)
  const workspaceCustomStringToTags = stringToTags(workspaceCustomTags)

  const selectedProdNo = selectedProducts?.length || 0

  //* one product selected in audit catalog
  if (selectedProdNo === 1 && !itemId) {
    const itemTags = checkItemTags(
      selectedProducts[0]?.tags,
      workspaceSystemTags,
      workspaceCustomTags,
    )
    setSystemTags(itemTags.systemTags)
    setCustomTags(itemTags.customTags)
  }

  //* modal open from Item Preview
  if (itemId) {
    const itemTags = checkItemTags(
      catalogItem.tags,
      workspaceSystemTags,
      workspaceCustomTags,
    )
    setSystemTags(itemTags.systemTags)
    setCustomTags(itemTags.customTags)
  }

  //* no item selected in audit catalog -> edit workspace tags
  if (selectedProdNo === 0 && !itemId) {
    setSystemTags(workspaceSystemStringToTags)
    setCustomTags(workspaceCustomStringToTags)
  }

  //* more than 1 item selected in audit catalog
  if (selectedProdNo > 1 && !itemId) {
    const itemsTags = partialCheckTags(
      workspaceSystemTags,
      workspaceCustomTags,
      selectedProducts,
    )
    setSystemTags(itemsTags.systemTags)
    setCustomTags(itemsTags.customTags)
  }
}

function checkItemTags(
  itemTags: string[],
  workspaceSystemTags: string[],
  workspaceCustomTags: string[],
) {
  const itemTagsSet = new Set(itemTags)

  const checkedSystemTags = workspaceSystemTags.map((tag) => {
    const rez: TagType = {
      name: tag,
      checked: itemTagsSet.has(tag) ? 2 : 0,
      intermediate: false,
    }
    return rez
  })

  const checkedCustomTags = workspaceCustomTags.map((tag) => {
    const rez: TagType = {
      name: tag,
      checked: itemTagsSet.has(tag) ? 2 : 0,
      intermediate: false,
    }
    return rez
  })

  return {
    systemTags: checkedSystemTags,
    customTags: checkedCustomTags,
  }
}

function partialCheckTags(
  workspaceSystemTags: string[],
  workspaceCustomTags: string[],
  selectedProducts: any[],
) {
  const selectedProdNo = selectedProducts?.length || 0
  const customCountMap = new Map()
  const systemCountMap = new Map()

  workspaceCustomTags.forEach((tag) => {
    customCountMap.set(tag, 0)
  })

  workspaceSystemTags.forEach((tag) => {
    systemCountMap.set(tag, 0)
  })

  selectedProducts.forEach((product) => {
    const productTags = product.tags
    productTags.forEach((tag: string) => {
      if (customCountMap.has(tag)) {
        customCountMap.set(tag, customCountMap.get(tag) + 1)
      }

      if (systemCountMap.has(tag)) {
        systemCountMap.set(tag, systemCountMap.get(tag) + 1)
      }
    })
  })

  const checkedCustomTags: TagType[] = workspaceCustomTags.map((tag) => {
    return checkCountMap(customCountMap, tag, selectedProdNo)
  })

  const checkedSystemTags = workspaceSystemTags.map((tag) => {
    return checkCountMap(systemCountMap, tag, selectedProdNo)
  })

  return {
    systemTags: checkedSystemTags,
    customTags: checkedCustomTags,
  }
}

export function computeItemsTagUpdate(
  selectedProducts: CatalogItemModel[],
  customTags: TagType[],
  systemTags: TagType[],
  workspaceSystemTags: string[],
  workspaceCustomTags: string[],
): ItemTagsModel[] {
  const customCountMap = new Map()
  const systemCountMap = new Map()

  customTags.forEach((tag) => {
    customCountMap.set(tag.name, tag.checked)
  })

  systemTags.forEach((tag) => {
    systemCountMap.set(tag.name, tag.checked)
  })

  //? O(n^2)
  return selectedProducts.map((product) => {
    const productTags = product.tags

    const productCustomTags: string[] = []
    const productSystemTags: string[] = []

    workspaceCustomTags.forEach((tag) => {
      const customTagCount = customCountMap.get(tag)
      if (customTagCount === 2) {
        productCustomTags.push(tag)
      }
      if (customTagCount === 1 && productTags.includes(tag)) {
        productCustomTags.push(tag)
      }
    })

    workspaceSystemTags.forEach((tag) => {
      const systemTagCount = systemCountMap.get(tag)
      if (systemTagCount === 2) {
        productSystemTags.push(tag)
      }
      if (systemTagCount === 1 && productTags.includes(tag)) {
        productSystemTags.push(tag)
      }
    })

    const itemTags: ItemTagsModel = {
      id: product.id,
      customTags: productCustomTags,
      systemTags: productSystemTags,
    }

    return itemTags
  })
}
