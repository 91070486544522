import DOMPurify from "dompurify"
import { ColumnDef } from "@tanstack/react-table"

import { Table } from "components/table/table"

type Props = {
  columns: ColumnDef<any>[]
  data: any[] | undefined
  pageSize?: number
  emptyGridMessage?: string
  className?: string
  title?: any
  description?: string | JSX.Element
  subDescription?: string | JSX.Element
  industryStandard?: string
  bestClass?: string
  average?: any
  grade?: any
  descriptionContainerClass?: string
  isOneLineTitle?: boolean
  specsContainerClassName?: string
  hasAnalysis?: boolean
}

export const DashboardAggregatedCard = ({
  columns,
  data = [],
  className,
  title,
  description,
  subDescription,
  industryStandard,
  bestClass,
  average,
  grade,
  descriptionContainerClass,
  isOneLineTitle,
  specsContainerClassName,
  hasAnalysis,
}: Props) => {
  return (
    <div
      className={`dashboard-aggregated-card h-full flex flex-col shadow rounded-lg bg-white p-7 ${
        className ? className : ""
      }`}
    >
      <div
        className={`min-h-[230px] flex flex-col ${
          descriptionContainerClass ? descriptionContainerClass : ""
        }`}
      >
        {isOneLineTitle ? (
          <div className="flex items-baseline">
            <h1 className="text-title-2 font-bold mb-4">{title?.name}</h1>
          </div>
        ) : (
          <div className="flex mb-7 items-center">
            <div className="text-title-2 text-gray-500 mr-2">{title.name}</div>
            <div className="font-bold text-title-2 flex">{title.value}</div>
          </div>
        )}
        <div className={`${specsContainerClassName ? specsContainerClassName : ""}`}>
          {grade && (grade.name || grade.value) && (
            <div className="4xl:text-headline flex mb-2">
              <div className="4xl:min-w-24 font-bold min-w-20">{grade.name}:&nbsp;</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(grade?.value),
                }}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                title={DOMPurify.sanitize(grade?.value)}
              ></div>
            </div>
          )}
          {average && (average.name || average.value) && (
            <div className="4xl:text-headline flex mb-2">
              <div className="font-bold min-w-24">{average.name}:&nbsp;</div>
              <div title={average?.value}>{average.value}</div>
            </div>
          )}
          {bestClass && (
            <div className="4xl:text-headline flex mb-2">
              <div className="font-bold min-w-24">Best-in-Class:&nbsp;</div>
              <div className="truncate" title={bestClass}>
                {bestClass}
              </div>
            </div>
          )}
          {industryStandard && (
            <div className="4xl:text-headline flex">
              <div className="4xl:min-w-[9.5rem] font-bold min-w-32">
                Industry Standard:&nbsp;
              </div>
              <div className="truncate" title={industryStandard}>
                {industryStandard}
              </div>
            </div>
          )}
        </div>
        {(description || subDescription) && (
          <div className="mb-3 flex-1">
            {description && (
              <div className="4xl:text-headline h-[38%] mb-0.5 mt-2 text-body text-gray-500 4xl:min-h-[120px] min-h-[130px]">
                <div className="font-bold text-black mb-1 border-t mt-4 border-gray-300 pt-4">
                  {hasAnalysis && "ANALYSIS"}
                </div>
                {description}
              </div>
            )}
            {subDescription && (
              <div className="4xl:text-headline flex-1 mb-0.5 4xl:min-h-[160px] text-body text-gray-500">
                {subDescription}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="">
        <Table columns={columns} data={data} />
      </div>
    </div>
  )
}
