import React from "react"
import { useNavigate } from "react-router-dom"
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"

import { Button } from "components/button/button"
import { Input } from "components/form-components/input/input"
import { Select } from "components/form-components/select/select"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import { percentToColor } from "utils"
import { LINK_CLASS_NAME } from "utils/constants"

export function StepButtons({
  step,
  setStep,
  lastStep,
  canContinue,
}: {
  step: number
  setStep: (st: number) => void
  lastStep: number
  canContinue: boolean
}) {
  const navigate = useNavigate()

  return (
    <div className="flex mt-6">
      {step !== 0 && step !== lastStep && (
        <Button variant="outlined" onClick={() => setStep(step - 1)}>
          Back
        </Button>
      )}
      {step < lastStep - 1 && (
        <Button
          className="ml-auto"
          variant="primary"
          disabled={!canContinue}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setStep(step + 1)
            e.currentTarget.blur()
          }}
        >
          Continue
        </Button>
      )}
      {step === lastStep - 1 && (
        <Button
          className="ml-auto"
          variant="primary"
          disabled={!canContinue}
          type="submit"
        >
          Submit
        </Button>
      )}
      {step === lastStep && (
        <Button
          className="mx-auto"
          variant="primary"
          onClick={() => {
            navigate("/main/dashboard")
          }}
        >
          Go to App
        </Button>
      )}
    </div>
  )
}

export function StepOne() {
  return (
    <>
      <p className="onboarding-step-title">Let’s get you started</p>
      <p className="onboarding-step-description">
        Please finish your registration so that we can provide a customized experience.
      </p>
      <div className="onboarding-step-body">
        <Input label="First Name*" placeholder="Enter your first name" name="firstName" />

        <Input label="Last Name*" placeholder="Enter your last name" name="lastName" />

        <Input
          className="col-span-2"
          label="Email"
          placeholder="Enter your email"
          name="userName"
          type="email"
          disabled
        />
      </div>
    </>
  )
}

export function StepTwo({
  companyTypes,
}: {
  companyTypes: { value: string; label: string }[]
}) {
  return (
    <>
      <p className="onboarding-step-title">Company Details</p>
      <p className="onboarding-step-description">
        Add company details below. You can also skip this step.
      </p>
      <div className="onboarding-step-body mb-[34px]">
        <Input label="Title*" placeholder="Enter your title" name="companyTitle" />

        <Input
          label="Company Name*"
          placeholder="Enter your company name"
          name="companyName"
        />

        <Select
          className="col-span-2"
          label="Company Type*"
          placeholder="Select your company type"
          name="companyType"
          options={companyTypes}
        />
      </div>
    </>
  )
}

export function StepThree({
  roleTypes,
  industryTypes,
  marketingSources,
  terms,
}: {
  roleTypes: { value: string; label: string }[]
  industryTypes: { value: string; label: string }[]
  marketingSources: { value: string; label: string }[]
  terms: any
}) {
  return (
    <>
      <p className="onboarding-step-title">Company Details</p>
      <p className="onboarding-step-description">
        Add company details below. You can also skip this step but you must agree to the
        terms and conditions in order to complete.
      </p>
      <div className="onboarding-step-body">
        <Select
          label="Role*"
          placeholder="Select your role"
          name="companyRole"
          options={roleTypes}
        />

        <Select
          label="Industry*"
          placeholder="Select your industry"
          name="companyIndustry"
          options={industryTypes}
        />

        <Select
          className="col-span-2"
          label="How did you hear about us?*"
          name="marketingSource"
          options={marketingSources}
        />
      </div>
      <div className="flex flex-row font-medium mt-3 mb-10">
        <div>
          <Checkbox name="licenseAgreement" />
        </div>
        <div>
          {"I agree to the terms and conditions set forth in the "}
          &nbsp;
          <a href={terms.termsOfUseUrl} target="_blank" className={LINK_CLASS_NAME}>
            Terms of Use
          </a>
          , &nbsp;
          <a href={terms.privacyPolicyUrl} target="_blank" className={LINK_CLASS_NAME}>
            Privacy Policy
          </a>
          &nbsp; and &nbsp;
          <a href={terms.cookiesPolicyUrl} target="_blank" className={LINK_CLASS_NAME}>
            Cookies Policy.
          </a>
        </div>
      </div>
    </>
  )
}

export function LastStep({ progress }: { progress: number }) {
  return (
    <div className="flex flex-col items-center mt-3">
      <div
        style={{
          height: "110px",
          width: "110px",
        }}
      >
        <CircularProgressbarWithChildren
          value={progress}
          text={`${progress}%`}
          styles={buildStyles({
            textSize: "20px",
            textColor: percentToColor(progress),
            trailColor: "#FDF4FF",
            pathColor: percentToColor(progress),
          })}
        />
      </div>
      <p className="text-title-2 font-bold text-gray-900 mt-8">
        {progress === 100 ? "You’re good to go!" : `${progress}% Completed!`}
      </p>
      <p className="text-body font-medium text-gray-500 my-5 text-center">
        {progress === 100 ? (
          "You’ve successfully set up your Content Status Account."
        ) : (
          <>
            <div>You are partially set up your Content Status Account.</div>
            <div>Don't worry, you can also update it later.</div>
          </>
        )}
      </p>
    </div>
  )
}
