import { Header } from "components/header/header"
import { Icon, ICONS } from "components/icon/icon"

const KEYWORDS_TIP =
  "By utilizing the most current keywords within your titles, descriptions and bullets helps to ensure your products show up in search results."
const ADDING_IN_BULK =
  " will add them to all the selected SKUs, which is great for adding the brand name or common features."

const ADD_IN_BULK_MESSAGE =
  "This feature won't duplicate if a SKU already has one of the keywords. However, plurals will be considered unique keywords."

const ADD_IN_BULK_TIP =
  "If your selected SKUs share a lot of the same keywords, but not all - it might be faster to paste them all in bulk anyway and simply scroll down to remove those that don't fit."

export const KeywordsModalHeader = () => (
  <Header
    title="Edit Keywords"
    description={
      <div className="mt-5 text-sub-headline font-medium text-gray-500">
        {KEYWORDS_TIP}
      </div>
    }
  />
)

type KeywordsHeaderProps = {
  selectedProdNo: number
  itemId: string | null
}

export const KeywordsHeader = ({ selectedProdNo, itemId }: KeywordsHeaderProps) => {
  return (
    <>
      <div className="title">
        {!itemId
          ? `You've Selected ${selectedProdNo} SKUs for Keywords`
          : "Edit Keywords"}
      </div>
      <div className="text">{KEYWORDS_TIP}</div>
      {!itemId && (
        <div className="text flex">
          <b className="mr-1 text-gray-700">Adding keywords in Bulk </b>
          {ADDING_IN_BULK}
          <div className="self-center ml-2">
            <Icon
              tippyProps={{
                placement: "right-start",
                maxWidth: 1080,
                content: (
                  <div className="p-3 rounded-md shadow-lg border-solid w-96 bg-white justify-self-center self-center">
                    <div className="text-caption-1 font-medium mb-2 text-black">
                      Add in Bulk
                    </div>
                    <div className="text-caption-1 text-gray-500 font-normal flex-initial">
                      {ADD_IN_BULK_MESSAGE}
                    </div>
                    <div className="text-caption-1 text-gray-500 font-normal flex-initial">
                      <div>
                        <span className="text-black">TIP:&nbsp;</span>
                        {ADD_IN_BULK_TIP}
                      </div>
                    </div>
                  </div>
                ),
              }}
              icon={ICONS.INFORMATION_CIRCLE_OUTLINED}
            />
          </div>
        </div>
      )}
    </>
  )
}
