
export class AlertFilterModel {
  public alertIdentifier?: string = "";
  public operator?: string = "";
  public value: number | string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.alertIdentifier = obj.alertIdentifier === null? "" : obj.alertIdentifier;
  this.operator = obj.operator === null? "" : obj.operator;
  this.value = obj.value === null? "" : obj.value;
}

}
