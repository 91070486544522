import { FC, ReactElement, ReactNode } from "react"
import Tippy, { TippyProps } from "@tippyjs/react"
import { useSelector } from "react-redux"
import { RootState } from "store"

type Props = {
  children: ReactElement
  content?: ReactNode
  tippyProps?: Partial<TippyProps>
}

export const TippyWrapper: FC<Props> = ({ children, content, tippyProps }: Props) => {
  const target = useSelector((state: RootState) => state.tippyTarget.target)

  return content !== undefined || tippyProps !== undefined ? (
    <Tippy
      placement="bottom"
      content={
        typeof content === "string" ? (
          <div className="tippy-wrapper-content">{content}</div>
        ) : (
          content ?? ""
        )
      }
      {...(target !== null ? { singleton: target } : {})}
      {...tippyProps}
    >
      {children}
    </Tippy>
  ) : (
    children
  )
}
