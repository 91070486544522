import { useLayoutEffect } from "react"
import { useSelector } from "react-redux"
import isEmpty from "is-empty"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import { RootState } from "../../../store"

const HEALTH_SCORE_VS_COMPETITORS_DIV_ID = "HEALTH_SCORE_VS_COMPETITORS_DIV_ID"

export const HealthScoreVsCompetitors = () => {
  const auditSummarySection = useSelector(
    (state: RootState) =>
      state.dashboard.aggregatedDashboardCards?.auditSummarySection?.cards[0]?.items,
  )
  const trendSection = useSelector(
    (state: RootState) => state.dashboard.aggregatedDashboardCards?.trendSection,
  )

  //chart data + init
  useLayoutEffect(() => {
    if (!isEmpty(auditSummarySection)) {
      const root = am5.Root.new(HEALTH_SCORE_VS_COMPETITORS_DIV_ID)
      root.setThemes([am5themes_Animated.new(root)])

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
          layout: root.verticalLayout,
        }),
      )

      // Create X-Axis
      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.1,
          groupData: false,
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 50,
          }),
          tooltip: am5.Tooltip.new(root, {
            themeTags: ["axis"],
            animationDuration: 300,
          }),
        }),
      )

      // Create Y-axis
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0.1,
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      )

      const cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          xAxis: xAxis,
        }),
      )
      cursor.lineY.set("visible", false)

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "My Brand",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value1",
          valueXField: "date",
          seriesTooltipTarget: "bullet",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueY}",
          }),
        }),
      )

      const series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Competitor",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value2",
          valueXField: "date",
          seriesTooltipTarget: "bullet",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueY}",
          }),
        }),
      )

      series.strokes.template.setAll({
        strokeWidth: 2,
      })

      series2.strokes.template.setAll({
        strokeWidth: 2,
      })

      series.bullets.push(function () {
        const circle = am5.Circle.new(root, {
          radius: 6,
          templateField: "bulletSettings",
          fill: series.get("fill"),
          strokeWidth: 2,
          stroke: root.interfaceColors.get("background"),
        })

        return am5.Bullet.new(root, {
          sprite: circle,
          locationX: 0,
        })
      })

      series2.bullets.push(function () {
        const circle = am5.Circle.new(root, {
          radius: 6,
          templateField: "bulletSettings",
          fill: series2.get("fill"),
          strokeWidth: 2,
          stroke: root.interfaceColors.get("background"),
        })

        return am5.Bullet.new(root, {
          sprite: circle,
          locationX: 0,
        })
      })

      root.dateFormatter.setAll({
        dateFormat: "yyyy-MM-dd",
        dateFields: ["valueX"],
      })

      // Define data
      const trendsData = trendSection?.myBrandTrends?.map((point: any, index: number) => {
        return {
          date: new Date(point.dateTime).getTime(),
          value1: point.score,
          value2: trendSection?.competitorTrends[index]?.score,
        }
      })

      if (trendsData?.length) {
        series.data.setAll(trendsData)
        series2.data.setAll(trendsData)
      }

      series.set("stroke", am5.color("#FFB100"))
      series.set("fill", am5.color("#FFB100"))
      series2.set("stroke", am5.color("#00C138"))
      series2.set("fill", am5.color("#00C138"))

      series.appear(1000)
      series2.appear(1000)
      chart.appear(1000, 100)

      //set legend
      const legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
          marginTop: 10,
        }),
      )
      legend.data.setAll(chart.series.values)

      //remove logo
      root._logo?.dispose()

      return () => {
        root.dispose()
      }
    }
  }, [trendSection])

  return (
    <div className="shadow rounded-lg bg-white p-7">
      <div className="flex mb-7 items-center">
        <div className="text-title-2 text-gray-500 mr-2">Health Score</div>
        <div className="font-bold text-title-2 flex">vs Competitors</div>
      </div>

      <div
        id={HEALTH_SCORE_VS_COMPETITORS_DIV_ID}
        style={{ width: "100%", height: "90%" }}
        className="mb-4"
      />
    </div>
  )
}
