import { CatalogExportModel } from "common/models/CatalogExportModel"
import { CatalogReportType } from "common/enums/CatalogReportType.enum"
import { CatalogRequestOptions } from "common/models/CatalogRequestOptions"
import { ExportRequestOptions } from "common/models/ExportRequestOptions"
import { ExportModelPageResultModel } from "common/models/ExportModelPageResultModel"
import { ExportDownloadModel } from "common/models/ExportDownloadModel"
import { ExportCountModel } from "common/models/ExportCountModel"
import { API } from "../api"

export const exportCatalogApi = (
  workspaceId: string,
  body: CatalogExportModel,
): Promise<boolean> => API().post(`catalog/workspace/${workspaceId}/exports`, body)

export const exportCatalogReportsApi = (
  workspaceId: string,
  reportType: CatalogReportType,
): Promise<boolean> =>
  API().post(`catalog/workspace/${workspaceId}/report/${reportType}/export`)

export const exportFilteredCatalogReportsApi = (
  workspaceId: string,
  reportType: CatalogReportType,
  body: CatalogRequestOptions,
): Promise<boolean> =>
  API().post(
    `catalog/workspace/${workspaceId}/report/${reportType}/export/filtered`,
    body,
  )

export const exportCatalogTrendReportApi = (
  workspaceId: string,
  reportType: CatalogReportType,
): Promise<boolean> =>
  API().post(`catalog/workspace/${workspaceId}/trend-report/${reportType}/export`)

export const exportFilteredCatalogTrendReportApi = (
  workspaceId: string,
  reportType: CatalogReportType,
  body: CatalogRequestOptions,
): Promise<boolean> =>
  API().post(
    `catalog/workspace/${workspaceId}/trend-report/${reportType}/export/filtered`,
    body,
  )

export const getWorkspaceExportsApi = (
  workspaceId: string,
  body: ExportRequestOptions,
): Promise<ExportModelPageResultModel> =>
  API().post(`catalog/workspace/${workspaceId}/exports/paged`, body)

export const downloadExportApi = (
  workspaceId: string,
  exportId: string,
  blobName: string,
): Promise<ExportDownloadModel> =>
  API().post(`catalog/workspace/${workspaceId}/exports/${exportId}/download/${blobName}`)

export const deleteExportApi = (
  workspaceId: string,
  exportId: string,
  blobName: string,
): Promise<boolean> =>
  API().delete(`catalog/workspace/${workspaceId}/exports/${exportId}/delete/${blobName}`)

export const exportsCountApi = (workspaceId: string): Promise<ExportCountModel> =>
  API().post(`catalog/workspace/${workspaceId}/exports/count`)
