import { useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react"
import { FC, useEffect } from "react"
import { FullPageLoader } from "components/full-page-loader/full-page-loader"
import { InteractionType } from "@azure/msal-browser"
import { apiConfig } from "api/msalConfig"
import { getAccessToken, setAccessToken } from "api/api"
import { useNavigate, useParams } from "react-router-dom"
import { getCurrentUserApi } from "../../api/endpoints/users.api"

const loginRequest = {
  scopes: ["openid", ...apiConfig.b2cScopes],
}

export const Invite: FC = () => {
  const { login, error, acquireToken, result } = useMsalAuthentication(
    InteractionType.Silent,
    loginRequest,
  )
  const isAuthenticated = useIsAuthenticated()
  const { token } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (getAccessToken()) {
      getCurrentUserApi(token || "").then(() => navigate("/main/dashboard"))
    }
  }, [getAccessToken(), token])

  useEffect(() => {
    if (error) {
      login(InteractionType.Redirect, loginRequest)
    }
  }, [error])

  useEffect(() => {
    const interactionInProgress = Object.keys(sessionStorage)
      .toString()
      .includes("interaction.status")
    if (isAuthenticated && !interactionInProgress) {
      acquireToken(InteractionType.Silent, loginRequest)
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (result?.accessToken) {
      setAccessToken(result.accessToken)
    }
  }, [result])

  return <FullPageLoader loading />
}
