import { FC, useEffect, useState } from "react"
import "./enter-keywords.scss"
import { useSelector } from "react-redux"
import { RootState } from "store"
import * as yup from "yup"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AuditSubmitButtons } from "../audit-submit-buttons/audit-submit-buttons"
import { KeywordsAccordionBodyColumn } from "pages/catalogs/audit-catalog/keywords-modal/keywords-body-column/keywords-body-column"

type Props = {
  onSubmit: (values: any) => void
  handleBack: () => void
  isDisabledBack: boolean
}

const schema = yup.object({})

export const EnterKeywords: FC<Props> = ({
  onSubmit,
  handleBack,
  isDisabledBack,
}: Props) => {
  const [skus, setSkus] = useState<string[]>([])
  const audits = useSelector((state: RootState) => state.audits)

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      skus: audits.selectedAudit?.items || [],
    },
  })

  useEffect(() => {
    if (audits.selectedAudit?.items) {
      setSkus(audits.selectedAudit?.items)
    }
  }, [audits.selectedAudit?.items])

  useEffect(() => {
    methods.setValue("skus", skus)
  }, [skus])

  return (
    <FormProvider {...methods}>
      <form
        className="add-edit-audit-card-content"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="add-edit-audit-card-body">
          <div className="enter-keywords">
            <KeywordsAccordionBodyColumn title="" keywords={skus} setKeywords={setSkus} />
          </div>
        </div>

        <AuditSubmitButtons
          handleBack={handleBack}
          isDisabledBack={isDisabledBack}
          isDisabledSubmit={skus.length <= 0}
        />
      </form>
    </FormProvider>
  )
}
