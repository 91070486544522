import { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { RootDispatch, RootState } from "store"
import { PageModel } from "common/models/PageModel"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import { CatalogRequestOptions } from "common/models/CatalogRequestOptions"
import { SelectOption } from "components/form-components/select/select"
import { getBasicViewData, reportsColumns } from "../reports-utils"
import { InfiniteLoadTable } from "components/infinite-load-table/infinite-load-table"

type BasicViewProps = {
  selectedReport: SelectOption
}

export const BasicView: FC<BasicViewProps> = ({ selectedReport }) => {
  const dispatch: RootDispatch = useDispatch()
  const [columns, setColumns] = useState<any>([])

  const allReports = useSelector((state: RootState) => state.reports.list)
  const filterModel = useSelector(
    (state: RootState) => state.reports.reportsRequestOptions.itemFilterModel,
  )
  const pageModel = useSelector(
    (state: RootState) => state.reports.reportsRequestOptions.pageModel,
  )

  const getReports = async ({ page, pageSize }: any) => {
    const options = new CatalogRequestOptions({
      pageModel: new PageModel({
        ...pageModel,
        page,
        pageSize,
      }),
      itemFilterModel: filterModel,
    })
    const emptyFilter = new ItemFilterModel()

    if (JSON.stringify(filterModel) === JSON.stringify(emptyFilter)) {
      return dispatch.reports.fetchReports({
        reportType: selectedReport?.value,
        requestOptions: options,
      })
    } else {
      return dispatch.reports.fetchReportsFiltered({
        reportType: selectedReport?.value,
        requestOptions: options,
      })
    }
  }

  useEffect(() => {
    getBasicViewData(allReports, selectedReport, setColumns)
  }, [allReports, selectedReport])

  return (
    <InfiniteLoadTable
      columns={[reportsColumns(selectedReport), ...columns]}
      fetchSize={50}
      fetchData={getReports}
      height="calc(100vh - 370px)"
      emptyTableMessage="No Reports found."
      expandable
    />
  )
}
