import { Checkbox } from "components/form-components/checkbox/checkbox"
import { Select } from "components/form-components/select/select"
import { Icon, ICONS } from "components/icon/icon"
import { ChangeEvent, FC, ReactNode, useEffect, useState } from "react"
import "./value-sign-option.scss"

export enum SIGN {
  GT = "gt",
  LT = "lt",
  EQ = "eq",
}

const SIGNS = [
  {
    value: SIGN.GT,
    label: <Icon icon={ICONS.CHEVRON_RIGHT} size={3} />,
  },
  {
    value: SIGN.LT,
    label: <Icon icon={ICONS.CHEVRON_LEFT} size={3} />,
  },
  {
    value: SIGN.EQ,
    label: <Icon icon={ICONS.EQUALS} size={3} />,
  },
]

type Props = {
  name: string | undefined
  value: number | string
  sign: SIGN | undefined
  children?: ReactNode
  checked?: boolean
  onChange?: (item: any) => void
  labelSuffix?: string
}

export const ValueSignOption: FC<Props> = ({
  name = "",
  value = 5,
  sign = SIGN.LT,
  children,
  checked = false,
  onChange = () => {},
  labelSuffix = "",
}: Props) => {
  const [innerValue, setInnerValue] = useState(value)
  const [innerSign, setInnerSign] = useState(
    SIGNS.find((item) => item.value === sign) || SIGNS[0],
  )
  const [innerChecked, setInnerChecked] = useState(checked)

  useEffect(() => {
    setInnerChecked(checked)
  }, [checked])

  useEffect(() => {
    setInnerValue(value)
  }, [value])

  useEffect(() => {
    setInnerSign(SIGNS.find((item) => item.value === sign) || SIGNS[0])
  }, [sign])

  const setValue = (event: ChangeEvent) => {
    let newValue = (event.target as HTMLInputElement).value

    const numericValue = Number(newValue)
    if (
      Number.isNaN(numericValue) ||
      numericValue < 0 ||
      numericValue > 5 ||
      (numericValue === 0 && newValue.length > 1 && !newValue.startsWith("0."))
    ) {
      return
    }

    if (newValue.startsWith("0") && numericValue >= 1) {
      newValue = newValue.replace("0", "")
    }

    if (newValue === "") {
      newValue = ""
    } else if (newValue.length === 2 && newValue[1] === "." && Number(newValue[0]) < 5) {
      newValue = newValue.trim()
    } else if (Number(newValue) >= 0 && Number(newValue) <= 5) {
      newValue = newValue.slice(0, 4)
    }
    setInnerValue(newValue)

    onChange({
      name,
      value: newValue,
      sign: innerSign.value,
      checked: innerChecked,
    })
  }

  const toggleChecked = () => {
    setInnerChecked(!innerChecked)
    onChange({
      name,
      value: innerValue,
      sign: innerSign.value,
      checked: !innerChecked,
    })
  }

  const setSign = (item: any) => {
    setInnerSign(item)
    onChange({
      name,
      value: innerValue,
      sign: item.value,
      checked: innerChecked,
    })
  }

  return (
    <div className="">
      <div className="value-sign-option">
        <Checkbox
          label={name + labelSuffix}
          small
          onChange={toggleChecked}
          value={innerChecked}
        />
        <div className="grid grid-flow-col justify-end gap-x-1">
          {/* //* this select overflows to the right */}
          <Select
            options={SIGNS}
            value={innerSign.value}
            hideIcon
            placeholder=""
            styles={{
              control: (provided: any) => ({
                ...provided,
                width: "30px",
                minHeight: "20px",
                height: "1.5rem",
                padding: "0px 5px",
              }),
              menu: (provided: any) => ({
                ...provided,
                minWidth: "30px",
              }),
              menuPortal: (base: any) => ({
                ...base,
                zIndex: 9999,
                transform: "translate(-110%, -66px)",
              }),
            }}
            onChange={setSign}
          />
          <input
            value={innerValue}
            onChange={setValue}
            className="border z-10 w-7 h-6 border-gray-200 text-caption-1 rounded-md placeholder-gray-400 text-center"
          />
        </div>
      </div>
      <div className="pl-4">{children}</div>
    </div>
  )
}
