
export class UserFlag {
  public isSwitchWorkspaceWarningDisabled: boolean = false;
  public isOnBoardingFlowPassed: boolean = false;
constructor(obj = {} as any) {
  obj = obj || {};
  this.isSwitchWorkspaceWarningDisabled = obj.isSwitchWorkspaceWarningDisabled === null? false : obj.isSwitchWorkspaceWarningDisabled;
  this.isOnBoardingFlowPassed = obj.isOnBoardingFlowPassed === null? false : obj.isOnBoardingFlowPassed;
}

}
