import { InvoiceGetModel } from 'common/models/InvoiceGetModel';
import { InvoiceRequestOptions } from 'common/models/InvoiceRequestOptions';
import { InvoiceGetModelPageResult } from 'common/models/InvoiceGetModelPageResult';
import { ItemsCountModel } from 'common/models/ItemsCountModel';
import { InvoiceDownloadModel } from 'common/models/InvoiceDownloadModel';
import { API } from "../api";

export const getInvoicesApi = (): Promise<InvoiceGetModel[]> => API().get(`invoices`);

export const getInvoicesPaginatedApi = (body: InvoiceRequestOptions): Promise<InvoiceGetModelPageResult> => API().post(`invoices/paginated`, body);

export const getInvoicesCountApi = (body: InvoiceRequestOptions): Promise<ItemsCountModel> => API().post(`invoices/count`, body);

export const downloadInvoiceApi = (userId: string, body: string[]): Promise<InvoiceDownloadModel> => API().post(`invoices/${userId}/download`, body);
