import { FC, SyntheticEvent } from "react"

import { SearchInput } from "components/form-components/search-input/search-input"
import { formatSearchString } from "utils"

import "./filter-search.scss"

type Props = {
  options: any[]
  setOptions: (options: any[]) => void
  compareProp?: string
  value?: string
  setSearchValue?: (value: any) => void
}

export const FilterSearch: FC<Props> = ({
  options,
  setOptions,
  compareProp,
  value,
  setSearchValue,
}: Props) => {
  const filterOptions = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement
    const formattedValue = formatSearchString(value)
    if (compareProp) {
      setOptions(
        options.filter((option) =>
          formatSearchString(option[compareProp]).includes(formattedValue),
        ),
      )
    } else {
      setOptions(
        options.filter((option) => formatSearchString(option).includes(formattedValue)),
      )
    }
    setSearchValue && setSearchValue(value)
  }

  return (
    <SearchInput
      placeholder="Search"
      onChange={filterOptions}
      className="grid relative rounded-lg mt-1.5 mx-1.5 mb-2"
      value={value}
    />
  )
}
