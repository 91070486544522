import { FC, useEffect, useState } from "react"

import { CustomFieldFilterModel } from "common/models/CustomFieldFilterModel"
import { CustomFieldValue } from "common/models/CustomFieldValue"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import { Select } from "components/form-components/select/select"
import { FilterSearch } from "../filter-search/filter-search"

import "./custom-field-model-options.scss"

type Props = {
  options?: CustomFieldFilterModel[]
  filters: ItemFilterModel
  setFilters: (filters: ItemFilterModel) => void
  collapsed?: boolean
}

export const CustomFieldModelOption: FC<Props> = ({
  options = [],
  filters,
  setFilters,
  collapsed,
}: Props) => {
  const [currentFilter, setCurrentFilter] = useState([] as CustomFieldValue[])
  const [filteredOptions, setFilteredOptions] = useState(options.slice(0, 6))
  const [searchValue, setSearchValue] = useState("")

  useEffect(() => {
    setCurrentFilter(filters.customFields as CustomFieldValue[])
  }, [filters])

  useEffect(() => {
    if (collapsed) {
      return
    }

    if (searchValue) {
      setFilteredOptions(filteredOptions)
    } else {
      setFilteredOptions(options)
    }
  }, [collapsed, searchValue])

  const setValue = (item: CustomFieldValue) => {
    const currentIndex = currentFilter.findIndex((filter) => filter.key === item.key)

    const customFields =
      currentIndex > -1
        ? currentFilter.filter((f, index) => index !== currentIndex)
        : [...currentFilter, item]

    if (
      item.value !== undefined &&
      currentIndex > -1 &&
      currentFilter[currentIndex].value !== item.value
    ) {
      customFields.push(item)
    }

    setFilters({
      ...filters,
      customFields,
    })
  }

  return (
    <div className="string-model-options">
      <FilterSearch
        options={options}
        setOptions={setFilteredOptions}
        value={searchValue}
        setSearchValue={setSearchValue}
        compareProp="key"
      />
      {filteredOptions.map((item: CustomFieldFilterModel, index: number) => {
        const currentFieldValue = currentFilter.find((f) => f.key === item.key)
        return (
          <div className="flex items-center">
            <Checkbox
              value={currentFieldValue !== undefined}
              key={index}
              label={item.key}
              small
              onChange={() =>
                setValue(
                  currentFieldValue ?? {
                    key: item.key,
                    value: item.values.length === 1 ? item.values[0] : undefined,
                  },
                )
              }
            />
            <div className="grid grid-flow-col justify-end gap-x-1">
              <Select
                disabled={!currentFieldValue}
                options={item.values?.map((val) => ({ label: val, value: val }))}
                value={currentFieldValue?.value ?? null}
                hideIcon
                placeholder="Select value"
                styles={{
                  control: (provided: any) => ({
                    ...provided,
                    width: "150px",
                    minHeight: "20px",
                    height: "1.5rem",
                    padding: "0px 5px",
                  }),
                  menu: (provided: any) => ({
                    ...provided,
                    minWidth: "30px",
                  }),
                  menuPortal: (base: any) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                onChange={(option) => {
                  if (option.value !== currentFieldValue?.value) {
                    setValue({ key: item.key, value: option.value })
                  }
                }}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
