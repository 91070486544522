import { useSelector } from "react-redux"
import { useFormContext } from "react-hook-form"
import { RetailerModel } from "common/models/RetailerModel"
import { RetailerProductIdDetail } from "common/enums/RetailerProductIdDetail.enum"
import { enumToArray } from "utils"
import { SkuType } from "common/enums/SkuType.enum"
import { Textarea } from "components/form-components/textarea/textarea"
import { Button } from "components/button/button"
import { buildErrorObject, getSkusInputPlaceholder } from "pages/audits/utils"
import { RootState } from "store"
import { SkuDetails } from "common/types/SkuDetails.type"

type Props = {
  retailer: RetailerModel
  skuTypeSelection: SkuType
  skus: (string | SkuDetails)[]
  clearAll: () => void
  loadSkus: () => void
}

export const SkuInput = ({
  retailer,
  skuTypeSelection,
  skus,
  clearAll,
  loadSkus,
}: Props) => {
  const { watch } = useFormContext()
  const retailers = useSelector((state: RootState) => state.retailers.allRetailers)
  const selectedAudit = useSelector((state: RootState) => state.audits.selectedAudit)
  const isSkusActionDisabled = watch("skusText") === undefined || watch("skusText") === ""
  const productIdDetail = enumToArray(RetailerProductIdDetail)
  const { label } = productIdDetail[retailer?.productIdDetail] ?? {}

  const hasNoChanges = () => {
    const currentSkus = buildErrorObject(
      watch("skusText")?.trim().split("\n") || [],
      retailer,
      skuTypeSelection,
      retailers,
      selectedAudit,
    ).skuErrors

    const newSkuArray = [] as string[]
    const currentSkusArray = [] as string[]
    skus.forEach((sku) => {
      if (typeof sku !== "string") {
        for (let i = 0; i < sku.count; i++) {
          newSkuArray.push(sku.value)
        }
      }
    })
    currentSkus.forEach((sku: any) => {
      for (let i = 0; i < sku.count; i++) {
        currentSkusArray.push(sku.value)
      }
    })

    const newSkusText = newSkuArray.join("\n") || ""
    const currentSkusText = currentSkusArray.join("\n") || ""

    return newSkusText === currentSkusText
  }

  return (
    <>
      <Textarea
        placeholder={getSkusInputPlaceholder(skuTypeSelection, label)}
        name="skusText"
        rows={8}
        className="min-h-56 max-h-80"
      />
      <div
        className="grid grid-flow-col gap-3 justify-items-end justify-end mt-2.5"
        id="skuButtons"
      >
        <Button
          onClick={clearAll}
          variant="outlined"
          disabled={isSkusActionDisabled}
          isSmall
        >
          Clear All
        </Button>
        <Button
          onClick={loadSkus}
          variant="success"
          disabled={isSkusActionDisabled || hasNoChanges()}
          isSmall
        >
          Load SKUs
        </Button>
      </div>
    </>
  )
}
