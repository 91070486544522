import React from "react"
import { useFormContext } from "react-hook-form"
import { BaseFormComponent } from "../base-form-component/base-form-component"
import { resolvePath } from "utils"
import "./input.scss"

type Props = {
  onClick?: any
  value?: string
  placeholder?: string
  label?: string | JSX.Element
  name?: string
  type?: string
  autoComplete?: string
  onChange?: any
  disabled?: boolean
  className?: string
  required?: any
  readOnly?: boolean
  defaultValue?: string
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  style?: React.CSSProperties
  autofocus?: boolean
  onKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void
}

export const Input = ({
  onClick,
  onChange,
  placeholder,
  label,
  name,
  type,
  value,
  autoComplete,
  disabled,
  className = "",
  required,
  readOnly,
  prefix,
  suffix,
  style,
  autofocus,
  onKeyDown,
}: Props) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext() || { formState: {} }
  const error = name ? resolvePath(errors, name) : undefined

  const onWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    if (type === "number") {
      event.currentTarget.blur()
    }
  }

  return name && control ? (
    <BaseFormComponent
      name={name}
      label={label}
      required={required}
      className={disabled ? "disabled" : ""}
    >
      <>
        {prefix && <div className="text-input-prefix">{prefix}</div>}
        <input
          onClick={onClick}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete={autoComplete}
          type={type}
          {...register(name, { required })}
          placeholder={placeholder}
          className={`base-input ${disabled ? "disabled" : ""} ${className} ${
            error ? "error" : ""
          }`}
          autoFocus={autofocus}
          onKeyDown={onKeyDown}
          onWheel={onWheel}
        />
        {suffix && <div className="text-input-suffix">{suffix}</div>}
      </>
    </BaseFormComponent>
  ) : (
    <input
      onClick={onClick}
      disabled={disabled}
      readOnly={readOnly}
      onChange={onChange}
      autoComplete={autoComplete}
      type={type}
      value={value}
      placeholder={placeholder}
      className={`base-input ${className}`}
      style={style}
      autoFocus={autofocus}
      onKeyDown={onKeyDown}
      onWheel={onWheel}
    />
  )
}
