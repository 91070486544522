import { RematchDispatch } from "@rematch/core"

import { WORKSPACE_ID } from "api/api"
import {
  getCatalogReportsApi,
  getFilteredCatalogReportsApi,
  getFilteredTrendScoreReportsApi,
  getTrendReportApi,
  getTrendScoreReportsApi,
} from "api/endpoints/catalog.api"
import { CatalogReportType } from "common/enums/CatalogReportType.enum"
import { SortDirection } from "common/enums/SortDirection.enum"
import { CatalogReportRowModel } from "common/models/CatalogReportRowModel"
import { CatalogRequestOptions } from "common/models/CatalogRequestOptions"
import { PageModel } from "common/models/PageModel"
import { TrendScoreReportModel } from "common/models/TrendScoreReportModel"
import { TrendScoreReportRowModel } from "common/models/TrendScoreReportRowModel"
import { updateArrayData } from "./store-utils"

type State = Readonly<{
  list: CatalogReportRowModel[]
  trendsList: TrendScoreReportRowModel[]
  selectedReportType: CatalogReportType
  reportsRequestOptions: CatalogRequestOptions
  trendReportsColumns: any
}>

const model = {
  state: {
    list: [],
    trendsList: [],
    selectedReportType: 0,
    reportsRequestOptions: new CatalogRequestOptions({
      pageModel: new PageModel({
        page: 1,
        pageSize: 50,
        sortField: "name",
        sortDirection: SortDirection.Ascending,
      }),
    }),
    trendReportsColumns: [],
  } as State,
  reducers: {
    loaded: (state: State, payload: any): State => ({
      ...state,
      list: updateArrayData(
        state.list,
        payload.data,
        payload.requestOptions.pageModel.page,
      ),
    }),
    loadedTrendsList: (state: State, payload: any): State => ({
      ...state,
      trendsList: updateArrayData(
        state.trendsList,
        payload.data,
        payload.requestOptions.pageModel.page,
      ),
    }),
    setRequestOptions: (state: State, payload: CatalogRequestOptions): State => {
      return {
        ...state,
        reportsRequestOptions: payload,
      }
    },
    setSelectedReportType: (state: State, payload: CatalogReportType): State => {
      return {
        ...state,
        selectedReportType: payload,
      }
    },
    clearReports: (state: State): State => {
      return {
        ...state,
        list: [],
        trendsList: [],
      }
    },
    setTrendReportColumns: (state: State, payload: TrendScoreReportModel): State => {
      return {
        ...state,
        trendReportsColumns: payload,
      }
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchReports(payload: any) {
      const reports = await getCatalogReportsApi(
        WORKSPACE_ID,
        payload.reportType,
        payload.requestOptions.pageModel,
      )

      if (reports) {
        dispatch.reports.loaded({
          data: reports.items,
          requestOptions: payload.requestOptions,
        })
        dispatch.reports.setSelectedReportType(payload.reportType)

        return reports
      }
    },
    async fetchReportsFiltered(payload: any) {
      const reports = await getFilteredCatalogReportsApi(
        WORKSPACE_ID,
        payload.reportType,
        payload.requestOptions,
      )

      if (reports) {
        dispatch.reports.loaded({
          data: reports.items,
          requestOptions: payload.requestOptions,
        })
        dispatch.reports.setSelectedReportType(payload.reportType)

        return reports
      }
    },
    async fetchScoreTrendReportColumns(payload: CatalogReportType) {
      const trendReportColumns = await getTrendReportApi(WORKSPACE_ID, payload)

      if (trendReportColumns) {
        const columns = trendReportColumns.dates.map((date) => {
          return {
            label: date,
            width: "90px",
          }
        })
        dispatch.reports.setTrendReportColumns(columns)
      }
    },
    async fetchScoreTrendReportsData(payload: any) {
      const reports = await getTrendScoreReportsApi(
        WORKSPACE_ID,
        payload.reportType,
        payload.requestOptions.pageModel,
      )

      if (reports) {
        dispatch.reports.loadedTrendsList({
          data: reports.items,
          requestOptions: payload.requestOptions,
        })
        dispatch.reports.setSelectedReportType(payload.reportType)

        return reports
      }
    },
    async fetchScoreTrendReportsDataFiltered(payload: any) {
      const reports = await getFilteredTrendScoreReportsApi(
        WORKSPACE_ID,
        payload.reportType,
        payload.requestOptions,
      )

      if (reports) {
        dispatch.reports.loadedTrendsList({
          data: reports.items,
          requestOptions: payload.requestOptions,
        })
        dispatch.reports.setSelectedReportType(payload.reportType)

        return reports
      }
    },
  }),
}

export default model
