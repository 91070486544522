import { RematchDispatch } from "@rematch/core"
import { WORKSPACE_ID } from "api/api"
import {
  getAllNotificationsApi,
  markAsReadNotificationsApi,
} from "api/endpoints/notification.api"
import { NotificationModel } from "common/models/NotificationModel"

type State = Readonly<{
  notifications: NotificationModel[]
  highPriorityNotifications: NotificationModel[]
}>

const model = {
  state: {
    notifications: [],
    highPriorityNotifications: [],
  } as State,
  reducers: {
    setNotifications: (state: State, payload: NotificationModel[]): State => ({
      ...state,
      notifications: payload,
    }),
    setHighPriorityNotifications: (
      state: State,
      payload: NotificationModel[],
    ): State => ({
      ...state,
      highPriorityNotifications: payload,
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchAllNotifications() {
      const notifications = await getAllNotificationsApi(WORKSPACE_ID)

      if (notifications) {
        dispatch.notifications.setNotifications(notifications)
      }
    },
    async markAsRead(payload: string[]) {
      const result = await markAsReadNotificationsApi(payload)

      if (result) {
        this.fetchAllNotifications()
      }
      return result
    },
  }),
}

export default model
