import "./tags-info.scss"
import { Icon, ICONS } from "components/icon/icon"
import { ToolTipInfoIcon } from "components/info-tool-tip/info-tool-tip"

const WHAT_IS_A_TAG = "What's a tag and how should I use them?"
const TAG_TIP =
  "Adding tags to your SKUS allow you to easily view insights specific to the tagged groups that you select."

const EXAMPLE_TITLE = "Example use cases"
const EXAMPLE_1 =
  "Tagging SKUS that have a high return rate so you can review their Health Status and Accuracy Status."
const EXAMPLE_2 =
  "Tagging SKUS that are new launches so you can review their Live Status."
const EXAMPLE_3 =
  "Tagging competitive SKUS to compare how their content grades out vs your own."

const UPDATE_OVERWRITE = "Update vs overwrite"
const UPDATE_1 =
  "Update Tags: Only the checked tags will be saved to each SKU that has been selected for tagging. (This is the default behavior.)"
const UPDATE_2 =
  "Overwrite Tags: All tags will be saved to each SKU that has been selected for tagging. (This means that the tags which are not checked will be deleted from each SKU.)"

//? This could me made a lot more general if it's needed somewhere else
type ToolTipExampleProps = {
  text: string
  className?: string
}

const ToolTipParagraph = ({ text, className = "" }: ToolTipExampleProps) => {
  return (
    <div className={`example mb-1 ${className}`}>
      <Icon className="text-violet-600 mr-1" size={4} icon={ICONS.ARROW_SM_RIGHT} />
      <div className="text">{text}</div>
    </div>
  )
}

export const TagsToolTipInfoIcon = () => {
  return (
    <div className="tags-info-icon-container w-5 ml-2.5">
      <ToolTipInfoIcon cardStyle={{ width: "405px", height: "350px" }}>
        <div className="title mb-2">{WHAT_IS_A_TAG}</div>
        <div className="text mb-3">{TAG_TIP}</div>
        <div className="title mb-2">{EXAMPLE_TITLE}</div>
        <ToolTipParagraph text={EXAMPLE_1} />
        <ToolTipParagraph text={EXAMPLE_2} />
        <ToolTipParagraph text={EXAMPLE_3} />
        <div className="title mb-2">{UPDATE_OVERWRITE}</div>
        <ToolTipParagraph text={UPDATE_1} />
        <ToolTipParagraph text={UPDATE_2} />
      </ToolTipInfoIcon>
    </div>
  )
}
