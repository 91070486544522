import { DashboardFilterModel } from './DashboardFilterModel';
//TODO Fix swagger script to accept nullable values instead of empty string
// !! filterScore is nullable
export class ItemDashboardFilterModel {
  public tags: string[] = [];
  public filterScore?: null | number | string = "";
  public filter: DashboardFilterModel = {} as DashboardFilterModel;
constructor(obj = {} as any) {
  obj = obj || {};
  this.tags = obj.tags || [];
  this.filterScore = obj.filterScore === null ? null : obj.filterScore;
  this.filter = !obj.filter ? new DashboardFilterModel() : new DashboardFilterModel(obj.filter);
}

}
