import React, { FC, Suspense, useEffect } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { SuspenseLoader } from "../../components/suspense-loader/suspense-loader"
import { AddEditAudit } from "./new-audit/add-edit-audit"
import { lazyLoadRetry } from "../../utils/lazyLoadRetry"
import "./audits.scss"
import { abortController } from "api/api"

const MyAudits = React.lazy(lazyLoadRetry(() => import("./my-audits/my-audits")))

export const Audits: FC = () => {
  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className="audits">
      <Suspense fallback={<SuspenseLoader delay={500} />}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/main/audits/my-audits#0" />} />
          <Route path="my-audits" element={<MyAudits />} />
          <Route path=":auditId/*" element={<AddEditAudit />} />
        </Routes>
      </Suspense>
    </div>
  )
}
