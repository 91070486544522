import { SortDirection } from 'common/enums/SortDirection.enum';

export class PageModel {
  public page: number | string = "";
  public pageSize: number | string = "";
  public sortField?: string = "";
  public sortDirection: SortDirection = {} as SortDirection;
constructor(obj = {} as any) {
  obj = obj || {};
  this.page = obj.page === null? "" : obj.page;
  this.pageSize = obj.pageSize === null? "" : obj.pageSize;
  this.sortField = obj.sortField === null? "" : obj.sortField;
  this.sortDirection = obj.sortDirection === null? {} as SortDirection : obj.sortDirection;
}

}
