import { AccuracyAnalysisResultModel } from './AccuracyAnalysisResultModel';

export class ImageSectionModel {
  public image: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
constructor(obj = {} as any) {
  obj = obj || {};
  this.image = !obj.image ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.image);
}

}
