import { FC, useEffect, useState } from "react"
import { Button } from "components/button/button"
import { useModal } from "context/modal-context"
import { Icon, ICONS } from "components/icon/icon"
import { Radio } from "components/form-components/radio/radio"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"
import { FormProvider, useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

type Props = {
  audit: any
}

const schema = yup.object({
  deleteOption: yup.string(),
})

export const DeleteAuditsModal: FC<Props> = ({ audit }: Props) => {
  const dispatch: RootDispatch = useDispatch()
  const { unSetModal } = useModal()
  const [disableDelete, setDisableDelete] = useState<boolean>(true)

  const loadingDeleteAudit = useSelector(
    (state: RootState) => state.loading.effects.audits.deleteAudit,
  )
  const loadingDeleteAudiAndItems = useSelector(
    (state: RootState) => state.loading.effects.audits.deleteAuditAndItems,
  )

  const deleteAudit = dispatch.audits.deleteAudit
  const deleteAuditAndItems = dispatch.audits.deleteAuditAndItems

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      deleteJobOption: "",
    },
  })
  const watchDeleteOption = methods.watch("deleteJobOption")

  useEffect(() => {
    const subscription = methods.watch((value: any) => {
      if (value.deleteJobOption === "1" || value.deleteJobOption === "2") {
        setDisableDelete(false)
      } else {
        setDisableDelete(true)
      }
    })
    return () => subscription.unsubscribe()
  }, [watchDeleteOption])

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-6 items-center max-w-sm"
        onSubmit={methods.handleSubmit(() => {})}
      >
        <Icon
          icon={ICONS.DELETE}
          className="text-red-600 justify-self-center"
          size={10}
        />
        <span className="text-title-3 font-medium justify-self-center">
          You are about to delete your audit!
        </span>
        <div className="border-t border-gray-300 w-full" />

        <span className="text-red-600 text-callout font-semibold self-start">
          Warning: this action cannot be undone!
        </span>
        <div className="bg-gray-50 border rounded-lg p-4">
          <Radio
            label={
              <div className="text-black text-body">
                Delete audit and all the collected data
              </div>
            }
            name="deleteJobOption"
            value="1"
            className="pb-4 font-medium"
            onChange={() => {
              methods.setValue("deleteJobOption", "1")
            }}
          />
          <Radio
            label={
              <div className="text-black text-body">
                Delete audit but keep the collected data
              </div>
            }
            name="deleteJobOption"
            value="2"
            className="font-medium"
            onChange={() => {
              methods.setValue("deleteJobOption", "2")
            }}
          />
          <p className="text-footnote text-gray-500 font-normal ml-7">
            The audited products will remain in the catalog.
          </p>
        </div>
        <div className="flex justify-between mt-6 w-full">
          <Button variant="outlined" onClick={unSetModal}>
            Cancel
          </Button>
          <Button
            disabled={disableDelete}
            loading={loadingDeleteAudit || loadingDeleteAudiAndItems}
            variant="danger"
            onClick={() => {
              if (methods.getValues().deleteJobOption === "1") {
                deleteAuditAndItems(audit).then((response) => {
                  if (response) {
                    unSetModal()
                  }
                })
              } else {
                deleteAudit(audit).then((response) => {
                  if (response) {
                    unSetModal()
                  }
                })
              }
            }}
          >
            Yes, Delete my Audit
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
