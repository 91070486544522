import { Icon, ICONS } from "components/icon/icon"
import { useEffect, useState } from "react"

import "./tags-checkboxes.scss"

type TagsCheckBoxProps = {
  label?: string
  value: number
  disabled?: boolean
  className?: string
  onChange?: any
}

export const TagsCheckBox = ({
  label = "",
  className = "",
  disabled,
  value,
  onChange,
}: TagsCheckBoxProps) => {
  const [state, setState] = useState(value || 0)

  useEffect(() => {
    setState(value % 3)
  }, [value])

  function handleClick() {
    if (disabled) {
      return
    }
    const newState = (state + 1) % 3
    setState(newState)
    if (onChange) {
      onChange(newState)
    }
  }

  function ButtonBody() {
    switch (state) {
      case 1:
        return <div className="intermediate"></div>
      case 2:
        return (
          <div className="checked">
            <Icon className="text-white" icon={ICONS.CHECK} />
          </div>
        )
      case 0:
        return <div></div>
      default:
        break
    }
  }

  return (
    <span className={`tags-checkbox ${className}`}>
      <button
        type="button"
        className="checkbox-btn"
        disabled={disabled}
        onClick={handleClick}
      >
        {ButtonBody()}
      </button>
      <span className="checkbox-label ml-2" onClick={handleClick}>
        {label}
      </span>
    </span>
  )
}
