import React, { CSSProperties, ReactNode } from "react"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import "./new-audit-card.scss"

type Props = {
  className?: string
  title: string | ReactNode
  body: string | string[] | ReactNode
  selected?: boolean
  disabled?: boolean
  checkbox?: boolean
  onClick?: () => void
  style?: CSSProperties
}

export const NewAuditCard: React.FC<Props> = ({
  className = "",
  title = "",
  body = "",
  selected = false,
  disabled = false,
  checkbox = false,
  onClick,
  style = {},
}) => (
  <div
    className={`new-audit-card${selected ? " selected" : ""}${
      disabled ? " disabled" : ""
    } ${className}`}
    onClick={typeof onClick === "function" && !disabled ? onClick : undefined}
    style={style}
  >
    {checkbox && (
      <Checkbox
        readonly
        value={selected}
        disabled={disabled}
        className="-my-4 ml-[90%] z-10 pointer-events-none"
      />
    )}
    {React.isValidElement(title) && title}
    {typeof title === "string" && <div className="new-audit-card-title">{title}</div>}
    <hr />
    <div className="new-audit-card-body">
      {React.isValidElement(body) && body}
      {Array.isArray(body) &&
        body?.map((i) => <span className="new-audit-card-description">{i}</span>)}
      {typeof body === "string" && (
        <span className="new-audit-card-description">{body}</span>
      )}
    </div>
  </div>
)
