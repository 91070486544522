import { Button } from "components/button/button"
import { Icon, ICONS } from "components/icon/icon"
import { useModal } from "context/modal-context"
import { FC } from "react"
import "./export-report-modal.scss"

type Props = {
  exportAction: any
}

export const ExportReportModal: FC<Props> = ({ exportAction }: Props) => {
  const { unSetModal } = useModal()

  return (
    <div className="export-report-modal">
      <span className="text-title-3 font-bold mb-5 text-gray-900">Reports Export</span>
      <span className="text-gray-500 mb-5">
        When the export is complete, you will receive a system notification to access the
        file.
      </span>
      <div className="bg-gray-50 rounded pb-4">
        <span className="flex text-fuchsia-900 justify-self-center mt-4 ml-4 font-medium mb-2.5">
          <Icon icon={ICONS.INFORMATION_CIRCLE_OUTLINED} className="mr-3" />
          Important!
        </span>
        <span className="text-footnote text-gray-900 ml-12 mt-2.5">
          Only the selected report will be exported.
        </span>
      </div>
      <div className="flex justify-between mt-14">
        <Button variant="outlined" onClick={() => unSetModal()}>
          Cancel
        </Button>
        <Button
          variant="success"
          className="ml-3"
          onClick={() => {
            exportAction()
            unSetModal()
          }}
        >
          Export
        </Button>
      </div>
    </div>
  )
}
