import { DashboardCardItemType } from 'common/enums/DashboardCardItemType.enum';

export class DashboardFilterModel {
  public cardType: DashboardCardItemType = {} as DashboardCardItemType;
  public values: any[] = [];
constructor(obj = {} as any) {
  obj = obj || {};
  this.cardType = obj.cardType === null? {} as DashboardCardItemType : obj.cardType;
  this.values = obj.values || [];
}

}
