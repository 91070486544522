import { KeywordMatchModel } from './KeywordMatchModel';

export class ItemKeywordMatchModel {
  public primaryKeywordMatches: KeywordMatchModel[] = [];
  public secondaryKeywordMatches: KeywordMatchModel[] = [];
constructor(obj = {} as any) {
  obj = obj || {};
  this.primaryKeywordMatches = obj.primaryKeywordMatches?.filter((item: any) => item !== undefined).map((item: any) => new KeywordMatchModel(item)) || [];
  this.secondaryKeywordMatches = obj.secondaryKeywordMatches?.filter((item: any) => item !== undefined).map((item: any) => new KeywordMatchModel(item)) || [];
}

}
