import "./audit-type-selector.scss"
import { Controller, useFormContext } from "react-hook-form"
import { NewAuditCard } from "../../new-audit-card/new-audit-card"
import { AuditType } from "common/enums/AuditType.enum"

type Props = {
  name: string
  required?: any
}

export const AuditTypeSelector: React.FC<Props> = ({ name, required }) => {
  const { setValue, control, getValues } = useFormContext()

  const handleSelect = (value: AuditType) => {
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => {
        const value = field.value !== getValues()[name] ? getValues()[name] : field.value
        return (
          <div className="audit-type-selector">
            <NewAuditCard
              className="audit-card"
              selected={value === AuditType.Product}
              onClick={() => handleSelect(AuditType.Product)}
              title="PRODUCT CONTENT"
              body="Retrieval of product attributes such as title, description, bullets (meta-data about the product) and assets (images, videos and documents) from the product detail page."
            />
            <NewAuditCard
              className="audit-card"
              selected={value === AuditType.Search}
              onClick={() => handleSelect(AuditType.Search)}
              title="SHARE OF SEARCH"
              body="Retrieval of product as it relates to the site search. We'll retrieve product page position, search term, and other key attributes (sponsorship, product badge, and so on)."
              disabled={process.env.REACT_APP_ENVIRONMENT === "production"}
            />
            <NewAuditCard
              className="audit-card"
              selected={value === AuditType.Review}
              onClick={() => handleSelect(AuditType.Review)}
              title="QUESTIONS & REVIEWS"
              body={
                <div className="flex flex-col" style={{ gap: 9 }}>
                  <span className="text-caption-1">
                    Retrieval of reviews and questions (if applicable) per product
                    defined. We'll grab the review or question title and the body
                    contents.
                  </span>
                  <span className="text-footnote font-semibold">COMING SOON</span>
                </div>
              }
              disabled
            />
          </div>
        )
      }}
    />
  )
}
