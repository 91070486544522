import { useSelector } from "react-redux"

import { RootState } from "store"
import { DashboardSectionCardModel } from "common/models/DashboardSectionCardModel"
import { getHealthScoreColumns } from "../columns"
import { DashboardAggregatedCard } from "../dashboard-aggregated-cards/DashboardAggregatedCard"

export const Analysis = () => {
  const dashboardData = useSelector(
    (state: RootState) => state.dashboard.aggregatedDashboardCards,
  )

  const basicContent = dashboardData?.basicContentSection?.cards
  const imageSection = dashboardData?.imageSection?.cards
  const richContentSection = dashboardData?.richContentSection?.cards
  const sentimentSection = dashboardData?.sentimentSection?.cards
  const specSection = dashboardData?.specsSection?.cards

  const titleAnalysisDescription = (
    <div className="min-h-[180px] flex flex-col">
      <div className="font-bold text-black mb-1 border-t border-gray-300 pt-4">
        SUCCESS FORMULA
      </div>
      <div className="mb-4">Brand Name + Defining Features + Type + Key Specs</div>
      <div>
        <div className="font-bold text-black mb-1">RESULTS</div>
        <div>
          By following the success formula above, you'll drive more organic traffic to
          your pages by adding keywords. While the increased traffic will boost sales, it
          will be magnified further as you improve all the content elements.
        </div>
      </div>
    </div>
  )

  const descriptionAnalysis = (
    <div className="min-h-[180px] flex flex-col">
      <div className="font-bold text-black mb-1 border-t border-gray-300 pt-4">
        PRO TIP
      </div>
      <div className="mb-4">
        Write in paragraph form connecting features with benefits while talking to specifc
        users or lifestyles. Also, don't skip descriptions just because the site offers
        enhanced content. Maximize your opportunities to sell!
      </div>
      <div>
        <div className="font-bold text-black mb-1">RESULTS</div>
        <div>
          Drive higher conversions by telling the user how your product is going to make
          their life better.
        </div>
      </div>
    </div>
  )

  const featureBullets = (
    <div className="min-h-[180px] flex flex-col">
      <div className="font-bold text-black mb-1 border-t border-gray-300 pt-4">
        PRO TIP
      </div>
      <div className="mb-4">
        Review your most important spec attributes and create a bullet for each one.
        Bullets should be a short phrase with less than 80 characters. List the most
        important feature bullets first.
      </div>
      <div>
        <div className="font-bold text-black mb-1">RESULTS</div>
        <div>
          You'll motivate shoppers to buy if you highlight the key features that match
          their needs.
        </div>
      </div>
    </div>
  )

  const imageAnalysis = (
    <div className="min-h-[180px] flex flex-col">
      <div className="font-bold text-black mb-1 border-t border-gray-300 pt-4">
        PRO TIP
      </div>
      <div className="mb-4">
        Need more images? Investing in 360 rotational images is a cost efficient way
        (typically under $90) to get 24 images of your products - even if you don't use
        the actual rotation itself.
      </div>
      <div>
        <div className="font-bold text-black mb-1">RESULTS</div>
        <div>
          Need more images? Investing in 360 rotational images is a cost efficient way
          (typically under $90) to get 24 images of your products - even if you don't use
          the actual rotation itself.
        </div>
      </div>
    </div>
  )

  // @ts-expect-error columns
  const HEALTH_SCORE_COLUMNS: ColumnDef<any>[] = getHealthScoreColumns()

  return (
    <>
      <div className="4xl:max-w-[2000px] max-w-[1360px] mx-auto mb-4 mt-10">
        <div className="grid h-10 items-center gap-3">
          <div className="font-bold text-title-1">Core Content Analysis</div>
        </div>
      </div>
      <div className="4xl:max-w-[2000px] max-w-[1360px] mx-auto grid grid-cols-4 gap-5">
        {basicContent?.map((card: DashboardSectionCardModel, index) => {
          let industryStandard
          let bestClass
          let subDescription

          switch (index) {
            case 0:
              industryStandard = "50+ characters"
              bestClass = "65-80 characters"
              subDescription = titleAnalysisDescription
              break
            case 1:
              industryStandard = "400+ characters (or 200+ words)"
              bestClass = "600+ characters (or 300+ words)"
              subDescription = descriptionAnalysis
              break
            case 2:
              industryStandard = "5+ bullets"
              bestClass = "7+ bullets"
              subDescription = featureBullets
              break
            default:
              industryStandard = "-"
              bestClass = "-"
          }

          return (
            <DashboardAggregatedCard
              columns={HEALTH_SCORE_COLUMNS}
              data={card.items}
              title={card.title}
              description={card.description}
              industryStandard={industryStandard}
              bestClass={bestClass}
              average={card.sectionAverageValue}
              grade={card.sectionGradeValue}
              subDescription={subDescription}
              isOneLineTitle
              descriptionContainerClass="core-content-container"
              hasAnalysis
            />
          )
        })}
        {imageSection?.map((card: DashboardSectionCardModel) => (
          <DashboardAggregatedCard
            columns={HEALTH_SCORE_COLUMNS}
            data={card.items}
            title={card.title}
            description={card.description}
            subDescription={imageAnalysis}
            industryStandard={"4+ images"}
            bestClass={"6+ images"}
            average={card.sectionAverageValue}
            grade={card.sectionGradeValue}
            isOneLineTitle
            descriptionContainerClass="core-content-container"
            hasAnalysis
          />
        ))}
      </div>

      <div className="4xl:max-w-[2000px] max-w-[1360px] mx-auto mb-4 mt-10">
        <div className="grid h-10 items-center gap-3">
          <div className="font-bold text-title-1">
            Specs, Rich Content & Sentiment Analysis
          </div>
        </div>
      </div>
      <div className="4xl:max-w-[2000px] max-w-[1360px] mx-auto grid grid-cols-4 gap-5">
        {specSection?.map((card) => (
          <DashboardAggregatedCard
            columns={HEALTH_SCORE_COLUMNS}
            data={card.items}
            title={card.title}
            description={<div className="min-h-44">{card.description}</div>}
            average={card.sectionAverageValue}
            grade={card.sectionGradeValue}
            isOneLineTitle
            specsContainerClassName="min-h-14"
          />
        ))}
        {richContentSection?.map((card) => (
          <DashboardAggregatedCard
            columns={HEALTH_SCORE_COLUMNS}
            data={card.items}
            title={card.title}
            description={<div className="min-h-44">{card.description}</div>}
            average={card.sectionAverageValue}
            grade={card.sectionGradeValue}
            isOneLineTitle
            specsContainerClassName="min-h-14"
          />
        ))}
        {sentimentSection?.map((card: DashboardSectionCardModel) => (
          <DashboardAggregatedCard
            columns={HEALTH_SCORE_COLUMNS}
            data={card.items}
            title={card.title}
            description={<div className="min-h-44">{card.description}</div>}
            average={card.sectionAverageValue}
            grade={card.sectionGradeValue}
            isOneLineTitle
            specsContainerClassName="min-h-14"
          />
        ))}
      </div>
    </>
  )
}
