import { ColumnDef } from "@tanstack/react-table"
import { RetailerProductIdDetail } from "common/enums/RetailerProductIdDetail.enum"
import { SkuType } from "common/enums/SkuType.enum"
import { JobCreateModel } from "common/models/JobCreateModel"
import { RetailerModel } from "common/models/RetailerModel"
import { ICONS, Icon } from "components/icon/icon"
import { enumToArray, toUTC } from "utils"
import { EMPTY_SKU_ERROR_OBJECT } from "utils/constants"
import { SuccessfulItems } from "./my-audits/table-components/SuccessfulItems"
import { MissingItems } from "./my-audits/table-components/MissingItems"
import { FailedItems } from "./my-audits/table-components/FailedItems"
import { ActionButtons } from "./my-audits/table-components/ActionButtons"

export const findSelectedRetailer = (retailers: RetailerModel[], retailerId?: string) =>
  retailers.find((r) => r.id === retailerId) ?? ({} as RetailerModel)

export const getSkuType = (skus?: string[], retailer?: RetailerModel) => {
  if (retailer && JSON.stringify(skus) === JSON.stringify(retailer.sampleUrls)) {
    return SkuType.SampleURLs
  }
  if (skus === undefined || skus[0].includes("www.") || skus[0].includes("http")) {
    return SkuType.URL
  }
  if (skus[0].length === 12 && /^\d+$/.test(skus[0])) {
    return SkuType.GSIN
  }
  return SkuType.ProductId
}

export const buildErrorObject = (
  skus: string[],
  selectedRetailer: RetailerModel,
  skuType: SkuType,
  retailers: RetailerModel[],
  selectedAudit: JobCreateModel | null,
) => {
  const duplicates: { [key: string]: number } = {}
  const productIdDetail = enumToArray(RetailerProductIdDetail)

  for (const sku of skus) {
    duplicates[sku] = (duplicates[sku] || 0) + 1
  }

  const newErrorState = { ...EMPTY_SKU_ERROR_OBJECT }
  const skuErrors = Object.keys(duplicates).map((sku) => {
    const count = duplicates[sku]
    if (count > 1) {
      newErrorState.duplicateError = true
    }

    switch (skuType) {
      case SkuType.ProductId: {
        const { label } = productIdDetail[selectedRetailer?.productIdDetail] ?? {}
        let ASINError = false
        if (label?.toUpperCase() === "ASIN") {
          const ASINRegEx = /^[A-Z0-9]*$/
          ASINError = sku.length !== 10 || !ASINRegEx.test(sku)
          if (ASINError) {
            newErrorState.ASINError = ASINError
          }
        }
        return { value: sku, count, ASINError }
      }

      case SkuType.GSIN: {
        const GTINRegEx = /^[0-9]*$/
        const GTINError = !GTINRegEx.test(sku)
        if (selectedRetailer.name === "Walmart.com") {
          const walmartGTINError = sku.length !== 12 || GTINError
          if (walmartGTINError) {
            newErrorState.GTINError = true
          }
        }
        return { value: sku, count, GTINError }
      }

      default: {
        const domains =
          retailers.find((r) => r.id === selectedAudit?.retailerId)?.acceptedDomains || []
        const domainError = domains.every((domain) => !sku.includes(domain))
        const linkRegExp = /^(https?:\/\/|www.)/
        const linkError = !linkRegExp.test(sku)
        if (domainError) {
          newErrorState.domainError = domainError
        }
        if (linkError) {
          newErrorState.linkError = linkError
        }
        return { value: sku, count, domainError, linkError }
      }
    }
  })

  return { skuErrors, newErrorState }
}

export const getSkusInputPlaceholder = (skuType: SkuType, label?: string) => {
  switch (skuType) {
    case SkuType.URL:
      return "Insert URLs (one per line)"
    case SkuType.GSIN:
      return "Insert GTINs (one per line)"
    case SkuType.ProductId:
      return `Insert ${label?.toUpperCase() === "ASIN" ? "ASIN" : label}s (one per line)`
    case SkuType.SampleURLs:
      return "Insert sample URLs (one per line)"
    default:
      return "Insert Product IDs (one per line)"
  }
}

export const getOneTimeAuditsColumns = (): ColumnDef<any>[] => [
  {
    header: "Opened",
    accessorKey: "startDate",
    cell: ({ row: { original: data } }: any) => toUTC(data.startDate),
  },
  {
    header: "Closed",
    accessorKey: "endDate",
    cell: ({ row: { original: data } }: any) => toUTC(data.endDate),
  },
  {
    header: "Owner",
    accessorKey: "owner",
  },
  {
    header: "Retailer",
    accessorKey: "retailer",
  },
  {
    header: "Audit Name",
    accessorKey: "auditName",
  },
  {
    header: "SKUs",
    accessorKey: "totalItems",
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: ({ row: { original: data } }: any) => (
      <span>{data.status === "InProgress" ? "In Progress" : data.status}</span>
    ),
  },
  {
    id: "one_time_successfull_items",
    header: () => <Icon icon={ICONS.CHECK_SOLID_ICON} className="text-green-500" />,
    accessorKey: "",
    cell: ({ row: { original: data } }: any) => <SuccessfulItems data={data} />,
  },
  {
    id: "one_time_missing_items",
    header: () => <Icon icon={ICONS.CANCEL_SOLID_ICON} className="text-red-500" />,
    accessorKey: "",
    cell: ({ row: { original: data } }: any) => <MissingItems data={data} />,
  },
  {
    id: "one_time_failed_items",
    header: () => (
      <Icon
        icon={ICONS.EXCLAMATION_SOLID}
        className="text-yellow-300 justify-self-center"
      />
    ),
    accessorKey: "",
    cell: ({ row: { original: data } }: any) => <FailedItems data={data} />,
  },
  {
    id: "one_time_actions",
    header: "Actions",
    accessorKey: "",
    minSize: 130,
    cell: ({ row: { original: data } }: any) => <ActionButtons data={data} />,
  },
]

export const getMyAuditsColumns = (): ColumnDef<any>[] => [
  {
    header: "Last Run",
    accessorKey: "lastRun",
    cell: ({ row: { original: data } }: any) => toUTC(data.lastRun),
  },
  {
    header: "Frequency",
    accessorKey: "scheduledJobType",
  },
  {
    header: "Owner",
    accessorKey: "owner",
  },
  {
    header: "Retailer",
    accessorKey: "retailer",
  },
  {
    header: "Audit Name",
    accessorKey: "auditName",
  },
  {
    header: "SKUs",
    accessorKey: "totalItems",
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: ({ row: { original: data } }: any) => (
      <span>{data.status === "InProgress" ? "In Progress" : data.status}</span>
    ),
  },
  {
    id: "successfull_items",
    header: () => <Icon icon={ICONS.CHECK_SOLID_ICON} className="text-green-500" />,
    accessorKey: "",
    cell: ({ row: { original: data } }: any) => <SuccessfulItems data={data} />,
  },
  {
    id: "missing_items",
    header: () => <Icon icon={ICONS.CANCEL_SOLID_ICON} className="text-red-500" />,
    accessorKey: "",
    cell: ({ row: { original: data } }: any) => <MissingItems data={data} />,
  },
  {
    id: "failed_items",
    header: () => <Icon icon={ICONS.EXCLAMATION_SOLID} className="text-yellow-300" />,
    accessorKey: "",
    cell: ({ row: { original: data } }: any) => <FailedItems data={data} />,
  },
  {
    id: "actions",
    header: "Actions",
    accessorKey: "",
    minSize: 130,
    cell: ({ row: { original: data } }: any) => <ActionButtons data={data} />,
  },
]
