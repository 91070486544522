import { RuleType } from 'common/enums/RuleType.enum';
import { ScoreDescriptionType } from 'common/enums/ScoreDescriptionType.enum';

export class RuleScoreCardModel {
  public name?: string = "";
  public ruleType: RuleType = {} as RuleType;
  public score?: number | string = "";
  public scoreDescription: ScoreDescriptionType = {} as ScoreDescriptionType;
  public isActive: boolean = false;
constructor(obj = {} as any) {
  obj = obj || {};
  this.name = obj.name === null? "" : obj.name;
  this.ruleType = obj.ruleType === null? {} as RuleType : obj.ruleType;
  this.score = obj.score === null? "" : obj.score;
  this.scoreDescription = obj.scoreDescription === null? {} as ScoreDescriptionType : obj.scoreDescription;
  this.isActive = obj.isActive === null? false : obj.isActive;
}

}
