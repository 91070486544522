import { FC, memo } from "react"
import Tippy from "@tippyjs/react"
import "./image.scss"
import imageFallback from "../../assets/images/Image_cannot_render.png"

type Props = {
  src: string
  alt: string
  className?: string
  popover?: boolean
  style?: any
  tooltipInstance?: any
  tooltipPlacement?: any
}

export const ImageComponent: FC<Props> = memo(
  ({
    src,
    alt = "",
    className = "",
    popover = true,
    style,
    tooltipInstance = undefined,
    tooltipPlacement = null,
  }: Props) => {
    const placement = tooltipPlacement ? tooltipPlacement : "bottom"

    const replaceImage = (event: React.SyntheticEvent<HTMLImageElement, Event>) =>
      ((event.target as HTMLImageElement).src = imageFallback)

    return (
      <>
        {popover ? (
          <Tippy
            singleton={tooltipInstance}
            placement={placement}
            content={
              <img
                src={src}
                alt={alt}
                onError={(event) => {
                  replaceImage(event)
                }}
                className="w-52 h-52 z-50 rounded p-2 object-contain bg-white popover"
              />
            }
          >
            <div className={`relative cursor-pointer`}>
              <img
                src={src}
                alt={alt}
                style={style}
                className={`image ${className}`}
                onError={(event) => {
                  replaceImage(event)
                }}
              />
            </div>
          </Tippy>
        ) : (
          <img
            src={src}
            alt={alt}
            className={`image ${className}`}
            style={style}
            onError={(event) => {
              replaceImage(event)
            }}
          />
        )}
      </>
    )
  },
)
