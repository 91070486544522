import { FC } from "react"
import { Button } from "components/button/button"
import { useModal } from "context/modal-context"
import { Icon, ICONS } from "components/icon/icon"

export const CannotDeleteModal: FC = () => {
  const { unSetModal } = useModal()

  return (
    <>
      <div className="grid">
        <Icon
          icon={ICONS.EXCLAMATION}
          className="text-red-600 justify-self-center mb-7"
          size={10}
        />
        <span className="text-gray-900 font-bold text-title-3 text-center pb-6">
          Cannot delete your account yet!
        </span>
      </div>
      <div className="border-t border-gray-300 mb-6" />
      <div className="grid grid-flow-row">
        <span className="text-red-600 font-bold text-body">
          In order to delete your account, one of the following must be completed:
        </span>
        <div className="flex flex-row mt-6">
          <Icon
            size={4}
            className="text-red-600 mr-2.5 self-center"
            icon={ICONS.CANCEL_SOLID_ICON}
          ></Icon>
          <span className="text-sub-headline text-gray-900 font-normal">
            Another member must be assigned the role of Owner across your workspace(s).
          </span>
        </div>
        <div className="flex flex-row mt-4">
          <Icon
            size={4}
            className="text-red-600 mr-2.5 self-center"
            icon={ICONS.CANCEL_SOLID_ICON}
          ></Icon>
          <span className="text-sub-headline text-gray-900 font-normal">
            Additionally, remove all other Members from each workspace.
          </span>
        </div>

        <div className="flex justify-center mt-12">
          <Button variant="primary" onClick={unSetModal}>
            Confirm
          </Button>
        </div>
      </div>
    </>
  )
}
