
export class ItemSnapshotSpecificationModel {
  public name?: string = "";
  public value?: string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.name = obj.name === null? "" : obj.name;
  this.value = obj.value === null? "" : obj.value;
}

}
