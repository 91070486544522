import { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Button } from "components/button/button"
import { Icon, ICONS } from "components/icon/icon"
import { NotificationsList } from "components/notifications/notifications-list/notifications-list"
import { RootDispatch, RootState } from "store"
import { NotificationModel } from "common/models/NotificationModel"
import { enumToArray } from "utils"
import { NotificationType } from "common/enums/NotificationType.enum"
import { NotificationPriority } from "common/enums/NotificationPriority.enum"
import { abortController } from "api/api"
import { Switch } from "components/form-components/switch/switch"

import "./notifications.scss"

const notificationTypeFilters = () => {
  const enumOptions = enumToArray(NotificationType)
  const filters = enumOptions.map((option) => {
    return { ...option, value: option.value + 1 }
  })

  return [{ label: "All", value: 0 }].concat(filters)
}

const notificationPriorityFilters = () => {
  const enumOptions = enumToArray(NotificationPriority)
  const filters = enumOptions.map((option) => {
    return { ...option, value: option.value + 1 }
  })

  return [{ label: "All", value: 0 }].concat(filters)
}

export const Notifications: FC = () => {
  const [selectedTypeFilter, setSelectedTypeFilter] = useState<number>(0)
  const [selectedPriorityFilter, setSelectedPriorityFilter] = useState<number>(0)
  const [filteredNotifications, setFilteredNotifications] = useState<NotificationModel[]>(
    [],
  )
  const [isFiltered, setIsFiltered] = useState<boolean>(false)
  const [isUnreadChecked, setIsUnreadChecked] = useState<boolean>(false)
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications,
  )

  const markAsRead = useDispatch<RootDispatch>().notifications.markAsRead

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    if (notifications) {
      if (isUnreadChecked) {
        setFilteredNotifications(
          notifications.filter((notification) => !notification.isRead),
        )
      } else {
        setFilteredNotifications(notifications)
      }
    }
  }, [notifications])

  const onSwitch = (newValue: boolean) => {
    if (newValue) {
      let updatedNotifications = notifications.filter(
        (notification) => !notification.isRead,
      )

      if (selectedPriorityFilter !== 0) {
        const priority = notificationPriorityFilters().find(
          (priority) => priority.value === selectedPriorityFilter,
        )

        if (priority) {
          updatedNotifications = updatedNotifications.filter(
            (notification) => notification.notificationPriority === priority.value - 1,
          )
        }
      }

      if (selectedTypeFilter !== 0) {
        const type = notificationTypeFilters().find(
          (type) => type.value === selectedTypeFilter,
        )

        if (type) {
          updatedNotifications = updatedNotifications.filter(
            (notification) => notification.notificationType === type.value - 1,
          )
        }
      }

      setFilteredNotifications(updatedNotifications)
      setIsFiltered(true)
      setIsUnreadChecked(true)
    } else {
      let updatedNotifications = notifications

      if (selectedPriorityFilter !== 0) {
        const priority = notificationPriorityFilters().find(
          (priority) => priority.value === selectedPriorityFilter,
        )

        if (priority) {
          updatedNotifications = updatedNotifications.filter(
            (notification) => notification.notificationPriority === priority.value - 1,
          )
        }
      }

      if (selectedTypeFilter !== 0) {
        const type = notificationTypeFilters().find(
          (type) => type.value === selectedTypeFilter,
        )

        if (type) {
          updatedNotifications = updatedNotifications.filter(
            (notification) => notification.notificationType === type.value - 1,
          )
        }
      }

      setFilteredNotifications(updatedNotifications)
      setIsUnreadChecked(false)
      if (selectedPriorityFilter === 0 && selectedTypeFilter === 0) {
        setIsFiltered(false)
      }
    }
  }

  const onTypeFilter = (type: any) => {
    if (type.label === "All") {
      let updatedNotifications = notifications

      if (selectedPriorityFilter !== 0) {
        const priority = notificationPriorityFilters().find(
          (priority) => priority.value === selectedPriorityFilter,
        )

        if (priority) {
          updatedNotifications = updatedNotifications.filter(
            (notification) => notification.notificationPriority === priority.value - 1,
          )
        }
      }

      if (isUnreadChecked) {
        setFilteredNotifications(
          updatedNotifications.filter((notification) => !notification.isRead),
        )
        setIsFiltered(true)
      } else {
        setFilteredNotifications(updatedNotifications)
        setIsFiltered(false)
      }

      setSelectedTypeFilter(type.value)
    } else {
      let updatedNotifications = notifications.filter(
        (notification) => notification.notificationType === type.value - 1,
      )

      if (selectedPriorityFilter !== 0) {
        const priority = notificationPriorityFilters().find(
          (priority) => priority.value === selectedPriorityFilter,
        )

        if (priority) {
          updatedNotifications = updatedNotifications.filter(
            (notification) => notification.notificationPriority === priority.value - 1,
          )
        }
      }

      if (isUnreadChecked) {
        setFilteredNotifications(
          updatedNotifications.filter((notification) => !notification.isRead),
        )
      } else {
        setFilteredNotifications(updatedNotifications)
      }

      setSelectedTypeFilter(type.value)
      setIsFiltered(true)
    }
  }

  const onPriorityFilter = (priority: any) => {
    if (priority.label === "All") {
      let updatedNotifications = notifications

      if (selectedTypeFilter !== 0) {
        const type = notificationTypeFilters().find(
          (type) => type.value === selectedTypeFilter,
        )

        if (type) {
          updatedNotifications = updatedNotifications.filter(
            (notification) => notification.notificationType === type.value - 1,
          )
        }
      }

      if (isUnreadChecked) {
        setFilteredNotifications(
          updatedNotifications.filter((notification) => !notification.isRead),
        )
        setIsFiltered(true)
      } else {
        setFilteredNotifications(updatedNotifications)
        setIsFiltered(false)
      }

      setSelectedPriorityFilter(priority.value)
    } else {
      let updatedNotifications = notifications.filter(
        (notification) => notification.notificationPriority === priority.value - 1,
      )

      if (selectedTypeFilter !== 0) {
        const type = notificationTypeFilters().find(
          (type) => type.value === selectedTypeFilter,
        )

        if (type) {
          updatedNotifications = updatedNotifications.filter(
            (notification) => notification.notificationType === type.value - 1,
          )
        }
      }

      if (isUnreadChecked) {
        setFilteredNotifications(
          updatedNotifications.filter((notification) => !notification.isRead),
        )
      } else {
        setFilteredNotifications(updatedNotifications)
      }

      setSelectedPriorityFilter(priority.value)
      setIsFiltered(true)
    }
  }

  return (
    <div className="notifications-page">
      <div className="notifications-filters">
        <div>
          <div className="notifications-filters-title">Filter By</div>
          <div>
            {notificationTypeFilters().map((filter) => (
              <div
                key={filter.value}
                className={`notifications-filters-item ${
                  selectedTypeFilter === filter.value && "selected-item"
                }`}
                onClick={() => onTypeFilter(filter)}
              >
                {filter.label}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="notifications-filters-title mt-6">Priority</div>
          <div>
            {notificationPriorityFilters().map((priority) => (
              <div
                key={priority.value}
                className={`notifications-filters-item ${
                  selectedPriorityFilter === priority.value && "selected-item"
                }`}
                onClick={() => onPriorityFilter(priority)}
              >
                {priority.label}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="notifications-content">
        <div className="notifications-header">
          <span className="text-title-2 font-medium">Notifications</span>
          <div className="flex self-end">
            <label className="switch mr-2">
              <div className={`ml-3 text-gray-500 text-caption-2 font-medium mr-2`}>
                Only show unread
              </div>
              <Switch value={isUnreadChecked} onChange={onSwitch} withoutLabel />
            </label>
            <Button
              variant="link"
              className="secondary justify-self-end mr-6"
              disabled={
                filteredNotifications.filter((notification) => !notification.isRead)
                  .length === 0
              }
              onClick={() => {
                markAsRead(
                  filteredNotifications.map((notification) => notification.id || ""),
                )
              }}
            >
              Mark all as read
            </Button>
          </div>
        </div>
        <NotificationsList notifications={filteredNotifications} variant="page" />
        {!isFiltered && (
          <div className="grid w-full justify-items-center text-center mt-16">
            <Icon icon={ICONS.FLAG} size={6} className="text-blue-600" />
            <span className="text-gray-500 text-body font-medium mt-4 w-1/4">
              That's all your notifications from the last 30 days.
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
