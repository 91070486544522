import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import EmptyLink from "./EmptyLink"
import TableLoader from "./TableLoader"
import { SortDirection } from "common/enums/SortDirection.enum"
import { TableHead } from "./table-head"
import { TableBody } from "./table-body"
import { useState } from "react"

export type TableProps = {
  columns: ColumnDef<any>[]
  data: any[]
  emptyTableMessage?: string
  emptyTableLink?: { label: string; route: string }
  maxHeight?: string
  loading?: boolean
  resizable?: boolean
  className?: string
  rowHeight?: number
  scrollableDivId?: string
  sortField?: string
  sortDirection?: SortDirection
  expandable?: boolean
  customExpand?: boolean
  getSubRows?: (row: any) => any
  onSortChange?: (sortField: any) => void
  onRowClick?: (row: any) => void
}

export const Table = ({
  columns,
  data,
  emptyTableMessage,
  emptyTableLink,
  maxHeight,
  loading = false,
  resizable = false,
  className,
  rowHeight = 45,
  scrollableDivId,
  sortField,
  sortDirection,
  expandable = false,
  customExpand = false,
  getSubRows,
  onSortChange,
  onRowClick,
}: TableProps) => {
  const [expanded, setExpanded] = useState<ExpandedState>({})

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: getSubRows ? getSubRows : (row: any) => row.rows,
    getExpandedRowModel: getExpandedRowModel(),
    columnResizeMode: "onChange",
  })
  const { rows } = table.getRowModel()

  return (
    <>
      <div
        id={scrollableDivId}
        className={`min-h-28 w-full overflow-auto relative ${className ?? ""}`}
        style={{ maxHeight: maxHeight ?? "100%" }}
      >
        <table className="w-full table-fixed">
          <TableHead
            headerGroups={table.getHeaderGroups()}
            resizable={resizable}
            sortField={sortField}
            sortDirection={sortDirection}
            onSortChange={onSortChange}
          />
          {rows.length === 0 && !loading ? (
            <EmptyLink
              emptyTableMessage={emptyTableMessage}
              emptyTableLink={emptyTableLink}
            />
          ) : (
            <TableBody
              rows={rows}
              resizable={resizable}
              rowHeight={rowHeight}
              onRowClick={onRowClick}
              expandable={expandable}
              customExpand={customExpand}
            />
          )}
        </table>
      </div>

      {loading && <TableLoader />}
    </>
  )
}
