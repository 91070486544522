import { useEffect, useState } from "react"
import { Loader } from "../loader/loader"
import "./suspense-loader.scss"

export const SuspenseLoader = ({ delay = 500 }: { delay?: number }) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  useEffect(() => {
    const timeOut = setTimeout(() => setIsLoaderVisible(true), delay)

    return () => clearTimeout(timeOut)
  })

  return (
    <div className="suspense-loader">
      {isLoaderVisible ? (
        <div>
          <Loader className="loader-position" message="Loading..." />
        </div>
      ) : (
        <div className="overlay"></div>
      )}
    </div>
  )
}
