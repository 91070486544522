
export class CatalogCountsModel {
  public titleCount: number | string = "";
  public descriptionCharCount: number | string = "";
  public descriptionSectionCharCount: any[] = [];
  public bulletsCount: number | string = "";
  public bulletsCharCount: any[] = [];
  public imagesCount: number | string = "";
  public videosCount: number | string = "";
  public documentsCount: number | string = "";
  public specificationsCount: number | string = "";
  public reviewsCount: number | string = "";
  public ratingsCount: number | string = "";
  public aPlusModuleTemplateCount: number | string = "";
  public aPlusModuleCount: number | string = "";
  public variantCount: number | string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.titleCount = obj.titleCount === null? "" : obj.titleCount;
  this.descriptionCharCount = obj.descriptionCharCount === null? "" : obj.descriptionCharCount;
  this.descriptionSectionCharCount = obj.descriptionSectionCharCount || [];
  this.bulletsCount = obj.bulletsCount === null? "" : obj.bulletsCount;
  this.bulletsCharCount = obj.bulletsCharCount || [];
  this.imagesCount = obj.imagesCount === null? "" : obj.imagesCount;
  this.videosCount = obj.videosCount === null? "" : obj.videosCount;
  this.documentsCount = obj.documentsCount === null? "" : obj.documentsCount;
  this.specificationsCount = obj.specificationsCount === null? "" : obj.specificationsCount;
  this.reviewsCount = obj.reviewsCount === null? "" : obj.reviewsCount;
  this.ratingsCount = obj.ratingsCount === null? "" : obj.ratingsCount;
  this.aPlusModuleTemplateCount = obj.aPlusModuleTemplateCount === null? "" : obj.aPlusModuleTemplateCount;
  this.aPlusModuleCount = obj.aPlusModuleCount === null? "" : obj.aPlusModuleCount;
  this.variantCount = obj.variantCount === null? "" : obj.variantCount;
}

}
