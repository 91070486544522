import { SectionScoreCardModel } from './SectionScoreCardModel';
import { ScoreDescriptionType } from 'common/enums/ScoreDescriptionType.enum';

export class ScoreCardModel {
  public sectionScoreCards: SectionScoreCardModel[] = [];
  public score: number | string = "";
  public scoreDescription: ScoreDescriptionType = {} as ScoreDescriptionType;
  public rubricId?: string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.sectionScoreCards = obj.sectionScoreCards?.filter((item: any) => item !== undefined).map((item: any) => new SectionScoreCardModel(item)) || [];
  this.score = obj.score === null? "" : obj.score;
  this.scoreDescription = obj.scoreDescription === null? {} as ScoreDescriptionType : obj.scoreDescription;
  this.rubricId = obj.rubricId === null? "" : obj.rubricId;
}

}
