import { RematchDispatch } from "@rematch/core"
import { getInvoicesCountApi, getInvoicesPaginatedApi } from "api/endpoints/invoices.api"
import {
  getUserPaymentMethodsApi,
  updateUserDefaultPaymentMethodApi,
  deleteUserPaymentMethodApi,
  updateUserPaymentMethodApi,
} from "api/endpoints/users.api"
import { SortDirection } from "common/enums/SortDirection.enum"
import { InvoiceGetModelPageResult } from "common/models/InvoiceGetModelPageResult"
import { InvoiceRequestOptions } from "common/models/InvoiceRequestOptions"
import { PageModel } from "common/models/PageModel"
import { PaymentMethodModel } from "common/models/PaymentMethodModel"

type State = Readonly<{
  paymentMethods: PaymentMethodModel[]
  paginatedInvoices: any
  requestOptions: any
  itemsCount: number
}>

const model = {
  state: {
    paymentMethods: [],
    paginatedInvoices: [],
    requestOptions: {
      filter: {
        searchQuery: "",
        invoiceStatus: null,
        planFrequencyType: null,
      },
      pageModel: new PageModel({
        page: 1,
        pageSize: 10,
        sortField: "createdAt",
        sortDirection: SortDirection.Descending,
      }),
    },
    itemsCount: 0,
  } as State,
  reducers: {
    loadedPaymentMethods: (state: State, payload: any): State => ({
      ...state,
      paymentMethods: payload,
    }),
    loadedPaginatedInvoices: (
      state: State,
      payload: InvoiceGetModelPageResult,
    ): State => ({
      ...state,
      paginatedInvoices: payload,
    }),
    setRequestOptions: (state: State, payload: any): State => {
      return {
        ...state,
        requestOptions: payload,
      }
    },
    setItemsCount: (state: State, payload: number): State => {
      return {
        ...state,
        itemsCount: payload,
      }
    },
    selectAllPaginatedInvoices: (state: State, payload: any): State => ({
      ...state,
      paginatedInvoices: state.paginatedInvoices.map((invoice: any) => {
        if (invoice.paymentStatus === "Paid") {
          return { ...invoice, selected: payload }
        } else {
          return { ...invoice }
        }
      }),
    }),
    selectPaginatedInvoice: (state: State, payload: any): State => ({
      ...state,
      paginatedInvoices: state.paginatedInvoices.map((invoice: any) => ({
        ...invoice,
        selected:
          invoice.invoiceId === payload.invoiceId ? payload.selected : invoice.selected,
      })),
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchPaymentMethods() {
      const paymentMethods = await getUserPaymentMethodsApi()

      if (paymentMethods) {
        dispatch.billing.loadedPaymentMethods(paymentMethods)
      }
    },
    async setDefaultPaymentMethod(paymentMethodId: string) {
      const result = await updateUserDefaultPaymentMethodApi(paymentMethodId)

      if (result) {
        await this.fetchPaymentMethods()
      }
      return result
    },
    async deletePaymentMethod(paymentMethodId: string) {
      const result = await deleteUserPaymentMethodApi(paymentMethodId)

      if (result) {
        await this.fetchPaymentMethods()
      }
      return result
    },
    async updatePaymentMethod(payload: any) {
      const result = await updateUserPaymentMethodApi(
        payload.paymentMethodId,
        payload.body,
      )

      if (result) {
        await this.fetchPaymentMethods()
        return result
      }
      return false
    },
    async getPaginatedInvoices(payload: InvoiceRequestOptions) {
      const invoices = await getInvoicesPaginatedApi(payload)

      if (invoices) {
        dispatch.billing.loadedPaginatedInvoices(invoices.items)
      }
    },
    async getInvoicesCount(payload: InvoiceRequestOptions) {
      const { count } = await getInvoicesCountApi(payload)

      if (count) {
        dispatch.billing.setItemsCount(count)
      }
    },
  }),
}

export default model
