import React from "react"
import useResizeObserver from "@react-hook/resize-observer"

type Size = {
  height: number
  width: number
}

export const useSize = (target: any) => {
  const [size, setSize] = React.useState<Size>({ height: 0, width: 0 })

  React.useLayoutEffect(() => {
    setSize(target?.current?.getBoundingClientRect())
  }, [target])

  useResizeObserver(target, (entry) => {
    if (entry) {
      return setSize({
        height: entry.target?.getBoundingClientRect().height,
        width: entry.target?.getBoundingClientRect().width,
      })
    }
  })

  return size
}
