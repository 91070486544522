import { useSelector } from "react-redux"
import { useFormContext } from "react-hook-form"
import { RootState } from "store"
import { Radio } from "components/form-components/radio/radio"
import { RetailerModel } from "common/models/RetailerModel"
import { RetailerProductIdDetail } from "common/enums/RetailerProductIdDetail.enum"
import { enumToArray } from "utils"
import { SkuType } from "common/enums/SkuType.enum"
import { SkuDetails } from "common/types/SkuDetails.type"
import "./sku-type-selector.scss"

type Props = {
  retailer: RetailerModel
  skus: (string | SkuDetails)[]
  loadedSkusType: SkuType
  setSkuTypeSelection: (skuType: SkuType) => void
}

export const SkuTypeSelector = ({
  retailer,
  skus,
  loadedSkusType,
  setSkuTypeSelection,
}: Props) => {
  const retailers = useSelector((state: RootState) => state.retailers.allRetailers)
  const audits = useSelector((state: RootState) => state.audits)
  const { setValue, trigger } = useFormContext()
  const productIdDetail = enumToArray(RetailerProductIdDetail)
  const { label } = productIdDetail[retailer?.productIdDetail] ?? {}

  const loadExamples = () => {
    setValue("skusText", retailer?.sampleUrls.join("\n"))
    setValue("skus", retailer?.sampleUrls)
    trigger("skusText")
    setSkuTypeSelection(SkuType.SampleURLs)
  }

  const onSkuTypeSelection = (type: SkuType) => {
    setSkuTypeSelection(type)
    const inputSkus = type === loadedSkusType ? skus : []
    const inputSkusText =
      inputSkus.length > 0
        ? skus.map((sku) => typeof sku !== "string" && sku.value).join("\n")
        : ""
    setValue("skus", inputSkus)
    setValue("skusText", inputSkusText)
  }

  return (
    <div className="flex gap-10 mb-3 ml-1">
      <Radio
        label="URL"
        name="skuType"
        value={SkuType.URL.toString()}
        onChange={() => onSkuTypeSelection(SkuType.URL)}
      />
      {retailer?.sampleIds?.length > 0 && (
        <Radio
          label={
            retailer?.name?.includes("Amazon")
              ? "ASIN (must contain 10 characters)"
              : label
          }
          name="skuType"
          value={SkuType.ProductId.toString()}
          onChange={() => onSkuTypeSelection(SkuType.ProductId)}
        />
      )}
      {(retailer?.name?.includes("Amazon") ||
        retailer?.name?.includes("Walmart.com")) && (
        <Radio
          label={
            retailer?.name?.includes("Walmart.com")
              ? "GTIN (must contain 12 digits per line)"
              : "GTIN (must contain only digits)"
          }
          name="skuType"
          value={SkuType.GSIN.toString()}
          onChange={() => onSkuTypeSelection(SkuType.GSIN)}
        />
      )}
      <Radio
        label={`Insert sample ${
          retailers.find((r) => r.id === audits.selectedAudit?.retailerId)?.name
        } URLs`}
        name="skuType"
        value={SkuType.SampleURLs.toString()}
        onChange={loadExamples}
      />
    </div>
  )
}
