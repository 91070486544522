import { JobCreateModel } from 'common/models/JobCreateModel';
import { JobItemStatus } from 'common/enums/JobItemStatus.enum';
import { MyAuditsModel } from 'common/models/MyAuditsModel';
import { ScheduledJobType } from 'common/enums/ScheduledJobType.enum';
import { MyAuditGetModel } from 'common/models/MyAuditGetModel';
import { WorkspaceAuditEntityModelPageResultModel } from 'common/models/WorkspaceAuditEntityModelPageResultModel';
import { PageModel } from 'common/models/PageModel';
import { ItemsCountModel } from 'common/models/ItemsCountModel';
import { RecurringJobModel } from 'common/models/RecurringJobModel';
import { API } from "../api";

export const createJobApi = (workspaceId: string, body: JobCreateModel): Promise<boolean> => API().post(`workspaces/${workspaceId}/jobs`, body);

export const importJobsApi = (workspaceId: string, retailerId: string, planId: string, body: any): Promise<boolean> => API().post(`workspaces/${workspaceId}/jobs/import/retailer/${retailerId}/plan/${planId}`, body);

export const getJobApi = (workspaceId: string, auditId: string): Promise<JobCreateModel> => API().get(`workspaces/${workspaceId}/jobs/myaudits/${auditId}`);

export const getAuditItemsApi = (workspaceId: string, auditId: string, status: JobItemStatus): Promise<string[]> => API().get(`workspaces/${workspaceId}/jobs/myaudits/${auditId}/items/${status}`);

export const getWorkspaceJobsApi = (workspaceId: string): Promise<MyAuditsModel[]> => API().get(`workspaces/${workspaceId}/jobs/myaudits`);

export const getJobNextExecutionDateApi = (workspaceId: string, scheduledJobType: ScheduledJobType): Promise<string> => API().get(`workspaces/${workspaceId}/jobs/next-execution-date/${scheduledJobType}`);

export const getWorkspaceAuditsApi = (workspaceId: string, body: MyAuditGetModel): Promise<WorkspaceAuditEntityModelPageResultModel> => API().post(`workspaces/${workspaceId}/jobs/myaudits/paged`, body);

export const getAllWorkspaceAuditsApi = (workspaceId: string, body: PageModel): Promise<WorkspaceAuditEntityModelPageResultModel> => API().post(`workspaces/${workspaceId}/jobs/myaudits/all/paged`, body);

export const getWorkspaceJobsCountApi = (workspaceId: string, body: MyAuditGetModel): Promise<ItemsCountModel[]> => API().post(`workspaces/${workspaceId}/jobs/myaudits/count`, body);

export const deleteWorkspaceJobApi = (workspaceId: string, auditId: string): Promise<boolean> => API().delete(`workspaces/${workspaceId}/jobs/myaudits/${auditId}/keepCollectedData`);

export const deleteWorkspaceJobAndCollectedDataApi = (workspaceId: string, auditId: string): Promise<boolean> => API().delete(`workspaces/${workspaceId}/jobs/myaudits/${auditId}/deleteCollectedData`);

export const getReccurringJobsApi = (workspaceId: string): Promise<RecurringJobModel[]> => API().get(`workspaces/${workspaceId}/jobs/reccurringJobs`);

export const deleteWorkspaceJobNewApi = (workspaceId: string, auditId: string): Promise<boolean> => API().delete(`workspaces/${workspaceId}/jobs/myaudits/${auditId}/keepData`);

export const deleteWorkspaceJobAndCollectedDataNewApi = (workspaceId: string, auditId: string): Promise<boolean> => API().delete(`workspaces/${workspaceId}/jobs/myaudits/${auditId}/deleteData`);
