import { RematchDispatch } from "@rematch/core"
import { WORKSPACE_ID } from "api/api"
import { updateItemKeywordsApi, updateItemTagsApi } from "api/endpoints/items.api"
import { getWorkspaceTagsApi, updateWorkspaceTagsApi } from "api/endpoints/workspace.api"
import { ItemKeywordsModel } from "common/models/ItemKeywordsModel"
import { ItemTagsModel } from "common/models/ItemTagsModel"
import { WorkspaceTagsModel } from "common/models/WorkspaceTagsModel"

type State = Readonly<{
  closeModal: boolean
  updatedWorkspaceTags: boolean
  isRenamingTag: { renaming: boolean; index?: number }
  saveActive: boolean
  workSpaceTags: WorkspaceTagsModel | null
}>

const model = {
  state: {
    closeModal: false,
    updatedWorkspaceTags: false,
    isRenamingTag: { renaming: false, index: -1 },
    saveActive: false,
    workSpaceTags: null,
  } as State,
  reducers: {
    setWorkspaceTags: (state: State, payload: any): State => {
      return { ...state, workSpaceTags: payload }
    },
    setUpdatedItemTags: (state: State, payload: any): State => {
      return { ...state, closeModal: payload }
    },
    setUpdatedWorkspaceTags: (state: State, payload: any): State => {
      return { ...state, closeModal: payload }
    },
    setUpdatedWorkspaceTagsMultipleItem: (state: State, payload: any): State => {
      return {
        ...state,
        workSpaceTags: payload.getTagsResponse,
        updatedWorkspaceTags: payload.updateResponse,
      }
    },
    setUpdatedItemKeywords: (state: State, payload: any): State => {
      return { ...state, closeModal: payload }
    },
    setIsRenamingTag: (
      state: State,
      payload: { renaming: boolean; index?: number },
    ): State => {
      return { ...state, isRenamingTag: payload }
    },
    setSaveActive: (state: State, payload: any): State => {
      return { ...state, saveActive: payload }
    },
    setWorkspaceTagsAndAddItemTags: (state: State, payload: any): State => {
      return { ...state, closeModal: payload }
    },
    resetCloseModalRed: (state: State): State => {
      return { ...state, closeModal: false }
    },
    resetUpdatedWorkspaceTagsRed: (state: State): State => {
      return { ...state, updatedWorkspaceTags: false }
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchWorkspaceTags() {
      const workspaceTags = await getWorkspaceTagsApi(WORKSPACE_ID)

      if (workspaceTags) {
        dispatch.tags.setWorkspaceTags(workspaceTags)
      }
      return workspaceTags
    },
    async updateItemTags(payload: { tags: ItemTagsModel[] }) {
      const result = await updateItemTagsApi(WORKSPACE_ID, payload.tags)

      if (result) {
        dispatch.tags.setUpdatedItemTags(result)
      }
      return result
    },
    async updateWorkspaceTags(payload: { tags: string[] }) {
      const result = await updateWorkspaceTagsApi(WORKSPACE_ID, payload.tags)

      if (result) {
        dispatch.tags.setUpdatedWorkspaceTags(result)
      }
      return result
    },
    async updateWorkspaceTagsMultipleItems(payload: {
      tags: string[]
      itemTags: ItemTagsModel[]
    }) {
      const updateResponse = await updateWorkspaceTagsApi(WORKSPACE_ID, payload.tags)
      const workspaceTags = await getWorkspaceTagsApi(WORKSPACE_ID)
      const itemTagsResponse = await updateItemTagsApi(WORKSPACE_ID, payload.itemTags)

      if (updateResponse && workspaceTags && itemTagsResponse) {
        dispatch.tags.setUpdatedWorkspaceTagsMultipleItem({
          updateResponse: updateResponse,
          getTagsResponse: workspaceTags,
        })

        dispatch.tags.setWorkspaceTagsAndAddItemTags(workspaceTags && itemTagsResponse)
      }
      return updateResponse && itemTagsResponse && workspaceTags
    },
    async updateItemKeywords(payload: ItemKeywordsModel[]) {
      const result = await updateItemKeywordsApi(WORKSPACE_ID, payload)

      if (result) {
        dispatch.tags.setUpdatedItemKeywords(result)
      }
      return result
    },
    async addWorkspaceTagsAndAddItemTags(payload: {
      workspaceTags: string[]
      itemTags: ItemTagsModel[]
    }) {
      const workspaceTagsResponse = await updateWorkspaceTagsApi(
        WORKSPACE_ID,
        payload.workspaceTags,
      )
      const itemTagsResponse = await updateItemTagsApi(WORKSPACE_ID, payload.itemTags)

      if (itemTagsResponse && workspaceTagsResponse) {
        dispatch.tags.setWorkspaceTagsAndAddItemTags(
          workspaceTagsResponse && itemTagsResponse,
        )
      }
      return itemTagsResponse && workspaceTagsResponse
    },
    async resetCloseModal() {
      dispatch.tags.resetCloseModalRed()
    },
    async resetUpdatedWorkspaceTags() {
      dispatch.tags.resetUpdatedWorkspaceTagsRed()
    },
  }),
}

export default model
