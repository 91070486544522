import { SkuInputError } from "common/types/SkuInputError.type"
import { Button } from "components/button/button"

type Props = {
  errorObject: SkuInputError
  removeFaultyItems: (property: string) => void
  removeDuplicates: () => void
}

export const UploadErrors = ({
  errorObject,
  removeDuplicates,
  removeFaultyItems,
}: Props) => (
  <div
    className="grid grid-flow-col gap-3 justify-items-end justify-end my-3"
    id="skuButtons"
  >
    {errorObject.ASINError && (
      <Button onClick={() => removeFaultyItems("ASINError")} variant="danger" isSmall>
        Remove ASIN Errors
      </Button>
    )}
    {errorObject.GTINError && (
      <Button onClick={() => removeFaultyItems("GTINError")} variant="danger" isSmall>
        Remove GTIN Errors
      </Button>
    )}
    {errorObject.linkError && (
      <Button onClick={() => removeFaultyItems("linkError")} variant="danger" isSmall>
        Remove Link Errors
      </Button>
    )}
    {errorObject.domainError && (
      <Button onClick={() => removeFaultyItems("domainError")} variant="danger" isSmall>
        Remove Domain Errors
      </Button>
    )}
    {errorObject.duplicateError && (
      <Button onClick={removeDuplicates} variant="danger" isSmall>
        Remove Duplicates
      </Button>
    )}
  </div>
)
