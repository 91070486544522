import { useCallback, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"

export const useHashRouting = <T extends string | number>(
  isAvailableStep: (step: T) => boolean,
  defaultStep: T,
  withRouting = true,
): [T, (st: T) => void] => {
  const navigate = useNavigate()
  const location = useLocation()
  const [step, setStep] = useState(defaultStep)

  const pushToStep = useCallback(
    (nexStep?: T) => {
      const hash = "#" + (nexStep !== undefined ? nexStep : step)
      if (hash !== location.hash) {
        navigate(location.pathname + hash)
      }
    },
    [location.pathname, location.hash],
  )

  useEffect(() => {
    if (withRouting) {
      pushToStep(defaultStep)
    }
  }, [])

  useEffect(() => {
    if (withRouting) {
      const hash = "#" + step
      if (location.hash && location.hash !== hash && location.key !== "default") {
        const locationHash = location.hash.replace("#", "")
        const stepFromHash =
          typeof defaultStep === "number" ? Number(locationHash) : locationHash
        if (isAvailableStep(stepFromHash as T)) {
          setStep(stepFromHash as T)
        }
      }
    }
  }, [location.hash])

  return [step, withRouting ? pushToStep : setStep]
}
