import { DependencyModel } from './DependencyModel';

export class PropertyModel {
  public dependencies: DependencyModel[] = [];
  public value?: string = "";
constructor(obj = {} as any) {
  obj = obj || {};
  this.dependencies = obj.dependencies?.filter((item: any) => item !== undefined).map((item: any) => new DependencyModel(item)) || [];
  this.value = obj.value === null? "" : obj.value;
}

}
