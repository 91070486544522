import { ItemFilterModel } from "common/models/ItemFilterModel"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import { FC, useEffect, useState } from "react"
import { FilterSearch } from "../filter-search/filter-search"
import "./string-model-options.scss"

type Props = {
  options: string[] | undefined
  filters: ItemFilterModel
  filterName: keyof ItemFilterModel
  setFilters: (filters: ItemFilterModel) => void
  collapsed?: boolean
}

export const StringModelOption: FC<Props> = ({
  options = [],
  filters,
  filterName,
  setFilters,
  collapsed,
}: Props) => {
  const [currentFilter, setCurrentFilter] = useState([] as string[])
  const [filteredOptions, setFilteredOptions] = useState(options.slice(0, 6))
  const [searchValue, setSearchValue] = useState("")

  useEffect(() => {
    setCurrentFilter(filters[filterName] as string[])
  }, [filters, filterName])

  useEffect(() => {
    if (collapsed) {
      return
    }

    if (searchValue) {
      setFilteredOptions(filteredOptions)
    } else {
      setFilteredOptions(options)
    }
  }, [collapsed, searchValue])

  const setValue = (item: string) => {
    setFilters({
      ...filters,
      [filterName]: currentFilter.includes(String(item))
        ? currentFilter.filter((f) => f !== item)
        : [...currentFilter, String(item)],
    })
  }

  return (
    <div className="string-model-options">
      <FilterSearch
        options={options}
        setOptions={setFilteredOptions}
        value={searchValue}
        setSearchValue={setSearchValue}
      />
      {filteredOptions.map((item: string, index: number) => (
        <Checkbox
          value={currentFilter.includes(item)}
          key={index}
          label={item}
          small
          onChange={() => setValue(item)}
        />
      ))}
    </div>
  )
}
