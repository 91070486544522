import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

import "./user-account.scss"

import { RootState } from "../../store"
import { Button } from "components/button/button"
import { Input } from "components/form-components/input/input"
import { Icon, ICONS } from "components/icon/icon"
import { UserModel } from "common/models/UserModel"
import { Skeleton } from "components/skeleton/skeleton"
import { Select } from "components/form-components/select/select"
import { useModal } from "context/modal-context"
import { DeleteAccountModal } from "./delete-account-modal/delete-account-modal"
import { CannotDeleteModal } from "./delete-account-modal/cannot-delete-modal/cannot-delete-modal"
import { Card } from "components/card/card"
import { Header } from "../../components/header/header"
import { percentToColor } from "../../utils"
import { abortController } from "api/api"

const schema = yup
  .object({
    firstName: yup.string().max(75).trim().required(),
    lastName: yup.string().max(75).trim().required(),
    companyName: yup.string().max(75).trim().required(),
    companyTitle: yup.string().max(75).trim().required(),
    companyRole: yup.string().max(75).required(),
    companyIndustry: yup.string().max(75).required(),
    companyType: yup.string().max(75).required(),
    marketingSource: yup.string().max(75).required(),
  })
  .required()

export const UserAccount: FC = () => {
  const dispatch = useDispatch()

  const updateUser = dispatch.account.updateUser
  const fetchUserAccountDetailsOptions = dispatch.account.fetchUserAccountDetailsOptions
  const checkCanBeDeletedAccount = dispatch.account.checkCanBeDeletedAccount

  const account = useSelector((state: RootState) => state.account)
  const loading = useSelector(
    (state: RootState) => state.loading.effects.account.fetchUserData,
  )
  const saving = useSelector(
    (state: RootState) => state.loading.effects.account.updateUser,
  )
  const [isViewMode, setIsViewMode] = useState(true)
  const [completionPercentage, setCompletionPercentage] = useState(0)

  const companyTypes =
    account.userAccountDetailsOptions.companyTypes?.map((companyType) => ({
      value: companyType,
      label: companyType,
    })) || []
  const industryTypes =
    account.userAccountDetailsOptions.industryTypes?.map((industryType) => ({
      value: industryType,
      label: industryType,
    })) || []
  const roleTypes =
    account.userAccountDetailsOptions.roleTypes?.map((roleType) => ({
      value: roleType,
      label: roleType,
    })) || []
  const marketingSources =
    account.userAccountDetailsOptions?.marketingSources?.map((source) => ({
      value: source,
      label: source,
    })) || []

  const { setModal } = useModal()
  const { instance } = useMsal()
  const navigate = useNavigate()
  const [isDeletedUser, setIsDeletedUser] = useState<boolean>(false)

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: new UserModel(account.user),
  })

  const onSubmit = (values: any) => {
    setIsViewMode(true)
    updateUser(values)
  }

  useEffect(() => {
    fetchUserAccountDetailsOptions()

    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    methods.reset(new UserModel(account.user))
  }, [account.user, methods])

  useEffect(() => {
    if (isDeletedUser) {
      localStorage.clear()
      navigate("/")
      instance.logoutRedirect()
    }
  }, [isDeletedUser])

  useEffect(() => {
    let percentage = 100
    const fields = methods.watch()
    const fieldKeys = Object.keys(fields).filter(
      (key) =>
        key !== "userFlags" &&
        key !== "defaultPaymentMethod" &&
        key !== "stripeCustomerId",
    )
    const filedFraction = Math.round((100 / fieldKeys.length) * 100) / 100

    fieldKeys.forEach((key) => {
      if (!fields[key as keyof UserModel]) {
        percentage = Math.round(percentage - filedFraction)
      }
    })

    setCompletionPercentage(percentage)
  }, [methods.watch()])

  const deleteCurrentAccount = () => {
    checkCanBeDeletedAccount().then((response: boolean) => {
      if (response) {
        setModal(<DeleteAccountModal setIsDeletedUser={setIsDeletedUser} />)
      } else {
        setModal(<CannotDeleteModal />)
      }
    })
  }

  return (
    <div className="user-account-container p-5 pt-[30px]">
      <Header title="User Account" className="account-title" />
      <Card className="w-full user-account-card">
        <div className="flex">
          <FormProvider {...methods}>
            <form className="flex-1">
              <Skeleton loading={loading} className="form-container">
                <Input
                  label="First Name"
                  placeholder="Enter your first name"
                  required
                  name="firstName"
                  disabled={isViewMode}
                />

                <Input
                  label="Last Name"
                  placeholder="Enter your last name"
                  required
                  name="lastName"
                  disabled={isViewMode}
                />

                <Input
                  label="Email"
                  placeholder="Enter your email"
                  required
                  disabled
                  name="userName"
                  type="email"
                />

                <Input
                  label="Company Name"
                  placeholder="Enter your company name"
                  name="companyName"
                  disabled={isViewMode}
                  required
                />
                <Input
                  label="Title"
                  placeholder="Enter your title"
                  name="companyTitle"
                  disabled={isViewMode}
                  required
                />

                <Select
                  label="Role"
                  placeholder="Select your role"
                  name="companyRole"
                  options={roleTypes}
                  disabled={isViewMode}
                  required
                />

                <Select
                  label="Industry"
                  placeholder="Select your industry"
                  name="companyIndustry"
                  options={industryTypes}
                  disabled={isViewMode}
                  required
                />

                <Select
                  label="Company Type"
                  placeholder="Select your company type"
                  name="companyType"
                  options={companyTypes}
                  disabled={isViewMode}
                  required
                />
                <Select
                  label="How did you hear About Us?"
                  placeholder="Select your option"
                  name="marketingSource"
                  options={marketingSources}
                  disabled={isViewMode}
                  required
                />
              </Skeleton>
            </form>
          </FormProvider>
          <div className="flex flex-col justify-center px-[60px] mb-[14px] mt-6 ml-6 border border-solid border-gray-300 rounded-lg">
            <div className="font-sans font-semibold text-sub-headline mb-6">
              Overall progress
            </div>
            <div style={{ height: "110px", width: "110px" }}>
              <CircularProgressbarWithChildren
                value={completionPercentage}
                text={`${
                  completionPercentage >= 100 && isViewMode
                    ? ``
                    : `${completionPercentage}%`
                }`}
                styles={buildStyles({
                  textSize: "20px",
                  textColor: percentToColor(completionPercentage),
                  trailColor: "#FDF4FF",
                  pathColor: percentToColor(completionPercentage),
                })}
              >
                {completionPercentage >= 100 && isViewMode ? (
                  <Icon icon={ICONS.THUMBS_UP} size={7} className="text-green-600" />
                ) : null}
              </CircularProgressbarWithChildren>
            </div>
          </div>
        </div>
        <div className="mt-12">
          {!isViewMode ? (
            <div className="flex justify-between w-full">
              <Button variant="danger" onClick={deleteCurrentAccount}>
                Delete Account
              </Button>
              <div>
                <Button
                  className="mr-[10px]"
                  onClick={() => {
                    methods.reset()
                    setIsViewMode(true)
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  className="ml-2 self-end"
                  type="submit"
                  loading={saving}
                  variant="success"
                  onClick={methods.handleSubmit(onSubmit)}
                  disabled={!methods.formState.isValid}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex justify-end">
              <Button
                className="ml-2 self-end"
                onClick={() => {
                  setIsViewMode(false)
                }}
              >
                Edit account
              </Button>
            </div>
          )}
        </div>
      </Card>
    </div>
  )
}
