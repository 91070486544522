import { useCallback, useEffect, useRef, useState } from "react"
import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { useInfiniteQuery } from "@tanstack/react-query"

import { PageModel } from "common/models/PageModel"
import EmptyLink from "components/table/EmptyLink"
import { TableHead } from "components/table/table-head"
import TableLoader from "components/table/TableLoader"
import { TableBody } from "components/table/table-body"

type Props = {
  columns: ColumnDef<any>[]
  fetchData: (options: any) => Promise<any>
  fetchSize: number
  emptyTableMessage?: string
  emptyTableLink?: { label: string; route: string }
  requestOptions?: any
  height?: string | number
  expandable?: boolean
  headerHeight?: number
}

export const InfiniteLoadTable = ({
  columns,
  fetchSize,
  fetchData,
  emptyTableMessage,
  emptyTableLink,
  requestOptions,
  height,
  expandable = false,
  headerHeight,
}: Props) => {
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<any[]>([])
  const [expanded, setExpanded] = useState<ExpandedState>({})

  const { fetchNextPage, isFetching } = useInfiniteQuery<any>(
    ["table-data"],
    async () => {
      const options = new PageModel({
        ...requestOptions,
        page: page,
        pageSize: fetchSize,
      })

      await fetchData(options).then((res) => {
        setPage(typeof res.nextPage === "number" ? res.nextPage : parseInt(res.nextPage))
        setData([...data, ...res.items])
      })
    },
    {
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  )
  const totalFetched = data.length
  const total = page === 0 && totalFetched > 0 ? totalFetched : fetchSize * page

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        if (
          scrollHeight - scrollTop - clientHeight < 300 &&
          !isFetching &&
          totalFetched < total
        ) {
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, total],
  )

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
    state: {
      expanded,
      pagination: {
        pageIndex: 0,
        pageSize: 10000,
      },
    },
    onExpandedChange: setExpanded,
    getSubRows: (row: any) => row.rows,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    paginateExpandedRows: false,
  })
  const { rows } = table.getRowModel()

  return (
    <>
      <div
        className="overflow-auto w-full relative"
        onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
        ref={tableContainerRef}
        style={{ height: height ?? 340 }}
      >
        <table className={`w-full ${expandable ? "table-fixed" : ""}`}>
          <TableHead
            headerGroups={table.getHeaderGroups()}
            resizable
            headerHeight={headerHeight}
          />
          {rows.length === 0 && !isFetching ? (
            <EmptyLink
              emptyTableMessage={emptyTableMessage}
              emptyTableLink={emptyTableLink}
            />
          ) : (
            <TableBody rows={rows} expandable={expandable} />
          )}
        </table>
      </div>

      {isFetching && <TableLoader />}
    </>
  )
}
