import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Card } from "components/card/card"
import { Header } from "components/header/header"
import { RootDispatch, RootState } from "store"
import { Select } from "components/form-components/select/select"
import { Icon, ICONS } from "components/icon/icon"
import { CatalogReportRowModel } from "common/models/CatalogReportRowModel"
import { SelectOption } from "../../../../components/form-components/select/select"
import {
  exportCatalogReportsApi,
  exportCatalogTrendReportApi,
  exportFilteredCatalogReportsApi,
  exportFilteredCatalogTrendReportApi,
} from "api/endpoints/exports.api"
import { abortController, WORKSPACE_ID } from "api/api"
import { useModal } from "context/modal-context"
import { ExportReportModal } from "./export-reports-modal/export-report-modal"
import { Button } from "components/button/button"
import { PageModel } from "common/models/PageModel"
import { CatalogFilters } from "../catalog-filters/catalog-filters"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import { CatalogRequestOptions } from "common/models/CatalogRequestOptions"
import { ReportsSearch } from "./reports-search/reports-search"
import { countFilters, reports } from "./reports-utils"
import { TrendScoreReportRowModel } from "common/models/TrendScoreReportRowModel"
import { ScoreTrendsView } from "./reports-views/score-trends"
import { BasicView } from "./reports-views/basic-view"
import { Switch } from "components/form-components/switch/switch"

import "./reports.scss"

export type Row = CatalogReportRowModel & { expanded?: boolean; padding?: number }

export type TrendRow = TrendScoreReportRowModel & { expanded?: boolean; padding?: number }

export const Reports = () => {
  const { setModal } = useModal()
  const dispatch: RootDispatch = useDispatch()
  const setRequestOptions = dispatch.reports.setRequestOptions
  const getCatalogFilters = dispatch.auditCatalog.getCatalogFilters
  const getScoreTrendsReportColumns = dispatch.reports.fetchScoreTrendReportColumns

  const selectedReportType = useSelector(
    (state: RootState) => state.reports.selectedReportType,
  )
  const filterModel = useSelector(
    (state: RootState) => state.reports.reportsRequestOptions.itemFilterModel,
  )
  const pageModel = useSelector(
    (state: RootState) => state.reports.reportsRequestOptions.pageModel,
  )
  const [selectedReport, setSelectedReport] = useState<SelectOption>(reports[6])
  const [isScoreTrends, setIsScoreTrends] = useState<boolean>(false)
  const [update, setUpdate] = useState<boolean>(false)

  const filtersCount = countFilters(filterModel)

  useEffect(() => {
    getCatalogFilters()

    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    if (isScoreTrends) {
      getScoreTrendsReportColumns(selectedReport.value)
    }
  }, [isScoreTrends])

  useEffect(() => {
    if (selectedReport.value !== selectedReportType) {
      if (isScoreTrends) {
        getScoreTrendsReportColumns(selectedReport.value)
      }
      setUpdate(true)
    }
  }, [selectedReport])

  useEffect(() => {
    setUpdate(true)
  }, [filterModel])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (update) {
        setUpdate(false)
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [update])

  const exportToExcel = () => {
    const payload = new CatalogRequestOptions({
      pageModel: new PageModel({
        ...pageModel,
      }),
      itemFilterModel: filterModel,
    })

    if (filtersCount) {
      if (isScoreTrends) {
        exportFilteredCatalogTrendReportApi(WORKSPACE_ID, selectedReport.value, payload)
      } else {
        exportFilteredCatalogReportsApi(WORKSPACE_ID, selectedReport.value, payload)
      }
    } else {
      if (isScoreTrends) {
        exportCatalogTrendReportApi(WORKSPACE_ID, selectedReport.value)
      } else {
        exportCatalogReportsApi(WORKSPACE_ID, selectedReport.value)
      }
    }
  }

  const exportReport = () => {
    setModal(<ExportReportModal exportAction={exportToExcel} />)
  }

  const updateFilters = (filters: ItemFilterModel) => {
    const options = new CatalogRequestOptions({
      pageModel: new PageModel({
        ...pageModel,
        page: 1,
      }),
      itemFilterModel: filters,
    })

    setRequestOptions(options)
  }

  const resetFilter = () => {
    const emptyFilter = new ItemFilterModel()

    if (JSON.stringify(filterModel) !== JSON.stringify(emptyFilter)) {
      updateFilters(new ItemFilterModel({ searchValue: undefined }))
    }
  }

  return (
    <div className="reports">
      <Header
        title="Reports"
        description="Important! Report data is generated based on what SKUs are showing in the Catalog view."
      />
      <Card
        className="grid gap-y-4"
        style={{ minHeight: "calc(100vh - 240px)" }}
        loading={update}
      >
        <div className="flex justify-between">
          <div>
            <span className="text-caption-1 font-bold">Available Reports</span>
            <div className="w-80 mt-1">
              <Select
                options={reports}
                value={selectedReport.value}
                onChange={setSelectedReport}
              />
            </div>
          </div>
          <div className="flex self-end">
            <label className="switch mr-5">
              <div className={`ml-3 text-body text-gray-500 font-semibold mr-2`}>
                Show Score Trends
              </div>
              <Switch value={isScoreTrends} onChange={setIsScoreTrends} withoutLabel />
            </label>
            <span className="border border-gray-200"></span>
            <Button
              tippyContent="Filters"
              variant="icon-btn"
              className="catalog-filter-btn ml-8"
              onClick={() =>
                setModal(
                  <CatalogFilters
                    initialFilters={filterModel}
                    callback={updateFilters}
                  />,
                )
              }
            >
              <Icon icon={ICONS.FILTER} />
            </Button>
            <Button
              variant="link"
              className="secondary mr-8"
              onClick={resetFilter}
              disabled={filtersCount === 0}
            >
              {`Reset Filter (${filtersCount})`}
            </Button>
            <span className="border border-gray-200"></span>
            <Button
              tippyContent="Export"
              key="excel-export"
              variant="icon-btn"
              onClick={exportReport}
              className="mx-6"
            >
              <Icon icon={ICONS.DOWNLOAD} />
            </Button>
            <ReportsSearch value={filterModel.searchValue} />
          </div>
        </div>

        {!update && (
          <>
            {!isScoreTrends ? (
              <BasicView selectedReport={selectedReport} />
            ) : (
              <ScoreTrendsView selectedReport={selectedReport} />
            )}
          </>
        )}
      </Card>
    </div>
  )
}

export default Reports
