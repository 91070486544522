import { AccuracyAnalysisResultModel } from './AccuracyAnalysisResultModel';

export class UniqueSectionModel {
  public brand: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
  public retailerId: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
  public modelNumber: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
  public manufacturerPartNumber: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
  public upc: AccuracyAnalysisResultModel = {} as AccuracyAnalysisResultModel;
constructor(obj = {} as any) {
  obj = obj || {};
  this.brand = !obj.brand ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.brand);
  this.retailerId = !obj.retailerId ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.retailerId);
  this.modelNumber = !obj.modelNumber ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.modelNumber);
  this.manufacturerPartNumber = !obj.manufacturerPartNumber ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.manufacturerPartNumber);
  this.upc = !obj.upc ? new AccuracyAnalysisResultModel() : new AccuracyAnalysisResultModel(obj.upc);
}

}
