import { RematchDispatch } from "@rematch/core"
import { WORKSPACE_ID } from "api/api"
import {
  deleteApi,
  getAllApi,
  getDefinitionsApi,
  insertApi,
  reTriggerAlertApi,
  testAlertApi,
} from "api/endpoints/alerts.api"
import { AlertDescription } from "common/models/AlertDescription"
import { AlertModel } from "common/models/AlertModel"
import { TestAlertModel } from "common/models/TestAlertModel"
import { updateApi } from "../api/endpoints/alerts.api"

type State = Readonly<{
  definitions: AlertDescription | undefined
  list: AlertModel[]
  groups: string[]
  testResult: TestAlertModel | undefined
  copyAlertId: string
  createAlertRetailerName: string
}>

const model = {
  state: {
    definitions: undefined,
    list: [],
    groups: [],
    testResult: undefined,
    copyAlertId: "",
    createAlertRetailerName: "",
  } as State,
  reducers: {
    definitionsLoaded: (state: State, payload: any): State => ({
      ...state,
      definitions: payload,
    }),
    alertsLoaded: (state: State, payload: any): State => ({
      ...state,
      list: payload ? payload.reverse() : payload,
    }),
    setTestResult: (state: State, payload: any): State => ({
      ...state,
      testResult: payload,
    }),
    setCopyAlertId: (state: State, payload: any): State => ({
      ...state,
      copyAlertId: payload,
    }),
    setCreateAlertRetailerName: (state: State, payload: any): State => ({
      ...state,
      createAlertRetailerName: payload,
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async getDefinitions() {
      const definitions = await getDefinitionsApi(WORKSPACE_ID)

      if (definitions) {
        dispatch.alerts.definitionsLoaded(definitions)
        return definitions
      }
      return false
    },
    async fetchAlerts() {
      const alerts = await getAllApi(WORKSPACE_ID)

      if (alerts) {
        dispatch.alerts.alertsLoaded(alerts)
        return alerts
      }
      return false
    },
    async createAlert(alert: AlertModel) {
      const result = await insertApi(WORKSPACE_ID, alert)

      if (result) {
        this.fetchAlerts()
        return result
      }
      return false
    },
    async updateAlert(alert: AlertModel) {
      const result = await updateApi(WORKSPACE_ID, alert.id || "", alert)

      if (result) {
        this.fetchAlerts()
      }
      return result
    },
    async deleteAlert(alertId: string) {
      const result = await deleteApi(WORKSPACE_ID, alertId)

      if (result) {
        this.fetchAlerts()
      }
      return result
    },
    async testRule(alert: TestAlertModel) {
      const result = await testAlertApi(WORKSPACE_ID, alert)

      if (result) {
        dispatch.alerts.setTestResult(result)
        return result
      }
      return false
    },
    async reTestRules() {
      return await reTriggerAlertApi(WORKSPACE_ID)
    },
  }),
}

export default model
