import { TippyProps } from "@tippyjs/react"
import { Loader } from "components/loader/loader"
import { TippyWrapper } from "components/tippyWrapper/tippyWrapper"
import React, { CSSProperties, ReactNode } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { RootState } from "store"
import "./button.scss"

type Props = {
  children?: React.ReactNode
  onClick?: any
  className?: string
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "outlined"
    | "danger"
    | "success"
    | "link"
    | "icon-btn"
  type?: "button" | "submit" | "reset"
  isSmall?: boolean
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  disabled?: boolean
  title?: string
  loading?: boolean
  style?: CSSProperties
  isActionButton?: boolean
  withOnClickPropagation?: boolean
  tippyContent?: ReactNode
  tippyProps?: Partial<TippyProps>
}

export const Button = React.memo(
  ({
    children,
    className = "",
    variant = "primary",
    onClick,
    type = "button",
    isSmall = false,
    iconLeft = null,
    iconRight = null,
    disabled = false,
    loading = false,
    isActionButton = false,
    title = "",
    style = {},
    withOnClickPropagation = false,
    tippyContent,
    tippyProps,
    ...rest
  }: Props) => {
    const workspaceAssignedPlans = useSelector(
      (state: RootState) => state.plans.selectedWorkspaceAssignedPlans,
    )
    const location = useLocation()

    const isDisabledButton = () => {
      let isDisabled = false

      if (!workspaceAssignedPlans || workspaceAssignedPlans.length === 0) {
        if (typeof children === "string") {
          if (
            children.toLowerCase().includes("new") ||
            children.toLowerCase().includes("submit") ||
            children.toLowerCase().includes("test") ||
            children.toLowerCase().includes("save") ||
            children.toLowerCase().includes("export") ||
            children.toLowerCase().includes("flagged report") ||
            children.toLowerCase().includes("delete")
          ) {
            if (
              !location.pathname.includes("billing") &&
              !location.pathname.includes("plans") &&
              !location.pathname.includes("workspaces") &&
              !location.pathname.includes("user") &&
              !location.pathname.includes("available") &&
              !location.pathname.includes("onboarding")
            ) {
              isDisabled = true
            }
          }
        } else {
          if (isActionButton) {
            if (
              !location.pathname.includes("billing") &&
              !location.pathname.includes("plans") &&
              !location.pathname.includes("workspaces") &&
              !location.pathname.includes("user") &&
              !location.pathname.includes("available") &&
              !location.pathname.includes("onboarding")
            ) {
              isDisabled = true
            }
          }
        }
      }

      return isDisabled
    }

    return (
      <TippyWrapper content={tippyContent} tippyProps={tippyProps}>
        <button
          type={type}
          style={isSmall ? { height: 26, ...style } : style}
          title={title}
          className={`button ${disabled || isDisabledButton() ? "disabled" : ""} ${
            type === "submit" ? "submit" : ""
          } ${loading ? "loading" : ""} ${variant} ${className}`}
          disabled={disabled || loading || isDisabledButton()}
          onClick={(e) => {
            !withOnClickPropagation && e.stopPropagation()
            onClick && onClick(e)
          }}
          {...rest}
        >
          <span className={`btn-content ${loading ? "opacity-0" : ""}`}>
            {iconLeft}
            {children}
            {iconRight}
          </span>
          <span className={`absolute ${loading ? "opacity-100" : "opacity-0"}`}>
            {loading && <Loader size={7} />}
          </span>
        </button>
      </TippyWrapper>
    )
  },
)
