import { RuleScoreCardModel } from "common/models/RuleScoreCardModel"
import { SectionScoreCardModel } from "common/models/SectionScoreCardModel"
import { ImageComponent } from "components/image/image"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { toUTC, scoreToColor } from "utils"

const getCard = (row: any, name: string, subName?: string) => {
  const section = row.currentSnapshotScoreCard?.[name]
  if (section && subName) {
    return section?.[subName] || { score: 0 }
  } else {
    return section || { score: 0 }
  }
}

export const ScoreRender = ({ scorecard }: { scorecard?: any }) => {
  const scoreColor = () => {
    if (!scorecard) {
      return scoreToColor(null)
    }

    if (!scorecard.name) {
      return scoreToColor(scorecard.score)
    }

    if (!scorecard?.isActive) {
      if (scorecard.score === 0 || scorecard.score === null) {
        return scoreToColor(null)
      } else {
        return scoreToColor(scorecard.score)
      }
    }

    return scoreToColor(scorecard.score)
  }

  return (
    <div
      className="text-white p-2 rounded font-semibold text-center w-auto"
      style={{
        backgroundColor:
          scorecard?.isActive !== undefined && !scorecard?.isActive
            ? scoreToColor(null)
            : scoreColor(),
      }}
    >
      <div className={`item items-center flex flex-row justify-center`}>
        {scorecard && scorecard.score
          ? (Math.trunc(scorecard.score * 10) / 10).toFixed(1)
          : "0.0"}
      </div>
    </div>
  )
}

export const ImageArray = ({
  pipeSeparatedImageURLs,
}: {
  pipeSeparatedImageURLs: string
}) => {
  const tooltipInstance = useSelector((state: RootState) => state.tippyTarget.target)
  const imageContainerRef = useRef(null)
  const [width, setWidth] = useState(0)
  const [numberOfDisplayedImages, setNumberOfDisplayedImages] = useState(0)

  const imageList = pipeSeparatedImageURLs?.split("|") || []

  const numberOfImages = imageList.length

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentBoxSize) {
          setWidth(entry.contentRect.width)
        }
      }
    })

    if (imageContainerRef.current) {
      observer.observe(imageContainerRef.current)
    }

    return () => {
      if (imageContainerRef.current) {
        observer.unobserve(imageContainerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (width) {
      setNumberOfDisplayedImages(Math.trunc(width / 40) - 1)
    }
  }, [width])

  return (
    <div className="flex w-full" ref={imageContainerRef}>
      {imageList &&
        imageList.slice(0, numberOfDisplayedImages).map((imageURL) => {
          if (imageURL === "" || imageURL === " ") {
            return
          }

          return (
            <ImageComponent
              src={imageURL}
              alt={imageURL}
              tooltipInstance={tooltipInstance}
              tooltipPlacement={"left"}
              className="h-10 w-10 min-w-10 rounded border border-gray-100"
            />
          )
        })}
      {numberOfImages > numberOfDisplayedImages && (
        <>
          {numberOfImages - numberOfDisplayedImages - 1 > 0 &&
          imageList
            .slice(numberOfDisplayedImages)
            .some((imageURL) => imageURL.trim() === "") ? (
            <div className="flex items-center pl-2">{`+${
              numberOfImages - numberOfDisplayedImages - 1
            }`}</div>
          ) : (
            imageList
              .slice(numberOfDisplayedImages)
              .some((imageURL) => imageURL.trim() !== "") && (
              <div className="flex items-center pl-2">{`+${
                numberOfImages - numberOfDisplayedImages
              }`}</div>
            )
          )}
        </>
      )}
    </div>
  )
}

export const renderObject = {
  "currentSnapshot.data.productUrl": (row: any) => {
    return <>{row.currentSnapshot?.data.productUrl}</>
  },
  "itemMonitor.previousItemSnapshot.data.flattenedImages": (row: any) => {
    return (
      <ImageArray
        pipeSeparatedImageURLs={
          row.itemMonitor?.previousItemSnapshot?.data?.flattenedImages
        }
      />
    )
  },

  "masterVersionProductData.flattenedImages": (row: any) => {
    return (
      <ImageArray
        pipeSeparatedImageURLs={row.masterVersionProductData?.flattenedImages}
      />
    )
  },

  itemIdentifier: (row: any) => {
    return <>{row.retailerName.startsWith("Amazon") ? row.itemIdentifier : ""}</>
  },
  status: (row: any) => {
    const productURL = row?.currentSnapshot?.data?.productUrl
    const status = row?.status
    const isLive = status === "Live"

    return (
      <div
        className={`text-gray-50 py-0.5 px-1.5 mx-auto font-medium rounded text-center w-min uppercase ${
          isLive ? "bg-green-500" : "bg-red-500"
        }`}
        onClick={(e) => {
          e.stopPropagation()
          window.open(productURL, "_blank")
        }}
      >
        {status}
      </div>
    )
  },
  "currentSnapshot.data.msrp": (row: any) => (
    <>
      {row.currentSnapshot?.data.msrp.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}
    </>
  ),
  "currentSnapshot.data.price": (row: any) => (
    <>
      {row.currentSnapshot?.data.price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}
    </>
  ),
  "currentSnapshot.createdAt": (row: any) => toUTC(row.currentSnapshot.createdAt),
  "currentSnapshot.data.recordType": () => <>Product</>,
  "currentSnapshot.data.isPromotion": (row: any) => (
    <span>{row.currentSnapshot?.data.isPromotion ? "Yes" : "No"}</span>
  ),
  "currentSnapshot.data.subscribeAndSave": (row: any) => (
    <span>{row.currentSnapshot?.data?.subscribeAndSave ? "Yes" : "No"}</span>
  ),
  "currentSnapshotScoreCard.score": (row: any) => (
    <ScoreRender scorecard={row.currentSnapshotScoreCard} />
  ),
  "currentSnapshotScoreCard.marketing.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "marketing")} />
  ),
  "currentSnapshotScoreCard.marketing.titleScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "marketing", "titleScore")} />
  ),
  "currentSnapshotScoreCard.marketing.descriptionScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "marketing", "descriptionScore")} />
  ),
  "currentSnapshotScoreCard.marketing.bulletsScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "marketing", "bulletsScore")} />
  ),
  "currentSnapshot.descriptionSectionCharCount": (row: any) => {
    return (
      <>
        {row.currentSnapshot.snapshotCounts?.descriptionSectionCharCount
          ?.map((item: any) => `${Object.keys(item)[0]}: ${Object.values(item)[0]}`)
          .join(" | ")}
      </>
    )
  },
  "currentSnapshot.snapshotCounts.bulletsCharCount": (row: any) => {
    return (
      <>
        {row.currentSnapshot.snapshotCounts?.bulletsCharCount
          ?.map((bullet: any, index: number) => `${index} : ${bullet[index]}`)
          .join(", ")}
      </>
    )
  },
  "currentSnapshotScoreCard.assets.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "assets")} />
  ),
  "currentSnapshotScoreCard.assets.documentsScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "assets", "documentsScore")} />
  ),
  "currentSnapshotScoreCard.assets.imagesScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "assets", "imagesScore")} />
  ),
  "currentSnapshotScoreCard.assets.videosScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "assets", "videosScore")} />
  ),
  "currentSnapshotScoreCard.richContent.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "richContent")} />
  ),
  "currentSnapshotScoreCard.richContent.videosScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "richContent", "videosScore")} />
  ),
  "currentSnapshotScoreCard.richContent.documentsScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "richContent", "documentsScore")} />
  ),
  "currentSnapshotScoreCard.richContent.product360ViewScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "richContent", "product360ViewScore")} />
  ),
  "currentSnapshotScoreCard.richContent.enhancedContentScore.score": (row: any) => {
    return <ScoreRender scorecard={getCard(row, "richContent", "enhancedContentScore")} />
  },
  "currentSnapshot.data.has360View": (row: any) => (
    <span>{row.currentSnapshot?.data.has360View ? "Yes" : "No"}</span>
  ),
  "currentSnapshot.data.hasEnhancedContent": (row: any) => (
    <span>{row.currentSnapshot?.data.hasEnhancedContent ? "Yes" : "No"}</span>
  ),
  "currentSnapshot.data.fitment": (row: any) => (
    <span>{row.currentSnapshot?.data?.fitment ? "Yes" : "No"}</span>
  ),
  "currentSnapshot.data.aPlusModuleType": (row: any) => (
    <span>{row.currentSnapshot?.data.aPlusModuleType}</span>
  ),
  "currentSnapshotScoreCard.sentiment.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "sentiment")} />
  ),
  "currentSnapshotScoreCard.sentiment.ratingsCountScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "sentiment", "ratingsCountScore")} />
  ),
  "currentSnapshotScoreCard.sentiment.reviewsCountScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "sentiment", "reviewsCountScore")} />
  ),
  "currentSnapshotScoreCard.keywords.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "keywords")} />
  ),
  "currentSnapshotScoreCard.keywords.titleScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "keywords", "titleScore")} />
  ),
  "currentSnapshotScoreCard.keywords.descriptionScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "keywords", "descriptionScore")} />
  ),
  "currentSnapshotScoreCard.keywords.bulletsScore.score": (row: any) => (
    <ScoreRender scorecard={getCard(row, "keywords", "bulletsScore")} />
  ),
  "currentSnapshot.data.specifications": (row: any) => {
    const specifications = row.currentSnapshot?.data?.specifications
      ?.map((specification: any) => {
        return `${specification.name} :: ${specification.value}`
      })
      .join(" | ")
    return <>{specifications}</>
  },
  primarykeywords: (row: any) => <span>{row.primaryKeywords.join(", ")}</span>,
  secondarykeywords: (row: any) => <span>{row.secondaryKeywords.join(", ")}</span>,
  amazonSellers: (row: any) => (
    <span>{row.currentSnapshot?.data.amazonSellers.join(", ")}</span>
  ),
}
