import { ItemFilterModel } from "common/models/ItemFilterModel"
import { FC } from "react"
import "./alert-score-options.scss"
import {
  ValueSignOption,
  SIGN,
} from "../health-score-options/value-sign-option/value-sign-option"
import { AlertFilterModel } from "common/models/AlertFilterModel"

type Props = {
  filters: ItemFilterModel
  setFilters: (filters: ItemFilterModel) => void
}

type AlertOption = {
  name: string
  value: number
  sign: SIGN
  checked: boolean
}

export const AlertsScoreOptions: FC<Props> = ({ filters, setFilters }: Props) => {
  const getAlertIdentifier = (name: string) => {
    return name.includes("Total")
      ? "flagsCount"
      : name.includes("Warning")
      ? "warningFlagsCount"
      : name.includes("Critical")
      ? "criticalFlagsCount"
      : "indicatorFlagsCount"
  }

  const getFilterIndex = (item: AlertFilterModel): number => {
    return filters.alertFilterModels
      ? filters.alertFilterModels.findIndex(
          (filter) => item.alertIdentifier === filter.alertIdentifier,
        )
      : -1
  }

  const setAlertScore = (item: AlertOption) => {
    const alertScoreFilter = new AlertFilterModel({
      alertIdentifier: getAlertIdentifier(item.name),
      operator: item.sign,
      value: Number(item.value),
    })
    const alertFilterModels = filters.alertFilterModels
      ? [...filters.alertFilterModels]
      : []
    const index = getFilterIndex(alertScoreFilter)

    if (index === -1) {
      if (item.checked) {
        alertFilterModels.push(alertScoreFilter)
      }
    } else {
      if (item.checked) {
        alertFilterModels[index] = alertScoreFilter
      } else {
        alertFilterModels.splice(index, 1)
      }
    }

    setFilters({
      ...filters,
      alertFilterModels,
    })
  }

  const isChecked = (name: string) => {
    return !!filters.alertFilterModels?.find(
      (alertFilter) => alertFilter.alertIdentifier === getAlertIdentifier(name),
    )
  }

  const getValueAndSign = (name: string) => {
    const alertFilter = filters.alertFilterModels?.find(
      (filter) => filter.alertIdentifier === getAlertIdentifier(name),
    )

    if (alertFilter) {
      return {
        value: alertFilter.value,
        sign: alertFilter.operator as SIGN,
      }
    }

    return { value: 5, sign: SIGN.LT }
  }

  return (
    <div className="flag-score-options">
      <ValueSignOption
        name="Total flagged items is"
        {...getValueAndSign("Total flagged items is")}
        checked={isChecked("Total flagged items is")}
        onChange={(value) => {
          setAlertScore(value)
        }}
      />
      <ValueSignOption
        name="Warning flags is"
        {...getValueAndSign("Warning flags is")}
        checked={isChecked("Warning flags is")}
        onChange={(value) => {
          setAlertScore(value)
        }}
      />
      <ValueSignOption
        name="Critical flags is"
        {...getValueAndSign("Critical flags is")}
        checked={isChecked("Critical flags is")}
        onChange={(value) => {
          setAlertScore(value)
        }}
      />
      <ValueSignOption
        name="Indicator flags is"
        {...getValueAndSign("Indicator flags is")}
        checked={isChecked("Indicator flags is")}
        onChange={(value) => {
          setAlertScore(value)
        }}
      />
    </div>
  )
}
