import React, { Suspense, useEffect } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { abortController, SELECTED_WORKSPACE_ID } from "api/api"
import { Button } from "components/button/button"
import { SuspenseLoader } from "../../components/suspense-loader/suspense-loader"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { lazyLoadRetry } from "../../utils/lazyLoadRetry"
import "./dashboard.scss"

const DashboardCards = React.lazy(
  lazyLoadRetry(() => import("./dashboard-cards/dashboard-cards")),
)

export const Dashboard = () => {
  const navigate = useNavigate()
  const isViewer = useSelector(
    (state: RootState) => state.workspaces.isSelectedWorkspaceViewer,
  )

  const quickLinks = [
    {
      title: "Add a Team Member",
      link: `/main/workspaces/${localStorage.getItem(
        SELECTED_WORKSPACE_ID,
      )}/edit/team-composition`,
      disabled: false,
    },
    {
      title: "New Audit",
      link: "/main/audits/new-audit#1",
      disabled: isViewer,
    },
  ]

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className="dashboard">
      <div className="dashboard-menu">
        <div className="dashboard-menu-title header-title">Quick Links</div>
        <div className="dashboard-menu-items">
          {quickLinks.map((menuItem: any) => (
            <Button
              key={menuItem.title}
              variant="link"
              className="dashboard-menu-item"
              onClick={() => navigate(menuItem.link)}
              disabled={menuItem.disabled}
            >
              {menuItem.title}
            </Button>
          ))}
        </div>
      </div>
      <div className="dashboard-content">
        <Suspense fallback={<SuspenseLoader delay={500} />}>
          <Routes>
            <Route path="/" element={<DashboardCards />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  )
}
