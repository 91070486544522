import { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"

import "./enter-keywords-modal.scss"

import { useModal } from "context/modal-context"
import { Button } from "components/button/button"
import { KeywordsAccordionBodyColumn } from "pages/catalogs/audit-catalog/keywords-modal/keywords-body-column/keywords-body-column"
import { AddInBulkKeywordsTitle } from "components/add-in-bulk-title/add-in-bulk-title"
import { RootState } from "store"
import { ImageComponent } from "components/image/image"
import { Icon, ICONS } from "components/icon/icon"
import { KeywordsModalHeader } from "pages/catalogs/audit-catalog/keywords-modal/utils/keywords-headers"

const JOIN_STRING = "%"

type EnterKeywordsModalProps = {
  primaryKeywords: string[]
  secondaryKeywords: string[]
  setPrimaryKeywords: (keywordList: string[]) => void
  setSecondaryKeywords: (keywordList: string[]) => void
}

export const EnterKeywordsModal: FC<EnterKeywordsModalProps> = ({
  primaryKeywords,
  secondaryKeywords,
  setPrimaryKeywords,
  setSecondaryKeywords,
}: EnterKeywordsModalProps) => {
  const { unSetModal } = useModal()
  const [bulkPrimaryKeywords, setBulkPrimaryKeywords] = useState<string[]>([])
  const [bulkSecondaryKeywords, setBulkSecondaryKeywords] = useState<string[]>([])
  const [primaryKeywordsString, setPrimaryKeywordsString] = useState("")
  const [secondaryKeywordsString, setSecondaryKeywordsString] = useState("")
  const [canSave, setCanSave] = useState(false)

  const selectedAudit = useSelector((state: RootState) => state.audits.selectedAudit)
  const retailers = useSelector((state: RootState) => state.retailers.allRetailers)

  const retailer = retailers.find((retailer) => retailer.id === selectedAudit?.retailerId)

  function onSubmit() {
    setPrimaryKeywords(bulkPrimaryKeywords)
    setSecondaryKeywords(bulkSecondaryKeywords)
    unSetModal()
  }

  useEffect(() => {
    setBulkPrimaryKeywords(primaryKeywords)
    setPrimaryKeywordsString(primaryKeywords.join(JOIN_STRING))
    // eslint-disable-next-line no-sparse-arrays
  }, [, primaryKeywords])

  useEffect(() => {
    setBulkSecondaryKeywords(secondaryKeywords)
    setSecondaryKeywordsString(secondaryKeywords.join(JOIN_STRING))
    // eslint-disable-next-line no-sparse-arrays
  }, [, secondaryKeywords])

  useEffect(() => {
    let hasKeywordsChanged = false

    const bulkPrimaryKeywordsString = bulkPrimaryKeywords.join(JOIN_STRING)
    const bulkSecondaryKeywordsString = bulkSecondaryKeywords.join(JOIN_STRING)

    if (
      primaryKeywordsString !== bulkPrimaryKeywordsString ||
      secondaryKeywordsString !== bulkSecondaryKeywordsString
    ) {
      hasKeywordsChanged = true
    }

    setCanSave(hasKeywordsChanged)
  }, [bulkPrimaryKeywords, bulkSecondaryKeywords])

  return (
    <div className="enter-keywords-modal-container">
      <KeywordsModalHeader />

      <div className="enter-keywords-modal-body">
        <div className="keywords-header">
          <div className="flex justify-center items-center gap-4">
            {retailer && (
              <>
                <div className="flex justify-center items-center">
                  {retailer.logoUrl && (
                    <ImageComponent
                      popover={false}
                      className="h-8 w-12"
                      src={retailer.logoUrl}
                      alt="Product"
                    />
                  )}
                </div>
                {retailer.name}
              </>
            )}
          </div>
          <div className="flex gap-3 items-center">
            <AddInBulkKeywordsTitle
              total={bulkPrimaryKeywords?.length + bulkSecondaryKeywords?.length}
              primary={bulkPrimaryKeywords?.length}
              secondary={bulkSecondaryKeywords?.length}
            />
            <Icon className="text-gray-300" icon={ICONS.CHEVRON_UP} />
          </div>
        </div>
        <div className="keywords-body-container">
          <KeywordsAccordionBodyColumn
            title="Primary Keywords"
            keywords={bulkPrimaryKeywords}
            setKeywords={setBulkPrimaryKeywords}
          />
          <KeywordsAccordionBodyColumn
            title="Secondary Keywords"
            keywords={bulkSecondaryKeywords}
            setKeywords={setBulkSecondaryKeywords}
          />
        </div>
      </div>

      <div className="enter-keywords-modal-buttons">
        <span className="flex justify-start">
          <Button variant="outlined" type="submit" onClick={unSetModal}>
            Cancel
          </Button>
        </span>

        <span className="flex justify-end">
          <Button disabled={!canSave} variant="success" type="reset" onClick={onSubmit}>
            Save
          </Button>
        </span>
      </div>
    </div>
  )
}
