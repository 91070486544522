import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { RootState } from "store"
import "./switch.scss"

type Props = {
  label?: string
  name?: string
  value?: boolean
  disabled?: boolean
  className?: string
  readonly?: boolean
  required?: boolean
  onChange?: any
  onLabel?: string
  offLabel?: string
  withoutLabel?: boolean
}

export const Switch: React.FC<Props> = React.memo(
  ({
    label,
    name,
    value = false,
    readonly,
    className = "",
    disabled,
    required,
    onChange = () => {},
    onLabel,
    offLabel,
    withoutLabel = false,
  }) => {
    const formContext = useFormContext()
    const { register, setValue, control } = formContext || {}

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(name as string, e.target.checked, {
        shouldValidate: true,
        shouldDirty: true,
      })
    }

    const workspaceAssignedPlans = useSelector(
      (state: RootState) => state.plans.selectedWorkspaceAssignedPlans,
    )
    const location = useLocation()

    const isDisabledButton = () => {
      let isDisabled = false

      if (workspaceAssignedPlans.length === 0) {
        if (
          !location.pathname.includes("billing") &&
          !location.pathname.includes("plans") &&
          !location.pathname.includes("workspaces") &&
          !location.pathname.includes("user")
        ) {
          isDisabled = true
        }
      }

      return isDisabled
    }

    return (
      <label
        className={`switch ${disabled ? "disabled" : ""}${
          withoutLabel ? " withoutLabel" : ""
        } ${className}`}
      >
        <div className="switch-control">
          {name && control ? (
            <Controller
              name={name}
              control={control}
              rules={{ required }}
              render={({ field }) => (
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={field.value}
                  {...register(name)}
                  onChange={handleChange}
                  disabled={disabled || readonly || isDisabledButton()}
                />
              )}
            />
          ) : (
            <input
              type="checkbox"
              className="sr-only"
              checked={value}
              onChange={(e: any) => onChange(e.target.checked)}
              disabled={disabled || readonly || isDisabledButton()}
            />
          )}
          <div className="switch-background" />
          <div className="dot" />
          {!withoutLabel && (
            <>
              <span className="switch-on-label">
                {onLabel !== undefined ? onLabel : "ON"}
              </span>
              <span className="switch-off-label">
                {offLabel !== undefined ? offLabel : "OFF"}
              </span>
            </>
          )}
        </div>
        {label !== undefined && (
          <div className="ml-3 text-gray-700 font-medium">{label}</div>
        )}
      </label>
    )
  },
)
